
import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import html2canvas from 'html2canvas'; 
import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
import { LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.css']
})
export class BalanceSheetComponent implements OnInit {

  fileName = 'balance_sheet.xlsx';


  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  date_as_on = new Date();
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_type = '1';
  vch_filter: any;
  ret_ref_filter: any;



  dayList: string[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: 'as_on', name: 'As on' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'Summary' },
    { id: '2', name: 'Group wise' },
    { id: '3', name: 'Detailed' },

  ];
  filterPurchrate = [
    { id: 'last', name: 'Last Purchase Rate' },
    { id: 'avg', name: 'Avg. Purchase Rate' },
    // { id: 'avg_by_date', name: 'Avg. Purchase Rate By Date' }
  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  group_all: any;
  ledgerList: any;
  ledgerList1: any;
  total_liab: any;
  total_assets: any;
  total_liab_ledger: any;
  total_assets_ledger: any;

  reportData: any;
  diffInOpengBal: any;

  math = Math;
  disp_purch_rate = 'last';
  period_type = 'as_on';
  ExpledgerList: any;
  ExpdiffInOpengBal: any;
  Exptotal_debit: any;
  Exptotal_credit: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_all: any;
  userType: any;
  netDiff: any;
  isSuperPriv = false;
  superPassword = '';
  accLoginInProcess = false;
  loginErrMsg = '';
  filter_branch_id: any;
  headerImage = '';
  country_dec: string;
  display_sub_total = false;
  constructor(private apiService: ApiService, private coreService: CoreService, private currencyPipe: CurrencyPipe,
              private excelService: ExcelService, private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
   this.getHeaderImage();
  }

  
  langChange(){
    this.translate.get(['Common.as_on', 'Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','ACCOUNTS.Group_wise','Common.detailed','Common.last_purchase_rate','Common.average_purchase_rate','Common.avg_purchase_rate_by_date']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    // { id: 't', name: res['Common.today'] },
    // { id: 'ld', name: res['Common.last_day'] },
    // { id: 'lw', name: res['Common.last_week'] },
    // { id: 'lm', name: res['Common.last_maonth'] },
    // { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: 'as_on', name: res['Common.as_on'] },
    // { id: '', name: res['Common.all'] }
        
      ];

      this.filter_purchtypes = [
        { id: '1', name: res['Common.summary'] },
        { id: '2', name: res['ACCOUNTS.Group_wise'] },
        { id: '3', name: res['Common.detailed'] },
    
      ];
      this.filterPurchrate = [
        { id: 'last', name: res['Common.last_purchase_rate'] },
        { id: 'avg', name: res['Common.average_purchase_rate'] },
        // { id: 'avg_by_date', name: res['Common.avg_purchase_rate_by_date'] }
      ];
    });

  }
  ngOnDestroy() {
    $('.close').click();

  }

  checkEnterClicked(keycode) {
   // console.log(keycode);
    if (keycode.keyCode === 13) {
      this.superAccess();
    }
  }
  superAccess() {
    this.loginErrMsg = '';
    this.accLoginInProcess = true;
    this.apiService.superAccess({superPassword : this.superPassword}).subscribe((res: any) => {
      this.accLoginInProcess = false;
      if (res.status) {
        localStorage.setItem('spracs', res.passcode);
        this.isSuperPriv = true;
        this.userType = this.coreService.getUserData('user_type');
        this.getAllBranch();
        this.pageLoadingImg = true;

        const searchval = new FormData();
        this.getBalanceSheetData(searchval, 1);

        this.branch_display_name = this.coreService.getUserData('branch_disp_name');
        this.branch_name = this.coreService.getUserData('branch_name');
        this.branch_display_code = this.coreService.getUserData('branch_code');
      } else {
        this.loginErrMsg = res.msg;
      }
    });
  }

  getBalanceSheetData(searchval, pageNo) {
    this.ledgerList = [];
    // this.ledgerList1 = [];
    this.total_liab = 0;
    this.total_assets = 0;
    this.total_liab_ledger = 0;
    this.total_assets_ledger = 0;
    // this.total_indir_exp = 0;
    // this.total_indir_inc = 0;
    // this.netDiff = 0;
    this.pageLoadingImg = true;
    this.apiService.getBalanceSheetReport(searchval, pageNo).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      // this.ledgerList1 = res.data1.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_liab = res.info.total_liab;
      this.total_assets = res.info.total_assests;
      this.total_liab_ledger = res.info.total_liab_ledger;
      this.total_assets_ledger = res.info.total_assests_ledger;
      // this.total_indir_exp = res.info.total_indir_exp;
      // this.total_indir_inc = res.info.total_indir_inc;
      // this.netDiff = res.info.netDiff;
      this.diffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }



  pageNext(formdata: { value: any; }, pageno: any) {




    $('.resultdata').empty();
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('pageno', pageno);

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    if (formdata.value.period_type === 'as_on') {
      formdata.value.date_as_on = (formdata.value.date_as_on) ? this.apiService.formatDate(formdata.value.date_as_on) : formdata.value.date_as_on;
    }

    this.getBalanceSheetData(formdata.value, pageno);
  }




  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res: any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;

      // console.log(  this.branch_all );

    });
  }
  searchBranch(search: string) {
    const searchval = new FormData();
    searchval.append('branch_name', search);
    this.apiService.getBranchByName(searchval).subscribe((res: any) => {
      this.branch_all = res.data;

    });
  }







  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.voucherType = res.data;
    });
  }

  searchLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.getLedByName(searchval).subscribe((res: any) => {
      this.ledger_all = res.data;
    });
  }

  searchAccGroup(search: string) {
    const searchval = new FormData();
    searchval.append('name', search);
    this.apiService.getAccGroup(searchval).subscribe((res: any) => {
      this.group_all = res.data;

      console.log(this.group_all);
    });
  }



  // form search
  searchUser(search: string) {
    const searchval = new FormData();
    searchval.append('usr_name', search);
    this.apiService.getUserByName(searchval).subscribe((res: any) => {
      this.users = res.data;
    });
  }



  pgEnd(curr, end) {
    if (curr === end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr === 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  grpSummaryexport() {

  }

  openPDF() {
    $('#contentToConvert').show();

    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      // 208
      const pageHeight = 495;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/jpeg', 1.0);
      // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      // var position = 0;
      // pdf.addImage(contentDataURL, 'JPG', 0, position, imgWidth, imgHeight);

      // pdf.save('Account Group Summary Report.pdf'); // Generated PDF

    });

    $('#contentToConvert').hide();


  }

   // To get Header image which will be the part of pdf report
   getHeaderImage() {
    this.apiService.getSettings().subscribe((res) => {
      if (res.data.prnt_header_logo) {
        this.headerImage = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.headerImage = ''; // default
      }

      if (res.data.prnt_header_type === 2) {
        this.headerImage = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.headerImage = '';
      }

    });
  }

 exportPDF() {
  const heads = [this.translate.instant('ACCOUNTS.Particulars'),this.translate.instant('ACCOUNTS.liabilities'), this.translate.instant('ACCOUNTS.assets')];
  const data = [];
  const headerHeight = 52;

  this.ledgerList.forEach(item => {
    if ( item.display) {
      const arr = [item.accgrp_name, item.balance < 0 ? this.currencyPipe.transform(this.math.abs(item.balance), '', '', this.country_dec) : '',
       item.balance > 0 ? this.currencyPipe.transform(item.balance, '', '', this.country_dec) : '' ];
      data.push(arr);
    }

    if ( this.disp_type === '3') {
      item.ledgers.forEach(subledgers => {
          if (subledgers.balance !== 0) {
           const arr1 = ['    ' + subledgers.ledger_name, subledgers.balance < 0 ?
            this.currencyPipe.transform(this.math.abs(subledgers.balance), '', '', this.country_dec) : '',
           subledgers.balance > 0 ? this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec) : '' ];
           data.push(arr1);
          }
      });
    }

    if ( item.sub_groups.length !== 0 && this.disp_type !== '1') {
      item.sub_groups.forEach(sub => {
        if (sub.display) {
          const arr3 = ['    ' + sub.accgrp_name, sub.balance < 0 ? this.currencyPipe.transform(this.math.abs(sub.balance), '', '', this.country_dec) : '',
          sub.balance > 0 ? this.currencyPipe.transform(sub.balance, '', '', this.country_dec) : '' ];
          data.push(arr3);
        }

        if (this.disp_type === '3') {
          sub.ledgers.forEach(subitem => {
            if (subitem.balance !== 0) {
              const col2 = subitem.balance < 0 ? this.currencyPipe.transform(this.math.abs(subitem.balance), '', '', this.country_dec) :
               this.currencyPipe.transform(subitem.balance, '', '', this.country_dec);
              const arr4 = ['        ' + subitem.ledger_name, subitem.balance < 0 ?
               this.currencyPipe.transform(this.math.abs(subitem.balance), '', '', this.country_dec) : '',

              subitem.balance > 0 ? this.currencyPipe.transform(subitem.balance, '', '', this.country_dec) : ''];
              data.push(arr4);
            }
          });
        }
    });
    }
  });

  const lblXPos = 18;

  let doc = this.excelService.getPdfObj();

  doc.setFont('Amiri');
  doc.setFontSize(12);
  doc.text('BALANCE SHEET', 85, headerHeight - 2);

  doc.setFont('Amiri');
  doc.setFontSize(10);
  doc.setTextColor(195, 196, 195);
  doc.text(`${this.repDate.date1} to ${this.repDate.date2}`,  80, headerHeight + 2);
  doc.setTextColor(0, 0, 0);

  if (this.ledgerList.length > 0 && !this.pageLoadingImg ) {
    const arr6 = [this.translate.instant('HOME.total'), this.currencyPipe.transform(this.math.abs(this.total_liab), '', '', this.country_dec),
     this.currencyPipe.transform(this.total_assets, '', '', this.country_dec)];
    data.push(arr6);
  }

  doc = this.excelService.addTableToPdf(doc, headerHeight + 5, lblXPos, heads, data, this.headerImage, this.branch_display_name);

  doc.setDrawColor(0, 0, 0);
  // tslint:disable-next-line:no-string-literal
  const tblFooterY = doc['lastAutoTable'].finalY + 5;
  doc.line(lblXPos, tblFooterY - 11, lblXPos + 178, tblFooterY - 11);
  doc.save(`balance_sheet.pdf`);

 }

  exportAsXLSX(formdata: { value: any; }): void {

    this.apiService.groupSummaryToatalReport(formdata.value).subscribe((res: any) => {
      this.reportData = res.data;
      console.log(this.reportData);
    });

    this.excelService.exportAsExcelFile(this.reportData, 'Account Group Summary Report');
  }



  exportexcel(): void {
    /* table id is passed over here */
    const element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }


  export(formdata: { value: any; }) {

    this.exportexcel();

  }

}
