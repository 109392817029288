import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-sales-void',
  templateUrl: './sales-void.component.html',
  styleUrls: ['./sales-void.component.css']
})

export class SalesVoidComponent implements OnInit {

  pageLoadingImg: boolean;
  showdata: boolean;
  resultobj: any;
  purchaseItems: any;
  sales_inv_no: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  savedTemplate = {
    title_text: 'TAX INVOICE  فاتورة ضريبية فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: '',
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  }
  payTypes = ['Cash', 'Credit', 'Bank / Card','Both'];
  country_dec: string;
  cmp_tax: any;
  logoImgUrl: string;
  footImgUrl: string;
  tempImgUrl: string;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }


  ngOnInit() {
    this.pageLoadingImg = false;
    this.showdata = false;
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.country_dec=this.coreService.setDecimalLength();
    this.getTemplete();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
      
    });
  }


  sale_search(search: string) {

    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
        console.log(this.saleData);
        console.log(this.saleData.sales_flags);
      }


    });
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }
      this.footImgUrl = (res.data.prnt_footer_img_path) ? this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path : '';
      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }


  salesVoid(purchId: any) {
    
    const searchval = new FormData();
    searchval.append('sales_inv_num', purchId);
    this.apiService.salesDet(searchval).subscribe((res) => {
         
      console.log("res");
      console.log(res);

    

    if(res){
      var voidstk = confirm("This Sales converted from delivery note , Are you sure  want to Void this Sale and Delivery Note ? ");
    }
    else{
      var voidstk = confirm("Are you sure  want to Void this Sale ? ");
    }

   
    if (voidstk) {
      this.pageLoadingImg = true;

      const searchval = new FormData();
      searchval.append('sales_inv_num', purchId);
      this.apiService.salesVoid(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
       
        if (res.error != null) {
          this.saleData.is_returned = 1;
      }else{
        
        searchval.append('sales_inv_no', purchId);
        this.apiService.getSalesInv(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
    
          if (res.error != null) {
            this.resultobj = res.error;
            this.purchaseItems = [];
            this.saleData = {
              sales_id: null,
              sales_inv_no: null,
              error: null,
              message: null,
              data: null,
    
            };
            this.resultobj = this.coreService.getValidation(this.resultobj);
          } else {
            this.showdata = true;
    
            this.resultobj = {};
            this.purchaseItems = res.data;
            this.saleData = res.data;
          }
    
    
        });
        this.coreService.showMessage(res.message);
      }
      });
      this.apiService.updatebatchExNotification();
    }

  });
  }



}
