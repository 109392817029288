import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-purchase-return-void',
  templateUrl: './purchase-return-void.component.html',
  styleUrls: ['./purchase-return-void.component.css']
})
export class PurchaseReturnVoidComponent implements OnInit {
  resultobj: any = {};
  purchData: any = {};
  purchDataItems: [];
  netPrice: any;
  netAmount: any;
  netTax: any;
  totalAmount: any;
  visibility = true;
  purch_amount: any;
  pageLoadingImg:boolean;
  up: any;
  country_dec: string;
  cmp_tax: any;
  cust_state_id: any;
  ledger: any;
  branch_state: any;
  igst: boolean=false;

  constructor(private apiService: ApiService, private coreService: CoreService,private datePipe: DatePipe,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.up = this.coreService.getUserPrivilage();
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.country_dec=this.coreService.setDecimalLength();
    if(this.cmp_tax==2){
      this.branch_state = this.coreService.getUserData('branch_state_id');
     
    }
  }
  // form search
  purchase_search(search: string) {
    const searchval = new FormData();
    searchval.append('purchret_id', search);
    this.apiService.getpurchaseRetDetails(searchval).subscribe((res) => {     
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
        this.visibility = true;
        $('#pret-void-table').hide();
      } else {
        // console.log(res['data'][0]);
        this.resultobj = {};
        if (res['data'][0]) {
          $('#pret-void-table').show();
          this.visibility = true;
          this.purchData = res['data'][0];
          this.purchData.purchret_date = this.datePipe.transform(this.purchData.purchret_date,"dd-MM-yyyy");  
          this.purchDataItems = res['data'][0]['items'];
          this.netPrice = 0;
          this.netAmount = 0;
          this.netTax = 0;
          this.totalAmount = 0;
          for (var j = 0; j < this.purchDataItems.length; j++) {
            this.netPrice = this.netPrice + Number(this.purchDataItems[j]['purchase_amount']);
            // this.netTax = this.netTax + (Number(this.purchDataItems[j]['purchretsub_tax']) * this.purchDataItems[j]['purchretsub_qty']);
          }
          this.netTax = this.purchData.purchret_tax
          this.totalAmount = Number(this.netPrice) + Number(this.netTax)-Number(this.purchData.purchret_discount);
          this.ledger=this.purchData.ledger;
          if(this.cmp_tax==2){
            this.cust_state_id = this.ledger.supp_state_id;
            this.iGSTchecking();
          }

        } else {
          $('#pret-void-table').hide();        
          this.visibility = false;
          this.purchData = [];

        }
      }
    });
    this.apiService.updatebatchExNotification();
  }

  iGSTchecking(){

        
    if(this.cmp_tax==2){
  
      // for is igst verify
      if((this.cust_state_id!=this.branch_state)&&(this.cust_state_id)&&(this.ledger)){
        this.igst=true;
      }else{
        this.igst=false;
      }
      console.log(this.igst);
    
      
     
      }
  }

  voidPurchaseRet(purchId: any) {

    var voidstk = confirm("Are you sure you want to Void this purchase  return ? ");
    if (voidstk) {
    this.pageLoadingImg = true;
      
      // console.log(purchId);
      const searchval = new FormData();
      searchval.append('purchret_id', purchId);
      this.apiService.voidPurchaseRet(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
        this.coreService.showMessage(res.message,3000);
        this.purchData.items = [];
        $('#pret-void-table').hide();
        this.igst=false;
      });
      this.apiService.updatebatchExNotification();
    }
  }

}
