import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-rep-auditor',
  templateUrl: './rep-auditor.component.html',
  styleUrls: ['./rep-auditor.component.css']
})
export class RepAuditorComponent implements OnInit {
  up: any;
  cmp_tax: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');

  }

}
