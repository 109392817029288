import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-rep-gst-summary',
  templateUrl: './rep-gst-summary.component.html',
  styleUrls: ['./rep-gst-summary.component.css']
})
export class RepGstSummaryComponent implements OnInit {
  Alldet: any;
  repDate: any;
  stndData: any;
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  exportLoader: boolean;
  fileName = 'gst_report_summary.xlsx';
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  ExprepDate: any;
  country_dec: string;
  price_grp : any = null;
  cur_lang: string;
  customer: any;
  customer_category: any;
  customersupp: any;
  salesPriviewLoader: boolean;
  resultobj: any;
  purchaseItems: any[];
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  showdata: boolean;
  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: 'P',
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  };
  logoImgUrl: string;
  tempImgUrl: string;
  footImgUrl: string;
  saleprivewLoadingImg: boolean;
  salesRetPreview: any;
  qr_inv: any;
  previewData: any[];
  is_prew_description: any;
  purchData: any;
  purchDataItems: string[];
  gd_name: string;
  purchDataRetAmount: number;
  total_invoice: any;
  total_igst: any;
  total_cgst: any;
  total_sgst: any;
  total_sales_invoice: any;
  total_sales_return_invoice: any;
  total_purchase_invoice: any;
  total_purchase_return_invoice: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

 
  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  trans_types = [
    // { id: 0, name: 'No Tarnsaction' },
    { id: 1, name: 'Sales' },
    { id: 2, name: 'Sales Return' },
    { id: 3, name: 'purchase' },
    { id: 4, name: 'purchase Return' },
    // { id: 5, name: 'Credit Note' },
   
  ];

  inv_types=[
    { id: 1, name: 'B2B' },
    { id: 2, name: 'B2C' }

  ]

  ngOnInit() {
    this.readProdSumm(1);
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    // this.getAllCustomerCategory();
  }


  readProdSumm(pageno) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("period_type", '');

    this.apiService.gstReport(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data']['data']
      this.total_invoice=res['total_invoice'];
      this.total_sales_invoice=res['total_sales_invoices'];
      this.total_sales_return_invoice=res['total_sales_return_invoices'];
      this.total_purchase_invoice=res['total_purchase_invoices'];
      this.total_purchase_return_invoice=res['total_purchase_return_invoices'];
      this.total_igst=res['total_igst'];
      this.total_cgst=res['total_cgst'];
      this.total_sgst=res['total_sgst'];

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      

      // this.stndData = res.data.data;
      // this.repDate = res.data.Date;
      this.stndData = res.Date;
      this.repDate = res.Date;


      // console.log(this.stndData);

    });


  }
  pageNext(formdata: { value: any; }, pageno: any, pagination_for: any) {

    // this.stndData = [];

    // formdata.value = pagination_for;
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.gstReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data']['data']
      console.log(this.Alldet,'alldetails');
      this.total_invoice=res['total_invoice'];
      this.total_sales_invoice=res['total_sales_invoices'];
      this.total_sales_return_invoice=res['total_sales_return_invoices'];
      this.total_purchase_invoice=res['total_purchase_invoices'];
      this.total_purchase_return_invoice=res['total_purchase_return_invoices'];
      
      this.total_igst=res['total_igst'];
      this.total_cgst=res['total_cgst'];
      this.total_sgst=res['total_sgst'];

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      console.log(this.curpage,'currentpage');
      
      this.lastpage = res['data']['last_page'];
      console.log(this.lastpage,'lastpage');
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      

      this.stndData = res.Date;
      this.repDate = res.Date;


    });
  }

  searchCustomerSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('price_group', this.price_grp ? this.price_grp : '');
    //  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    //   searchval.append("with-alias", "1");
    // }
    this.apiService.searchCustomerSupplier(searchval).subscribe((res) => {
      this.customersupp = res;
    });
  
  }
  // getAllCustomerCategory()
  // {
  //   this.apiService.listCustCategory().subscribe((res) => {
  //     this.customer_category = res['data'];
  //   });
  // }




  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  sale_search(search) {
    this.salesPriviewLoader = true;
   
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
     
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: 'P',
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      };
      if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }

      if (res.data.prnt_header_type === 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
      this.footImgUrl=this.apiService.PHP_API_SERVER + '/' + res.data.prnt_reciept_footer_img;

    });
  }

  getSalesRetPreview(prdn_id) {
    this.saleprivewLoadingImg = true;
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);

      this.qr_inv = this.salesRetPreview.qr_link;

      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        } else {
          this.tempImgUrl = '';
        }
  
      });

    });

  }
  previewPurchase(id) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', id);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.previewData = res.data;
      this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
      // setTimeout(function () { $("#previewButton").click(); }, 1000);

    });
  }

  getPurchRetPreview(id: any) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purchret_id', id);
    this.apiService.getpurchaseRetDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.purchData = res['data'][0];
      this.purchDataItems = res['data'][0]['items']; 
      if(!res['data'][0].purchret_frieght)
      res['data'][0].purchret_frieght = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0; 
      
      this.gd_name = 'Shop';

      this.purchDataRetAmount = Number(res['data'][0].purchret_amount);
      this.purchData.purchret_amount = Number(res['data'][0].purchret_amount)-Number(res['data'][0].purchret_tax)
      +Number(res['data'][0].purchret_discount);
    });

  }

  



  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    
      
    this.apiService.gstReport(Expformdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data']['data']
      console.log(this.Alldet,'alldetails');
      this.total_invoice=res['total_invoice'];
      this.total_sales_invoice=res['total_sales_invoices'];
      this.total_sales_return_invoice=res['total_sales_return_invoices'];
      this.total_purchase_invoice=res['total_purchase_invoices'];
      this.total_purchase_return_invoice=res['total_purchase_return_invoices'];
      
      this.total_igst=res['total_igst'];
      this.total_cgst=res['total_cgst'];
      this.total_sgst=res['total_sgst'];
      // this.Alldet = res['data']
      this.ExprepDate = res.Date;
      this.repDate = res.Date;
      
      setTimeout(() => {
        this.exportexcel();
      }, 3000);


      Expformdata.value.export = '';
      
  
    });


  }


  // pdf download
generatePdf(Expformdata: { value: any; }) {

  this.exportLoader = true;

  // debugger;
  Expformdata.value.export = 'export';

  if (Expformdata.value.period_type === 'c') {
    Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
    Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
  }
  // this.apiService.salesrepbyserialno(Expformdata.value, 1).subscribe((res) => {
  //   this.pageLoadingImg = false;
  //   this.ExprepDate = res.data.Date;
  //   this.repDate = res.data.Date;
    
  //   this.expsalesSum = res['data']['prdt_list']['data'];
  //   this.exptotal_qty_sold=res['data'].total_sold_qty
  //   this.exptotal_amount=res['data'].total_sale_amnt
  //   this.exptotal_discount_amount=res['data'].total_discount_amnt
  this.apiService.gstReport(Expformdata.value, 1).subscribe((res: any) => {

    this.pageLoadingImg = false;
    this.Alldet = res['data']['data']
    // this.pageFrom =  res['data']['from'];
    console.log(this.Alldet);
    this.total_invoice=res['total_invoice'];
    this.total_sales_invoice=res['total_sales_invoices'];
    this.total_sales_return_invoice=res['total_sales_return_invoices'];
    this.total_purchase_invoice=res['total_purchase_invoices'];
    this.total_purchase_return_invoice=res['total_purchase_return_invoices'];
    
    this.total_igst=res['total_igst'];
    this.total_cgst=res['total_cgst'];
    this.total_sgst=res['total_sgst'];
    
    
    const heads = [
      this.translate.instant('HOME.sl_no'),
      this.translate.instant('Table.date'),
      this.translate.instant('Common.voucherno'),
      this.translate.instant('Common.customer_type'),
      this.translate.instant('Common.customer_name'),
      this.translate.instant('HOME.transaction_type'),
      this.translate.instant('Purchase.GST_No'),
      this.translate.instant('Common.State'),
      this.translate.instant('HOME.taxable_amount'),
      this.translate.instant('Purchase.cgst_amount'),
      this.translate.instant('Purchase.sgst_amount'),
      this.translate.instant('Purchase.igst_amount'),
      this.translate.instant('Table.total_amount')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.Alldet.forEach(item=>{
        let transactionTypeLabel = ''; // Initialize transaction type label variable
        if (item.trans_type === 'sales') {
            transactionTypeLabel = item.voucherno; // For sales transaction
        } else if (item.trans_type === 'sales return') {
            transactionTypeLabel = item.voucherno; // For sales return transaction
        } else if (item.trans_type === 'purchase') {
            transactionTypeLabel = item.voucherno; // For purchase transaction
        } else if (item.trans_type === 'purchase return') {
            transactionTypeLabel = item.voucherno; // For purchase return transaction
        }

        let customerTypeLabel = ''; // Initialize customer type label variable
        if (item.cgst) {
            customerTypeLabel = 'State'; // For customers with CGST
        } else if (item.igst) {
            customerTypeLabel = 'Inter State'; // For customers with IGST
        }
        
      //   if (this.translate.currentLang === 'Arabic') {
      //     this.prd= item.prd_alias;
      //     this.alias= item.ledger_alias_AR;
      // } else if (this.translate.currentLang === 'English') {
      //   this.alias= item.sales_cust_name;
      //   this.prd= item.prd_name;
      // }
        data.push([
          data.length + 1,
          item.date,
          item.voucherno,
          customerTypeLabel,
          item.name,
          item.trans_type,
          item.gstno,
          item.state,
          this.currencyPipe.transform(item.taxable_amount, '', '', this.country_dec),
          this.currencyPipe.transform(item.cgst, '', '', this.country_dec),
          this.currencyPipe.transform(item.sgst, '', '', this.country_dec),
          this.currencyPipe.transform(item.igst, '', '', this.country_dec),
          this.currencyPipe.transform(item.total_amount, '', '', this.country_dec)
         
        ])
      })
  
      let doc = this.excelService.getPdfObj();
  
      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');
  
      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);
  
      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('GST.GST_Summary')}`,  valueXPos, headerHeight + 15);
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Invoice_Number')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.total_invoice,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Sales_Invoice')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_sales_invoice, '', '', this.country_dec),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Sales_return_Invoice')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_sales_return_invoice, '', '', this.country_dec),  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_purchase_Invoice')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_purchase_invoice, '', '', this.country_dec),  valueXPos, headerHeight + 35);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Purchase_Return')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_purchase_return_invoice, '', '', this.country_dec),  valueXPos, headerHeight + 40);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Igst_Amount')}`, lblXPos, headerHeight + 45);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_igst, '', '', this.country_dec),  valueXPos, headerHeight + 45);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Cgst_Amount')}`, lblXPos, headerHeight + 50);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_cgst, '', '', this.country_dec),  valueXPos, headerHeight + 50);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Sgst_Amount')}`, lblXPos, headerHeight + 55);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_sgst, '', '', this.country_dec),  valueXPos, headerHeight + 55);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 60, lblXPos, heads, data, null);
  
     
      doc.save(`Gst Summary Report .pdf`);


    Expformdata.value.export = '';

    this.exportLoader = false;

  });
  
}



}
