import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { Feature } from '../../../../../model/feature.model';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-godown-stock-summary',
  templateUrl: './godown-stock-summary.component.html',
  styleUrls: ['./godown-stock-summary.component.css']
})
export class GodownStockSummaryComponent implements OnInit {
  fileName= 'Godown_summary.xlsx';
  elseBlocknostart: any;
  elseBlocknoend: any;
  prd_id: any;
  prd_cat_id: any;
  prd_manufact_id: any;
  qty_filter: any;
  qty_val: any;
  qty_val1: any;
  rate_filter: any;
  rate_val: any;
  rate_val2: any;
  bar_filter: any;
  bar_val: any;
  bar_val2: any;
  stkeanbarcode: any;
  ean_key: any;
  isChecked: any;
  gd_id: any;

  products_all: string[];
  catagories: string[];
  subcatagories: string[];
  mfs: string[];
  cat_id: number;
  prodsumms: string[];
  prodsummscat: string[];
  unit_all: string[];
  features: string[];
  baseunit: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  stkprd_all: string[];
  slNo: number;
  mrpDef = 0;
  uspDef = 0;
  mrpExp = 'all';
  calc_mode: string;
  totalproducts: number;
  totalstock: number;
  totalpurchase: any;
  totcats: number;
  remark: string;
  list_type_remark: any;
  godown_all: any;
  listType:string;
  pageLoadingImg:boolean;
  active_stock:any;
  userType : any;
  up: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_address:any;
  branch_name: any;
  branch_display_code: any;
  Expprodsumms: any;
  Exptotalproducts: any;
  Exptotalstock: any;
  Exptotalpurchase: any;
  Exptotcats: any;
  Expremark: any;
  ExplistType: any;
  Expcalc_mode: any;
  usr_hide_purch_cost: any;
  remarkData: any;
  country_dec: string;
  cur_lang: string;
  PrdName: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.up = this.coreService.getUserPrivilage();
    this.userType =  this.coreService.getUserData('user_type');
    this.pageLoadingImg =true;
    this.reeadFeat();
    let searchval = new FormData(); 
    this.apiService.godownSumPage(searchval, 1).subscribe((res: Repprddet) => {
      this.pageLoadingImg=false;
      this.prodsumms = res['data']['data'];   
      this.totalproducts = res['data'].alldetais.total_products;
      this.totalstock = res['data'].alldetais.total_qty;
      this.totalpurchase = res['data'].alldetais.total_purchase_amount;
      if (this.totalpurchase) { this.totalpurchase = this.totalpurchase.toFixed(2); }
      this.totcats = res['data'].alldetais.total_categories;
      this.remark = res['data'].alldetais.remark;
      this.listType  =  res['data'].list_type;        
      this.calc_mode = res['data'].calc_mode;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.slNo = 0;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.translate.get(['Common.'+this.remark,]).subscribe((res: string) => {
        this.remarkData = res['Common.'+this.remark];
      
      });
    });
    this.searchForm('');
    this.searchManf('');
    this.getGodownList();
    

  }

  langChange(){
    this.translate.get(['Common.purchase_rate','Table.sales_rate_mrp','Common.last_purchase_rate','Common.average_purchase_rate','Common.all','Common.cust_date']).subscribe((res: string) => {    
     
      
      this.filter_rate = [
        { id: 0, name: res['Common.purchase_rate'] },
        { id: 1, name: res['Table.sales_rate_mrp'] }
      ];
      this.filter_prate = [
        { id: 0, name: res['Common.last_purchase_rate'] },
        { id: 1, name: res['Common.average_purchase_rate'] }
      ];
      this.stk_ean_barcode = [
        { id: 'all', name:  res['Common.all'] },
        { id: 'between', name:  res['Common.cust_date'] }
      ];
    });

  }

  // ngAfterContentInit(){

  //   this.searchForm('');
  //   this.searchManf('');
  //   this.getGodownList();
    

  // }
  
  // ngOnDestroy(){
  //   $('.close').click();
 
  // }

  //form search  for category
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }
  

  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  // search godown
  searchGodown(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
    });

  }
  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godown_all = res['data'];  
      this.godown_all.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }

  checkValue(event: any) {
    if(event =='Y')
    {
     $('#gdsel').show();   
    }
    else
    {
      $('#gdsel').hide();
      }
  }

  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    this.reeadFeat();
  }


  selectCat(cat_id: number) {
    this.cat_id = cat_id;
  }



  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg =true;
    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.mrp == 1)
      formdata.value.avg_rate = undefined;
    if(formdata.value.gdcheck==true)
    formdata.value.is_godown = true;
   
    this.apiService.godownSumPage(formdata.value, pageno).subscribe((res: Repprddet) => {
      this.pageLoadingImg =false;
      this.prodsumms = res['data']['data'];      
      this.totalproducts = res['data'].alldetais.total_products;
      this.totalstock = res['data'].alldetais.total_qty;
      this.totalpurchase = res['data'].alldetais.total_purchase_amount;
      if (this.totalpurchase) { this.totalpurchase = this.totalpurchase.toFixed(2); }
      this.totcats = res['data'].alldetais.total_categories;
      this.remark = res['data'].alldetais.remark;
      this.listType  =  res['data'].list_type;  
      this.calc_mode = res['data'].calc_mode;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.slNo = 0;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }

  selectSubCats(catid: number) {
    if(catid){
      this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
        this.subcatagories = res['data'];
        if (this.changeBox)
          this.changeBox = false;
        else
          this.changeBox = true;
  
      });
    }
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  //form search unit
  searchUnit(search: string) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];
    });

  }


  // filter  types
  filter_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_rate = [
    { id: 0, name: 'Purchase Rate' },
    { id: 1, name: 'Sales Rate -(M.R. P)' }
  ];
  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];

  expity_rate = [
    { id: 'all', name: 'All' },
    { id: 'no', name: 'No Expiry' },
    { id: 'has', name: 'Has Expiry' },
    { id: 'expired', name: 'Expired' },
    { id: 'not_expired', name: 'Not Expired' },
    { id: 'custom', name: 'Custom Date' }
  ];



  selectRate(rateid: number) {
    if (rateid == 1) { $('#useprate').hide(); }
    else {
      $('#useprate').show();
    }
  }

  selectExp(expid: string) {
    if (expid == 'custom') {
      $('#expiry_val').show();
      $('#expiry_val2').show();
    }
    else {
      $('#expiry_val').hide();
      $('#expiry_val2').hide();
    }
  }

  selectFqty(qtyidsel: string) {
  
    if (qtyidsel == 'between') {
      $('#qty_val1').show();
    }
    else {
      $('#qty_val1').hide();
    }
  }

  selectRateFilter(rateid: string) {
    if (rateid == 'between') {
      $('#rate_val2').show();
    }
    else {
      $('#rate_val2').hide();
    }
  }

  // Barcode and Ean
  stk_ean_barcode = [
    { id: 'all', name: 'All' },
    { id: 'between', name: 'Custom Date' }
  ];

  selectBarcodeFilter(id: string) {
    if (id == 'between') {
      $('#bar_val2').show();
    }
    else {
      $('#bar_val2').hide();
    }
  }

  selectStkEanBarcode(id: string) {
    if (id == 'between') {
      $('#ean_key').show();
    }
    else {
      $('#ean_key').hide();
    }
  }


  reeadFeat() {
    this.apiService.readFeat().subscribe((res: Feature[]) => {
      this.features = res['data'];

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }





  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    export(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      formdata.value.export = 'export';
      this.apiService.godownSumPage(formdata.value, 1).subscribe((res: Repprddet) => {
        this.Expprodsumms = res['data']['data'];      
        this.Exptotalproducts = res['data'].alldetais.total_products;
        this.Exptotalstock = res['data'].alldetais.total_qty;
        this.Exptotalpurchase = res['data'].alldetais.total_purchase_amount;
        if (this.Exptotalpurchase) { this.Exptotalpurchase = this.Exptotalpurchase.toFixed(2); }
        this.Exptotcats = res['data'].alldetais.total_categories;
        this.Expremark = res['data'].alldetais.remark;
        this.ExplistType  =  res['data'].list_type;  
        this.Expcalc_mode = res['data'].calc_mode;
            
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
    formdata.value.export = '';
   

    });



    }

    // pdf download
    generatePdf(formdata: { value: any; }) {

      this.exportLoader = true;
     
      // debugger;
      formdata.value.export = 'export';
      this.apiService.godownSumPage(formdata.value, 1).subscribe((res: Repprddet) => {
        this.Expprodsumms = res['data']['data'];      
        this.Exptotalproducts = res['data'].alldetais.total_products;
        this.Exptotalstock = res['data'].alldetais.total_stock;
        this.Exptotalpurchase = res['data'].alldetais.total_purchase_amount;
        if (this.Exptotalpurchase) { this.Exptotalpurchase = this.Exptotalpurchase.toFixed(2); }
        this.Exptotcats = res['data'].alldetais.total_categories;
        this.Expremark = res['data'].alldetais.remark;
        this.ExplistType  =  res['data'].list_type;  
        this.Expcalc_mode = res['data'].calc_mode;
            
        const heads = [];

        if(this.listType=='Shop'){
          if(this.calc_mode=='purchase_rate'){

              heads.splice(0,0, this.translate.instant('Common.prd_name')) 
              heads.splice(1,0, this.translate.instant('Common.itm_code')) 
              heads.splice(2,0, this.translate.instant('Common.available') + '\n' + this.translate.instant('HOME.STOCK'))

            if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
              heads.splice(3,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.rate')) 
              heads.splice(4,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt'))
            }
          } else {
              heads.splice(0,0, this.translate.instant('Common.prd_name')) 
              heads.splice(1,0, this.translate.instant('Common.itm_code')) 
              heads.splice(2,0, this.translate.instant('Common.Available_Stock'))

               if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
                heads.splice(3,0, 'Sales' + '\n' + 'Rate (M.R.P)') 
                heads.splice(4,0, 'Sales' + '\n' + 'Price (M.R.P)')
               }
          }
        } else {
          if(this.calc_mode=='purchase_rate'){
              heads.splice(0,0, this.translate.instant('Common.prd_name')) 
               heads.splice(1,0, this.translate.instant('Common.itm_code')) 
              heads.splice(2,0, this.translate.instant('Common.Available_Stock'))
              heads.splice(5,0, this.translate.instant('HOME.GODOWN'))

            if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
              heads.splice(3,0, this.translate.instant('Table.purch_rate') ) 
              heads.splice(4,0, this.translate.instant('Common.Purch_Amt'))
            }
          } else {
              heads.splice(0,0, this.translate.instant('Common.prd_name')) 
              heads.splice(1,0, this.translate.instant('Common.itm_code')) 
              heads.splice(2,0, this.translate.instant('Common.Available_Stock'))
              heads.splice(3,0, this.translate.instant('HOME.SALES') + '\n' + this.translate.instant('Godown.Rate_MRP'))
              heads.splice(4,0, this.translate.instant('HOME.SALES') + '\n' + this.translate.instant('Godown.Price_MRP'))
              heads.splice(5,0, this.translate.instant('HOME.GODOWN'))
          }
        }
  
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];

          if(this.listType=='Shop'){
            if(this.calc_mode=='purchase_rate'){
              this.Expprodsumms.forEach(item=>{
                item.category.forEach(item1=>{
                  const arr=[
                    item1.name,'',
                    this.currencyPipe.transform(item1.stock, '', '', '1.1-1')
                  ]

                  if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
                    arr.splice(3,0,'')
                    arr.splice(4,0,this.currencyPipe.transform(item1.amount, '', '', this.country_dec))
                  }
      
                  data.push(arr)
                })

                item.products.forEach(item2=>{
                  if(this.translate.currentLang == "Arabic"){this.PrdName = item2.prd_alias}else{this.PrdName = item2.name}
                  const arr=[
                    this.PrdName, item2.prd_code,
                    this.translate.instant('HOME.SHOP') + ' :   ' + this.currencyPipe.transform(item2.shop_qty, '', '', '1.1-1') +'\n' +
                    this.translate.instant('Purchase.godown') + ' :   ' + this.currencyPipe.transform(item2.godown_qty, '', '', '1.1-1') +'\n' +
                    this.translate.instant('Van.van') + ' :   ' + this.currencyPipe.transform(item2.van_qty, '', '', '1.1-1') +'\n' +
                    this.translate.instant('HOME.total') + ' :   ' + this.currencyPipe.transform(item2.total_qty, '', '', '1.1-1') +'\n' 
                  ]

                  if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
                    arr.splice(3,0,this.currencyPipe.transform(item2.rate, '', '', this.country_dec))
                    arr.splice(4,0,this.currencyPipe.transform(item2.amount, '', '', this.country_dec))
                  }


                  data.push(arr)
                })
              
              })
            } else {
              this.Expprodsumms.forEach(item=>{
                item.category.forEach(item1=>{
                  const arr=[
                    item1.name,'',
                    this.currencyPipe.transform(item1.stock, '', '', '1.1-1')
                  ]

                  if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
                    arr.splice(3,0,'')
                    arr.splice(4,0,this.currencyPipe.transform(item1.amount, '', '', this.country_dec))
                  }
      
                  data.push(arr)
                })

                item.products.forEach(item2=>{
                  if(this.translate.currentLang == "Arabic"){this.PrdName = item2.prd_alias}else{this.PrdName = item2.name}
                  const arr=[
                    this.PrdName,   item2.prd_code,                 'Total' + ' :   ' + this.currencyPipe.transform(item2.total_qty, '', '', '1.1-1') +'\n' +
                    'Shop' + ' :   ' + this.currencyPipe.transform(item2.shop_qty, '', '', '1.1-1') +'\n' +
                    'Godown' + ' :   ' + this.currencyPipe.transform(item2.godown_qty, '', '', '1.1-1') +'\n'
                  ]

                  if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
                    arr.splice(3,0,this.currencyPipe.transform(item2.rate, '', '', this.country_dec))
                    arr.splice(4,0,this.currencyPipe.transform(item2.amount, '', '', this.country_dec))
                  }


                  data.push(arr)
                })
              
              })
            }
          } else {
            if(this.calc_mode=='purchase_rate'){
              this.Expprodsumms.forEach(item=>{
                item.category.forEach(item1=>{
                  const arr=[
                    item1.name,'',
                    this.currencyPipe.transform(item1.stock, '', '', '1.1-1'),
                    ''
                  ]

                  if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
                    arr.splice(3,0,'')
                    arr.splice(4,0,this.currencyPipe.transform(item1.amount, '', '', this.country_dec))
                  }
      
                  data.push(arr)
                })

                item.products.forEach(item2=>{
                  const arr=[
                    item2.name,item2.prd_code,
                    this.currencyPipe.transform(item2.stock, '', '', '1.1-1'),
                    item2.gd_name
                  ]

                  if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
                    arr.splice(3,0,this.currencyPipe.transform(item2.rate, '', '', this.country_dec))
                    arr.splice(4,0,this.currencyPipe.transform(item2.amount, '', '', this.country_dec))
                  }


                  data.push(arr)
                })
              
              })
            } else {
              this.Expprodsumms.forEach(item=>{
                item.category.forEach(item1=>{
                  const arr=[
                    item1.name,'',
                    this.currencyPipe.transform(item1.stock, '', '', '1.1-1'),
                    '',
                    this.currencyPipe.transform(item1.amount, '', '', this.country_dec),
                    ''
                  ]
      
                  data.push(arr)
                })

                item.products.forEach(item2=>{
                  const arr=[
                    item2.name,item2.prd_code,
                    this.currencyPipe.transform(item2.stock, '', '', '1.1-1'),
                    this.currencyPipe.transform(item2.rate, '', '', this.country_dec),
                    this.currencyPipe.transform(item2.amount, '', '', this.country_dec),
                    item2.gd_name
                  ]
                  data.push(arr)
                })
              
              })
            }
          }
      
          let doc = this.excelService.getPdfObj();
  
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
  
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Common.Godown_stock_summary')}`,  valueXPos, headerHeight + 10);
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + this.totalproducts,  valueXPos, headerHeight + 15);
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.STOCK')}`, lblXPos, headerHeight + 20);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.totalstock, '', '', '1.1-1'),  valueXPos, headerHeight + 20);
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_no_of_categories')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.totcats,  valueXPos, headerHeight + 25);
  
          if((this.userType =='ADMIN' || this.userType =='BRANCH ADMIN')&& !this.usr_hide_purch_cost){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Godown.Total_Purchase_Price')}`, lblXPos, headerHeight + 30);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.totalpurchase, '', '', this.country_dec),  valueXPos, headerHeight + 30);
          }
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.List_Type')}`, lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + this.listType,  valueXPos, headerHeight + 35);
          
          doc.setFontSize(10);
          doc.text(this.remarkData, lblXPos, headerHeight + 40);
  
          doc = this.excelService.addTableToPdf(doc, headerHeight + 45, lblXPos, heads, data, null);
      
         
          doc.save(`Godown Stock Summary.pdf`);
    
          this.exportLoader = false;

    formdata.value.export = '';
   

    });
      
    }


}
