import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-supplier-aging-rpt',
  templateUrl: './supplier-aging-rpt.component.html',
  styleUrls: ['./supplier-aging-rpt.component.css']
})
export class SupplierAgingRptComponent implements OnInit {
  
  @ViewChild('exportall', { static: true }) reportContent: ElementRef;
  @ViewChild('printBtn',{static: true}) printBtn: ElementRef<HTMLElement>;
  
  supplierAll: any;
  fileName = 'supplier_aging.xlsx';
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  pageLoadingImg: boolean;
  show: any;
  summary: any=[];
  repDate: any;
  showfull: any;
  total_bal_amnt: any;
  opening_balnce: any;
  total_due: any;
  atot: any;
  btot: any;
  ctot: any;
  dtot: any;
  etot: any;
  ftot: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  totalData: any;
  pgend: boolean;
  pgstart: boolean;
  exportLoader: boolean;
  exclude_zero=false;
  Expshow: any;
  Expsummary: any;
  ExprepDate: any;
  Exptotal_bal_amnt: any;
  Expopening_balnce: any;
  Exptotal_due: any;
  Expatot: any;
  Expbtot: any;
  Expctot: any;
  Expdtot: any;
  Expetot: any;
  Expftot: any;
  Expshowfull: any;
  Prntshow: any;
  Prntsummary: any;
  PrntrepDate: any;
  Prnttotal_bal_amnt: any;
  Prntopening_balnce: any;
  Prnttotal_due: any;
  Prntatot: any;
  Prntbtot: any;
  Prntctot: any;
  Prntdtot: any;
  Prntetot: any;
  Prntftot: any;
  Prntshowfull: any;
  ModelData: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  country_dec: string;
  cur_lang: string;
  branch_all: any;
  filter_branch_id:number;
  userType: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.getAllBranch();
    this.filter_branch_id = 1;

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();
    //searchval.append("period_type", 'ld');
    this.pageLoadingImg = true;
    this.apiService.suppAgingRpt(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.userType = this.coreService.getUserData('user_type');


      this.show = res['data'];



      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.opening_balnce = res['data']['opening_balnce'];
      this.total_due = res['data']['total_due'];

      this.atot = res['data']['atot'];
      this.btot = res['data']['btot'];
      this.ctot = res['data']['ctot'];
      this.dtot = res['data']['dtot'];
      this.etot = res['data']['etot'];
      this.ftot = res['data']['ftot'];
      this.showfull = res['data']['showall'];
      console.log(this.total_bal_amnt);

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      console.log( this.curpage);
      
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.ModelData = false;

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }
  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
  
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.suppAgingRpt(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
      this.show = res['data'];
      // this.showfull=1;


      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.opening_balnce = res['data']['opening_balnce'];
      this.total_due = res['data']['total_due'];
      this.atot = res['data']['atot'];
      this.btot = res['data']['btot'];
      this.ctot = res['data']['ctot'];
      this.dtot = res['data']['dtot'];
      this.etot = res['data']['etot'];
      this.ftot = res['data']['ftot'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.totalData);
      this.showfull = res['data'].showall;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });
console.log(this.showfull);

  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
    // form search supplier
    searchSupplier(search: string) {
      const searchval = new FormData();
      searchval.append('supp_name', search);
       if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append("with-alias", "1");
      }
      this.apiService.searchSupplier(searchval).subscribe((res) => {
        this.supplierAll = res.data;
      });
    
    }

    exportEXCL(Expformdata: { value: any; }) {

      // debugger;
      this.exportLoader = true;
  
      Expformdata.value.datewise = true;
      Expformdata.value.export = 'export';
      Expformdata.value.exclude_zero = this.exclude_zero;
      if (Expformdata.value.period_type === 'c') {
        Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
        Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
        Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
        Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
      }
      this.apiService.suppAgingRpt(Expformdata.value, 1).subscribe((res: any) => {
        // if(res['data']['data']){
        this.Expshow = res['data'];
  
        this.Expsummary = res['data']['data'];
        this.ExprepDate = res['data'].Date;
        this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
        this.Expopening_balnce = res['data']['opening_balnce'];
        this.Exptotal_due = res['data']['total_due'];
        this.Expatot = res['data']['atot'];
        this.Expbtot = res['data']['btot'];
        this.Expctot = res['data']['ctot'];
        this.Expdtot = res['data']['dtot'];
        this.Expetot = res['data']['etot'];
        this.Expftot = res['data']['ftot'];
        this.Expshowfull = res['data']['showall'];
  
        // }
  
  
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
        Expformdata.value.export = '';
  
  
      });
  
    }

    exportexcel(): void {
      /* table id is passed over here */
      let element = document.getElementById('export-group');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.exportLoader = false;
  
    }


    saveAndPrint(Expformdata: { value: any; }) {

      // debugger;
      this.exportLoader = true;
      Expformdata.value.exclude_zero = this.exclude_zero;
      Expformdata.value.datewise = true;
      Expformdata.value.export = 'export';
      if (Expformdata.value.period_type === 'c') {
        Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
        Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
        Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
        Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
      }
      this.apiService.suppAgingRpt(Expformdata.value, 1).subscribe((res: any) => {
        // if(res['data']['data']){
        this.Prntshow = res['data'];
  
        this.Prntsummary = res['data']['data'];
        this.PrntrepDate = res['data'].Date;
        this.Prnttotal_bal_amnt = res['data']['total_bal_amnt'];
        this.Prntopening_balnce = res['data']['opening_balnce'];
        this.Prnttotal_due = res['data']['total_due'];
        this.Prntatot = res['data']['atot'];
        this.Prntbtot = res['data']['btot'];
        this.Prntctot = res['data']['ctot'];
        this.Prntdtot = res['data']['dtot'];
        this.Prntetot = res['data']['etot'];
        this.Prntftot = res['data']['ftot'];
        this.Prntshowfull = res['data']['showall'];
  
        setTimeout(() => {
          this.exportLoader = false;
          let el: HTMLElement = this.printBtn.nativeElement;
          el.click();
      }, 2000);
        Expformdata.value.export = '';
  
  
      });
  
  
  
    }

    toggleExcZero(){
      $("#exc_0").prop("checked", !$("#exc_0").prop("checked"));
      this.exclude_zero = $("#exc_0").prop("checked");
    }

    // pdf download
    generatePdf(Expformdata: { value: any; }) {

            // debugger;
            this.exportLoader = true;
  
            Expformdata.value.datewise = true;
            Expformdata.value.export = 'export';
            Expformdata.value.exclude_zero = this.exclude_zero;
            if (Expformdata.value.period_type === 'c') {
              Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
              Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
              Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
              Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
            }
            this.apiService.suppAgingRpt(Expformdata.value, 1).subscribe((res: any) => {
              // if(res['data']['data']){
              this.Expshow = res['data'];
        
              this.Expsummary = res['data']['data'];
              this.ExprepDate = res['data'].Date;
              this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
              this.Expopening_balnce = res['data']['opening_balnce'];
              this.Exptotal_due = res['data']['total_due'];
              this.Expatot = res['data']['atot'];
              this.Expbtot = res['data']['btot'];
              this.Expctot = res['data']['ctot'];
              this.Expdtot = res['data']['dtot'];
              this.Expetot = res['data']['etot'];
              this.Expftot = res['data']['ftot'];
              this.Expshowfull = res['data']['showall'];
        
              // }
        
        
              const heads = [
                this.translate.instant('Table.sl_no'),
                this.translate.instant('Common.Ledger_name'),
                this.translate.instant('Sales.total_due_amount')
              ];

              if(this.showfull){
                heads.splice(2,0, this.translate.instant('Sales.no_due_yet'))
                heads.splice(3,0, '1-30' + ' ' + this.translate.instant('HOME.days'))
                heads.splice(4,0, '31-60' + ' ' + this.translate.instant('HOME.days'))
                heads.splice(5,0, '60-90' + ' ' + this.translate.instant('HOME.days'))
                heads.splice(6,0, '91-120' + ' ' + this.translate.instant('HOME.days'))
                heads.splice(7,0, '120+' + ' ' + this.translate.instant('HOME.days'))
              }
          
                const lblXPos = 10;
                const headerHeight = 15;
                const valueXPos = 55;
                const data = [];
        
                if(this.show != ''){
                  this.Expsummary.forEach((item,i)=>{
                    const arr=[
                      i+1,
                      this.currencyPipe.transform(item.duetot, '', '', this.country_dec)
                    ];

                    if(this.showfull){
                      arr.splice(2,0, this.currencyPipe.transform(item.a, '', '', this.country_dec))
                      arr.splice(3,0, this.currencyPipe.transform(item.b, '', '', this.country_dec))
                      arr.splice(4,0, this.currencyPipe.transform(item.c, '', '', this.country_dec))
                      arr.splice(5,0, this.currencyPipe.transform(item.d, '', '', this.country_dec))
                      arr.splice(6,0, this.currencyPipe.transform(item.e, '', '', this.country_dec))
                      arr.splice(7,0, this.currencyPipe.transform(item.f, '', '', this.country_dec))
                     }

                     if(this.translate.currentLang == 'Arabic'){
                      arr.splice(1,0, item.ledger_alias_AR)
                     } else {
                      arr.splice(1,0, item.ledger_name)
                     }
  
                    data.push(arr)
                  })
                }
                
                let doc = this.excelService.getPdfObj();
        
                var addressLines = this.branch_address.split('\r\n');
                var addressText = addressLines.join(', ');
        
                doc.setFont('Amiri');
                doc.setFontSize(12);
                doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
        
                doc.setFont('Amiri');
                doc.setFontSize(9);
                doc.text(addressText, 71, headerHeight + 2);
        
                if(this.ExprepDate){
                  doc.setFontSize(10);
                  doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
                  doc.setFontSize(10);
                  doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
                }
        
                doc.setFontSize(10);
                doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
                doc.setFontSize(10);
                doc.text(':  ' + `${this.translate.instant('Sales.supp_aging_rpt')}`,  valueXPos, headerHeight + 15);

                doc.setFontSize(10);
                doc.text(`${this.translate.instant('Sales.total_due_amount')}`, lblXPos, headerHeight + 50);
                doc.setFontSize(10);
                doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_due, '', '', this.country_dec),  valueXPos, headerHeight + 50);

                if(this.showfull){

                  doc.setFontSize(10);
                  doc.text(`${this.translate.instant('Sales.no_due_yet')}`, lblXPos, headerHeight + 20);
                  doc.setFontSize(10);
                  doc.text(':  ' + this.currencyPipe.transform(this.Expatot, '', '', this.country_dec),  valueXPos, headerHeight + 20);

                  doc.setFontSize(10);
                  doc.text('1-30' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 25);
                  doc.setFontSize(10);
                  doc.text(':  ' + this.currencyPipe.transform(this.Expbtot, '', '', this.country_dec),  valueXPos, headerHeight + 25);

                  doc.setFontSize(10);
                  doc.text('31-60' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 30);
                  doc.setFontSize(10);
                  doc.text(':  ' + this.currencyPipe.transform(this.Expctot, '', '', this.country_dec),  valueXPos, headerHeight + 30);

                  doc.setFontSize(10);
                  doc.text('60-90' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 35);
                  doc.setFontSize(10);
                  doc.text(':  ' + this.currencyPipe.transform(this.Expdtot, '', '', this.country_dec),  valueXPos, headerHeight + 35);

                  doc.setFontSize(10);
                  doc.text('91-120' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 40);
                  doc.setFontSize(10);
                  doc.text(':  ' + this.currencyPipe.transform(this.Expetot, '', '', this.country_dec),  valueXPos, headerHeight + 40);

                  doc.setFontSize(10);
                  doc.text('120+' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 45);
                  doc.setFontSize(10);
                  doc.text(':  ' + this.currencyPipe.transform(this.Expftot, '', '', this.country_dec),  valueXPos, headerHeight + 45);

                }

                doc = this.excelService.addTableToPdf(doc, headerHeight + 55, lblXPos, heads, data, null);
            
               
                doc.save(`Supplier Aging Report.pdf`);
        
                this.exportLoader = false;
        
              Expformdata.value.export = '';
        
        
            });
      
    }

    getAllBranch() {
      this.apiService.readAllBranch().subscribe((res:any) => {
        this.branch_all = res.data;
        // this.filter_branch_id = res.selected;
    
        // console.log(  this.branch_all );
      //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      });
    }
    
    searchBranch(search: string) {
      let searchval = new FormData();
      searchval.append("branch_name", search);
      this.apiService.getBranchByName(searchval).subscribe((res:any) => {
        this.branch_all = res.data;
        // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      });
    }
}
