import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgbDate,NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-van-calender',
  templateUrl: './van-calender.component.html',
  styleUrls: ['./van-calender.component.css']
})
export class VanCalenderComponent implements OnInit {
  vans: any;
  searchProducts: any[];
  selectedDate: NgbDate;
  pageLoadingImg: boolean;
  selectedMonth: any;
  selectedYear: any;

  rept_type = [
    { id: 1, name: 'Sale & Return' },
    { id: 2, name: 'Sale' },
    { id: 3, name: 'Return' },
  ];

  payment_types = [
    { id: 2, name: 'Cash' },
    { id: 1, name: 'Credit' },

  ];
  calender_rpt: any;
  selectedDay: number;
  report_type: any;
  product: any;
  van: any;
  rep_type: number;
  priceGroupList: any;
  total_sum: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address: any;
  prd_id: any;
  van_id: any;
  country_dec: string;
  date: any;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private calendar: NgbCalendar,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { 
    this.selectedDate = calendar.getToday();
    
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address= this.coreService.getUserData('branch_address');
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.searchVans('');
    this.updateSelectedMonthAndYear(this.selectedDate);
    this.rep_type=1;
    let searchval = new FormData();
    searchval.append("month", this.selectedMonth);
    searchval.append("year", this.selectedYear);
    this.pageLoadingImg = true;
    this.apiService.vanCalendarRpt(searchval,1).subscribe((res: any) => {
    this.pageLoadingImg = false;
    this.calender_rpt=res.dates;
    this.report_type=res.report_type;
    this.total_sum=res.total_qty_sum;
    this.product=res.product;
    this.van=res.van;
  
   });

   this.apiService.getAllPriceGroup().subscribe((res) => {
    this.priceGroupList = res.data;
  });
  }
  langChange(){
    this.translate.get(['Common.sales','Common.sales_return','Common.return','Common.cash','HOME.credit']).subscribe((res: string) => {    
     
      this.rept_type = [
        // { id: 1, name: 'Transfer & Returns' },
        { id: 1, name:  res['Common.sales'] },
        { id: 2, name:  res['Common.sales_return']},
        { id: 3, name:  res['Common.return'] },
      ];
      this.payment_types = [
        { id: 2, name:  res['Common.cash'] },
        { id: 1, name:  res['HOME.credit'] },
    
      ];
    });

  


  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

  // search product
 selectProd(name: string) {

  const searchval = new FormData();
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append('alias_search', '1');
  }
  searchval.append('prod_name', name);
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.searchProducts = res.data;
  });
}


onMonthYearChange(newDate: NgbDate) {
 
  this.updateSelectedMonthAndYear(newDate);
  
}

updateSelectedMonthAndYear(date: NgbDate) {
  this.selectedMonth = date.month;
  this.selectedYear = date.year;

  
  $('#searchItem').trigger('click');
}

pageNext(formdata: { value: any; },pageno){

  
    formdata.value.month=this.selectedMonth;
    formdata.value.year=this.selectedYear;
    this.pageLoadingImg = true;
    this.apiService.vanCalendarRpt(formdata.value,pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;
    this.calender_rpt=res.dates;
    this.report_type=res.report_type;
    this.total_sum=res.total_qty_sum;
    this.product=res.product;
    this.van=res.van;
    
   });

function monthName(monthNumber) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return months[monthNumber - 1];
}

this.date = monthName(this.selectedMonth) + ' - ' + this.selectedYear;
   

}

}
