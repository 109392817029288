import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-van-stock-tracking',
  templateUrl: './van-stock-tracking.component.html',
  styleUrls: ['./van-stock-tracking.component.css']
})
export class VanStockTrackingComponent implements OnInit {
  fileName= 'van_stock_tracking.xlsx';
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;
  van_id: any;

  searchProducts: any;
  catagories: any;
  company: any;
  vans: any;
  godowns: any;
  vanPrdData: any;
  vanTransData: any;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  totalRes: any;
  slnum: number;
  data: any;
  reportBy:any;
  rep_type:any;
  pageLoadingImg:boolean;
  reportType:any;
  repDate:any;


  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 't', name: 'Today' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];


  rept_type = [
    { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];



  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  from: any;
  subcatagories: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;

  exp_vanTransData: any;
  exp_totalRes: any;
  filteredData: any;
  expfilteredData: any;
  cmpny: any;
  byderived: boolean;
  cur_lang: string;

  branch_address:any;
  country_dec: any;
  filter_branch_id: any;
  userType: any;
  branch_all: any;
  PrdNAme: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }
  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.filter_branch_id = 1;
    this.getAllBranch();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');

    // if(this.cmpny=='AQE'){
    //   this.byderived=true;
    // }
    this.pageLoadingImg = true;
    const searchval = new FormData();
    // if(this.cmpny=='AQE'){
    //   searchval.append("byderived", '1');
    // }
    this.totalRes = {};
    this.company = this.searchComp('');
    this.catagories = this.searchCat('');
    this.vans = this.searchVans('');
    this.rep_type = 1;
    this.listGodowns();
    this.apiService.readVanStockTracking(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.vanTransData = res.data;
      this.filteredData = res.data.filter(data =>
        data.van_trasfer_qty != 0 || data.van_ret_qty != 0 || data.vn_excs_qty != 0 ||data.vn_mstk_qty != 0 ||data.sold_qty != 0 || data.sales_return_qty != 0
      );
      
      
      this.totalRes = res.totalDetails;
      this.reportBy = res.reportBy;
      this.reportType = res.data.reportType;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.from = res.from;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }
  langChange(){
    this.translate.get(['Common.January', 'Common.February','Common.March', 'Common.April','Common.May', 'Common.June','Common.July','Common.August','Common.September','Common.October','Common.November','Common.December','Common.detailed','Common.cash','HOME.credit']).subscribe((res: string) => {    
     
 
      this.filterDate = [
        
        { id: 1, name: res['Common.January' ]},
    { id: 2, name: res['Common.February'] },
    { id: 3, name: res['Common.March' ]},
    { id: 4, name: res['Common.April'] },
    { id: 5, name: res['Common.May'] },
    { id: 6, name: res['Common.June'] },
    { id: 7, name: res['Common.July'] },
    { id: 8, name: res['Common.August'] },
    { id: 9, name: res['Common.September'] },
    { id: 10, name: res['Common.October'] },
    { id: 11, name: res['Common.November' ]},
    { id: 12, name: res['Common.December' ]},         
          ];
    });

  


  }
  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      console.log(res);
      this.godowns = res['data'];

    });

  }

  ngOnDestroy(){
    $('.close').click();
 
  }




   // Loading Api to include for Filters


   searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.searchProducts = res.data;
    });
  }


  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      
    });
  }


  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
  this.apiService.readVanStockTracking(formdata.value, pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;
      
    this.vanTransData = res.data;
    this.filteredData = res.data.filter(data =>
      data.van_trasfer_qty != 0 || data.van_ret_qty != 0 || data.vn_excs_qty != 0 ||data.vn_mstk_qty != 0 ||data.sold_qty != 0 || data.sales_return_qty != 0
    );
 
      this.totalRes = res.totalDetails;
      this.reportBy = res.reportBy;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.reportType = res.data.reportType;
      this.repDate = res.Date;
      this.from = res.from;


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanStockTracking(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
        
        this.exp_vanTransData = res.data;
        this.expfilteredData = res.data.filter(data =>
          data.van_trasfer_qty != 0 || data.van_ret_qty != 0 || data.vn_excs_qty != 0 ||data.vn_mstk_qty != 0 ||data.sold_qty != 0 || data.sales_return_qty != 0
        );
        this.exp_totalRes = res.totalDetails;
        this.reportBy = res.reportBy;
        this.reportType = res.data.reportType;
        this.repDate = res.data.Date;
        // this.from = res.from;
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
      });
  }



  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanStockTracking(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
        
        this.exp_vanTransData = res.data;
        this.expfilteredData = res.data.filter(data =>
          data.van_trasfer_qty != 0 || data.van_ret_qty != 0 || data.vn_excs_qty != 0 ||data.vn_mstk_qty != 0 ||data.sold_qty != 0 || data.sales_return_qty != 0
        );
        this.exp_totalRes = res.totalDetails;
        this.reportBy = res.reportBy;
        this.reportType = res.data.reportType;
        this.repDate = res.data.Date;
        // this.from = res.from;

        const heads = [
          this.translate.instant('Table.sl') + '\n' + this.translate.instant('Common.no'),
          this.translate.instant('Table.itm_name'),
          this.translate.instant('settings.Barcode'),
          this.translate.instant('Common.category'),
          this.translate.instant('Van.bal_qty')
        ];

        if(!this.byderived){
          heads.splice(4,0, this.translate.instant('Van.van_trasfer_qty'))
          heads.splice(5,0, this.translate.instant('Van.van_return_qty'))
          heads.splice(6,0, this.translate.instant('Van.net_trnsfd_qty'))
          heads.splice(7,0, this.translate.instant('Van.mssng_qty'))
          heads.splice(8,0, this.translate.instant('Van.excs_qty'))
          heads.splice(9,0, this.translate.instant('Common.Sold_Qty'))
          heads.splice(10,0, this.translate.instant('Van.sales_return_qty'))
          heads.splice(11,0, this.translate.instant('Van.net_sold_qty'))
        }
    
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
    
          this.exp_vanTransData.forEach((item,i)=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.prd_alias}else{this.PrdNAme =  item.prd_name}

            const arr=[
              i+1,
              this.PrdNAme,
              item.prd_barcode,
              item.cat_name,
              this.currencyPipe.transform(((item.van_trasfer_qty - item.van_ret_qty) - (item.sold_qty - item.sales_return_qty))-(item.vn_mstk_qty)+(item.vn_excs_qty), '', '', '1.1-1')
            ]

            if(!this.byderived){
              arr.splice(4,0, this.currencyPipe.transform(item.van_trasfer_qty, '', '', '1.1-1'))
              arr.splice(5,0, this.currencyPipe.transform(item.van_ret_qty, '', '', '1.1-1'))
              arr.splice(6,0, this.currencyPipe.transform((item.van_trasfer_qty) - (item.van_ret_qty), '', '', '1.1-1'))
              arr.splice(7,0, this.currencyPipe.transform(item.vn_mstk_qty, '', '', '1.1-1'))
              arr.splice(8,0, this.currencyPipe.transform(item.vn_excs_qty, '', '', '1.1-1'))
              arr.splice(9,0, this.currencyPipe.transform(item.sold_qty, '', '', '1.1-1'))
              arr.splice(10,0, this.currencyPipe.transform(item.sales_return_qty, '', '', '1.1-1'))
              arr.splice(11,0, this.currencyPipe.transform((item.sold_qty) - (item.sales_return_qty), '', '', '1.1-1'))
            }

            data.push(arr)
          })
      
          let doc = this.excelService.getPdfObj();
    
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
    
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
    
          if(this.repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
          }
    
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Common.VAN_STOCK_TRACKING')}`,  valueXPos, headerHeight + 15);
    
          if(!this.byderived){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Table.totl_trans_qty')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.totalRes.total_transferd, '', '', "1.1-1"),  valueXPos, headerHeight + 20);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_Sold_Quantity')}`, lblXPos, headerHeight + 25);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.totalRes.total_sold, '', '', "1.1-1"),  valueXPos, headerHeight + 25);
          }

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Van.bal_qty')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.totalRes.bal_qty, '', '', "1.1-1"),  valueXPos, headerHeight + 30);

          if(this.byderived){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('ACCOUNTS.Display_Type')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + 'By Derived Qty',  valueXPos, headerHeight + 20);
          }

          if(this.reportBy){
            doc.setFontSize(10);
            doc.text('Van', lblXPos, headerHeight + 35);
            doc.setFontSize(10);
            doc.text(':  ' + this.reportBy,  valueXPos, headerHeight + 35);
          }
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
      
         
          doc.save(`Van Stock Tracking.pdf`);
          this.exportLoader = false;
      });
  
  }


    
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

}

