import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-einvoicing-simplified-tax-invoice',
  templateUrl: './einvoicing-simplified-tax-invoice.component.html',
  styleUrls: ['./einvoicing-simplified-tax-invoice.component.css']
})
export class EinvoicingSimplifiedTaxInvoiceComponent implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()show_base_unit_in_print;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()whatsapplink;
  country_dec: any;
  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
  }

  startPrint() {

    $('#printButton').click();
  }

}
