import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import * as jspdf from 'jspdf';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { DataTransferService } from '../../../../../service/data-transfer.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx'; 
import pdfMake from 'pdfmake/build/pdfmake';
import { ActivatedRoute, Router } from '@angular/router';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { DatePipe } from '@angular/common';

import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { CurrencyPipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs; 
pdfMake.fonts = { 'Roboto': { normal: 'Roboto-Regular.ttf', bold: 'Roboto-Medium.ttf', italics: 'Roboto-Italic.ttf', bolditalics: 'Roboto-Italic.ttf' },
 'THSarabunNew': { normal: 'THSarabunNew.ttf' }, 'code128': { normal: 'code128.ttf' } }


pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-sales-sammary',
  templateUrl: './sales-sammary.component.html',
  styleUrls: ['./sales-sammary.component.css']
}) 
export class SalesSammaryComponent implements OnInit {
  @ViewChild('exportall',{static: true}) reportContent: ElementRef;
  fileName= 'sales_summary.xlsx';
 
  pageLoadingImg: boolean;
  // value1:any;
  // value2:any;
  inv_val1:any;
  inv_val2:any;
  sales_val1:any;
  sales_val2:any;
  disc_val1:any;
  agents:any;

  sales_type = [
    { id: 0, name: 'ALL' },
    { id: 1, name: 'Standard Rated' },
    { id: 2, name: 'Zero Rated' },
    { id: 3, name: 'Exempt Sales' }
  ];
  sale_type: any;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_saldic = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_servamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: 1, name: 'Credit' },
    { id: 0, name: 'Cash' },
    { id: 2, name: 'Bank' },
    { id: 3, name: 'Multi' }

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];

  filter_custtyp = [
    { id: '1', name: 'Registered' },
    { id: '0', name: 'Non Registered' },

  ];
  

  payType = ['Cash','Credit','Bank', 'Multi'];

  godowns:any;
  salesSum: any;
  totalCat: any;
  totalProducts: any;
  sup_id:any;
  cust_id:any;
  cust_type:any;
  pay_type:any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  sales_disc_filter:any;
  sales_filter:any;
  inv_filter:any;
  period_type:any;
  gd_id:any;
  agent_id:any;
  company:any;
  supplier:any;
  users:any;
  customer:any;
  added_by:any;
  sales_flags:any;
  summary:any;
  repDate:any;
  totalData:number;
  date1:any;
  date2:any;
  time1:any;
  time2:any;
  vat_reg_no:any;
  ModelData: boolean;
  show: any;
  userType: any;
  filter_branch_id: number;
  branch_all: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  Expshow: any;
  ExpsalesSum: any;
  Expsummary: any;
  vans: any;
  exportLoader: boolean;
  pdf: any;
  is_item_disc: any;
  country_dec: string;
  custid: any;
  ptype: any;
  custname: boolean;
  usr_hide_others_sales_data: any;
  customer_category :any;
  cur_lang: string;
  branch_address: any;
  showNote: any;
  cmp_tax: any;
  vatAmount: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private dataTransferService: DataTransferService,private router: Router,private translate: TranslateService,private currencyPipe: CurrencyPipe,private activatedRoute: ActivatedRoute,private excelService: ExcelService,private datePipe: DatePipe) { }


 

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    console.log( this.cmp_tax);

    if(this.cmp_tax==2){
       this.vatAmount=this.translate.instant('GST Amount')
    }else{
       this.vatAmount=this.translate.instant('Table.vat_amount')
    }
    
    this.langChange();
    this.period_type = 'ld';
    this.exportLoader = false;
    this.is_item_disc = true;

    this.userType = this.coreService.getUserData('user_type');
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.totalData = 0;
    this.pageLoadingImg = true;
    let searchval = new FormData();

    
    searchval.append("period_type", 'ld');
    searchval.append("is_item_disc", this.is_item_disc);
    this.activatedRoute.params.subscribe(params => {
      this.custid = params['custid'];
      this.ptype = params['ptype'];
      if (this.custid && this.ptype) {
             
        searchval.append("cust_id", this.custid);
        if(this.ptype=='c'){
          
          this.date1= this.apiService.formatDate(params['d1']);
          this.date2= this.apiService.formatDate(params['d2']);
          searchval.append("period_type", this.ptype);
          searchval.append("date1", this.date1);
          searchval.append("date2", this.date2);
        }else{
          searchval.append("period_type", this.ptype);
        }
       
        
      }
      
  
    });
    if(this.usr_hide_others_sales_data){
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.apiService.salesSummary(searchval, 1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      this.show = res.data;

      this.salesSum = res['data'].data.data;
     
      this.summary = res.data.data;
      this.repDate = res.data.data.Date;

       // console.log(this.summary);
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.pdf = res.data.pdf;
      this.totalData = res.data.total;
      // console.log(this.pdf);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.searchUser('');
    this.ModelData = false;

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  
    this.getGodownList();
  

    this.country_dec=this.coreService.setDecimalLength();
    this.getAllCustomerCategory();

    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    // 
    // 
  }
  getAllCustomerCategory()
  {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
    });
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.NonVoided','Production.Voided','HOME.credit','Common.cash','HOME.Bank','settings.Multi','Vat.stnderd_rated','Vat.zero_rated','Common.exempt_sales','Common.registerd','Common.non_registerd']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_purchtypes = [
        { id: '1', name: res['Common.NonVoided'] },
        { id: '0', name: res['Production.Voided'] },
    
      ];

      this.filter_paytype = [
        { id: 1, name: res['HOME.credit'] },
        { id: 0, name: res['Common.cash'] },
        { id: 2, name: res['HOME.Bank'] },
        { id: 3, name: res['settings.Multi'] }
    
      ];

      this.filter_custtyp = [
        { id: '1', name:  res['Common.registerd'] },
        { id: '0', name:  res['Common.non_registerd'] },
    
      ];
      this.sales_type = [
        { id: 0, name:  res['Common.all'] },
        { id: 1, name:  res['Vat.stnderd_rated'] },
        { id: 2, name:  res['Vat.zero_rated']},
        { id: 3, name:  res['Common.exempt_sales'] }
      ];
    });

  }
  viewInvDet(inv : any){
    this.dataTransferService.inv_no=inv;
    this.router.navigate(['/sales_invoice']);
  }

  ngAfterContentInit(){
    
    this.searchVans('');
    this.searchComp('');
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;

      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }


  ngOnDestroy(){
    $('.close').click();
 
  }

  pageNext(formdata: { value: any; },newform: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    formdata.value.datewise =  formdata.value.datewise;
    formdata.value.is_year_rep =  newform.value.is_year_rep;
    formdata.value.is_item_disc =  newform.value.is_item_disc;
    if (formdata.value.period_type === 'c') {
      if(formdata.value.time1){
        this.time1 = new Date(formdata.value.time1);
      }
      if(formdata.value.time2){
        this.time2 = new Date(formdata.value.time2);
      }
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    if(this.usr_hide_others_sales_data){
      let hide_other='hide';
      formdata.value.hide_others =  hide_other;
    }
    this.apiService.salesSummary(formdata.value, pageno).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
      this.show = res.data;
      
      this.salesSum = res['data'].data.data;
      this.pdf = res.data.pdf;
      this.summary = res.data.data;
      this.repDate = res.data.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalData = res.data.total;
      // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });

  }


  
  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res:any) => {
      this.company = res.data;
    });

  }

  
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    
    console.log('fayis');
    
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      console.log('if');
       
        searchval.append("with-alias", "1");
      }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res.data;
    });

  }


  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res.data;
    });

  }

  searchUser(search: string)
  {
   let searchval = new FormData();      
   searchval.append("usr_name", search);    
   this.apiService.getUserByName(searchval).subscribe((res:any) => {
        this.users = res.data;     
      });
  }

  searchAgent(search: string)
  {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.agents = res['data'];

    });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res:any) => {
      this.godowns = res.data;
      this.godowns.push({ "gd_id": 0, 'gd_name': 'Shop' });
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

    });

  }

  getGodownList()
  {
       let searchval = new FormData();      
        
       this.apiService.getGodownList(searchval).subscribe((res:any) => {
          this.godowns = res.data;  
          this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }

  getGodownbybranch(bid)
  {
    console.log(bid);
    let searchval = new FormData();      
    searchval.append("bid", bid);
        
    this.apiService.listGodownbyBranch(searchval).subscribe((res) => {
          this.godowns = res.data;  
          this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }


  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    }
    else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    }
    else {
      this.pgstart = false;
    }
  }


  export() {
    // const doc = new jspdf('p', 'pt', 'A4');
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const content = this.reportContent.nativeElement;

    // doc.fromHTML(content.innerHTML,5, 5, {
    //   'width': 1000,
    //   'elementHandlers': specialElementHandlers
    // });
    // doc.setFontSize(5); 
    // doc.save('asdfghj' + '.pdf');
  }




  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
    formdata.value.datewise = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
        if(formdata.value.time1){
          this.time1 = new Date(formdata.value.time1);
        }
        if(formdata.value.time2){
          this.time2 = new Date(formdata.value.time2);
        }
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
    this.apiService.salesSummary(formdata.value,1).subscribe((res: Sales) => {
        this.pageLoadingImg = false;
        // if(res['data']['data']){
        this.Expshow = res.data;
        
        this.ExpsalesSum = res.data.data.data;

        this.Expsummary = res.data.data;
        this.repDate = res.data.data.Date;

        // console.log(this.totalData);


        // }


        setTimeout(() => {
        this.exportexcel();
    }, 3000);



    });



    }

    searchVans(search: string) {
      const searchval = new FormData();
      searchval.append('van_name', search);
      this.apiService.searchVans(searchval).subscribe((res: any) => {
        this.vans = res.data;
        this.vans.push({ van_id: 'All', van_name: 'All' });
      });

    }

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;
     
      // debugger;
    formdata.value.datewise = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
        if(formdata.value.time1){
          this.time1 = new Date(formdata.value.time1);
        }
        if(formdata.value.time2){
          this.time2 = new Date(formdata.value.time2);
        }
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
    this.apiService.salesSummary(formdata.value,1).subscribe((res: Sales) => {
        this.pageLoadingImg = false;
        // if(res['data']['data']){
        this.Expshow = res.data;
        
        this.ExpsalesSum = res.data.data.data;

        this.Expsummary = res.data.data;
        this.repDate = res.data.data.Date;

        const heads = [
          this.translate.instant('Sales.Inv_Amount'),
          this.vatAmount
        ];

        if(this.ModelData){
          heads.splice(0,0, this.translate.instant('Table.sl_no'))
          heads.splice(1,0, this.translate.instant('HOME.Invoice_no'))
          heads.splice(2,0, this.translate.instant('Sales.Name_Of_The_Customer'))
          heads.splice(3,0, this.translate.instant('Common.reference'))
          heads.splice(4,0, this.translate.instant('Sales.Pay_Type'))
          heads.splice(6,0, this.translate.instant('Sales.Item_Disc'))
          heads.splice(7,0, this.translate.instant('Purchase.discount'))
          heads.splice(9,0, this.translate.instant('transaction.Branch_Inv_No'))
          heads.splice(10,0, this.translate.instant('Sales.VAN_Inv_No'))
        }

        if(!this.ModelData){
          heads.splice(0,0, this.translate.instant('Table.date'))
        }

        if(this.pay_type==3){
          heads.splice(11,0, this.translate.instant('HOME.Bank'))
          heads.splice(12,0, this.translate.instant('Common.cash'))
        }
      
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 75;
          const data = [];
      
          this.ExpsalesSum.forEach(item=>{

            const arr=[
              this.currencyPipe.transform(item.tot_amount, '', '', this.country_dec),
              this.currencyPipe.transform(item.tot_tax, '', '', this.country_dec)
            ]

              if(this.summary.show_yr_wise == true){
                arr.splice(0,0, item.sales_date)
              } else if (this.summary.show_yr_wise == false) {
                arr.splice(0,0, this.datePipe.transform(item.sales_date, 'dd/MM/yyyy'))
              }

              if(this.ModelData){
                arr.splice(1,0, ' ')
                arr.splice(2,0, ' ')
                arr.splice(3,0, ' ')
                arr.splice(4,0, ' ')
                arr.splice(6,0, ' ')
                arr.splice(7,0, ' ')
                arr.splice(9 ,0, ' ')
                arr.splice(10 ,0, ' ')
              }

              if(this.pay_type==3){
                arr.splice(11,0, ' ')
                arr.splice(12,0, ' ')
              }

              data.push(arr)

            item.list.forEach((item1,i)=>{

              if(this.ModelData){
                const arr1 = []

              if (this.summary.shodet == true) { 
                arr1.splice(0, 0, i + 1)
                arr1.splice(1, 0, item1.sales_inv_no)
                if (this.translate.currentLang == 'English') {
                  if (this.showNote === true) {
                    arr1.splice(2, 0, item1.sales_cust_name + '\n' + item1.sales_notes)
                  } else {
                    arr1.splice(2, 0, item1.sales_cust_name)
                  }
                } else {
                  if (this.showNote === true) {
                    arr1.splice(2, 0, item1.alias + '\n' + item1.sales_notes)
                  } else {
                    arr1.splice(2, 0, item1.alias)
                  }
                }
                arr1.splice(3, 0, item1.sales_reference)
                arr1.splice(4, 0, this.payType[item1.sales_pay_type])
                arr1.splice(5, 0, this.currencyPipe.transform(item1.sales_total, '', '', this.country_dec))

                if (item1.item_disc) {
                  arr1.splice(6, 0, this.currencyPipe.transform(item1.item_disc.item_disc, '', '', '1.1-1'))
                } else {
                  arr1.splice(6, 0, ' ')
                }

                arr1.splice(7, 0, this.currencyPipe.transform(item1.sales_discount, '', '', '1.1-1'))
                arr1.splice(8, 0, this.currencyPipe.transform(item1.sales_tax, '', '', this.country_dec))
                arr1.splice(9, 0, item1.sales_branch_inv)
                arr1.splice(10, 0, item1.sales_van_inv)

                if (this.pay_type == 3) {
                  arr1.splice(11, 0, this.currencyPipe.transform(item1.bank_cash, '', '', this.country_dec))
                  arr1.splice(12, 0, this.currencyPipe.transform(item1.cash, '', '', this.country_dec))
                }

              }

              data.push(arr1)
              }
              
            })
          })
      
          let doc = this.excelService.getPdfObj();
      
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
      
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
      
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);

          if(this.repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
          }
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Sales.sales_summary')}`,  valueXPos, headerHeight + 15);
      
          if(this.ModelData){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_No_Invoices')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.summary.tot_result,  valueXPos, headerHeight + 20);
          }

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Days')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.summary.tot_days,  valueXPos, headerHeight + 25);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Invoice_Amount')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_inv_amt, '', '', this.country_dec),  valueXPos, headerHeight + 30);

          if(this.cmp_tax==2){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Total GST Amount')}`, lblXPos, headerHeight + 35);

          }else{
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Purchase.Total_VAT_Amount')}`, lblXPos, headerHeight + 35);
          }

         
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_vat_amt, '', '', this.country_dec),  valueXPos, headerHeight + 35);

          if(this.cmp_tax==2){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Total Amount Excl.Tax')}`, lblXPos, headerHeight + 40);

          }else{
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_Amount_Excl_VAT')}`, lblXPos, headerHeight + 40);
          }
         
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_excl_vat, '', '', this.country_dec),  valueXPos, headerHeight + 40);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.total_discount_amount')}`, lblXPos, headerHeight + 45);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_disc_amt, '', '', this.country_dec),  valueXPos, headerHeight + 45);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Promotional_Discount')}`, lblXPos, headerHeight + 50);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_disc_promo, '', '', this.country_dec),  valueXPos, headerHeight + 50);

          if(this.is_item_disc){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_Item_Discount')}`, lblXPos, headerHeight + 55);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_item_disc, '', '', this.country_dec),  valueXPos, headerHeight + 55);
          }

          if(this.pay_type==3){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.ttl_bank_amount')}`, lblXPos, headerHeight + 60);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_bank_amnt, '', '', '1.1-1'),  valueXPos, headerHeight + 60);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.ttl_cash')}`, lblXPos, headerHeight + 65);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.summary.tot_cash_amnt, '', '', '1.1-1'),  valueXPos, headerHeight + 65);
          }

          if(this.summary.godown){
            doc.setFontSize(10);
            doc.text(`Godown`, lblXPos, headerHeight + 70);
            doc.setFontSize(10);
            doc.text(':  ' + this.summary.godown.gd_name,  valueXPos, headerHeight + 70);
          }

          if(this.summary.van){
            doc.setFontSize(10);
            doc.text(`Van`, lblXPos, headerHeight + 75);
            doc.setFontSize(10);
            doc.text(':  ' + this.summary.van.van_name,  valueXPos, headerHeight + 75);
          }

          doc = this.excelService.addTableToPdf(doc, headerHeight + 80, lblXPos, heads, data, null);
      
         
          doc.save(`Sales Summary.pdf`);

          this.exportLoader = false;


    });

}

}
