import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-hsn-report',
  templateUrl: './hsn-report.component.html',
  styleUrls: ['./hsn-report.component.css']
})
export class HsnReportComponent implements OnInit {
  pageLoadingImg: boolean;
  Alldet: any;
  curpage: any;
  lastpage: any;
  fileName = 'hsn_report.xlsx';
  pgend: boolean;
  prd_code:any;
  exportLoader: boolean;
  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  report_type=[
    { id: 1, name: 'GSTR1' },
    { id: 2, name: 'GSTR3' }

  ]
  hsn_count: any;
  total_amount: any;
  taxable_amount: any;
  total_igst: any;
  total_cgst: any;
  total_sgst: any;
  pageFrom: any;
  pgstart: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  stndData: any;
  repDate: any;
  ExprepDate: any;
  country_dec: string;
  


 
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.readProdSumm(1);
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.country_dec=this.coreService.setDecimalLength();
    // this.report_type = 1;
  }
 
  readProdSumm(pageno) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("period_type", '');
    // searchval.append("report_type", '1');

    this.apiService.hsnReport(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data']
      console.log( this.Alldet);
      this.hsn_count=res['hsnCodeCount']
      this.total_amount=res['total_amount']
      this.taxable_amount=res['total_taxable_amount']
      this.total_igst=res['net_total_igst']
      this.total_cgst=res['net_total_cgst']
      this.total_sgst=res['net_total_sgst']
      this.curpage=res['current_page']
      // this.pageFrom = res['from'];
      //  this.lastpage = res['last_page'];
      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);
    
      // this.pageFrom = res['data']['from'];
      // this.curpage = res['data']['current_page'];
      // this.lastpage = res['data']['last_page'];
      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);
      

      
      this.stndData = res.date.Date;
      this.repDate = res.date.Date;


      console.log(this.stndData);

    });


  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  pageNext(formdata: { value: any; }, pageno: any, pagination_for: any) {

    // this.stndData = [];

    // formdata.value = pagination_for;
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.hsnReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data'];
      console.log(this.Alldet,'alldetails');
      console.log( this.Alldet);
      this.hsn_count=res['hsnCodeCount']
      this.total_amount=res['total_amount']
      this.taxable_amount=res['total_taxable_amount']
      this.total_igst=res['net_total_igst']
      this.total_cgst=res['net_total_cgst']
      this.total_sgst=res['net_total_sgst']
      // this.curpage=res['current_page']
      // this.pageFrom = res['from'];
      //  this.lastpage = res['last_page'];
      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);

      // this.pageFrom = res['data']['from'];
      // this.curpage = res['data']['current_page'];
      // console.log(this.curpage,'currentpage');
      
      // this.lastpage = res['data']['last_page'];
      // console.log(this.lastpage,'lastpage');
      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);
      

      // this.stndData = res.Date;
      // this.repDate = res.Date;
      this.stndData = res.date.Date;
      this.repDate = res.date.Date;


      console.log(this.stndData);


    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    
      
    this.apiService.hsnReport(Expformdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data']
      console.log(this.Alldet,'alldetails');
      this.hsn_count=res['hsnCodeCount']
      this.total_amount=res['total_amount']
      this.taxable_amount=res['total_taxable_amount']
      this.total_igst=res['net_total_igst']
      this.total_cgst=res['net_total_cgst']
      this.total_sgst=res['net_total_sgst']
      // this.Alldet = res['data']
      this.ExprepDate = res.date.Date;
      this.repDate = res.date.Date;
      
      setTimeout(() => {
        this.exportexcel();
      }, 3000);


      Expformdata.value.export = '';
      
  
    });


  }

  // pdf download
generatePdf(Expformdata: { value: any; }) {

  this.exportLoader = true;

  // debugger;
  Expformdata.value.export = 'export';

  if (Expformdata.value.period_type === 'c') {
    Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
    Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
  }
  this.apiService.hsnReport(Expformdata.value, 1).subscribe((res) => {
    this.pageLoadingImg = false;
    this.ExprepDate = res.data.Date;
    this.repDate = res.data.Date;
    
    this.Alldet = res['data'];
      console.log(this.Alldet,'alldetails');
      console.log( this.Alldet);
      this.hsn_count=res['hsnCodeCount']
      this.total_amount=res['total_amount']
      this.taxable_amount=res['total_taxable_amount']
      this.total_igst=res['net_total_igst']
      this.total_cgst=res['net_total_cgst']
      this.total_sgst=res['net_total_sgst']

    console.log(this.Alldet);
    
    
    const heads = [
      this.translate.instant('GST.HSN'),
      this.translate.instant('Table.description'),
      this.translate.instant('Quantity Unit Description'),
      this.translate.instant('Common.total_quantity'),
      this.translate.instant('Total Value'),
      this.translate.instant('Purchase.gst_percent'),
      this.translate.instant('HOME.taxable_amount'),
      this.translate.instant('Purchase.igst_amount'),
      this.translate.instant('Purchase.cgst_amount'),
      this.translate.instant('Purchase.sgst_amount')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.Alldet.forEach(item=>{
     
        item.units.forEach(unit => {

          data.push([
            item.prd_hsn_code,
            item.prd_desc,
            unit.unit_name,
            this.currencyPipe.transform(item.total_qty, '', '', this.country_dec),
            this.currencyPipe.transform(item.total_value, '', '', this.country_dec),
            this.currencyPipe.transform(item.prd_tax, '', '', this.country_dec),
            this.currencyPipe.transform(item.total_taxable, '', '', this.country_dec),
            this.currencyPipe.transform(item.total_igst, '', '', this.country_dec),
            this.currencyPipe.transform(item.total_cgst, '', '', this.country_dec),
            this.currencyPipe.transform(item.total_sgst, '', '', this.country_dec)
         
          ])



        })
      })
  
      let doc = this.excelService.getPdfObj();
  
      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');
  
      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);
  
      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('GST.HSN_Summary')}`,  valueXPos, headerHeight + 15);
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('No.of HSN')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.hsn_count,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Total Value')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_amount, '', '', this.country_dec),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Total Taxable Amount')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.taxable_amount, '', '', this.country_dec),  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Igst_Amount')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_igst, '', '', this.country_dec),  valueXPos, headerHeight + 35);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Cgst_Amount')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_cgst, '', '', this.country_dec),  valueXPos, headerHeight + 40);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('GST.Total_Sgst_Amount')}`, lblXPos, headerHeight + 45);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.total_sgst, '', '', this.country_dec),  valueXPos, headerHeight + 45);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 50, lblXPos, heads, data, null);
  
     
      doc.save(`HSN Report.pdf`);


    Expformdata.value.export = '';

    this.exportLoader = false;

  });
  
}



}
