import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ReportRoutingModule } from "./report-routing.module";

// forms
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// share
import { ErpMaterialModule } from "../../material-module";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";

import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
import { APP_BASE_HREF } from "@angular/common";
import { AppComponent } from "../../app.component";
import { NumonlyDirective } from "../../../app/directive/numonly.directive";
import { ComfunctModule } from "../.././common/comfunct/comfunct.module";
// validations

//date picker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";

// components
import { ReportDashComponent } from "./report-dash/report-dash.component";
import { RepProductComponent } from "./report-dash/rep-product/rep-product.component";
import { RepCatagoryComponent } from "./report-dash/rep-product/rep-catagory/rep-catagory.component";
import { RepProductDetailsComponent } from "./report-dash/rep-product/rep-product-details/rep-product-details.component";
import { RepProductSummaryComponent } from "./report-dash/rep-product/rep-product-summary/rep-product-summary.component";
import { RepManSummaryComponent } from "./report-dash/rep-product/rep-man-summary/rep-man-summary.component";
import { RepStockSummaryComponent } from "./report-dash/rep-product/rep-stock-summary/rep-stock-summary.component";
import { ManfDetailsComponent } from "./report-dash/rep-product/manf-details/manf-details.component";
import { RepStockComponent } from "./report-dash/rep-stock/rep-stock.component";
import { RepOpeningstockComponent } from "./report-dash/rep-stock/rep-openingstock/rep-openingstock.component";
import { RepStockbyperiodComponent } from "./report-dash/rep-stock/rep-stockbyperiod/rep-stockbyperiod.component";
import { RepPurchaseComponent } from "./report-dash/rep-purchase/rep-purchase.component";
import { PurchaseSummaryComponent } from "./report-dash/rep-purchase/purchase-summary/purchase-summary.component";
import { PurchaseDetailsComponent } from "./report-dash/rep-purchase/purchase-details/purchase-details.component";
import { PurchaseInvoiceComponent } from "./report-dash/rep-purchase/purchase-invoice/purchase-invoice.component";
import { ProductInvoiceComponent } from "./report-dash/rep-purchase/product-invoice/product-invoice.component";
import { RepGodownComponent } from "./report-dash/rep-godown/rep-godown.component";
import { GodownStockSummaryComponent } from "./report-dash/rep-godown/godown-stock-summary/godown-stock-summary.component";
import { GodownTransferComponent } from "./report-dash/rep-godown/godown-transfer/godown-transfer.component";
import { GodownProductComponent } from "./report-dash/rep-godown/godown-product/godown-product.component";
import { GodownTransactionComponent } from "./report-dash/rep-godown/godown-transaction/godown-transaction.component";
import { VanTransferComponent } from "./report-dash/rep-van/van-transfer/van-transfer.component";
import { RepVanComponent } from "./report-dash/rep-van/rep-van.component";
import { VanTransferItemComponent } from "./report-dash/rep-van/van-transfer-item/van-transfer-item.component";
import { VanTransferInvoiceComponent } from "./report-dash/rep-van/van-transfer-invoice/van-transfer-invoice.component";
import { VanTransferDatewiseComponent } from "./report-dash/rep-van/van-transfer-datewise/van-transfer-datewise.component";
import { RepCompanyDetComponent } from "./report-dash/rep-product/rep-company-det/rep-company-det.component";

import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { PurchaseReturnSummaryComponent } from "./report-dash/rep-purchase/purchase-return-summary/purchase-return-summary.component";
import { PurchaseReturnProductComponent } from "./report-dash/rep-purchase/purchase-return-product/purchase-return-product.component";
import { ProductionSummComponent } from "./report-dash/rep-production/production-summ/production-summ.component";
import { ProductionDashComponent } from "./report-dash/rep-production/production-dash/production-dash.component";
import { ProductionDetailsComponent } from "./report-dash/rep-production/production-details/production-details.component";
import { ProductionIncredientsComponent } from "./report-dash/rep-production/production-incredients/production-incredients.component";
import { ProductionCommComponent } from "./report-dash/rep-production/production-comm/production-comm.component";
import { ProductionProductComponent } from "./report-dash/rep-production/production-product/production-product.component";
import { PurchaseAndReturnReportSummaryComponent } from "./report-dash/rep-purchase/purchase-and-return-report-summary/purchase-and-return-report-summary.component";
import { RepStockExpiryComponent } from "./report-dash/rep-stock/rep-stock-expiry/rep-stock-expiry.component";
import { SalesSammaryComponent } from "./report-dash/rep-sales/sales-sammary/sales-sammary.component";
import { RepSalesComponent } from "./report-dash/rep-sales/rep-sales.component";
import { TestComponent } from "./report-dash/test/test.component";
import { SaleProdInvoiceComponent } from "./report-dash/rep-sales/sale-prod-invoice/sale-prod-invoice.component";
import { PrintSalesInvoiceComponent } from "./report-dash/rep-sales/print-sales-invoice/print-sales-invoice.component";
import { NgxPrintModule } from "ngx-print";
import { SalesProdRepComponent } from "./report-dash/rep-sales/sales-prod-rep/sales-prod-rep.component";
import { RepLedgerComponent } from "./report-dash/rep-ledger/rep-ledger.component";
import { LedgerDetailComponent } from "./report-dash/rep-ledger/ledger-detail/ledger-detail.component";
import { ReportCompanyComponent } from "./report-dash/report-company/report-company.component";
import { AccDaybookComponent } from "./report-dash/rep-accounts/acc-daybook/acc-daybook.component";
import { RepVatInvoiceComponent } from "./report-dash/rep-vat-invoice/rep-vat-invoice.component";
import { InputVatSummaryComponent } from "./report-dash/rep-vat-invoice/input-vat-summary/input-vat-summary.component";
import { InputExpenseVatSummaryComponent } from "./report-dash/rep-vat-invoice/input-expense-vat-summary/input-expense-vat-summary.component";
import { DailyVatSummaryComponent } from "./report-dash/rep-vat-invoice/daily-vat-summary/daily-vat-summary.component";
import { OutputVatSummaryComponent } from "./report-dash/rep-vat-invoice/output-vat-summary/output-vat-summary.component";
import { RepBranchTransferComponent } from "./report-dash/rep-branch-transfer/rep-branch-transfer.component";
import { TransferComponent } from "./report-dash/rep-branch-transfer/transfer/transfer.component";
import { ReceiptComponent } from "./report-dash/rep-branch-transfer/receipt/receipt.component";
import { SalesVoidSummaryComponent } from "./report-dash/rep-sales/sales-void-summary/sales-void-summary.component";
import { LedgerListComponent } from "./report-dash/rep-accounts/ledger-list/ledger-list.component";
import { RepGrpSummaryComponent } from "./report-dash/rep-grp-summary/rep-grp-summary.component";
import { AccGrpSummaryComponent } from "./report-dash/rep-grp-summary/acc-grp-summary/acc-grp-summary.component";
import { TrialBalanceComponent } from "./report-dash/rep-accounts/trial-balance/trial-balance.component";
import { OpBalanceSummaryComponent } from "./report-dash/rep-accounts/op-balance-summary/op-balance-summary.component";
import { SummaryComponent } from "./report-dash/rep-branch-transfer/summary/summary.component";
import { ReceiptsummaryComponent } from "./report-dash/rep-branch-transfer/receiptsummary/receiptsummary.component";
import { BranchWiseComponent } from "./report-dash/rep-stock/branch-wise/branch-wise.component";
import { InvoiceDueComponent } from "./report-dash/rep-sales/invoice-due/invoice-due.component";
import { VerificationOfBalanceComponent } from "./report-dash/rep-accounts/verification-of-balance/verification-of-balance.component";
import { RepDamageSummaryComponent } from "./report-dash/rep-product/rep-damage-summary/rep-damage-summary.component";
import { ProfitAndLossComponent } from "./report-dash/rep-accounts/profit-and-loss/profit-and-loss.component";
import { SalesandreturnComponent } from "./report-dash/rep-sales/saleswithreturn/salesandreturn/salesandreturn.component";
import { EditlogComponent } from "./report-dash/rep-purchase/editlog/editlog.component";
import { CustomeragingComponent } from "./report-dash/rep-sales/customeraging/customeraging.component";
import { BalanceSheetComponent } from "./report-dash/rep-accounts/balance-sheet/balance-sheet.component";
import { ReturnedproductsComponent } from "./report-dash/rep-sales/returnedproducts/returnedproducts.component";
import { CustomersalesComponent } from "./report-dash/rep-sales/customersales/customersales.component";
import { RepAuditorComponent } from "./report-dash/rep-auditor/rep-auditor.component";
import { CashCreditPurchaseComponent } from "./report-dash/rep-auditor/cash-credit-purchase/cash-credit-purchase.component";
import { SalesCashCreditComponent } from "./report-dash/rep-auditor/sales-cash-credit/sales-cash-credit.component";
import { BranchTransferComponent } from "./report-dash/rep-auditor/branch-transfer/branch-transfer.component";
import { ExpensesReportComponent } from "./report-dash/rep-auditor/expenses-report/expenses-report.component";
import { BanksComponent } from "./report-dash/rep-auditor/banks/banks.component";
import { EmployeeComponent } from "./report-dash/rep-auditor/employee/employee.component";
import { VatDailySummaryComponent } from "./report-dash/rep-vat-invoice/vat-daily-summary/vat-daily-summary.component";
import { SaleDetailRepComponent } from "./report-dash/rep-auditor/sale-detail-rep/sale-detail-rep.component";
import { RepMissingSummaryComponent } from "./report-dash/rep-stock/rep-missing-summary/rep-missing-summary.component";
import { RepExcessSummaryComponent } from "./report-dash/rep-stock/rep-excess-summary/rep-excess-summary.component";
import { RepLowStockAlertComponent } from "./report-dash/rep-stock/rep-low-stock-alert/rep-low-stock-alert.component";
import { RepExcessStockAlertComponent } from "./report-dash/rep-stock/rep-excess-stock-alert/rep-excess-stock-alert.component";
import { RepBranchTranInvBasedComponent } from "./report-dash/rep-branch-transfer/rep-branch-tran-inv-based/rep-branch-tran-inv-based.component";
import { RepBranchReciInvBasedComponent } from "./report-dash/rep-branch-transfer/rep-branch-reci-inv-based/rep-branch-reci-inv-based.component";
import { RepStockVerifySummaryComponent } from "./report-dash/rep-stock/rep-stock-verify-summary/rep-stock-verify-summary.component";
import { RepPurchaseDetailSummaryComponent } from "./report-dash/rep-purchase/rep-purchase-detail-summary/rep-purchase-detail-summary.component";
import { VanStockTrackingComponent } from "./report-dash/rep-van/van-stock-tracking/van-stock-tracking.component";
import { VanDistributorsRepComponent } from "./report-dash/rep-van/van-distributors-rep/van-distributors-rep.component";
import { RepItemMovmentComponent } from "./report-dash/rep-stock/rep-item-movment/rep-item-movment.component";
import { RepStockVerificationInvBasedComponent } from "./report-dash/rep-stock/rep-stock-verification-inv-based/rep-stock-verification-inv-based.component";
import { InvDueRecSummaryComponent } from "./report-dash/rep-sales/inv-due-rec-summary/inv-due-rec-summary.component";
import { RepCashBankBookComponent } from "./report-dash/rep-accounts/rep-cash-bank-book/rep-cash-bank-book.component";
import { GodownDetailsComponent } from "./report-dash/rep-godown/godown-details/godown-details.component";
import { RepCustomersComponent } from "./report-dash/rep-customers/rep-customers.component";
import { CashCounterReportComponent } from "./report-dash/rep-sales/cash-counter-report/cash-counter-report.component";
import { RepTopCustomersComponent } from "./report-dash/rep-top-customers/rep-top-customers.component";
import { RepTopProductsComponent } from "./report-dash/rep-top-products/rep-top-products.component";
//QR
import { QRCodeModule } from 'angularx-qrcode';
import { VanDetailedRepComponent } from './report-dash/rep-van/van-detailed-rep/van-detailed-rep.component';
import { BranchDetailedRepComponent } from './report-dash/rep-branch-transfer/branch-detailed-rep/branch-detailed-rep.component';
import { ItemTrackingRepComponent } from './report-dash/rep-product/item-tracking-rep/item-tracking-rep.component';
import { RepExtTransferComponent } from './report-dash/rep-ext-transfer/rep-ext-transfer.component';
import { ExtTransferInvBasedComponent } from './report-dash/rep-ext-transfer/ext-transfer-inv-based/ext-transfer-inv-based.component';
import { ExtTransferDetComponent } from './report-dash/rep-ext-transfer/ext-transfer-det/ext-transfer-det.component';
import { ExtReceiptInvBasedComponent } from './report-dash/rep-ext-transfer/ext-receipt-inv-based/ext-receipt-inv-based.component';
import { ExtReceiptDetComponent } from './report-dash/rep-ext-transfer/ext-receipt-det/ext-receipt-det.component';
import { ExpenseTaxPaymentSummaryComponent } from './report-dash/rep-accounts/expense-tax-payment-summary/expense-tax-payment-summary.component';
import { ItemMovementDetailsComponent } from './report-dash/rep-stock/item-movement-details/item-movement-details.component';
import { SalesAgentReportComponent } from './report-dash/rep-sales/sales-agent-report/sales-agent-report.component';
import { VanTopsheetRepComponent } from './report-dash/rep-van/van-topsheet-rep/van-topsheet-rep.component';
import { StockUnitReportComponent } from './report-dash/rep-stock/stock-unit-report/stock-unit-report.component';
import { VanStockTrakingUnitwiseComponent } from './report-dash/rep-van/van-stock-traking-unitwise/van-stock-traking-unitwise.component';
import { VanMissingStockComponent } from './report-dash/rep-van/van-missing-stock/van-missing-stock.component';
import { VanExcessStockRepComponent } from './report-dash/rep-van/van-excess-stock-rep/van-excess-stock-rep.component';
import { CostCategoryComponent } from './report-dash/rep-accounts/cost-category/cost-category.component';
import { ItemUnitwiseTrackingComponent } from './report-dash/rep-stock/item-unitwise-tracking/item-unitwise-tracking.component';
import { SalesQuoatationSummaryComponent } from './report-dash/rep-sales/sales-quoatation-summary/sales-quoatation-summary.component';
import { DeliveryChallanRepComponent } from './report-dash/rep-sales/delivery-challan-rep/delivery-challan-rep.component';
import { DeliveryChallanItemwiseComponent } from './report-dash/rep-sales/delivery-challan-itemwise/delivery-challan-itemwise.component';
import { RepWarrantySummaryComponent } from './report-dash/rep-stock/rep-warranty-summary/rep-warranty-summary.component';
import { SalesOrderRepComponent } from './report-dash/rep-sales/sales-order-rep/sales-order-rep.component';
import { GodownStockDatewiseComponent } from './report-dash/rep-godown/godown-stock-datewise/godown-stock-datewise.component';
import { DailySummaryComponent } from './report-dash/rep-accounts/daily-summary/daily-summary.component';
import { PurchaseOrderRepComponent } from './report-dash/rep-purchase/purchase-order-rep/purchase-order-rep.component';
import { CreditNoteRepComponent } from './report-dash/rep-accounts/credit-note-rep/credit-note-rep.component';
import { DebitNoteRptComponent } from './report-dash/rep-accounts/debit-note-rpt/debit-note-rpt.component';
import { CustomerDetailsComponent } from './report-dash/rep-accounts/customer-details/customer-details.component';
import { PurchInvoiceDueComponent } from './report-dash/rep-purchase/purch-invoice-due/purch-invoice-due.component';
import { SupplierAgingRptComponent } from './report-dash/rep-accounts/supplier-aging-rpt/supplier-aging-rpt.component';
import { RecieptSummaryComponent } from './report-dash/rep-accounts/reciept-summary/reciept-summary.component';
import { CustMovmentRptComponent } from './report-dash/rep-accounts/cust-movment-rpt/cust-movment-rpt.component';
import { InvoiceDuePaymentSummaryComponent } from './report-dash/rep-purchase/invoice-due-payment-summary/invoice-due-payment-summary.component';
import { RepTopSuppliersComponent } from './report-dash/rep-top-suppliers/rep-top-suppliers.component';
import { BatchWiseComponent } from './report-dash/rep-stock/batch-wise/batch-wise.component';
import { RepBatchstockbyperiodComponent } from './report-dash/rep-stock/rep-batchstockbyperiod/rep-batchstockbyperiod.component';
import { VanSaleRepComponent } from './report-dash/rep-van/van-sale-rep/van-sale-rep.component';
import { VanItemwiseRepComponent } from './report-dash/rep-van/van-itemwise-rep/van-itemwise-rep.component';
import { RepCashBookComponent } from './report-dash/rep-accounts/rep-cash-book/rep-cash-book.component';
import { VisitingRepComponent } from './report-dash/rep-van/visiting-rep/visiting-rep.component';
import { RepProductCommisionComponent } from './report-dash/rep-product/rep-product-commision/rep-product-commision.component';
import { VanCalenderComponent } from './report-dash/rep-van/van-calender/van-calender.component';
import { CategoryReportComponent } from './report-dash/rep-product/category-report/category-report.component';
import { FreeGoodsReportsComponent } from './report-dash/rep-stock/free-goods-reports/free-goods-reports.component';
import { VanStockverifyRepComponent } from './report-dash/rep-van/van-stockverify-rep/van-stockverify-rep.component';
import { VanCustomerTransactionComponent } from './report-dash/rep-van/van-customer-transaction/van-customer-transaction.component';
import { TransactionModule } from "../transaction/transaction.module";
import { BranchStockComponent } from './report-dash/rep-stock/branch-stock/branch-stock.component';
import { SaleRepBySerialNoComponent } from './report-dash/rep-sales/sale-rep-by-serial-no/sale-rep-by-serial-no.component';
import { RebateReportComponent } from './report-dash/rep-purchase/rebate-report/rebate-report.component';
import { GoodsRecieptReportComponent } from './report-dash/rep-purchase/goods-reciept-report/goods-reciept-report.component';
import { SupplierDetailsComponent } from './report-dash/rep-accounts/supplier-details/supplier-details.component';
import { ProDetailedRepComponent } from './report-dash/rep-product/pro-detailed-rep/pro-detailed-rep.component';
import { RepGstDashComponent } from './report-dash/rep-gst-dash/rep-gst-dash.component';
import { RepGstSummaryComponent } from './report-dash/rep-gst-dash/rep-gst-summary/rep-gst-summary.component';
import { HsnReportComponent } from './report-dash/rep-gst-dash/hsn-report/hsn-report.component';
import { GstrThreeComponent } from './report-dash/rep-gst-dash/gstr-three/gstr-three.component';
import { RepStockbyperiodOldComponent } from './report-dash/rep-stock/rep-stockbyperiod-old/rep-stockbyperiod-old.component';

@NgModule({
  declarations: [
    ReportDashComponent,
    RepProductComponent,
    RepCatagoryComponent,
    RepProductDetailsComponent,
    RepProductSummaryComponent,
    RepManSummaryComponent,
    RepStockSummaryComponent,
    ManfDetailsComponent,
    RepStockComponent,
    RepOpeningstockComponent,
    RepStockbyperiodComponent,
    RepPurchaseComponent,
    PurchaseSummaryComponent,
    PurchaseDetailsComponent,
    PurchaseInvoiceComponent,
    ProductInvoiceComponent,
    RepGodownComponent,
    GodownStockSummaryComponent,
    GodownTransferComponent,
    GodownProductComponent,
    GodownTransactionComponent,
    VanTransferComponent,
    RepVanComponent,
    VanTransferItemComponent,
    VanTransferInvoiceComponent,
    VanTransferDatewiseComponent,
    RepCompanyDetComponent,
    PurchaseReturnSummaryComponent,
    PurchaseReturnProductComponent,
    ProductionSummComponent,
    ProductionDashComponent,
    ProductionDetailsComponent,
    ProductionIncredientsComponent,
    ProductionCommComponent,
    ProductionProductComponent,
    PurchaseAndReturnReportSummaryComponent,
    RepStockExpiryComponent,
    SalesSammaryComponent,
    RepSalesComponent,
    TestComponent,
    SaleProdInvoiceComponent,
    PrintSalesInvoiceComponent,
    SalesProdRepComponent,
    RepLedgerComponent,
    LedgerDetailComponent,
    ReportCompanyComponent,
    AccDaybookComponent,
    RepVatInvoiceComponent,
    InputVatSummaryComponent,
    InputExpenseVatSummaryComponent,
    DailyVatSummaryComponent,
    OutputVatSummaryComponent,

    RepBranchTransferComponent,
    TransferComponent,
    ReceiptComponent,
    SalesVoidSummaryComponent,
    LedgerListComponent,
    RepGrpSummaryComponent,
    AccGrpSummaryComponent,
    TrialBalanceComponent,
    OpBalanceSummaryComponent,
    SummaryComponent,
    ReceiptsummaryComponent,
    BranchWiseComponent,
    InvoiceDueComponent,
    VerificationOfBalanceComponent,
    RepDamageSummaryComponent,
    ProfitAndLossComponent,
    SalesandreturnComponent,
    EditlogComponent,
    CustomeragingComponent,

    BalanceSheetComponent,

    ReturnedproductsComponent,

    CustomersalesComponent,

    RepAuditorComponent,

    CashCreditPurchaseComponent,

    SalesCashCreditComponent,

    BranchTransferComponent,

    ExpensesReportComponent,

    BanksComponent,

    EmployeeComponent,

    VatDailySummaryComponent,

    SaleDetailRepComponent,

    RepMissingSummaryComponent,

    RepExcessSummaryComponent,

    RepLowStockAlertComponent,

    RepExcessStockAlertComponent,

    RepBranchTranInvBasedComponent,

    RepBranchReciInvBasedComponent,

    RepStockVerifySummaryComponent,

    RepPurchaseDetailSummaryComponent,

    VanStockTrackingComponent,

    VanDistributorsRepComponent,

    RepItemMovmentComponent,

    RepStockVerificationInvBasedComponent,

    InvDueRecSummaryComponent,

    RepCashBankBookComponent,

    GodownDetailsComponent,

    RepCustomersComponent,

    CashCounterReportComponent,
    RepTopCustomersComponent,

    RepTopProductsComponent,

    VanDetailedRepComponent,

    BranchDetailedRepComponent,

    ItemTrackingRepComponent,

    RepExtTransferComponent,

    ExtTransferInvBasedComponent,

    ExtTransferDetComponent,

    ExtReceiptInvBasedComponent,

    ExtReceiptDetComponent,

    ExpenseTaxPaymentSummaryComponent,

    ItemMovementDetailsComponent,

    SalesAgentReportComponent,

    VanTopsheetRepComponent,

    StockUnitReportComponent,

    VanStockTrakingUnitwiseComponent,
    VanMissingStockComponent,
    VanExcessStockRepComponent,
    CostCategoryComponent,
    ItemUnitwiseTrackingComponent,
    SalesQuoatationSummaryComponent,
    DeliveryChallanRepComponent,
    DeliveryChallanItemwiseComponent,
    RepWarrantySummaryComponent,
    SalesOrderRepComponent,
    GodownStockDatewiseComponent,
    DailySummaryComponent,
    PurchaseOrderRepComponent,
    CreditNoteRepComponent,
    DebitNoteRptComponent,
    CustomerDetailsComponent,
    PurchInvoiceDueComponent,
    SupplierAgingRptComponent,
    RecieptSummaryComponent,
    CustMovmentRptComponent,
    InvoiceDuePaymentSummaryComponent,
    RepTopSuppliersComponent,
    BatchWiseComponent,
    RepBatchstockbyperiodComponent,
    VanSaleRepComponent,
    VanItemwiseRepComponent,
    RepCashBookComponent,
    VisitingRepComponent,
    RepProductCommisionComponent,
    VanCalenderComponent,
    CategoryReportComponent,
    FreeGoodsReportsComponent,
    VanStockverifyRepComponent,
    VanCustomerTransactionComponent,
    BranchStockComponent,
    SaleRepBySerialNoComponent,
    RebateReportComponent,
    GoodsRecieptReportComponent,
    SupplierDetailsComponent,
    ProDetailedRepComponent,
    RepGstDashComponent,
    RepGstSummaryComponent,
    HsnReportComponent,
    GstrThreeComponent,
    RepStockbyperiodOldComponent,
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    ErpMaterialModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DateInputsModule,
    ComfunctModule,
    TransactionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ,NgxPrintModule,QRCodeModule],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }, NumonlyDirective,DatePipe], 
  bootstrap: [AppComponent]
})
export class ReportModule {}
