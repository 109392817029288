import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { ProductionService } from '../../../../../service/production.service';
import { User } from '../../../../../model/user.model';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-production-summ',
  templateUrl: './production-summ.component.html',
  styleUrls: ['./production-summ.component.css']
})
export class ProductionSummComponent implements OnInit {

  fileName= 'production_summary.xlsx';

  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgstart: boolean;
  pgend: boolean;
  datas: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: string;
  prd_name: string;
  pid1: number;
  pid2: number;
  categories: string[];
  prd_formula: string;
  pcost1: any;
  pcost2: any;
  prate1: any;
  prate2: any;
  pamount1: any;
  pamount2: any;
  pcomm1: any;
  pcomm2: any;
  pmisc1: any;
  pmisc2: any;
  prod_id: any;
  prod_cost: any;
  prod_rate: any;
  prod_amount: any;
  prod_comm: any;
  prod_misc: any;
  prod_cat_id: any;
  tableCont: boolean;
  values: string[];
  isnoData : boolean;
  usr_id:any;
  filter:any;
  repDate:any;
  up: any;
  formula_all: any;
  country_dec: string;
  branch_address:any;
  branch_display_name: any;
  exportLoader: boolean;
  branch_name:any;
  branch_display_code:any;
  filter_branch_id: any;
  branch_all: any;
  userType: any;

  constructor(private productionService: ProductionService, private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,) { }

  ngOnInit() {

    this.branch_address = this.coreService.getUserData('branch_address');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');


    this.filter_branch_id = 1;
    this.getAllBranch();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
  
    this.country_dec=this.coreService.setDecimalLength();
    this.readProdSumm(1);    
    this.up = this.coreService.getUserPrivilage();
    this.listProductFormula();
    this.userType = this.coreService.getUserData('user_type');

  }
  
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterDate = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  readProdSumm(pageno) {
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();
    this.productionService.prodSumm(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data']['Date'];
      if(this.datas.length==0)
      this.isnoData = true;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.filter = 1;
  
    this.pageLoadingImg = true;
    this.isnoData = false;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.productionService.prodSumm(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data']['Date'];

      if(this.datas.length==0)
      this.isnoData = true;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  listProductFormula() { 
    this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
      this.formula_all = res.data.data;
    });
  }
  searchFormula(search: string) {
    const searchval = new FormData();
    searchval.append('formula_name', search);
    this.productionService.searchProductionFormula(searchval).subscribe((res) => {
      this.formula_all = res.data;
    });
  }

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];

  //form search  for category
  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.categories = res['data'];
    });
  }

  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  exportexcel(): void{
     /* table id is passed over here */
     let element = document.getElementById('export-group');
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;

  }

  exportEXCL(formdata: { value: any; }){
    
    formdata.value.filter = 1;
    this.pageLoadingImg = true;

    this.isnoData = false;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.productionService.prodSumm(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data']['Date'];

      if(this.datas.length==0)
      this.isnoData = true;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

      setTimeout(() => {
        this.exportexcel();
    }, 3000);

    });

    }

    // pdf download
    generatePdf(formdata: { value: any; }) {

      this.exportLoader = true;

      formdata.value.filter = 1;
  
      this.isnoData = false;
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      }
      this.productionService.prodSumm(formdata.value, 1).subscribe((res: any) => {
        this.datas = res['data']['data']['data'];
        this.curpage = res['data']['data']['current_page'];
        this.lastpage = res['data']['data']['last_page'];
        this.repDate = res['data']['Date'];
  
        if(this.datas.length==0)
        this.isnoData = true;
  
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
  
        const heads = [
          this.translate.instant('Table.sl_no'),
          this.translate.instant('Van.Prdn'),
          this.translate.instant('Production.formula_name'),
          this.translate.instant('Common.notes'),
          this.translate.instant('Production.total_cost'),
          this.translate.instant('HOME.Purchase_Amount'),
          this.translate.instant('Purchase.commissions'),
          this.translate.instant('Van.Misc_Expence')
        ];
    
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 35;
          const data = [];
  
          this.datas.forEach((item,i)=>{
            data.push([
              i+1,
              item.id,
              item.formula,
              item.prdn_note,
              this.currencyPipe.transform(item.total_cost, '', '', this.country_dec),
              this.currencyPipe.transform(item.incr_amount, '', '', this.country_dec),
              this.currencyPipe.transform(item.commission, '', '', this.country_dec),
              this.currencyPipe.transform(item.misc_exp, '', '', this.country_dec)
            ])
          })
      
          let doc = this.excelService.getPdfObj();
  
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
  
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
  
          if(this.repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
          }
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Van.production_summary')}`,  valueXPos, headerHeight + 15);
  
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
      
         
          doc.save(`Production Summary.pdf`);

          this.exportLoader = true;
  
      });
      
    }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

}
