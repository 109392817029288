import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
// routing
import { LedgerRoutingModule} from './ledger-routing.module';

// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../app.component';
import { NumonlyDirective } from '../../../app/directive/numonly.directive';
// validations

//date picker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// component
import { LedgerDashComponent } from './ledger-dash/ledger-dash.component';
import { SupplierComponent } from './ledger-dash/supplier/supplier.component';
import { StaffComponent } from './ledger-dash/staff/staff.component';
import { CustomerComponent } from './ledger-dash/customer/customer.component';
import { SalesAgentComponent } from './ledger-dash/sales-agent/sales-agent.component';
import { ImportCustomerComponent } from './ledger-dash/import-customer/import-customer.component';
import { ImportSuppierComponent } from './ledger-dash/import-supplier/import-supplier.component';
import { CusomerCategoryComponent } from './ledger-dash/cusomer-category/cusomer-category.component';
import { CustomerPopupComponent } from './ledger-dash/customer-popup/customer-popup.component';
import { SupplierPopupComponent } from './ledger-dash/supplier-popup/supplier-popup.component';




@NgModule({
  declarations: [LedgerDashComponent, SupplierComponent, StaffComponent, CustomerComponent, SalesAgentComponent, ImportCustomerComponent, ImportSuppierComponent, CusomerCategoryComponent, CustomerPopupComponent, SupplierPopupComponent],
  imports: [
    CommonModule, 
    LedgerRoutingModule,
    ErpMaterialModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }, NumonlyDirective,DatePipe], 
  bootstrap: [AppComponent],
  exports:[
    CustomerPopupComponent, SupplierPopupComponent
  ]
})
export class LedgerModule { }
