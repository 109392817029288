import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  period_type: string | Blob;
  pageLoadingImg: boolean;
  empSum: any;
  summary: any;
  repDate: any;
  opBalance: any;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  fileName= 'Employee.xlsx';


  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_cmptype = [
    { id: '1', name: 'Establishment' },
    { id: '2', name: 'Company' },

  ];

  exportLoader: boolean;
  ExpsalesSum: any;
  Expsummary: any;
  userType: any;
  branch_all: any;
  filter_branch_id: any;
  cmp_type: any;
  ExpAlldet: any;
  Alldet: any;
  cmpExist: boolean;
  country_dec: string;

  branch_display_name: any;
  branch_display_code: any;
  branch_address: any;
  
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.userType = this.coreService.getUserData('user_type');
 
    this.period_type = 't';

    this.cmp_type = '1';
    this.cmpExist = true;

    this.changeCmpType(1);

    let searchval = new FormData();
    searchval.append("period_type", 't');
    searchval.append("cmp_type",this.cmp_type);

    this.apiService.employeeReportAuditor(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false;

      this.empSum = res['data']['data'];
     
      this.summary = res['data']['info'].branch;
      this.repDate = res['data']['info'].Date;
      this.opBalance = res['data'].op_bal;
      this.Alldet = res['data'].Summary;

      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      //  console.log(this.pdf);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  changeCmpType(cmptype) {
    let searchval = new FormData();
    searchval.append("cmp_type", cmptype);
    this.filter_branch_id = [];

    this.apiService.getBranchbyType(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      this.checkBranch();

      // if (this.userType == 'ADMIN') {
      //   this.branch_all.push({ 'branch_id': '', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }

  checkBranch(){
    if(this.filter_branch_id.length > 0)
    {
      this.cmpExist = true;
    }else{
      this.cmpExist = false;
    }
  }

  pageNext(formdata: { value: any; },newform: { value: any; }, pageno: any) {
    this.empSum = [];
    this.pageLoadingImg = true;
    formdata.value.datewise =  newform.value.datewise;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      // formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      // formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.employeeReportAuditor(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      
      this.empSum = res['data']['data'];
      this.summary = res['data']['info'].branch;
      this.opBalance = res['data'].op_bal;
      this.Alldet = res['data'].Summary;

      this.repDate = res['data']['info'].Date;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  




  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      formdata.value.datewise = true;
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        // formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        // formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
      this.apiService.employeeReportAuditor(formdata.value,1).subscribe((res) => {
        this.pageLoadingImg = false;
        // if(res['data']['data']){
          // this.Expshow = res['data'];
        
        this.ExpsalesSum = res['data']['data'];
       
        this.Expsummary = res['data']['info'].branch;
        this.repDate = res['data']['info'].Date;
        this.opBalance = res['data'].op_bal;
        this.ExpAlldet = res['data'].Summary;
      
        // console.log(this.totalData);
  
      
        // }
      
     
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
   

    });



    }


          // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
     
    // debugger;
    formdata.value.datewise = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      // formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      // formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.employeeReportAuditor(formdata.value,1).subscribe((res) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
        // this.Expshow = res['data'];
      
      this.ExpsalesSum = res['data']['data'];
     
      // this.Expsummary = res['data']['info'].branch;
      this.repDate = res['data']['info'].Date;
      this.opBalance = res['data'].op_bal;
      this.ExpAlldet = res['data'].Summary;
    
      const heads = [
        this.translate.instant('Table.date'),
        this.translate.instant('Auditor.Employee_name'),
        this.translate.instant('Common.Opening_Balance'),
        this.translate.instant('HOME.debit'),
        this.translate.instant('HOME.credit'),
        this.translate.instant('Common.Balance'),
        this.translate.instant('Table.description')
      ];
    
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];

        if(this.curpage == '1'){
          const arr=[
            this.datePipe.transform(this.opBalance.vch_date, 'dd/MM/yyyy'),
            '',
            '',
            this.currencyPipe.transform(this.opBalance.sum_vch_in, '', '', this.country_dec),
            this.currencyPipe.transform(this.opBalance.sum_vch_out, '', '', this.country_dec),
            this.currencyPipe.transform(this.opBalance.bal, '', '', this.country_dec),
            this.opBalance.desc
          ]

          data.push(arr)
        }
    
        this.ExpsalesSum.forEach(item=>{
          const arr=[
            this.datePipe.transform(item.vch_date, 'dd/MM/yyyy'),
            item.ledger_name,
            this.currencyPipe.transform(item.open_bal, '', '', this.country_dec),
            this.currencyPipe.transform(item.sum_vch_in, '', '', this.country_dec),
            this.currencyPipe.transform(item.sum_vch_out, '', '', this.country_dec),
            this.currencyPipe.transform(item.total_openbal, '', '', this.country_dec),
            item.desc
          ]
    
          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
    
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
    
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
    
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Auditor.employee_report')} `,  valueXPos, headerHeight + 15);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.CR_No')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.summary.branch_reg_no,  valueXPos, headerHeight + 20);

        if(this.userType == 'ADMIN'){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Auditor.Company_type')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.Alldet.cmp_type,  valueXPos, headerHeight + 25);
        }
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Branch.Activity')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + ' ',  valueXPos, headerHeight + 30);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Branch.Mobile No')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + this.summary.branch_mob,  valueXPos, headerHeight + 35);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Branch.Charge Person')}`, lblXPos, headerHeight + 40);
        doc.setFontSize(10);
        doc.text(':  ' + ' ',  valueXPos, headerHeight + 40);

        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 45, lblXPos, heads, data, null);
    
       
        doc.save(`Employee Report.pdf`);
    
        this.exportLoader = false;
  
 

  });
    
  }

}
