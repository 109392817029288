import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-sales-ret-einvoicing-simplified-tax-invoice',
  templateUrl: './sales-ret-einvoicing-simplified-tax-invoice.component.html',
  styleUrls: ['./sales-ret-einvoicing-simplified-tax-invoice.component.css']
})
export class SalesRetEinvoicingSimplifiedTaxInvoiceComponent implements OnInit {

  @Input()salesRetPreview;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()footImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
  }

}
