import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-missing-summary',
  templateUrl: './rep-missing-summary.component.html',
  styleUrls: ['./rep-missing-summary.component.css']
})
export class RepMissingSummaryComponent implements OnInit {
  fileName = 'missing_stock_summary.xlsx';
  exportLoader: boolean;
  exp_repDate: any;
  exp_summary: any;
  exp_tot_items: any;
  exp_total_qty: any;
  exp_tot_purch_amt: any;
  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;

  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  pageFrom: any;
  summary: any;
  repDate: any;
  tot_items: any;
  total_qty: any;
  tot_purch_amt: any;
  qty_filter: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  products: any[];
  catagories: any;
  users: any;
  branch_all: any;
  userType: any;
  godowns: any;
  gd_id: any;
  country_dec: string;
  cur_lang: string;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    let searchval = new FormData();
   
    this.apiService.misiingStockSummaryReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];

      this.tot_items = res['data']['total']['tot_items'];
      this.total_qty = res['data']['total']['total_qty'];
      this.tot_purch_amt = res['data']['total']['tot_purch_amt'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.userType = this.coreService.getUserData('user_type');
    this.getGodownList();
    this.gd_id = '';

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.misiingStockSummaryReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];

      this.tot_items = res['data']['total']['tot_items'];
      this.total_qty = res['data']['total']['total_qty'];
      this.tot_purch_amt = res['data']['total']['tot_purch_amt'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }



  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }



  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.branch_all.push({ 'branch_id': '', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(formdata: { value: any; }, pageno: any) {
    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.misiingStockSummaryReport(formdata.value, 1).subscribe((res: any) => {
      formdata.value.export = '';
      this.exp_repDate = res.data.Date;
      this.exp_summary = res['data']['data']['data'];
      this.exp_tot_items = res['data']['total']['tot_items'];
      this.exp_total_qty = res['data']['total']['total_qty'];
      this.exp_tot_purch_amt = res['data']['total']['tot_purch_amt'];
      setTimeout(() => {
        this.exportexcel();
        this.exportLoader = false;
      }, 2000);
    });

  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.misiingStockSummaryReport(formdata.value, 1).subscribe((res: any) => {
      formdata.value.export = '';
      this.exp_repDate = res.data.Date;
      this.exp_summary = res['data']['data']['data'];
      this.exp_tot_items = res['data']['total']['tot_items'];
      this.exp_total_qty = res['data']['total']['total_qty'];
      this.exp_tot_purch_amt = res['data']['total']['tot_purch_amt'];
      
      const heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('HOME.missing_date'),
        this.translate.instant('Common.notes'),
        this.translate.instant('HOME.missing_qty'),
        this.translate.instant('Table.purch_rate'),
        this.translate.instant('Common.Purch_Amt')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.exp_summary.forEach(item=>{
          data.push([
            item.prd_name,
            '',
            '',
            this.currencyPipe.transform(item.qty, '', '', '1.1-1'),
            this.currencyPipe.transform(item.purch_rate, '', '', this.country_dec),
            this.currencyPipe.transform(item.purch_sum_amt, '', '', this.country_dec)
          ])

          item.items.forEach(item1=>{
            data.push([
              item.prd_barcode,
              item1.mstk_date,
              item1.mstk_notes,
              this.currencyPipe.transform(item1.stk_qty, '', '', '1.1-1'),
              this.currencyPipe.transform(item1.mstk_purch_rate, '', '', this.country_dec),
              this.currencyPipe.transform(item1.purch_amt, '', '', this.country_dec)
            ])
          })

        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.exp_repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.exp_repDate.date1 + '   to   ' + this.exp_repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('HOME.missing_stock_summary')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.tot_items,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.tot_purch_amt, '', '',  this.country_dec),  valueXPos, headerHeight + 30);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
    
       
        doc.save(`Missing Stock Summary.pdf`);
      
        this.exportLoader = false;

    });

  }


}
