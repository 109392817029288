import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-item-movement-details',
  templateUrl: './item-movement-details.component.html',
  styleUrls: ['./item-movement-details.component.css']
})
export class ItemMovementDetailsComponent implements OnInit {
  stkprdsel: any;
  elseBlocknoouterwrap: any;
  selecTed: any = null;
  stkprd_all: string[];
 
  id: any;
  up: any;
  pageLoadingImg = false;
  repDate: any;
  stock = 0;
  godowns: any;
  trn_types = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Opening Stock' },
    { id: 2, name: 'Sales' },
    { id: 3, name: 'Sales Return' },
    { id: 4, name: 'Purchase' },
    { id: 5, name: 'Purchase Return' },
    { id: 6, name: 'Stock Transfer' },
    { id: 7, name: 'Stock Receipt' },
    { id: 8, name: 'External Stock Transfer' },
    { id: 9, name: 'External Stock Receipt' },
    { id: 10, name: 'Production' },
    { id: 11, name: 'Production Ingredients' },
    { id: 12, name: 'Excess Stock' },
    { id: 13, name: 'Missing Stock' },
    { id: 14, name: 'Damage Product' },
    { id: 15, name: 'Van Transfer' },
    { id: 16, name: 'Van Return' },
    { id: 17, name: 'Godown Transfer Out' },
    { id: 18, name: 'Godown Transfer In' },
    { id: 19, name: 'Delivery Note' },
  ];
  vans: any;
  country_dec: string;
  branch_all: any;
  userType: any;
  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  byderived: any;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');
    
   this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
    this.getGodownList();
    this.searchVans('');
    this.userType = this.coreService.getUserData('user_type');
  }

  langChange(){
    this.translate.get(['Common.all','Common.openingstock','Vat.sales','HOME.sales_return','Common.Purchase','Purchase.return','Table.stl_trnsfr','Table.stck_recpt','transaction.ext_stock_transfer','transaction.ext_stock_Receipt','HOME.PRODUCTION','Van.production_ingredients','HOME.excess_stocks','HOME.missing_stocks','HOME.Damage_Product','Van.Van_Transfer','Van.Van_Return','Common.Godown_Transfer_out','Common.Godown_Transfer_in','HOME.Delivery_challan']).subscribe((res: string) => {    
     
      this.trn_types = [
        { id: 0, name: res['Common.all'] },
        { id: 1, name: res['Common.openingstock'] },
        { id: 2, name: res['Vat.sales'] },
        { id: 3, name: res['HOME.sales_return'] },
        { id: 4, name: res['Common.Purchase'] },
        { id: 5, name: res['Purchase.return'] },
        { id: 6, name: res['Table.stl_trnsfr'] },
        { id: 7, name: res['Table.stck_recpt'] },
        { id: 8, name: res['transaction.ext_stock_transfer'] },
        { id: 9, name: res['transaction.ext_stock_Receipt'] },
        { id: 10, name: res['HOME.PRODUCTION'] },
        { id: 11, name: res['Van.production_ingredients'] },
        { id: 12, name: res['HOME.excess_stocks'] },
        { id: 13, name: res['HOME.missing_stocks'] },
        { id: 14, name: res['HOME.Damage_Product'] },
        { id: 15, name: res['Van.Van_Transfer'] },
        { id: 16, name: res['Van.Van_Return'] },
        { id: 17, name: res['Common.Godown_Transfer_out'] },
        { id: 18, name: res['Common.Godown_Transfer_in'] },
        { id: 19, name: res['HOME.Delivery_challan'] },
      ];
    });

  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res: any) => {
      this.vans = res.data;
      this.vans.push({ van_id: 0, van_name: 'All' });
    });

  }
  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    } 
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.selecTed = null;

    });
  }
  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 'all', 'gd_name': 'All' });

    });
  }

  //form search select
  selectStockPrd(prd) {

    const searchval = new FormData();
    searchval.append('product_id', prd.prd_id);


    this.pageLoadingImg = true;
    this.selecTed = null;
    this.apiService.itemMovementDetails(searchval).subscribe((res: any) => {

      this.pageLoadingImg = false;
      this.selecTed = res.data;
      this.repDate = res.data.Date;

    });
  }

  filterSearch(formdata: { value: any; }, prd) {
    if (prd) {
      this.pageLoadingImg = true;
      this.selecTed = null;
      formdata.value.product_id = prd.prd_id;
      if (formdata.value.date) {
        formdata.value.date = (formdata.value.date) ? this.apiService.formatDate(formdata.value.date) : formdata.value.date;
      }

      this.apiService.itemMovementDetails(formdata.value).subscribe((res: any) => {

        this.pageLoadingImg = false;
        this.selecTed = res.data;
        this.repDate = res.data.Date;
      });

    } else {
      this.selecTed = null;

    }
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  // pdf download
  generatePdf() {
  
    const heads = [
      this.translate.instant('ACCOUNTS.Particulars'),
      this.translate.instant('Table.date'),
      this.translate.instant('HOME.transaction_type'),
      this.translate.instant('Purchase.ref_no'),
      this.translate.instant('Common.branch_ref_no'),
      this.translate.instant('Table.qty') + '(' + this.translate.instant('Repproduct.base') + ')',
      this.translate.instant('HOME.available_stock'),
      this.translate.instant('Table.price')
    ];

    if(this.byderived){
      heads.splice(6,0, this.translate.instant('Common.Derived_qty'))
    }

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      const arr=[
        "Opening Balance",
        this.datePipe.transform(this.selecTed.opening_stock.opstklog_date, 'dd/MM/yyyy'),
        "Opening Stock",
        '',
        '',
        this.selecTed.opening_stock.av_qty,
        this.selecTed.opening_stock.av_qty,
        ''
      ]

      if(this.byderived){
        arr.splice(6,0, ' ')
      }

      data.push(arr)

      this.selecTed.items.forEach(item=>{

        const arr1=[
          item.particular,
          this.datePipe.transform(item.date, 'dd/MM/yyyy'),
          item.trn_type,
          item.ref_no,
          item.b_ref_no,
          item.qty,
          item.av_stock,
          this.currencyPipe.transform(item.price, '', '', this.country_dec)
        ]

        if(this.byderived){
          arr.splice(6,0, item.drvd_qty)
        }

        data.push(arr1)
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('HOME.item_movement_detials')}`,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.itm_name')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.product_details.prd_name,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.itm_code')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.product_details.prd_code,  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.item_ean')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.product_details.prd_ean,  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.location_name')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.godown,  valueXPos, headerHeight + 35);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.available_stock')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.selecTed.av_stock, '', '', this.country_dec),  valueXPos, headerHeight + 40);

      if(this.selecTed.van_name){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.VAN')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + this.selecTed.van_name,  valueXPos, headerHeight + 45);
      }

      if(this.byderived){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.available_stock')}` + ' ' + `${this.translate.instant('Common.Derived_qty')}`, lblXPos, headerHeight + 50);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.selecTed.av_stock_drvd, '', '', this.country_dec),  valueXPos, headerHeight + 50);
      }
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 50, lblXPos, heads, data, null);
  
     
      doc.save(`Item Movement Details.pdf`);


    
  }

}
