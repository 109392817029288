import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { MatTabGroup } from '@angular/material/tabs';
import { ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.css']
})

export class DebitNoteComponent implements OnInit {
@ViewChild('myTabGroup', { static: false }) tabGroup: MatTabGroup;
  
  selectledgers: any[] = [{
    select_account: null,
    purch_id:0,
    unit_price: '',
      discount: '',
      amount: '',
      vat_type: 5,
      vat_amnt: 0,
      Amnt: 0,
      purch_flag :0,
    }];
  customer: any;


  filter_types = [
    { id: 1, name: 'Reported/Cleared'},
    { id: 2, name: 'Failed/Pending'},
    { id: 3, name: 'All'}
    
  ];
  pay_types = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank / Card' }
  ];
  payTypes = ['','Credit', 'Cash', 'Bank / Card'];
  defLedger: any;
  Account: string;
  printError = []; errObjArr = {
    accntErr: null, custErr: null,

  };
  vat_no: any;
  price_group_id: any;
  saleqt_date: string | number | Date;
  valid_till_date: Date;
  cust_balance: any;
  cust_bal_sign: any;
  sale_pay_type: number;
  debt_note_date: any;
  searchleadger: any;
  valErrors = <any>{};
  tax_types = [];

  tax_name = {
    1: '5',
    2: '50',
    5: '15',

  }
  pay_name = {
    1: 'Credit',
    2: 'Cash',
    5: 'Bank',

  }
  totalAmnt = <any>0;
  netAmnt = <any>0;
  totVatAmnt = <any>0;
  totcgstAmnt = <any>0;
  totsgstAmnt = <any>0;
  totigstAmnt = <any>0;
  roundOff = <any>0;
  grndttl: number;
  up: any;
  supp_id = <any>[{}];
  payment_type: any;
  pageLoadingImg: boolean;
  Reference: string;
  notes: string;
  editMessage: boolean;
  Ref: any;
  br_dr_no: any;
  searchLoadingImg: boolean;
  list_dr_note: any;
  from: any;
  importLoader: boolean;
  private modalRef: NgbModalRef;
  closeResult: string;
  isSubmitInProg: boolean;
  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email: '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    brch_id: 0,
    usr_type: 0,
    is_supplier: false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  userType: string;
  resultobj: any = {};
  crNoteData: any;
  is_prew_description: any;
  previewLoader: boolean;
  savedTemplate: { logo: any; header_type: any; footer_text:any;};
  logoImgUrl: string;
  tempImgUrl: string;
  branch_display_name: any;
  branch_name: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_reg_no: any;
  branch_tin: any;
  base_url: string;
  sales_settings: any;
  inv_type: any;
  print_style: any;
  terms: any;
  search_type: any;
  disable_sales_without_stock: any;
  sales_print_name: any;
  sales_duplicate_Item: any;
  supplierAll: any;
  purchaseAll :any;
  showBal: boolean;
  accLoading: boolean;
  sup_balance: any;
  sup_bal_sign: any;
  sup_tin: any;
  curpage: any;
  lastpage: any;
  dr_note_file: string;
  imageError: string;
  country_dec: string;
  hideButton =false;
  slno: number;
  purchaseItems: any;
  RebateAmount: any;
  cmp_tax: any;
  taxProdCategories: any;
  branch_state: any;
  states: any;
  cust_state_id: any;
  igst: boolean=false;
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
  ];

  eInvoiceSupport: boolean=false;
  _tax_invoice = 'Tax Invoice';
  _simplified_tax_invoice = 'Simplified Tax Invoice';
  _invoice = 'Tax Invoice';

  // "b2BRequirements": {
  //   "isSelfBilling": false,
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isExport": false,
  //   "isNominal": false
  // },
  // "b2CRequirements": {
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isNominal": false
  // }
  // NNPNESB
  // - 01 for tax invoice
  // - 02 for simplified tax invoice
  // P (position 3) = 3rd Party invoice 
  // transaction, 0 for false, 1 for true
  // N (position 4) = Nominal invoice 
  // transaction, 0 for false, 1 for true
  // ubl:Invoice / ubl:Invoice / cbc:InvoiceTypeCode /@name
  // Version 1.1 40 / 57
  // E (position 5) = Exports invoice 
  // transaction, 0 for false, 1 for true
  // S (position 6) = Summary invoice 
  // transaction, 0 for false, 1 for true 
  // B (position 7) = Self billed invoice
  _Exports = 'Exports';
  _self_billing = 'Self Billing';
  _none = 'None';
  _third_party = 'Third Party';
  _nominal = 'Nominal';
  _summary = 'Summary';
  inv_sub_types = [
    { id: '00000', name: 'None' },
    { id: '10000', name: 'Third Party' },
    { id: '01000', name: 'Nominal' },
    { id: '00010', name: 'Summary' },
    { id: '00100', name: 'Exports' },
    { id: '00001', name: 'Self Billing' }
  ];
  inv_sub_type = '00000';
  xmlPath = '';
  branch_code = '';
  mode = {
    1: "production",
    2: "simulation",
    3: "development",
  }
  branch_e_invoice_enabled = 0;
  cmpny = '';
  einv_simplfied_print_style = 1;
  invoiceId: any;
  invoice_type: any;
  path = '';
  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal,private translate: TranslateService) { }

  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.xmlPath = this.apiService.PHP_API_SERVER + '/public/xml/' + this.cmpny + '/' + this.branch_code + '/' ;
    this.nextRefNo();
    this.path = this.apiService.PHP_API_SERVER + '/' ;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');

    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');

    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
      }
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.debt_note_date = new Date();
    this.invoice_type=3;
    this.lisCrNotes(1);
    // for listing tax cats
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.tax_types = resp.data;
    });

    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
    this.payment_type = 2;
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_name = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.img_url = this.coreService.getUserData('img_url');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;
    this.getTemplete();
    this.getSalesSetting();
    if(this.cmp_tax==2){
      this.branch_state = this.coreService.getUserData('branch_state_id');
      this.getAllState();
    }
    this.up = this.coreService.getUserPrivilage();
  }


  langChange(){
    this.translate.get(['Vat.zero_rated', 'Vat.stnderd_rated','Vat.stnderd_rated', 'Vat.stnderd_rated','HOME.credit', 'Common.cash','HOME.bank_card',
    'Common.tax_invoice', 'Common.simplified_tax_invoice','Common.none','Branch.third_party','Branch.nominal','Branch.exports','Common.summary','Branch.self_billing'
    ]).subscribe((res: string) => {    
     
      
      this.tax_types = [
        { id: 3, name: res['Vat.zero_rated'] },
        { id: 1, name: res['Vat.stnderd_rated']+'@ 5%' },
        { id: 2, name: res['Vat.stnderd_rated']+'@ 50%' },
        { id: 5, name: res['Vat.stnderd_rated']+'@ 15%' }
      ];

      this.pay_types = [
        { id: 1, name: res['HOME.credit'] },
        { id: 2, name: res['Common.cash'] },
        { id: 3, name: res['HOME.bank_card'] }
    
      ];

      
      this._tax_invoice = res['Common.tax_invoice'];
      this._simplified_tax_invoice = res['Common.simplified_tax_invoice'];
      this.inv_types = [
        { id: 1, name: this._tax_invoice},
        { id: 2, name: this._simplified_tax_invoice},
      ];

      this._Exports = res['Branch.exports'];
      this._self_billing = res['Branch.self_billing'] ;
      this._none = res['Common.none'];
      this._third_party = res['Branch.third_party'];
      this._nominal = res['Branch.nominal'];
      this._summary =  res['Common.summary'];
      this.inv_sub_types = [
        { id: '00000', name: this._none},
        { id: '10000', name: this._third_party },
        { id: '01000', name: this._nominal },
        { id: '00010', name: this._summary }
      ];
      if (this.inv_type == 1) {
        this.inv_sub_types.push({ id: '00100', name:  this._Exports });
        this.inv_sub_types.push({ id: '00001', name: this._self_billing});
      }
    });

  }

  updatePayment() {
    this.inv_sub_types = [
      { id: '00000', name: this._none},
      { id: '10000', name: this._third_party },
      { id: '01000', name: this._nominal },
      { id: '00010', name: this._summary }
    ];
    if (this.inv_type == 1) {
      this.inv_sub_types.push({ id: '00100', name:  this._Exports });
      this.inv_sub_types.push({ id: '00001', name: this._self_billing});
    }
  }

  addRow() {
    this.slno = (this.selectledgers.length) + 1;
    this.selectledgers.push({
      slno:this.slno,
      select_account: null,
      unit_price: '',
      discount: '',
      amount: '',
      vat_type: 5,
      vat_amnt: 0,
      Amnt: 0,
      purch_id:0,
      purch_flag :0,
    });
  }
  delRow(data){ 
    var remopstk = confirm("Remove this Item");
    if (remopstk) {
      var type = data['slno'],i;
      for(i = this.selectledgers.length - 1; i >= 0; --i) {

        // console.log("i");
        // console.log(i);
        if (this.selectledgers[i].purch_id == data.purch_id) {
           this.selectledgers.splice(i, 1);
        }
      }
    }
    this.purchaseAll.forEach((value, k) => {
      if (value.purch_id == data['purch_id']) {
        this.purchaseAll[k].purch_flag = 1;
      }
    });
    // this.selectledgers.splice(i, 1);
    this.sumSaleTotal();  
  }

  // delRow(i){
  //   this.selectledgers.splice(i, 1);
  // }


  addDebitNotes(formgroup) {
    


    this.printError = [];

    if (this.payment_type != 1) {


      if (this.Account == '' || this.Account == undefined) {
        this.errObjArr.accntErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });

      } else {
        this.errObjArr.accntErr = "f";

      }
    }
    if (this.payment_type == 1) {
      this.errObjArr.accntErr = "f";
    }


    if (this.supp_id == "" || this.supp_id.supp_id == undefined) {
      this.errObjArr.custErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.custErr = "f";
    }

    if (this.printError.length <= 0) {

      if (this.validateForm()) {

        const searchval = new FormData(formgroup);
        

        this.debt_note_date = (this.debt_note_date) ? this.apiService.formatDate(this.debt_note_date) : this.debt_note_date;
        searchval.append("debit_note_date", this.debt_note_date);
        searchval.append("Ref", this.Ref);
        searchval.append("payment_type", this.payment_type);
        searchval.append("Account", this.Account);
        searchval.append("selectledgers", JSON.stringify(this.selectledgers));
        searchval.append("cust_id", JSON.stringify(this.supp_id));
        searchval.append("total_amnt", this.totalAmnt);
        searchval.append("total_vat_amnt", this.totVatAmnt);
        searchval.append("total_cgst_amnt", this.totcgstAmnt);
        searchval.append("total_sgst_amnt", this.totsgstAmnt);

        if((this.cmp_tax==2)&&(this.igst)){
          searchval.append("total_igst_amnt", this.totigstAmnt);
          searchval.append("dr_note_is_igst", '1');
        }
        searchval.append("net_amnt", this.netAmnt);
        searchval.append("round_off", this.roundOff ? this.roundOff : 0);
        searchval.append("inv_type", this.eInvoiceSupport ? this.inv_type : '');
        searchval.append("inv_sub_type", this.eInvoiceSupport ? this.inv_sub_type : '');

        
        if (confirm('Do you wish to continue?')) {

          this.pageLoadingImg = true;

          this.apiService.addDebitNotes(searchval).subscribe((res) => {
            let isError = false;
            if(this.eInvoiceSupport){
              if(res.error && res.error['error']){
                this.coreService.showMessage(res.error['error'], 4000);
                isError = true;
                this.pageLoadingImg = false;
              }
              if(res.message && res.message.preview){
                this.coreService.showMessage(res.message.msg, 4000);
              }
            }
            if(!isError){
              if (res.message.preview) {
                this.crNoteData = res.message['preview'];
                this.is_prew_description = this.crNoteData['items'].filter(x => x.item_descrp);
                this.previewLoader = false;
                // this.qr_inv = this.saleQtnData['qr_link'];
                $('#printviewBtn').click();
              
              }


              this.pageLoadingImg = false;
              // if (res.error != null) {
              //   this.resultobj = res.error;
              //   this.pageLoadingImg = false;
              //   for (var value in this.resultobj) {
              //     this.validationMsg += this.resultobj[value].toString() + '<br>';
              //   }
              // }
              // if (res.message) {
              //   if(print){
              //     this.saleQtnData = res.message['preview'];
              //     this.is_prew_description = this.saleQtnData['sales_sub'].filter(x => x.item_descrp);
              //     this.previewLoader = false;
              //     this.qr_inv = this.saleQtnData['qr_link'];
              //     $('#printviewBtn').click();
              //   } else{
              //     this.coreService.showMessage(res.message['msg']);
              //   }
              this.pageLoadingImg = false;
              this.supp_id = '';
              this.showBal=false;
              this.payment_type = 1;
              this.Reference = '';
              this.notes = '';
              this.Account = '';
              this.dr_note_file = '';
              this.selectledgers = [{
                select_account: null,
                unit_price: '',
                  discount: '',
                  amount: '',
                  vat_type: 5,
                  vat_amnt: 0,
                  Amnt: 0,
                  purch_id:0,
                  purch_flag :0,
              }];
              this.cust_balance=0;
              this.roundOff = 0;
              // this.imageError = '';
              this.lisCrNotes(1);
              this.nextRefNo();
              this.sumSaleTotal();
              this.purchaseAll =[];
              // setTimeout(() => {
              //   this.selPriduct.last.focus();
              // }, 500);
              // }
            }
          });


        }

      }


    }


  }

  updateDebitNotes(formgroup) {


    this.printError = [];

    if (this.payment_type != 1) {


      if (this.Account == '' || this.Account == undefined) {
        this.errObjArr.accntErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });

      } else {
        this.errObjArr.accntErr = "f";

      }
    }
    if (this.payment_type == 1) {
      this.errObjArr.accntErr = "f";
    }


    if (this.supp_id == "" || this.supp_id == undefined) {
      this.errObjArr.custErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.custErr = "f";
    }

    if (this.printError.length <= 0) {

      if (this.validateForm()) {

        const searchval = new FormData(formgroup);
        

        this.debt_note_date = (this.debt_note_date) ? this.apiService.formatDate(this.debt_note_date) : this.debt_note_date;
        searchval.append("debit_note_date", this.debt_note_date);
        searchval.append("Ref", this.Ref);
        searchval.append("payment_type", this.payment_type);
        searchval.append("Account", this.Account);
        searchval.append("selectledgers", JSON.stringify(this.selectledgers));
        searchval.append("cust_id", JSON.stringify(this.supp_id));
        searchval.append("total_amnt", this.totalAmnt);
        searchval.append("total_vat_amnt", this.totVatAmnt);
        searchval.append("total_cgst_amnt", this.totcgstAmnt);
        searchval.append("total_sgst_amnt", this.totsgstAmnt);

        if((this.cmp_tax==2)&&(this.igst)){
          searchval.append("total_igst_amnt", this.totigstAmnt);
        }
        searchval.append("net_amnt", this.netAmnt);
        searchval.append("round_off", this.roundOff ? this.roundOff : 0);

       
        if (confirm('Do you wish to continue?')) {

          this.pageLoadingImg = true;

          this.apiService.editDrNote(searchval).subscribe((res) => {

            if (res.message.preview) {
              this.crNoteData = res.message['preview'];
              this.is_prew_description = this.crNoteData['items'].filter(x => x.item_descrp);
              this.previewLoader = false;
              // this.qr_inv = this.saleQtnData['qr_link'];
              $('#printviewBtn').click();
              

            }


            this.pageLoadingImg = false;
            // if (res.error != null) {
            //   this.resultobj = res.error;
            //   this.pageLoadingImg = false;
            //   for (var value in this.resultobj) {
            //     this.validationMsg += this.resultobj[value].toString() + '<br>';
            //   }
            // }
            // if (res.message) {
            //   if(print){
            //     this.saleQtnData = res.message['preview'];
            //     this.is_prew_description = this.saleQtnData['sales_sub'].filter(x => x.item_descrp);
            //     this.previewLoader = false;
            //     this.qr_inv = this.saleQtnData['qr_link'];
            //     $('#printviewBtn').click();
            //   } else{
            //     this.coreService.showMessage(res.message['msg']);
            //   }
            this.pageLoadingImg = false;
            this.supp_id = '';
            this.showBal=false;
            this.payment_type = 1;
            this.Reference = '';
            this.notes = '';
            this.Account = '';
            this.dr_note_file = '';
            this.selectledgers = [{
              select_account: null,
              unit_price: '',
                discount: '',
                amount: '',
                vat_type: 5,
                vat_amnt: 0,
                Amnt: 0,
                purch_id:0,
                purch_flag :0,
            }];
            this.roundOff = 0;
            // this.imageError = '';
            this.igst=false;
            this.lisCrNotes(1);
            this.nextRefNo();
            this.sumSaleTotal();
            this.editMessage=false;
            this.purchaseAll = [];
            // setTimeout(() => {
            //   this.selPriduct.last.focus();
            // }, 500);
            // }
          });


        }

      }


    }


  }
  // form search supplier
  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    if(this.eInvoiceSupport){
      searchval.append('einvoice', '1');
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }
  searchSupplierAlias(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    searchval.append('with-alias','1'); 
    if(this.eInvoiceSupport){
      searchval.append('einvoice', '1');
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }

   //search Purchase
   searchParchase(search: string) {
    const searchval = new FormData();
    searchval.append('ref_numner', search);
    searchval.append('supp_id', this.supp_id.supp_id);
    this.apiService.searchPurchases(searchval).subscribe((res) => {
      this.purchaseAll = res.data;

   
    });
  }

  getPurchase(purch_id){
     
  
    if(!this.editMessage){

   
  
      const searchval = new FormData();
      searchval.append('purch_id', purch_id.purch_id);
      searchval.append('supp_id', this.supp_id.supp_id);
      this.apiService.getRebatePurchase(searchval).subscribe((res) => {
         this.purchaseAll = res.data;
  
        
  
     
      });

    }
   
  }
  searchDefaultLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    if (this.payment_type === 2)
      searchval.append('ledger_grp', '3');
    else if (this.payment_type === 3)
      searchval.append('ledger_grp', '4');

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }

  changePayType() {
    this.defLedger = [];
    if (this.payment_type == 1) {
      this.Account = '';
    } else if (this.payment_type === 2) {
      if (this.Account != '0') {
        this.defLedger = this.coreService.getUserData('cash_ledger');
        this.Account = this.coreService.getUserData('usr_cash_ledger_id');
        if (!this.Account) {

          this.apiService.getBranchCashAccount().subscribe((led_res) => {
            this.defLedger = led_res.data.ledger;
            this.Account = led_res.data.taxled_ledger_id;
          });
        }
      }
    } else if (this.payment_type === 3) {
      if (this.Account != '0') {
        this.defLedger = this.coreService.getUserData('bank_ledger');
        this.Account = this.coreService.getUserData('usr_bank_ledger_id');
      }
    }
  }

  selectCustomer(cust) {
    if (cust) {
      this.vat_no = cust.vat_no;
      if (cust.price_group_id) {
        this.price_group_id = cust.price_group_id;
      }
      let date = new Date(this.saleqt_date);
      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);

      this.cust_balance = cust.balance.bal_without_sign;
      this.cust_bal_sign = cust.balance.bal_sign;
    } else {
      this.cust_balance = 0;
      this.cust_bal_sign = '';
      // remove customer after selecting payment type credit, change payment type to cash
      if (this.sale_pay_type == 1) {
        this.sale_pay_type = 2;
      }
    }
  }

    // form search  ledger
    searchLedger(search: string) {
      $('#report-wrapper').hide();
  
      const searchval = new FormData();
      searchval.append('search', search);
      this.apiService.searchIDEDELedgerList(searchval, 1).subscribe((res: any) => {
        this.searchleadger = res.data;
        // this.selecTed = [];
      });
  
    }

    validateForm() {
      this.valErrors = {};
  
      let retVal = true;
      this.selectledgers.forEach((element, i) => {
        if (element.select_account == '' || element.select_account == null) {
          this.valErrors['select_acnt_' + i] = { msg: 'Required' }
          retVal = false;
        }
  
        element.discount = +element.discount;
        element.unit_price = +element.unit_price;
  
        if ((+element.unit_price == 0) || (+element.unit_price <= +element.discount)) {
          this.valErrors['unit_price_' + i] = { msg: 'Required' }
          retVal = false;
        }
      });
  
  
  
  
      return retVal;
    }
    selectTaxType(vattypeid, i) {

      const selTax = this.taxProdCategories.filter((taxCat) => taxCat.taxcat_id === this.selectledgers[i].vat_type);
    this.selectledgers[i].salesub_tax_per = selTax[0].taxcat_tax_per;
    this.selectledgers[i].taxcat_tax_per = selTax[0].taxcat_tax_per;
    if(this.cmp_tax==2){
      this.selectledgers[i].cgst_tax_per= selTax[0].cgst_tax_per;
      this.selectledgers[i].sgst_tax_per = selTax[0].sgst_tax_per;

    }
     
        this.selectledgers[i].vat_amnt = ((this.selectledgers[i].unit_price - this.selectledgers[i].discount) / 100) * this.selectledgers[i].salesub_tax_per;
        this.selectledgers[i].Amnt = this.selectledgers[i].vat_amnt + (this.selectledgers[i].unit_price - this.selectledgers[i].discount);
  
      
      this.selectledgers[i].amount=(this.selectledgers[i].unit_price - this.selectledgers[i].discount);
      this.sumSaleTotal();
    }

    sumSaleTotal() {

      this.totalAmnt = 0;
      this.totVatAmnt = 0;
      this.totcgstAmnt = 0;
      this.totsgstAmnt = 0;
      this.totigstAmnt = 0;
      this.netAmnt = 0;
      this.grndttl = 0;
  
      for (var i = 0; i < this.selectledgers.length; i++) {
        // console.log('sub_disc ' + this.salesItems[i]['item_disc']);
        this.totalAmnt = Number(this.totalAmnt) + (Number(this.selectledgers[i].unit_price) - Number(this.selectledgers[i].discount));
        this.totVatAmnt = Number(this.totVatAmnt) + (Number(this.selectledgers[i].vat_amnt));
        // this.grndttl = Number(this.grndttl) + Number(this.selectledgers[i].);
        // this.totItemDiscWithoutBillDisc = Number(this.totItemDiscWithoutBillDisc) + Number(this.salesItems[i]['item_disc_WithoutBillDisc']);
        // this.totVatAmnt = Number(this.totVatAmnt) + Number(this.salesItems[i]['salesub_tax_amnt']);
        this.netAmnt = Number(this.netAmnt) + Number(this.selectledgers[i].Amnt);

        if(this.cmp_tax==2){
          this.totcgstAmnt = Number(this.totcgstAmnt) + (Number((this.selectledgers[i].unit_price-this.selectledgers[i].discount)/100)*this.selectledgers[i].cgst_tax_per);
          this.totsgstAmnt = Number(this.totsgstAmnt) + (Number((this.selectledgers[i].unit_price-this.selectledgers[i].discount)/100)*this.selectledgers[i].cgst_tax_per);
        }
  
      }
  
      // for IGST Case
   if((this.cmp_tax==2)){
    // this.totsgstAmnt=0;
    // this.totcgstAmnt=0;
    this.totigstAmnt=this.totVatAmnt;
  
      }
    }

    goBack() {
      // this.listing = true;
      this.editMessage = false;
      // this.convertMessage=false;
      // this.button_show=true;
      this.clearForm();
    }

    clearForm() {

      this.pageLoadingImg = false;
      this.supp_id = '';
      this.showBal=false;
      this.payment_type = 1;
      this.Reference = '';
      this.notes = '';
      this.Account = '';
      this.dr_note_file = '';
      this.selectledgers = [{
        select_account: null,
        unit_price: '',
          discount: '',
          amount: '',
          vat_type: 5,
          vat_amnt: 0,
          Amnt: 0,
          purch_id:0,
          purch_flag :0,
      }];
      this.roundOff = 0;
      // this.imageError = '';
      this.sumSaleTotal();
      this.nextRefNo();
    }


    nextRefNo() {
      let searchval = new FormData();
      this.apiService.getDebitNoteRefNo(searchval).subscribe((res) => {
        this.Ref = res['data'].ref_num;

        this.br_dr_no = res['data'].branch_dr_note_no;
      });
    }

    lisCrNotes(pageNo = 1, keyword = '') {
      let searchval = new FormData();
      if (keyword != '')
        searchval.append("keyword", keyword);
      if(this.invoice_type &&this.eInvoiceSupport){
        searchval.append("invoiceType_id",this.invoice_type);
      }
      this.searchLoadingImg = true;
      this.apiService.getDrNoteList(searchval, pageNo).subscribe((res) => {
        this.list_dr_note = res.data.data;
        
        this.curpage = res.data['current_page'];
        this.lastpage = res.data['last_page'];
        this.from = res.data['from'];
        // this.pgEnd(this.curpage, this.lastpage);
        // this.pgStart(this.curpage);
        this.searchLoadingImg = false;
      });
    }


    Select_invoiceType(id){
      if (id){
        this.invoiceId=id;
        let searchval = new FormData();
        searchval.append("invoiceType_id",this.invoiceId);
        this.searchLoadingImg = true;
        this.apiService.searchDrNList(searchval, 1).subscribe((res) => {
          this.list_dr_note = res.data.data;
          this.curpage = res.data['current_page'];
          this.lastpage = res.data['last_page'];
          this.from = res.data['from'];
          this.searchLoadingImg = false;
        });
  
      }
    }

    editDrNotes(data) {

      this.searchLoadingImg = true;
      this.searchDefaultLedger('');
      this.searchLedger('');
      let searchval = new FormData();
      searchval.append("dr_note_no", data);
      
      this.apiService.getDrNoteDet(searchval).subscribe((res: any) => {
        this.searchLoadingImg = false;
        this.Ref = res.data.dr_note_no;
        this.debt_note_date = new Date(res.data.dr_note_date);
        // this.credit_note_date = res.data.cr_note_date;
        this.br_dr_no = res.data.branch_dr_note_no;
        this.payment_type = res.data.dr_note_pay_type;
        this.selectledgers = res.data.items;
        
  
  
  
  
  
        this.Account = res.data.dr_note_account;
        // this.cust_id = res.data.dr_note_cust_id;
        this.supp_id = res.data.customer
        this.Reference = res.data.dr_note_reference;
        this.notes = res.data.dr_note_notes;
        this.dr_note_file = res.data.dr_note_file;
        this.totalAmnt = res.data.dr_note_amnt;
        this.totVatAmnt = res.data.dr_note_vat_amnt;
        this.roundOff = res.data.dr_note_round_off;
        // this.sales_note = res.data.sales_notes;
        this.getSupBalance(this.supp_id);
        this.showBal=true;
        // this.sale_agent = res.data.sales_agent_ledger_id;
        this.sumSaleTotal();
        // this.changeAmnt();
  
        this.importLoader = false;
  
        // this.listing = false;
        // this.is_description = this.salesItems.filter(x => x.item_desc !== '');
  
        this.editMessage = true;
  
      });
  
      this.tabGroup.selectedIndex = 0;
    }

    openModal(content: any, size) {

      this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
      this.modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  
    }
  
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

    validateAndSubmit(){
      this.isSubmitInProg = true;
      let callFunction = 'createSup';
      if(this.userType =='ADMIN'){
        this.customer_dt.usr_type = 1;
      }else{
        this.customer_dt.usr_type = 0;
      }
  
      this.apiService[callFunction](this.customer_dt).subscribe((res) => {

        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.coreService.showMessage(res.message);
          this.resultobj = {};
          this.modalRef.close();
          this.clearCustForm();
        }
      });
    }
    clearCustForm() {
      this.customer_dt = {
        id: '',
        ledger_id: '',
        name: '',
        alias: '',
        code: '',
        email : '',
        mobile: '',
        vat_no: '',
        op_bal: 0,
        brch_id:0,
        usr_type:0,
        is_supplier : false,
        op_bal_type: true,
        add: {
          addr: '',
          zip: '',
          city: '',
          country: '',
          state: '',
          state_code: '',
          fax: ''
        },
        del: {
          addr: '',
          zip: '',
          city: '',
          country: '',
          state: '',
          state_code: '',
          fax: ''
        },
        due_day: '',
        van_line: 0,
        price_group: 1,
        note: ''
      };
    }
    getSupBalance(sup_id) {
      this.showBal = false;
      this.accLoading = false;
      if(sup_id){
        this.accLoading = true;
        this.apiService.getSupBalance({ sup_id: sup_id }).subscribe((res) => {
          this.accLoading = false;
          this.sup_balance = res.data.bal_without_sign;
          this.sup_bal_sign = res.data.bal_sign;
          this.sup_tin = res.data.supp_tin;
          this.showBal = true;
        });

        if(this.cmp_tax==2){
          this.cust_state_id = sup_id.supp_state_id;
         this.iGSTchecking();
          }
      }
      
    }

    getSupPurchase(sup_id){


      if(!this.editMessage){
      if(sup_id){
       
        this.apiService.getSupPurchase({ sup_id: sup_id.supp_id }).subscribe((res) => {
       
          this.purchaseAll = res.data;
         
        });
      }
    }
  }

    iGSTchecking(){

      if(this.cmp_tax==2){
      
        
        // for is igst verify
        if((this.cust_state_id!=this.branch_state)&&(this.cust_state_id)&&(this.supp_id)){
          this.igst=true;
        }else{
          this.igst=false;
        }
       
        }
    }

    previewCrNotes(id) {
      let searchval = new FormData();
      searchval.append("dr_note_no", id);
      this.previewLoader = true;
      this.apiService.getDrNotePreview(searchval).subscribe((res: any) => {
        if (res.data) {
  
          this.crNoteData = res.data['preview'];
          
          this.is_prew_description = this.crNoteData['items'].filter(x => x.item_descrp);
          this.previewLoader = false;
  
          // this.qr_inv = this.saleQtnData['qr_link'];
        }
      });
    }

    getTemplete() {

      this.apiService.getSettings().subscribe((res) => {
  
        this.savedTemplate = {
  
          logo: res.data.prnt_header_logo,
          header_type: res.data.prnt_header_type,
          footer_text: res.data.prnt_display_footer_text
        }
        if (res.data.prnt_header_logo) {
          this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
        } else {
          this.logoImgUrl = '';
        }
  
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        } else {
          this.tempImgUrl = '';
        }
  
      });
    }
  
    getSalesSetting() {
  
      this.apiService.getSalesSetting().subscribe((res) => {
        if (res['data']) {
          this.sales_settings = res['data'];
          this.inv_type = this.sales_settings.ss_inv_type;
          this.print_style = this.sales_settings.print_style;
          this.einv_simplfied_print_style = this.sales_settings.einv_simplfied_print_style;
          this.terms = this.sales_settings.sales_default_terms;
          this.search_type = this.sales_settings.search_type;
          // if(this.inv_type==3){
          //   this.purchtypesel = 2;
          //   this.prd_tax_cat_id = 3;
          //   this.selectTaxType(2);
          // }
          this.disable_sales_without_stock = this.sales_settings.disable_sales_without_stock;
          this.sales_print_name = this.sales_settings.sales_print_name;
          this.sales_duplicate_Item = this.sales_settings.sales_duplicate_item;
        } else {
          this.disable_sales_without_stock = 0;
          this.inv_type = 1;
          this.sales_print_name = 1;
          this.print_style = 1;
          this.terms = '';
          this.search_type = 1;
        }
        // this.updatePayment();
      });
  
    }

    deleteDrNotes(crNo){
      if (confirm('Are you sure to void this record ?')) {
        this.isSubmitInProg=true;
        this.apiService.voidDrNotes({ drNote_no: crNo }).subscribe((res) => {
          
          this.isSubmitInProg = false;
          this.lisCrNotes(1);
        });
      }
    }

    searchInp(keyword) {
      let searchval = new FormData();
      if (keyword)
        searchval.append("keyword", keyword);
      if(this.invoice_type &&this.eInvoiceSupport){
        searchval.append("invoiceType_id",this.invoice_type);
      }
      this.searchLoadingImg = true;
      this.apiService.searchDrNList(searchval, 1).subscribe((res) => {
        this.list_dr_note = res.data.data;
        this.curpage = res.data['current_page'];
        this.lastpage = res.data['last_page'];
        this.from = res.data['from'];
        this.searchLoadingImg = false;
      });
    }

    

    fileChangeEvent(fileInput: any) {
      this.imageError = null;
      if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const max_size = 2048000;
        const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
        // const max_height = 250;
        // const max_width = 250;
        if (fileInput.target.files[0].size > max_size) {
          this.imageError =
            'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';
  
          return false;
        }
  
        if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
          this.imageError = 'Only Images are allowed ( JPG | PNG )';
          return false;
        }
  
  
  
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = rs => {
          
          };
        };
  
        reader.readAsDataURL(fileInput.target.files[0]);
  
  
  
  
      }
    }

    appendItem(data){

     
      this.slno = (this.selectledgers.length) + 1;
      if(this.selectledgers[0].unit_price == ""){
        this.selectledgers[0].slno =this.slno;
        this.selectledgers[0].select_account =null;
        this.selectledgers[0].unit_price =data.purch_rebate_amounts;
        this.selectledgers[0].discount ='';
        this.selectledgers[0].amount ='';
        this.selectledgers[0].vat_type =5;
        this.selectledgers[0].vat_amnt =0;
        this.selectledgers[0].Amnt =0;
        this.selectledgers[0].purch_id  =data.purch_id;
        this.selectledgers[0].purch_flag =data.purch_flag;

        this.selectTaxType(this.selectledgers[0].vat_type,0);

      }
      else{
       
        this.selectledgers.push({
          slno:this.slno,
          select_account: null,
          unit_price: data.purch_rebate_amounts,
          discount: '',
          amount: '',
          vat_type: 5,
          vat_amnt: 0,
          Amnt: 0,
          purch_id:data.purch_id,
          purch_flag:data.purch_flag,
        });
  
  
        
   
        this.selectledgers.forEach((value, i) => {
          this.selectledgers[i].unit_price;
          this.selectTaxType(this.selectledgers[i].vat_type,i);
          });


         

      }

  
      this.purchaseAll.forEach((value, i) => {

        if (value.purch_id == data.purch_id) {
  
          // this.saleData.items[i].salesub_rem_qty -= this.qty_rtn;
  
       
            this.purchaseAll[i].purch_flag = 0;

        }

      });
      
     
      
     
      
  
      

    }

    purchaseItem(purchId){
      

      const searchval = new FormData();
      searchval.append('purch_id', purchId);
      this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
          
        this.RebateAmount =res.data.purch_rebate_amounts;
        this.purchaseItems =res.data.items;
      
      });
    }
      
    getAllState(){
      let searchval = new FormData();
      this.apiService.getAllState(searchval).subscribe((res) => {
        this.states = res.data;
        
      });
    }
    eInvoiceResubmit(refno,i){
      if(confirm('Do you wish to continue?')){
        let tmp = {
          type: 'debitNote',
          ref_no : refno
        }
        this.list_dr_note[i].resubmitLoadingImg = true;
        this.apiService.eInvoiceResubmit(tmp).subscribe((res) => {
          this.list_dr_note[i].resubmitLoadingImg = false;
          this.coreService.showMessage(res.msg, 4000);
          if (res.status) {
            this.lisCrNotes(1);
          }
        });
      }
    }
}
