import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-common-template',
  templateUrl: './common-template.component.html',
  styleUrls: ['./common-template.component.css']
})
export class CommonTemplateComponent implements OnInit {

  @Input()saleData;
  @Input()print_style;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()sales_settings;
  @Input()base_url;
  @Input()salesRetPreview;
  @Input()printPreview;
  @Input()previewData;
  @Input()purchData;
  @Input()branch_display_name;
  @Input()branch_address;
  @Input()branch_phone;
  @Input()branch_mob;
  @Input()footImgUrl;
  @Input()recPriviewLoader;
  payTypes = ['Cash','Credit','Bank / Card'];
  // payType = ['Cash', 'Credit'];
  cmp_tax: any;
  header_type: any;
  // tempImgUrl: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.apiService.getSettings().subscribe((res) => {
      this.header_type=res.data.prnt_header_type;
      if (res.data.prnt_header_type == 2) {
        
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }

}
