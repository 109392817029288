import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-sales-quoatation',
  templateUrl: './sales-quoatation.component.html',
  styleUrls: ['./sales-quoatation.component.css']
})
export class SalesQuoatationComponent implements OnInit {

  @ViewChild('selectproductId', { static: false }) selectproductId: NgSelectComponent;
  @ViewChild('selectQty', { static: false }) selectQty: ElementRef;
  @ViewChild('selctbarcode', { static: false }) selctbarcode: ElementRef;
  @ViewChild('firstnextButton', { static: false }) firstnextButton: ElementRef;
  @ViewChild('secndnextButton', { static: false }) secndnextButton: ElementRef;
 
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '350px',
    minHeight: '350px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  terms = '';


  commAll = 0;
  godown_all: any;
  stkprd_all: any;
  cust_types = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Registered' }
  ];

  vat_types = [
    { id: 1, name: 'Excl. Vat' },
    { id: 2, name: 'Incl. Vat' }
  ];

  item_disc_types = [
    { id: 1, name: 'Rate' },
    { id: 2, name: 'Percentage' }
  ];
  rate_type = [
    { id: 1, name: 'Rate1' },
    { id: 2, name: 'Rate2' },
  ];
  sel_rate_type = 1;
  isSecondOptionDisabled: boolean = true;
  customer: any;
  cust_type: any;
  cust_code: any;
  cust_addr: any;
  cntct_num: any;
  vat_no: any;
  godownStocks: any;
  branchStocks: any;
  selctedProd: any;
  excl_vat: number;
  item_disc_type: any;
  taxProdCategories: any;
  taxpercentage: any;
  rate: any;
  prd_tax_cat_id: any;
  unit: any;
  selectedProdDetails: any;
  qty: any;
  itemDisc: any;
  purchAmnt: any;
  tax_amnt: any;
  taxprice: number;
  grnd_totl: any;
  disc_amnt: any;
  itemTotal: any;
  printError: any;
  qtn_print_style: any;
  reference:any;
  gd_id: any;
  stkprdsel: any;
  base_qty: any;
  unit_name: any;
  salesItems = [];
  item_dicrptn: any;
  resDup: any;
  taxvaltotqty: number;
  totItemprice = 0;
  totItemDisc = 0;
  totVatAmnt = 0;
  totcgstAmnt = 0;
  totsgstAmnt = 0;
  totigstAmnt = 0;
  net_disc = 0;
  lastInvoice: any;
  cust_id: any;
  barcode: string;
  cust_name: string;
  saleqt_date: any;
  selectError: boolean;
  qt_no: string | Blob;
  saleRefNo: any;
  cmpny: any;
  available_stock: any;
  unit_available_stock: any;
  bs_prate :any;
  unit_bs_prate :any;
  no_refno: string='';
  water_mark: string;

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: '',
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  saleData: any;
  itemRate: number;

  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Sales' }
  ];
  purch_type_taxcat_id: any;
  taxListCategories: any;
  purchtypesel: any;
  zeroType: boolean;
  godownStocksTotal = 0;
  branchStocksTotal = 0;
  lastCust = 0;
  draftLoader: boolean;
  draftList: any;
  valid_till_date: any;
  sale_agent: any;
  sqdrf_id: any;
  listing: boolean;
  list_quotations: any;
  pgend: boolean;
  pgstart: boolean;
  curpage: any;
  lastpage: any;
  from: any;
  importLoader: boolean;
  imporDrafttLoader: boolean;
  sq_inv_no: any;
  isEdit: boolean;
  sl_no: any;
  saleQtnData: any;
  previewLoader: boolean;
  sales_note: "";
  sales_agents: any;
  is_description: any[];
  is_prew_description: any[];
  pageLoadingImg: boolean;
  up: any;
  pageLoading = false

  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    brch_id:0,
    usr_type:0,
    is_supplier : false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
resultobj: any = {};
isSubmitInProg = false;
userType: any;
sales_print_name: any;
sales_settings: any;
private modalRef: NgbModalRef;
closeResult: string;
  noCustomer: boolean;
  showSqmCalc = false;
  sqmCalc = [
    {
      height:0,
      width:0,
      qty:1,
      sqm:0
    }
  ]
  ttlSqm = 0;
  base_url: string;
  prodImgUrl: string;
  dflt_search_methd: any;
  matindex: number;
  item_code: any;
  ean_code: any;
  footImgUrl: any;
  footImg: any;
  country_dec: any;
  prd_location: any;
  cmp_tax: any;
  cgst_perc: any;
  sgst_perc: any;
  branch_state: any;
  states: any;
  cust_state_id: any;
  igst: boolean=false;
  descrptn: any;
  search_type: any;
  usr_hide_purch_cost: any;
  display_qty: any;
  stock_qty: any;
  unit_avg_prate: number;
  avg_prate: any;
  copyLoading: boolean;
  isAddMode: boolean=false;
  cust_balance: any;
  cust_bal_sign: any;
  cust_credit_limit: any;
  cust_bal: any;
  prod_alias: any;
  cur_lang: string;
  usr_block_pre_date_select: any;
  minDate:Date;
  hist_prd_id: any;
  desktoken: any;
  user_id: string;
  usr_hide_others_sales_data: any;
  prodUnitLoading: boolean;
  disable_sales_without_stock: any;
  show_base_unit_in_print: any;
  enable_sale_below_mrp: any;
  show_default_unit_in_barcode_search: any;
  sale_vat_type: any;
  increase_repeated_item: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,private translate: TranslateService) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.user_id = this.coreService.getUserData('usr_id');
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.base_url = this.apiService.PHP_API_SERVER;
    this.getGodownList();
    this.nextRefNo();
    this.cust_type = 2;
    this.excl_vat = 1;
    this.item_disc_type = 1;
    this.selectError = false;
    this.listing = false;
    this.importLoader = false;
    this.imporDrafttLoader = false;
    this.isEdit = false;
    this.sales_print_name=1;
    this.qtn_print_style=1;
    this.disable_sales_without_stock = 0;
    this.show_base_unit_in_print = 0;
    this.getSalesSetting();

    this.disc_amnt = 0;
    this.apiService.getAllTaxCategory().subscribe((resp) => {

      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
    this.checkDisplaySQMCalc();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.gd_id = 0;
    this.purchtypesel = 1;
    this.getTemplete();
    this.getDraftList();
    this.listQtn(1);
    this.saleqt_date = new Date();
    this.is_description = [];
    this.is_prew_description = [];
    this.item_dicrptn = '';
    this.up = this.coreService.getUserPrivilage();
    if(this.cmp_tax==2){
      this.branch_state = this.coreService.getUserData('branch_state_id');
      this.getAllState();
    }
    this.country_dec=this.coreService.setDecimalLength();
    this.getAllAgents();
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      
    }else{
      this.prod_alias =null;
  
    }
  }

  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
        this.sales_print_name=this.sales_settings.sales_print_name;
        this.terms = this.sales_settings.sales_qtn_default_terms;
        this.qtn_print_style = this.sales_settings.qtn_print_style;
        this.dflt_search_methd = this.sales_settings.dflt_search_methd;
        this.item_code = this.sales_settings.qtn_item_code_disply;
        this.ean_code = this.sales_settings.qtn_ean_code_disply;
        this.descrptn = this.sales_settings.qtn_item_descrptn;
        this.search_type = this.sales_settings.search_type;
        this.increase_repeated_item = this.sales_settings.qtn_repeated_item;
        this.disable_sales_without_stock = this.sales_settings.disable_sales_without_stock;
        this.show_base_unit_in_print = this.sales_settings.show_base_unit_in_print;
        
        this.enable_sale_below_mrp = this.sales_settings.enable_sale_below_mrp;
        this.sales_print_name = this.sales_settings.sales_print_name;
        this.purchtypesel = this.sales_settings.tax_type;
        this.show_default_unit_in_barcode_search = this.sales_settings.show_default_unit_in_barcode_search;
        this.sale_vat_type=this.sales_settings.vat_type;

      } else { 
        this.disable_sales_without_stock = 0;
        this.show_base_unit_in_print = 0;
        this.enable_sale_below_mrp = 0;
        this.sales_print_name=1;
        this.qtn_print_style=1;
        this.terms = '';
      }
    });

  }

  ngAfterViewInit() {
    this.selctbarcode.nativeElement.focus();

  }


  updateItemsIndex() {
    for (var index in this.salesItems) {
      this.salesItems[index].sl_no = Number(index) + 1;
    }
  }

  updateTaxPerc(taxCatId) {
    const selTax = this.taxProdCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.taxpercentage = selTax[0].taxcat_tax_per;
    if(this.cmp_tax==2){
      this.cgst_perc = selTax[0].cgst_tax_per;
      this.sgst_perc = selTax[0].sgst_tax_per;
    }
    this.calcRates();

  }

  showList(val) {
    if (val){
      this.listing = false;
      this.isAddMode=true;
    }else{
      this.listing = true;
      this.isAddMode=false;

    }
     
  }

  updateTotal() {

  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goNext(stepper: MatStepper) {
    stepper.next();
  }

  searchItem() {

    setTimeout(() => {
      if(this.dflt_search_methd){
        this.selectproductId.focus();
      }else{
        this.selctbarcode.nativeElement.focus();
      }
    }, 500);
  }

  resetStep(step1) {
    step1.reset();
    this.nextRefNo();
    setTimeout(() => {
      this.cust_type = 2;
      this.saleqt_date = new Date();

    }, 500);

  }

  newQtn(stepper: MatStepper, formdata, type) {
    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totcgstAmnt = 0;
    this.totsgstAmnt = 0;
    this.totigstAmnt = 0;
    this.net_disc = 0;
    this.resetProdForm();
    formdata.reset();
    this.nextRefNo();
    this.sqdrf_id = '';
    this.sq_inv_no = '';
    if (type == 'b'){
      stepper.reset();
    }
    this.saleqt_date = new Date();
    setTimeout(() => {
      this.cust_type = 2;
    }, 500);
   

  }

  resetProds(stepper: MatStepper) {
    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totcgstAmnt = 0;
    this.totsgstAmnt = 0;
    this.totigstAmnt = 0;
    this.net_disc = 0;
    stepper.previous();
  }

  proceedAddProd(stepper: MatStepper) {
    this.validateForm1();

    if(this.cmp_tax==2){
      this.iGSTchecking();
    }
    if (this.printError.length <= 0) {
      stepper.next();
    }
  }

  proceedToTerms(stepper: MatStepper) {

    if (this.salesItems.length > 0) {
      this.selectError = false;
      stepper.next();
    } else {
      this.selectError = true;
    }

  }

  validateForm1() {
    this.printError = [];
    if (this.cust_type === "" || this.cust_type === undefined || this.cust_type === null) {
      this.printError.push({ 'cust_type': 'Required' });
      $('#cust_type').css("border", "1px solid red");
    }
    else {
      $('#cust_type').css("border", "1px solid #dedede");
    }

    if (this.cust_type == 2) {
      if (this.cust_id === "" || this.cust_id === undefined || this.cust_id === null) {
        this.printError.push({ 'cust_id': 'Required' });
        $('#cust_id').css("border", "1px solid red");
      }
      else {
        $('#cust_id').css("border", "1px solid #dedede");
      }
    } else {
      if (this.cust_name === "" || this.cust_name === undefined || this.cust_name === null) {
        this.printError.push({ 'cust_name': 'Required' });
        $('#cust_name').css("border", "1px solid red");
      }
      else {
        $('#cust_name').css("border", "1px solid #dedede");
      }
    }

    if (this.saleqt_date === "" || this.saleqt_date === undefined || this.saleqt_date === null) {
      this.printError.push({ 'saleqt_date': 'Required' });
      $('#saleqt_date').css("border", "1px solid red");
    }
    else {
      $('#saleqt_date').css("border", "1px solid #dedede");
    }


  }

  addQtn(stepper: MatStepper, formdata: { value: any; }) {
    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = this.totItemprice;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sq_cgst_tax = this.totcgstAmnt;
    formdata.value.sq_sgst_tax = this.totsgstAmnt;
    if((this.cmp_tax==2)&&(this.igst)){
      formdata.value.sq_igst_tax = this.totigstAmnt;
      formdata.value.sq_is_igst = 1;
    }
    formdata.value.sale_items = this.salesItems;
    formdata.value.terms = this.terms;
    formdata.value.sales_note = this.sales_note;
    this.pageLoadingImg = true;

    this.apiService.addSalesQuotation(formdata.value).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message['msg']);
        this.saleData = res.message['preview'];
        this.is_prew_description = this.saleData['sales_qtn_sub'].filter(x => x.item_descrp);
        this.pageLoadingImg = false;
      //  this.prodImgUrl=this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
        stepper.next();
        this.sqdrf_id = '';
        this.sq_inv_no = '';

        this.getDraftList();
        this.listQtn(1);
        this.newQtn(stepper, formdata, '');
      
      }
    });
  }


  updateQtn(stepper: MatStepper, formdata: { value: any; }) {
    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = this.totItemprice;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sq_cgst_tax = this.totcgstAmnt;
    formdata.value.sq_sgst_tax = this.totsgstAmnt;
    if((this.cmp_tax==2)&&(this.igst)){
      formdata.value.sq_igst_tax = this.totigstAmnt;
      formdata.value.sq_is_igst = 1;
    }
    formdata.value.sale_items = this.salesItems;
    formdata.value.terms = this.terms;
    formdata.value.sales_note = this.sales_note;
    this.pageLoadingImg = true;

    this.apiService.updateSalesQuotation(formdata.value).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message['msg']);
        this.saleData = res.message['preview'];
        this.is_prew_description = this.saleData['sales_qtn_sub'].filter(x => x.item_descrp);
        this.pageLoadingImg = false;

        stepper.next();
        this.sqdrf_id = '';
        this.sq_inv_no = '';

        this.getDraftList();
        this.listQtn(1);
        this.newQtn(stepper, formdata, '');

      }
    });
  }


  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];

    });
  }

  nextRefNo() {
    let searchval = new FormData();

    this.apiService.getSaleQtRefNo(searchval).subscribe((res) => {
      this.saleRefNo = res['data'].sale_num;
      this.qt_no = res['data'].sq_branch_qt_no;

    });
  }

  searchStkPrd(search: string) {

    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append('stk_stat', '1');
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }
  searchCustomeralias(search: string){
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];

    });

  }

  findCustomer(cust_id) {
    const searchval = new FormData();
    searchval.append('cust_code', cust_id);

    this.apiService.getCustomerByCode(searchval).subscribe((res) => {
      this.cust_id = res['data'];
      if(res['data']){
        this.noCustomer = false;
        this.selectCustomer(this.cust_id);
      }
      else {
        this.noCustomer = true;
        this.clearCustomer();
      }


    });

  }

  selectCustomer(cust) {
    if (cust) {
      this.noCustomer = false;
      this.cust_code = cust.cust_code;
      this.cust_addr = cust.cust_home_addr;
      this.cntct_num = cust.mobile;
      this.vat_no = cust.vat_no;
      this.sale_agent= cust.default_sales_agent;
      this.cust_balance = cust.balance.bal_without_sign;
      this.cust_bal_sign = cust.balance.bal_sign;
      this.cust_bal = cust.balance.bal_amt;
      this.cust_credit_limit = cust.cust_credit_limit;

      if(this.cmp_tax==2){
        this.cust_state_id = cust.state_id;
       this.iGSTchecking();
        }
    }
  }

  iGSTchecking(){

    if(this.cmp_tax==2){
      
      // for is igst verify
      if((this.cust_state_id!=this.branch_state)&&(this.cust_type==2)&&(this.cust_state_id)){
        this.igst=true;
      }else{
        this.igst=false;
      }
     
      }
  }

  clearCustomer() {
    this.cust_code = '';
    this.cust_addr = '';
    this.cntct_num = '';
    this.vat_no = '';
  }

  selectDiscType() {

    // if(itemDiscTyp == 1){

    // }
    this.calcRates();

  }

  searchStkPrdBarcode(search: any) {
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.prodUnitLoading = true;
    this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
      // this.stkprd_all = res['data'];
      if (res.data) {
        this.stkprdsel = res.data;
        this.selectedProduct(res.data.prd_id, this.gd_id, this.stkprdsel);
        this.selectQty.nativeElement.focus();
        this.selectQty.nativeElement.select();

      } else {
        this.stkprdsel = {
          prd_name: null
        };
        this.stkprdsel = "";
        this.selctedProd = {};
        this.qty = '';
        this.rate = '';
        this.unit = '';
        this.disc_amnt = 0;
        // this.prd_tax_cat_id = '';
        this.tax_amnt = '';
        this.grnd_totl = '';
        this.item_dicrptn = '';
        this.godownStocks = [];
        this.branchStocks = [];
        this.lastInvoice = [];
        this.isEdit = false;
        this.sl_no = '';
        this.base_qty = '';
        this.unit_name = '';
      }
    });
  }

  selectedProduct(prd_id, gd_id, stkprdsel) {

    this.printError = [];

    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }


    this.resetLastProdDetails();
    if (this.printError.length <= 0) {
      this.prodUnitLoading = true;
      if (gd_id) {
        gd_id = gd_id;
      } else {
        gd_id = 0;
      }

      this.getGodownWiseStock(prd_id);
      this.getBranchWiseStock(prd_id);
      this.getStockDetails(prd_id, gd_id);
      // if(this.cmp_tax==2){
      //   this.updateTaxPerc(this.selctedProd.prd_tax_cat_id);
      // }
     
      if (this.cust_id) {
        this.getLastSaleToCust(prd_id, this.cust_id.cust_id);
      }
      if (this.purchtypesel == 1)
        this.prd_tax_cat_id = Number(stkprdsel.prd_tax_cat_id);
        this.updateTaxPerc(stkprdsel.prd_tax_cat_id);

      this.calcRates();
      // setTimeout(() => {
      //   this.selectQty.nativeElement.focus();
      //   this.selectQty.nativeElement.select();

      // }, 1000);

    }
  }

  getGodownWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.godownStocksTotal = 0;

    this.apiService.getGodownWiseStock(searchval).subscribe((res) => {
      this.godownStocks = res['data']['data'];
      this.godownStocksTotal = res['data']['total'];
    });

  }

  getLastSaleToCust(prd_id, cust_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('cust_id', cust_id);
    if(this.usr_hide_others_sales_data){
      searchval.append('user_id', '1');
  
      }
    this.apiService.getLastSaleToCust(searchval).subscribe((res) => {
      this.lastInvoice = res['data'];
      this.lastCust = 0;

      if (this.lastInvoice[0])
        this.lastCust = this.lastInvoice[0]['salesub_rate'];
      else
        this.lastCust = 0;

    });

  }

  getBranchWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.branchStocksTotal = 0;

    this.apiService.getBranchWiseStock(searchval).subscribe((res) => {
      this.branchStocks = res['data']['data'];
      this.branchStocksTotal = res['data']['total'];

    });

  }

  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];

      this.available_stock= this.selctedProd.stock_qty;
      this.unit_available_stock= this.selctedProd.stock_qty;
      this.bs_prate = this.selctedProd.bs_prate;
      this.avg_prate = this.selctedProd.bs_avg_prate;
      this.unit = (this.selctedProd.prd_default_unit ? this.selctedProd.prd_default_unit.unit_id : this.selctedProd.prd_base_unit_id);
      this.barcode = this.selctedProd.prd_barcode;
      this.item_dicrptn = this.selctedProd.prd_remarks;
      this.prd_location = this.selctedProd.prd_location;
      this.display_qty=this.selctedProd.bs_rate2_display_qty;
      this.selectedUnit(this.unit);
      this.calcRates();    
      if (this.purchtypesel == 1){
        this.updateTaxPerc(this.selctedProd.prd_tax_cat_id);
      }
      
       if(this.unit){
        setTimeout(() => {
          this.selectQty.nativeElement.focus();
          this.selectQty.nativeElement.select();
    
        }, 800);
       }

    });
   

  }

  resetProdForm() {
    this.barcode = '';
    this.stkprdsel = "";
    this.selctedProd = {};
    this.qty = '';
    this.rate = '';
    this.unit = '';
    this.disc_amnt = 0;
    // this.prd_tax_cat_id = '';
    this.tax_amnt = '';
    this.grnd_totl = '';
    this.item_dicrptn = '';
    this.godownStocks = [];
    this.branchStocks = [];
    this.lastInvoice = [];
    this.isEdit = false;
    this.sl_no = '';
    this.base_qty = '';
    this.unit_name = '';
    this.excl_vat = 1;

    this.selectproductId.focus();

  }

  resetLastProdDetails() {
    this.qty = '';
    this.rate = '';
    this.unit = '';
  }

  selectedUnit(unit) {
    const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === unit);
    var unit_rate = (this.sel_rate_type == 1) ? prdUnts[0].sur_unit_rate : prdUnts[0].sur_unit_rate2;
    this.rate = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
    this.base_qty = prdUnts[0].unit_base_qty;
    this.unit_name = prdUnts[0].unit_display;
    this.unit_available_stock = this.available_stock / this.base_qty;
    this.unit_bs_prate = this.bs_prate * this.base_qty;
    this.unit_avg_prate = this.avg_prate * this.base_qty;
    this.prodUnitLoading = false;
    this.calcRates();

  }

  calcRates() {

    this.qty = this.qty ? this.qty : '';
    let qty1 = this.qty*this.base_qty;
    this.rate = this.rate ? this.rate : 0;
    this.itemDisc = this.disc_amnt ? this.disc_amnt : 0;

    this.purchAmnt = this.qty * this.rate;

    if(qty1>=this.display_qty){
      this.isSecondOptionDisabled =false;
    }else{
      this.sel_rate_type=1;
      this.isSecondOptionDisabled =true;
      
    }

    if (this.item_disc_type == 1)
      this.itemDisc = this.itemDisc;
    else
      this.itemDisc = (this.purchAmnt * this.itemDisc) / 100;

    if (this.excl_vat == 1) {
      this.tax_amnt = ((this.purchAmnt - (this.itemDisc)) * this.taxpercentage) / 100;
      this.taxprice = ((this.rate - this.itemDisc) * this.taxpercentage) / 100;
      this.itemRate = this.rate;
    } else {
      this.tax_amnt = (this.purchAmnt - (this.itemDisc)) - (((this.purchAmnt - (this.itemDisc)) * 100) / (this.taxpercentage + 100));
      this.taxprice = (this.rate - this.itemDisc) - (((this.rate - this.itemDisc) * 100) / (this.taxpercentage + 100));
      this.itemRate = (((this.rate - this.itemDisc) * 100) / (this.taxpercentage + 100));
    }
    if (this.excl_vat == 1)
      this.itemTotal = this.purchAmnt + this.tax_amnt;
    else
      this.itemTotal = this.purchAmnt;

    this.grnd_totl = this.itemTotal - this.itemDisc;
    this.grnd_totl = this.itemTotal - this.itemDisc;

  }




  addSaleItem(selected: { value: any; }, close) {


    this.printError = [];
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }

    if (this.stkprdsel == "" || this.stkprdsel == undefined) {
      this.printError.push({ 'prdErr': 'Required' });
      $('#selprod').css("border", "1px solid red");
    }
    else {
      $('#selprod').css("border", "1px solid #dedede");
    }
      
    if (this.qty == "" || this.qty <= 0||isNaN(this.qty)) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#qty').css("border", "1px solid red");
    }
    else {
      $('#qty').css("border", "1px solid #dedede");
    }
    if ((this.unit_available_stock <= 0 && this.disable_sales_without_stock) || (this.disable_sales_without_stock && (this.unit_available_stock.toFixed(3) < parseFloat(this.qty)))) {
      this.printError.push({ 'without_stock': 'Disabled' });
      alert("Sales without stock is disabled");
      return false;
    }
    
    if (this.unit == "" || this.unit == undefined) {
      this.printError.push({ 'unitErr': 'Required' });
      $('#unit').css("border", "1px solid red");
    }
    else {
      $('#unit').css("border", "1px solid #dedede");
    }


    if (this.printError.length <= 0) {
      
      console.log(this.salesItems);
      console.log('this.salesItems');
      console.log(selected.value);
      
      this.resDup = this.coreService.findItemSalesQtn(this.salesItems, Number(selected.value.stkprdsel.prd_id), this.itemRate, selected.value.unit, selected.value.item_dicrptn,'',selected.value.prd_tax_cat_id,selected.value.disc_amnt);
      var existingArray = this.coreService.findItemSalesQtn(this.salesItems, Number(selected.value.stkprdsel.prd_id), this.itemRate, selected.value.unit, selected.value.item_dicrptn,'',selected.value.prd_tax_cat_id,selected.value.disc_amnt);
    
      if (this.resDup && existingArray.length > 0 && this.increase_repeated_item) {
        
          if(this.sales_settings.sales_item_grid_warning){
        
            let pname=existingArray?existingArray[0].prd_name:'Item';
            
             if(this.sales_settings.sales_item_grid_warning==1){
                 alert( pname+" Already Exist in this List");
               }else if(this.sales_settings.sales_item_grid_warning==2){
                 alert(pname +" Already Exist in this List,You Cannot Add");
                 return false;
               }
        
           
        }

        var existQty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_qty;
        
        
        // var existPrc = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate;
        this.taxvaltotqty = ((Number((Number(existQty)+Number(selected.value.qty)) * this.base_qty)) * ((selected.value.tax_amnt/selected.value.qty) / this.base_qty));
       

        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_qty = Number(existQty) + Number(selected.value.qty);
        // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate = Number(existPrc) + Number(selected.value.rate);
        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_tax_amnt = this.taxvaltotqty;
        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_amnt = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate * (Number(existQty)+Number(selected.value.qty));


      } else {
        if (this.gd_id == undefined)
          this.gd_id = 0;
        this.salesItems.push({
          "sl_no": (this.salesItems.length) + 1, 'salesub_prd_id': selected.value.stkprdsel.prd_id, 'prd_name': selected.value.stkprdsel.prd_name, 'ean': selected.value.stkprdsel.prd_ean, 'salesub_stock_id': selected.value.stkprdsel.bs_stock_id, 'salesub_qty': selected.value.qty,
          'salesub_rate': this.itemRate, 'salesub_amnt': this.itemRate * selected.value.qty, 'salesub_tax_amnt': selected.value.tax_amnt, 'salesub_tax': selected.value.tax_amnt / selected.value.qty, 'salesub_tax_per': this.taxpercentage, 'salesub_price': this.purchAmnt,'salesub_cgst_tax_per': this.cgst_perc,'salesub_sgst_tax_per': this.sgst_perc,
          'purchpricesingle': this.itemRate / this.base_qty, 'salesub_gd_id': this.gd_id, 'taxvalperqty': selected.value.tax_amnt / this.base_qty,
          'base_qty': this.base_qty, 'prd_unit_name': this.unit_name, 'salesub_unit_id': selected.value.unit, 'prd_barcode': selected.value.stkprdsel.prd_barcode, 'prd_tax_cat_id': selected.value.prd_tax_cat_id
          , 'item_desc': this.item_dicrptn, 'item_disc': this.itemDisc, 'prd_type': selected.value.stkprdsel.prd_type, 'vat_type': selected.value.excl_vat, 'item_disc_type': selected.value.item_disc_type
        });

      }

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.sumSaleTotal();
      this.resetProdForm();
      selected = null;
      if (close == 'close')
        $('.close').trigger("click");
    }


  }


  editSaleItem(selected: { value: any; }, close) {
    console.log( 'this.cgst_perc');
console.log( this.cgst_perc);
console.log( 'this.sgst_perc');
console.log( this.sgst_perc);

    this.printError = [];
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }

    if (this.stkprdsel == "" || this.stkprdsel == undefined) {
      this.printError.push({ 'prdErr': 'Required' });
      $('#selprod').css("border", "1px solid red");
    }
    else {
      $('#selprod').css("border", "1px solid #dedede");
    }

    if (this.qty == "" || this.qty <= 0) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#qty').css("border", "1px solid red");
    }
    else {
      $('#qty').css("border", "1px solid #dedede");
    }
    if ((this.unit_available_stock <= 0 && this.disable_sales_without_stock) || (this.disable_sales_without_stock && (this.unit_available_stock.toFixed(3) < parseFloat(this.qty)))) {
      this.printError.push({ 'without_stock': 'Disabled' });
      alert("Sales without stock is disabled");
      return false;
    }


    if (this.printError.length <= 0) {
      this.selectedUnit(this.unit);
      // console.log(this.unit);

      // this.resDup = this.coreService.checkSaleItem(this.salesItems, selected.value.stkprdsel.prd_id, selected.value.rate, selected.value.unit, selected.value.item_dicrptn);
      // var existingArray = this.coreService.findItemSales(this.salesItems, selected.value.stkprdsel.prd_id, selected.value.rate, selected.value.unit, selected.value.item_dicrptn);
      // if (this.resDup && existingArray.length > 0) {

      //   var existQty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty;
      //   // var existPrc = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate;
      //   this.taxvaltotqty = ((Number(existQty) + Number(selected.value.qty * this.base_qty)) * selected.value.tax_amnt / this.base_qty);
      //   this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_qty = Number(existQty) + Number(selected.value.qty);
      //   // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate = Number(existPrc) + Number(selected.value.rate);
      //   this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_tax = this.taxvaltotqty;
      //   // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty + this.base_qty;


      // } else {
      if (this.gd_id == undefined)
        this.gd_id = 0;
      // this.salesItems.push({
      //   "sl_no": (this.salesItems.length) + 1, 'salesub_prd_id': selected.value.stkprdsel.prd_id, 'prd_name': selected.value.stkprdsel.prd_name, 'ean': selected.value.stkprdsel.prd_ean, 'salesub_stock_id': selected.value.stkprdsel.bs_stock_id, 'salesub_qty': selected.value.qty,
      //   'salesub_rate': this.itemRate, 'salesub_amnt': this.itemRate * selected.value.qty, 'salesub_tax_amnt': selected.value.tax_amnt, 'salesub_tax': selected.value.tax_amnt / selected.value.qty, 'salesub_tax_per': this.taxpercentage, 'salesub_price': this.purchAmnt,
      //   'purchpricesingle': this.itemRate / this.base_qty, 'salesub_gd_id': this.gd_id, 'taxvalperqty': selected.value.tax_amnt / this.base_qty,
      //   'base_qty': this.base_qty, 'prd_unit_name': this.unit_name, 'salesub_unit_id': selected.value.unit, 'prd_barcode': selected.value.stkprdsel.prd_barcode, 'prd_tax_cat_id': selected.value.prd_tax_cat_id
      //   , 'item_desc': this.item_dicrptn, 'item_disc': this.itemDisc, 'prd_type': selected.value.stkprdsel.prd_type, 'vat_type': selected.value.excl_vat
      // });
      selected.value.rate = (selected.value.excl_vat == 2) ?  selected.value.rate - selected.value.tax_amnt/selected.value.qty : selected.value.rate;
      
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_prd_id = selected.value.stkprdsel.prd_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_name = selected.value.stkprdsel.prd_name;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).ean = selected.value.stkprdsel.prd_ean;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_stock_id = selected.value.stkprdsel.bs_stock_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_qty = selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_rate = selected.value.rate;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_amnt = selected.value.rate * selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_tax_amnt = selected.value.tax_amnt;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_cgst_tax_per = this.cgst_perc;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_sgst_tax_per = this.sgst_perc;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_tax = selected.value.tax_amnt / selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_tax_per = this.taxpercentage;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_price = this.purchAmnt;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).purchpricesingle = this.itemRate / this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_gd_id = this.gd_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).taxvalperqty = selected.value.tax_amnt / this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).base_qty = this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_unit_name = this.unit_name;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_unit_id = selected.value.unit;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_barcode = selected.value.stkprdsel.prd_barcode;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_tax_cat_id = selected.value.prd_tax_cat_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_desc = this.item_dicrptn;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_disc = this.itemDisc;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_type = selected.value.stkprdsel.prd_type;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).vat_type = selected.value.excl_vat;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_disc_type = selected.value.item_disc_type;

      // }

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.sumSaleTotal();
      this.resetProdForm();
      if (close == 'close')
        $('.close').trigger("click");

    }

  }


  // delete product from table
  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the List ?");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.salesItems.length - 1; i >= 0; --i) {
        if (this.salesItems[i].sl_no == type) {
          this.salesItems.splice(i, 1);
        }
      }

      this.updateItemsIndex();
      this.sumSaleTotal();
    }

  }

  // Edit product from List
  editProd(data) {

    if (data) {

      const searchval = new FormData();
      searchval.append('prd_id', data.salesub_prd_id);
      searchval.append('gd_id', data.salesub_gd_id);
      this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
        this.selctedProd = res['data'];
        if (this.purchtypesel == 1)
          this.updateTaxPerc(this.selctedProd.prd_tax_cat_id);
      });

      this.getGodownWiseStock(data.salesub_prd_id);
      this.getBranchWiseStock(data.salesub_prd_id);
      if (this.cust_id) {
        this.getLastSaleToCust(data.salesub_prd_id, this.cust_id.cust_id);
      }
      this.apiService.geteditSalesItem(data).subscribe((res: any) => {
        this.sl_no = data.sl_no;
        this.stkprdsel = res.data.product;
        this.qty = data.salesub_qty;
        this.unit = data.salesub_unit_id;
        this.unit_available_stock = this.stkprdsel.bs_stock_quantity/data.base_qty;
        this.unit_avg_prate=this.stkprdsel.bs_avg_prate *data.base_qty;
        this.unit_bs_prate=this.stkprdsel.bs_prate *data.base_qty;
        this.excl_vat = data.vat_type;
        if(this.excl_vat == 2){
          this.rate = data.salesub_rate + data.salesub_tax;
        }else{
          this.rate = data.salesub_rate;
        }

        if (data.item_disc_type == 2)
          this.disc_amnt = (data.item_disc / (data.salesub_qty * data.salesub_rate)) * 100;
        else
          this.disc_amnt = data.item_disc;

        this.prd_tax_cat_id = Number(data.prd_tax_cat_id);
        this.item_dicrptn = data.item_desc;
        this.calcRates();
        $('#searchItem').trigger('click');

      });

      this.isEdit = true;

    }
  }

  sumSaleTotal() {

    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totcgstAmnt = 0;
    this.totsgstAmnt = 0;
    this.totigstAmnt = 0;
    console.log(this.salesItems);

    for (var i = 0; i < this.salesItems.length; i++) {

      this.totItemprice = Number(this.totItemprice) + (Number(this.salesItems[i]['salesub_qty']) * Number(this.salesItems[i]['salesub_rate']));
      this.totItemDisc = Number(this.totItemDisc) + Number(this.salesItems[i]['item_disc']);
      this.totVatAmnt = Number(this.totVatAmnt) + Number(this.salesItems[i]['salesub_tax_amnt']);

      if(this.cmp_tax==2){
        this.totcgstAmnt = Number(this.totcgstAmnt) + Number(((this.salesItems[i]['salesub_price']-this.salesItems[i]['item_disc'])/100)*this.salesItems[i]['salesub_cgst_tax_per']);
        this.totsgstAmnt = Number(this.totsgstAmnt) + Number(((this.salesItems[i]['salesub_price']-this.salesItems[i]['item_disc'])/100)*this.salesItems[i]['salesub_sgst_tax_per']);
      }
    }

    // for IGST Case
    if((this.cmp_tax==2)){
      // this.totsgstAmnt=0;
      // this.totcgstAmnt=0;
      this.totigstAmnt=this.totVatAmnt;

    }
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      const imagePath = res.data.sale_qtn_header_img_path != null ? res.data.sale_qtn_header_img_path : res.data.prnt_header_img_path;
      const imageFtrPath = res.data.sale_qtn_footer_img_path != null ? res.data.sale_qtn_footer_img_path : res.data.prnt_footer_img_path;

      if (this.qtn_print_style == 3 || this.qtn_print_style == 4) {
        this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + imageFtrPath;
      } else {
        this.footImgUrl = '';
      }
      console.log(this.footImgUrl);
      
      this.footImg = this.apiService.PHP_API_SERVER + '/' + imageFtrPath;
      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + imagePath;
      } else {
        this.tempImgUrl = '';
      }

      if(res.data.water_mark_img_path){
        this.water_mark = this.apiService.PHP_API_SERVER + "/" + res.data.water_mark_img_path;
      } else {
        this.water_mark = "";
      }

    });
  }


  selectTaxType(ptypeid) {

    // purchase_types = [
    //   { id: 1, name: 'Standard Rated Domestic' },
    //   { id: 2, name: 'Zero Rated Domestic' },
    //   { id: 3, name: 'Exempt Purchase' }
    //   
    // ];


    if (ptypeid === 3 || ptypeid === 2) {
      // to set tax category with 0% tax percentage
      this.taxListCategories = this.taxProdCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);

      if (this.taxListCategories.length > 0) {
        this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
        this.taxpercentage = this.taxListCategories[0].taxcat_tax_per
      }
      this.zeroType = true;
    } else {
      this.zeroType = false;

    }
  }

  draftQuotation(formdata: { value: any; }) {
    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = this.totItemprice;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sale_items = this.salesItems;
    // formdata.value.terms = this.terms;
    formdata.value.sales_note = this.sales_note;

    this.draftLoader = true;

    this.apiService.draftSalesQuotn(formdata.value).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message);
        this.draftLoader = false;
        this.getDraftList();


      }
    });
  }

  getDraftList() {

    this.apiService.getSalesDraftList('').subscribe((res) => {
      this.draftList = res.data;

    });
  }

  removeSaleFromDraft(draft_id) {

    if (confirm("Are You Sure You Want To Remove This Quotation?")) {
      let searchval = new FormData();
      searchval.append("sqdrf_id", draft_id);
      this.apiService.removeSalesDraft(searchval).subscribe((res) => {
        this.coreService.showMessage("Removed Successfully");
        this.getDraftList();

      });
    }
  }


  getDraftDet(draft_id) {
    let searchval = new FormData();
    searchval.append("sqdrf_id", draft_id);
    this.imporDrafttLoader = true;

    this.apiService.getSalesDraft(searchval).subscribe((res: any) => {

      this.salesItems = res.data.sales_drft_sub;
      if (res.data.sqdrf_valid_till) {
        this.valid_till_date = new Date(res.data.sqdrf_valid_till);
      } else {
        this.valid_till_date = '';
      }
      if(this.cmp_tax==2){
        this.cust_state_id =  res.data.customer.state_id;
        this.iGSTchecking();
       
      }
      this.saleqt_date = new Date(res.data.sqdrf_date);
      this.cust_type = res.data.sqdrf_cust_type;
      this.cust_code = res.data.sqdrf_cust_code;
      this.cust_name = res.data.sqdrf_cust_name;
      this.cust_id = res.data.customer;
      this.cust_addr = res.data.sqdrf_cust_address;
      this.cntct_num = res.data.sqdrf_cust_phone;
      this.vat_no = res.data.sqdrf_cust_tin;
      this.net_disc = res.data.sqdrf_disc;
      this.sqdrf_id = res.data.sqdrf_id;
      this.sales_note = res.data.sqdrf_notes;


      // this.sale_agent = res.data.sqdrf_sales_agent;
      this.sumSaleTotal();
      this.imporDrafttLoader = false;

      $('.close').trigger("click");



    });
  }
  searchInp(keyword) {
    let searchval = new FormData();
    if(keyword)
    searchval.append("keyword", keyword);
    this.apiService.getSalesQtnList(searchval, 1).subscribe((res) => {
      this.list_quotations = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }
  searchInpNotes(keyword) {
    let searchval = new FormData();
    if(keyword)
    searchval.append("keyword", keyword);
    this.apiService.getSalesQtnListWithNotes(searchval, 1).subscribe((res) => {
      this.list_quotations = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  listQtn(pageNo = 1) {
    let searchval = new FormData();
    // if(keyword)
    // searchval.append("keyword", keyword);
    this.apiService.getSalesQtnList(searchval, pageNo).subscribe((res) => {
      this.list_quotations = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  importSales(sq_inv_no) {
    let searchval = new FormData();
    searchval.append("sq_inv_no", sq_inv_no);
    this.importLoader = true;
   
    // this.sq_inv_no=false

    this.apiService.getSalesQtn(searchval).subscribe((res: any) => {

      this.saleRefNo = res.data.sq_inv_no;
      this.qt_no = res.data.sq_branch_qt_no;
      this.sq_inv_no = res.data.sq_inv_no;
      this.salesItems = res.data.sales_qtn_sub;

      if (res.data.sq_valid_till) {
        this.valid_till_date = new Date(res.data.sq_valid_till);
      } else {
        this.valid_till_date = '';
      }
      this.saleqt_date = new Date(res.data.sq_date);
      this.cust_type = res.data.sq_cust_type;
      this.cust_code = res.data.sq_cust_code;
      this.cust_name = res.data.sq_cust_name;
      this.cust_id = res.data.customer;
      this.cust_addr = res.data.sq_cust_address;
      this.cntct_num = res.data.sq_cust_phone;
      this.vat_no = res.data.sq_cust_tin;
      this.net_disc = res.data.sq_disc;
      this.sales_note = res.data.sq_notes;
      this.terms = res.data.sq_terms;
      this.reference = res.data.sq_reference;
      this.sale_agent = res.data.sq_sales_agent;
      // this.sale_agent = res.data.sqdrf_sales_agent;
      this.cust_state_id=this.cust_id.state_id;
      this.iGSTchecking();
      this.sumSaleTotal();
      this.importLoader = false;

      this.listing = false;

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');


    });
  }


  quatationSales(id) {
    let searchval = new FormData();
    searchval.append("sq_inv_no", id);
    this.previewLoader = true;
    this.apiService.getSalesQtnPreview(searchval).subscribe((res: any) => {
      if (res.data) {

        this.saleQtnData = res.data['preview'];
        this.is_prew_description = this.saleQtnData['sales_qtn_sub'].filter(x => x.item_descrp);

        this.previewLoader = false;

      }
    });
  }
  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateAndSubmit(){
    this.isSubmitInProg = true;
    let callFunction = 'createCustOrSup';
    if(this.userType =='ADMIN'){
      this.customer_dt.usr_type = 1;
    }else{
      this.customer_dt.usr_type = 0;
    }

    this.apiService[callFunction](this.customer_dt).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.resultobj = {};
        this.modalRef.close();
        this.clearForm();
      }
    });
  }
  clearForm() {
    this.customer_dt = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      brch_id:0,
      usr_type:0,
      is_supplier : false,
      op_bal_type: true,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
  }


  copysale(inv_no){
    let searchval = new FormData();
    searchval.append("sq_inv_no", inv_no);
    this.pageLoadingImg = true;
    this.apiService.copySalesQtnToSale(searchval).subscribe((res: any) => {
      if (res.message) {
        this.pageLoadingImg = false;
        this.coreService.showMessage(res.message['msg']);
      }
    });
  }
  checkDisplaySQMCalc(){
    this.apiService.getClientSettingBykey({key: 'show_sales_sqm_calc'}).subscribe((res) => {
      if (res['data']) {
        this.showSqmCalc = (res['data']['cs_value']) ? true: false;
      } 
    });
  }
  
  addMoreRow() {
    this.sqmCalc.push(
      {
        height:0,
        width:0,
        qty:1,
        sqm:0
      }
    );
  }
  
  remove(index) {
    this.sqmCalc.splice(index, 1);
    this.updateTotoal();
  }
  sqmCalculate(index){
    this.sqmCalc[index].sqm = this.sqmCalc[index].height * this.sqmCalc[index].width * this.sqmCalc[index].qty / 10000;
    this.updateTotoal();
  }
  updateTotoal(){
    this.ttlSqm = 0;
    this.sqmCalc.forEach(element => {
      this.ttlSqm += Number(element.sqm);
    });
  }
  clearAll(){
    this.sqmCalc = [
      {
        height:0,
        width:0,
        qty:1,
        sqm:0
      }
    ]
    this.updateTotoal();
  }
  apply(){
    this.qty = this.ttlSqm;
    let desc = '';
    this.sqmCalc.forEach(element => {
      desc += element.height + 'H x ' + element.width + 'W x '  + element.qty + " QTY, ";
    });
    this.item_dicrptn = desc.replace(/,\s*$/, "");
    this.calcRates();
    $('.closbtn').parents('.modal:first').hide();
    $('#btnsqlcalc').click();
  }

  @HostListener('document:keydown.enter', ['$event.target'])
  handleEnterKey(target: HTMLElement) {
    // Check if the target element is not an input or textarea
    if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA') {
      // 
      
      if(this.matindex==1){
        this.secndnextButton.nativeElement.click()
      }else if(this.matindex !=2 && this.matindex !=3){
        this.firstnextButton.nativeElement.click()
      }
    }

  }
  onStepSelected(event: any) {
    if (event.selectedIndex == event.selectedIndex && event.previouslySelectedIndex != event.selectedIndex) {

      this.matindex=event.selectedIndex;
    
      
    }
  }

  removeSalesQuotation(id) {

    if (confirm("Do you wish to void this entry?")) {
      let searchval = new FormData();

      searchval.append("sq_inv_no", id);
      this.apiService.voidQuotation(searchval).subscribe((res) => {
        if (res.message)
          this.coreService.showMessage('Voided Successfully');
          // this.listing = true;
          this.listQtn(1);

      });

    }

  }


  langChange(){
    this.translate.get(['Common.new', 'Common.registerd','Common.standard_rated_domestic','Common.zero_rated_domestic','Common.exempt_sales','Sales.excl_vat','Sales.incl_vat','Table.rate','Production.percentage']).subscribe((res: string) => {    
     

      this.cust_types = [
        { id: 1, name: res['Common.new']},
        { id: 2, name: res['Common.registerd'] },
  
      ];
      this.purchase_types = [
        { id: 1, name: res['Common.standard_rated_domestic']},
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_sales'] },
      ];
      
      this.vat_types = [
        { id: 1, name:  res['Sales.excl_vat'] },
        { id: 2, name:  res['Sales.incl_vat'] }
      ];
    
      this.item_disc_types = [
        { id: 1, name:  res['Table.rate'] },
        { id: 2, name:  res['Production.percentage'] }
      ];
    });

  }

  copyReference(refno){

    const searchval = new FormData();
    searchval.append("sq_inv_no", refno);
    // this.copyLoading=true;

  // this.isAddMode = true;
  this.pageLoading = true
    this.apiService.copyTransferRef(searchval).subscribe((res) => {
      this.pageLoading = false

      // this.saleRefNo = res.data.sq_inv_no;
      // this.qt_no = res.data.sq_branch_qt_no;
      // this.sq_inv_no = res.data.sq_inv_no;
      this.salesItems = res.data.sales_qtn_sub;

      if (res.data.sq_valid_till) {
        this.valid_till_date = new Date(res.data.sq_valid_till);
      } else {
        this.valid_till_date = '';
      }
      if(this.cmp_tax==2){
     
        console.log('hello');
        
        this.cust_state_id =  res.data.customer.state_id;
        console.log(this.cust_state_id);
        this.iGSTchecking();
        
       
      }
      this.saleqt_date = new Date(res.data.sq_date);
      this.cust_type = res.data.sq_cust_type;
      this.cust_code = res.data.sq_cust_code;
      this.cust_name = res.data.sq_cust_name;
      this.cust_id = res.data.customer;
      this.cust_addr = res.data.sq_cust_address;
      this.cntct_num = res.data.sq_cust_phone;
      this.vat_no = res.data.sq_cust_tin;
      this.net_disc = res.data.sq_disc;
      this.sales_note = res.data.sq_notes;
      this.terms = res.data.sq_terms;
      this.reference = res.data.sq_reference;
      // this.sale_agent = res.data.sqdrf_sales_agent;
      if (!this.reference && !this.cust_name) {
        this.no_refno = "Reference number is not matching";
      } else {
        this.no_refno = ""; // Clear the error message if reference is valid
      }
      this.sumSaleTotal();
      this.importLoader = false;

      this.listing = false;

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');



   
    });
  }

  rate_history(prd_id) {
 
    this.hist_prd_id=prd_id;
    this.desktoken =localStorage.cmptoken;
    
    $("#ifrm").attr("src", environment.php_api_server + "/product-history?prod_id="+prd_id +"&desktoken="+this.desktoken+"&user="+this.user_id+"&Lang="+this.translate.currentLang);
    
  }

  getAllState(){
    let searchval = new FormData();
    this.apiService.getAllState(searchval).subscribe((res) => {
      this.states = res.data;
      
    });
  }
}

