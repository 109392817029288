import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-cash-bank-book',
  templateUrl: './rep-cash-bank-book.component.html',
  styleUrls: ['./rep-cash-bank-book.component.css']
})
export class RepCashBankBookComponent implements OnInit {



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  accType = [
    { id: '3', name: 'Cash' },
    { id: '4', name: 'Bank' }
  ];

  report_type = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' },
    { id: 'grouped_detail', name: 'Grouped Ledger' }

  ];


  ledger_sel_id: any;
  reportData: any;
  selecTed: any[];
  pageLoadingImg: boolean;
  searchleadger: any;
  period_type: string;
  acc_type: string;
  rep_type: string;
  show_op_bal :any;
  op_bal :any;
  vch_type: any;
  country_dec: string;
  branch_all: any;
  filter_branch_id: any;
  userType: any;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  repDate: any;
  repTotal: any;
  repResult: any;
  Amt: any;
  repAccType: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.userType = this.coreService.getUserData('user_type');
    this.period_type = 't';
    this.acc_type = '3'
    this.rep_type = 'summary';
   

   this.filter_branch_id = 1;
   this.getAllBranch();
   this.getDefaultLedgers();
    
    let searchval = new FormData();
    searchval.append("ledger_grp", '3');
    searchval.append("ledger_name", '');
    if(this.userType == 'ADMIN'){
      searchval.append("filter_branch_id", this.filter_branch_id);
    }
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.searchleadger = res['data'];
      this.repDate = res['data']['Date'];
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
      
    });

    this.country_dec=this.coreService.setDecimalLength();
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','Common.detailed','ACCOUNTS.grpd_ledger','Common.cash','HOME.Bank']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.report_type = [
        { id: 'summary', name: res['Common.summary'] },
        { id: 'detailed', name: res['Common.detailed'] },
        { id: 'grouped_detail', name: res['ACCOUNTS.grpd_ledger'] }
    
      ];
      this.accType = [
        { id: '3', name: res['Common.cash'] },
        { id: '4', name: res['HOME.Bank'] }
      ];
    });

  }

  //form search  ledger
  searchLedger(search: string, grp = '3') {
    // $("#report-wrapper").hide();

    let searchval = new FormData();
    searchval.append("ledger_grp", grp);
    searchval.append("ledger_name", search);
    if(this.userType == 'ADMIN'){
      searchval.append("filter_branch_id", this.filter_branch_id);
    }
    
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.searchleadger = res['data'];
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
      this.selecTed = [];
    });

  }
  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.vch_type = res.data;
    });
  }

  changeAccType() {
    this.searchleadger = [];
    this.ledger_sel_id = {};

    let searchval = new FormData();
    searchval.append("ledger_grp", this.acc_type);
    searchval.append("ledger_name", '');
    if(this.userType == 'ADMIN'){
      searchval.append("filter_branch_id", this.filter_branch_id);
    }
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.searchleadger = res['data'];
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
    });


  }

  selectLedger(ledg_id, form) {
    if (this.ledger_sel_id > 0 || this.ledger_sel_id == 'All') {
      if (form && form.value.period_type === 'c') {
        form.value.date1 = (form.value.date1) ? this.apiService.formatDate(form.value.date1) : form.value.date1;
        form.value.date2 = (form.value.date2) ? this.apiService.formatDate(form.value.date2) : form.value.date2;

      }
      this.selecTed = [];

      // this.is_notes = true;
      // this.is_branch_ref_no = true;
      // this.is_ref_no = true;
      // this.colSpan = 7;

      this.pageLoadingImg = true;
      form.value.ledger_sel_id = this.ledger_sel_id;
      form.value.acc_type = this.acc_type;


      this.apiService.getCashBankBookRep(form.value).subscribe((res) => {
        this.pageLoadingImg = false;
        this.selecTed = res['data'];
        this.repResult = res['data']['data'];
        this.op_bal = res['data'].op_bal;
        this.repDate = res['data']['Date'];
        this.repTotal = res['data']['total']
        this.repAccType = res['data'];

      });

    }



  }

  getDefaultLedgers() {

    let searchval = new FormData();
    if(this.userType == 'ADMIN'){
      searchval.append("filter_branch_id", this.filter_branch_id);
    }
    searchval.append("tax_sub_cat", '9');
    this.apiService.getDefaultLedgers(searchval).subscribe((res) => {

  
    
      this.searchleadger = res['data'];
  
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
      this.ledger_sel_id = Number(res['ledger_id']);
      if (this.ledger_sel_id) {
        var form = {
          value:
            { period_type: '' }
        };
        form.value.period_type = 't'
        this.selectLedger(this.ledger_sel_id, form);
      }
    });



  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
     // this.filter_branch_id = res.selected;

      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });

  }
  // pdf download
  generatePdf() {

    // console.log(this.repDate);
    // console.log(this.repTotal);
    console.log(this.repResult);
    
  
    const heads = [
      this.translate.instant('ACCOUNTS.Particulars'),
      this.translate.instant('HOME.Cash_IN'),
      this.translate.instant('HOME.Cash_Out')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      if(this.op_bal){

        const arr=[
          this.translate.instant('Common.Opening_Balance'),
        ];

        if(this.op_bal.vch_in != 0){
          arr.splice(1,0, this.currencyPipe.transform(this.op_bal.vch_in, '', '', this.country_dec))
        } else {
          arr.splice(1,0,' ')
        }

        if(this.op_bal.vch_out != 0){
          arr.splice(2,0, this.currencyPipe.transform(this.op_bal.vch_out, '', '', this.country_dec))
        } else {
          arr.splice(2,0,' ')
        }

        data.push(arr)
      }

      this.repResult.forEach(item=>{

        const arr1=[];

        if(item.vch_vchtype_id != 2){
          arr1.splice(0,0, item.vchtype_name)

          if(item.vch_in != 0){
            arr1.splice(1,0,this.currencyPipe.transform(item.vch_in, '', '', this.country_dec))
          } else {
            arr1.splice(1,0,' ')
          }

          if(item.vch_out != 0){
            arr1.splice(2,0,this.currencyPipe.transform(item.vch_out, '', '', this.country_dec))
          } else {
            arr1.splice(2,0,' ')
          }

          data.push(arr1)

          if(this.rep_type=='detailed'){
            if(item.items_vchr){
              item.items_vchr.forEach(item1=>{

                const arr2=[
                  item1.ledger_name
                ];

                if(item1.vch_in != 0){
                  arr2.splice(1,0,this.currencyPipe.transform(item1.vch_in, '', '', this.country_dec))
                } else {
                  arr2.splice(1,0,' ')
                }
      
                if(item1.vch_out != 0){
                  arr2.splice(2,0,this.currencyPipe.transform(item1.vch_out, '', '', this.country_dec))
                } else {
                  arr2.splice(2,0,' ')
                }

                data.push(arr2)

              })
            }
          }

          if(this.rep_type=='grouped_detail'){
            
            if(item.items_vchr){
              item.items_vchr.forEach((item1)=>{

                const arr3=[
                  item1[0].accgrp_name,
                  '',
                  ''
                ]; 

                data.push(arr3)

                item1.forEach(item2=>{
                  const arr4=[
                    item2.ledger_name
                  ];
  
                  if(item2.vch_in != 0){
                    arr4.splice(1,0,this.currencyPipe.transform(item2.vch_in, '', '', this.country_dec))
                  } else {
                    arr4.splice(1,0,' ')
                  }
        
                  if(item2.vch_out != 0){
                    arr4.splice(2,0,this.currencyPipe.transform(item2.vch_out, '', '', this.country_dec))
                  } else {
                    arr4.splice(2,0,' ')
                  }
  
                  data.push(arr4)
                })
  
              })
            }
          }

        } else {

          if(item.vch_in > 0){
            const arr5 =[
              '',
              this.currencyPipe.transform(item.vch_out, '', '', this.country_dec)
            ];

            if(this.repAccType.rep_acc_type == 4){
              arr5.splice(0,0, this.translate.instant('ACCOUNTS.withdrawal'))
            } else if(this.repAccType.rep_acc_type == 3){
              arr5.splice(0,0, this.translate.instant('ACCOUNTS.deposit'))
            }

            data.push(arr5)

            if(this.rep_type=='detailed'){
           
              if(item.items_vchr){
  
                item.items_vchr.forEach(item1=>{
                  data.push([
                    item1.ledger_name,
                    '',
                    this.currencyPipe.transform(item1.vch_out, '', '', this.country_dec),
                  ])
                })
  
              }
            }
  
            if(this.rep_type=='grouped_detail'){
             
              if(item.items_vchr){
  
                item.items_vchr.forEach(item1=>{
                  data.push([
                    item1[0].accgrp_name,
                    '',
                    ''
                  ])

                  item1.forEach(item2=>{

                    const arr6=[
                      item2.ledger_name,
                      '',

                    ];

                    if(item2.vch_out != 0){
                      arr6.splice(2,0, this.currencyPipe.transform(item2.vch_out, '', '', this.country_dec))
                    }

                    data.push(arr6)
                  })

                })
  
              }
            }

          }

          if(item.vch_out > 0){
            const arr7 =[
              this.currencyPipe.transform(item.vch_in, '', '', this.country_dec),
              ''
            ];

            if(this.repAccType.rep_acc_type == 3){
              arr7.splice(0,0, this.translate.instant('ACCOUNTS.withdrawal'))
            } else if(this.repAccType.rep_acc_type == 4){
              arr7.splice(0,0, this.translate.instant('ACCOUNTS.deposit'))
            } 

            data.push(arr7)

            if(this.rep_type=='detailed'){
           
              if(item.items_vchr){
  
                item.items_vchr.forEach(item1=>{
                  data.push([
                    item1.ledger_name,
                    this.currencyPipe.transform(item1.vch_in, '', '', this.country_dec),
                    ''
                  ])
                })
  
              }
            }

            if(this.rep_type=='grouped_detail'){
             
              if(item.items_vchr){
  
                item.items_vchr.forEach(item1=>{
                  data.push([
                    item1[0].accgrp_name,
                    '',
                    ''
                  ])

                  item1.forEach(item2=>{

                    const arr8=[
                      item2.ledger_name,
                      ''

                    ];

                    if(item2.vch_out != 0){
                      arr8.splice(1,0, this.currencyPipe.transform(item2.vch_in, '', '', this.country_dec))
                    }

                    data.push(arr8)
                  })

                })
  
              }
            }

          }

        }
      })

      data.push([
        this.translate.instant('HOME.total'),
        this.currencyPipe.transform(this.repTotal.in, '', '', this.country_dec),
        this.currencyPipe.transform(this.repTotal.out, '', '', this.country_dec)
      ])

      if(this.repTotal.out > 0){
        this.Amt = this.currencyPipe.transform(this.repTotal.in - this.repTotal.out, '', '', this.country_dec)
      } else if(this.repTotal.out <= 0) {
        this.Amt = this.currencyPipe.transform(this.repTotal.in + this.repTotal.out, '', '', this.country_dec)
      }

      data.push([
        this.translate.instant('ACCOUNTS.Closing_Balance'),
        '',
        this.Amt
      ])
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.cash_bank_book')}`,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('ACCOUNTS.Ledger_Name')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.repTotal.opening_balance.ledger_name,  valueXPos, headerHeight + 20);
      
      if(this.acc_type == '3'){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.cash_in_hand')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' +this.currencyPipe.transform( this.repTotal.cash_in_hand.bal_amt, '', '', this.country_dec) + ' ' + this.repTotal.cash_in_hand.bal_sign,  valueXPos, headerHeight + 25);
      } else if(this.acc_type == '4'){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.cash_in_bank')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' +this.currencyPipe.transform( this.repTotal.cash_in_hand.bal_amt , '', '', this.country_dec)+ ' ' + this.repTotal.cash_in_hand.bal_sign,  valueXPos, headerHeight + 25);
      }
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
  
     
      doc.save(`Cash & Bank Book.pdf`);


    
  }

}
