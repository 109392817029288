import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Stockedit } from '../../../../model/stockedit.model';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Batch } from '../../../../model/batch.model';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
// import { HostListener } from '@angular/core';
// modal
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Branch } from '../../../../model/branch.model';
// import { environment } from 'C:/wamp64/www/bquick/bquick/src/environments/environment';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})



export class PurchaseComponent implements OnInit {
  @ViewChild('inputItemId', { static: false }) inputItemId: ElementRef;
  @ViewChild('selectproductId', { static: false }) selectproductId: NgSelectComponent;
  @ViewChild('supplierSelect', { static: false }) supplierSelect: NgSelectComponent;

  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   // if (event.key === '27') {
  //   //   this.supplierSelect.focus();
  //   // }
  // }



  showdata: boolean;
  purchaseItems: any;
  sales_inv_no: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };

  purch_no: any;
  purch_date: any;
  purch_pay_type: any;
  purch_supp_id: any;
  purch_inv_no: any;
  purch_inv_date: any;
  purch_type: any;
  purch_frieght_ledger_id: any;
  frChecked: any;
  freeGoods = false;
  addstkbatchsel: any;
  purchsub_prd_id: any;
  supp_id: any;
  newf: any;

  purch_tax_ledger_id: any;
  stkprdselbc: any;
  purchasePayType = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank' }

  ];
  payType = ['Cash', 'Credit', 'Bank'];
  stkprd_all: string[];
  selecTed: any = {
    prd_id: null, prd_name: null, message: null, rec_unit_rates: null,
    upt_unit_rates: null, unit_rates: null, units_rates: null, error: null, gdstock: null, branch_id: null, prd_units: null,
    manufacture_date: null, expiry_date: null, commissions: null, total_freight: null, excise_duty: null, customs_duty: null, shipping_cost: null, batch_code: null, cmp_stock_id: null, purchase_rate: null
  };
  // selBatch: Batch = { sb_batch_code: null, sb_expiry_date: null, message: null, sb_manufacture_date: null };
  selBatch: any = {};
  selectedUnitId = 1;
  filter_types: any[];
  purRate: number;
  baserate: any;
  items = [];
  prd_base_unit_id: number;
  taxamount: number;
  taxpercentage: any;
  freightTaxPercentage:any;
  totalfreightTaxPercentage:any;
  totalsubfreightTaxPercentage:any;
  purchaseqty: any;
  unit_qty: any;
  singlerate: number;
  baseratefrombox: any;
  totalqty: any;
  purchaserateperbox: any;
  unit_qty_inp: any;
  godown_all = [];
  gdid: number;
  errObj: string;
  resDup: any;
  resultobj: any = {};
  hist_prd_id: any;
  desktoken: any;
  baserateval: any;
  purqtyval: any;
  taxval: any;
  supplierAll: string[];
  purch_amount = 0;
  purch_tax = 0;
  purch_discount = 0;
  purch_frieght_val = 0;
  sel_gd_id = 0;
  changeBox: number;
  purchprice: any;
  purchpricetotal: any;
  totpurchprice = 0;
  totpriceVat = 0;
  totalfreight: any;
  subtotalfrieght = 0;
  subTotalFrieghtTax = 0;
  purchFreightTotal =0;
  totalRebateAmount = 0;
  totalfrieghtproduct = 0;
  custduty: any;
  exciseduty: any;
  shipcost: any;
  impcomm: any;
  stkbtch_all: string[];
  batchcode: any;
  selectBtch: boolean;
  purchaseno: number;
  stkbatchselected = '';
  taxamounttotal: any;
  ledger_all: any;
  currency_all:any;
  sb_manufacture_date: any;
  sb_expiry_date: any;
  discount = 0;
  frieghtledid = 0;
  stkunitsel: any;
  stkprdsel: any;
  stkbatchsel: any;
  closeResult: string;
  boxname: string;
  boxqty: any;
  isChecked: boolean;
  batchid: number;
  taxvalperqty: any;
  taxvaltotqty: any;
  searchExisting = true;
  printError = [];
  batchError = [];
  private modalRef: NgbModalRef;
  boxdiplayname: string;
  boxdisplaybaseqty: any;
  prd_base_unit_name: any;
  purchsub_unit_id: number;
  prd_base_unit_name_master: number;
  purchsub_gd_id = 0;
  unitType = 'piece';
  purchtypesel: any;
  purchase_types = [];
  freight_types =[];
  freight_charges_on_total: any;
  prd_all: string[];
  stkprdbc_all: any;
  draft_id: any;
  pageLoadingImg: boolean;
  purch_note: any;
  pord_ref_no: '';
  // numonly
  value = '';
  counter = 0;
  slno: number;
  draftList: any;
  purchasedate: any;
  purch_amt: any;
  prch_tax: any;
  purchpageLoadingImg: boolean;
  manufacture_date: any;
  expiry_date: any;
  item_barcode: any;
  baseUnitName: any;
  baseUnitRate: any;
  currentStock: any;
  purchsub_qty: any;
  purchsub_rebate_amount =0;
  purchsub_rebate_cost_effect : any;
  purch_items_rebate_cost_effect  = false;
  purchsub_rebate :any;
  commissions:any;
  customs_duty:any;
  excise_duty:any;
  total_freight:any;
  shipping_cost:any;
  // purchasePayType: any;
  // supp_id: any;
  // purch_inv_no: any;
  // purch_inv_date: any;
  // stkprdselbc: any;
  searchResult: string;
  baseUnit: [];
  is_edit: any;
  p_type_id: any;
  purchType: any;

  date = new Date();
  serializedDate = new FormControl((new Date()).toISOString());
  taxCategories = [];
  taxListCategories = [];
  taxProdCategories = [];
  prd_tax_cat_id = 0;
  purch_type_taxcat_id = 3;
   //setforTotqty: string | number | string[];
   setforTotqty =0;
 // setforTotqty =1;
  defLedger = [];
  purch_acc_ledger_id = '';
  up: any;
  preview_data: any;
  previewData: any[];
  branch_display_name: any;
  branch_address: any;
  branch_name: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;
  purchData: { sales_id: any; sales_inv_no: any; error: any; message: any; data: any; inactive: any; items: any };
  showdpurch: boolean;
  dbConnected: boolean;
  datevar: any;
  gd_id_active: any;
  gd_id: number;
  editData: any;
  dateinvvar: string;
  dateinfo: any;
  update: boolean;
  purch_id_no:any;
  showedit: boolean;
  userdefltGwn: any;
  errObjArr = {
    dtErr: null,
    purch_round_sign: null,
    purch_roundoff: null
  };
  productSearchLoader: boolean;
  settings: any;
  round_sign: any;
  roundoff_sign = [
    { value: 1, name: '+' },
    { value: -1, name: '-' }

  ];
  roundoff: any;
  listing: boolean;
  list_purchase: any;
  curpage: any;
  lastpage: any;
  from: any;
  pgstart: boolean;
  pgend: boolean;
  item_desc: any;
  is_description: any[];
  is_prew_description: any;

  savedTemplate = {

    logo: false,
    header_type: 1
  }
  logoImgUrl: string;
  tempImgUrl: string;
  srch_mtd: any;
  qtn_id: any;
  up_last_purch_amt = false;
  accLoading = false;
  sup_balance: 0;
  sup_bal_sign: '';
  showBal = false;
  sup_tin: any;
  rate_set_for = [
    { value: 1, name: 'For Single' },
  ]
  selRateQty = 1;
  updateMrp = false;
  mrp = 0;
  cmp_tax: any;
  pageLoadingImgCalc = false;
  loadingImgLastPurch = false;
  lastPurchs = [];
  excludeTax = 0;
  purch_rate: any;
  exchange_rate = 1;
  country_dec: string;
  batchSellRate = <any>[];
  freightTypes =<any>[];
  loadingBatchSellRate = false;
  usr_hide_others_sales_data: any;
  user_id: any;
  purch_cgst_tax =0;
  purch_sgst_tax =0;
  purch_igst_tax =0;
  cgst_perc: any;
  sgst_perc: any;
  states: any;
  supp_details: any;
  cust_state_id: any;
  branch_state: any;
  igst: boolean=false;
  purch_sub_description: any;
  editfreeGoods = false;
  Freightbaserate: any;
  totalFreightbaserate:any;
  totalsubFreightbaserate:any;
  freightTaxamount: any;
  totalfreightTaxamount :any;
  totalsubfreightTaxamount :any;
  freight_total_tax = 0;
  freight_total_net_amount = 0;
  freight_total_rate = 0;
  freight_type =0;
  freightList: any;
  freightTypeTotal =<any>[];
  total_freight_total_tax = 0;
  total_freight_total_net_amount = 0;
  total_freight_total_rate = 0;
  valErrors = <any>{};
  freightTaxCatStatus= false;
  totalFreightTaxCatStatus = false;
  testTotal: any;
  salesRate: any;
  salesRateData:any;
  prod_alias: any;
  itemcode_show: any;
  prd_id: any;
  sup_invoice_no: any;
  copy_sup_invoice_no: any;
  payTypes = ['Cash', 'Credit', 'Bank / Card','Both'];
  purchasenumber: any;
  supp_invoice_array:any;



  constructor(private apiService: ApiService, private coreService: CoreService,
    private datePipe: DatePipe, private modalService: NgbModal, private ActiveRoute: ActivatedRoute, private route: Router, private translate: TranslateService,) {
    this.changeBool();
  }

  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email: '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    brch_id:0,
    usr_type:0,
    supp_state_id:null,
    is_supplier : false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  userType: any;
  isSubmitInProg = false;
  isEdit = false;
  editIndex = -1;
  fullbranches: any;
  branchselids: any;
  multipleCurrency = false;
  checkBatch = false;
  currencies = [];
  supp_default_currency_id = 0;
  selcurrency: any;
  bind_sp: any = '';
  bind_sp_tax: any = '';
  bind_sp_inc_tax: any = '';
  cmpny: any;
  cur_lang: string;
    prodUnitLoading: boolean;
  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.apiService.getClientSettingBykey({ key: 'multiple_currency_support' }).subscribe((res) => {
      if (res['data']) {
        this.multipleCurrency = (res['data']['cs_value']) ? true : false;
      }
    });

    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });


    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });
    if(this.cmp_tax==2){
      this.getAllState();
    }

    this.cmpny = this.coreService.getUserData('cmpny');
    this.user_id = this.coreService.getUserData('usr_id');
    const cur_branch = this.coreService.getUserData('branch_id');
    this.branchselids = [cur_branch];
    this.langChange();
    this.country_dec = this.coreService.setDecimalLength();
    this.userType = this.coreService.getUserData('user_type');
    this.listing = false;
    this.getPurchSetting();
    this.listPurchase(1);
    this.allCurrency();
    this.getFreight(1);
    this.getTemplete();
    this.round_sign = 1;
    this.roundoff = 0.00;
    setTimeout(() => {
      this.round_sign = 1;
      this.roundoff = 0.00;
    }, 3000);
    this.userdefltGwn = this.coreService.getUserData('usr_default_godown_id');
    // if(this.userdefltGwn < 0){
    //   this.userdefltGwn = 0;
    // }
    this.purchsub_gd_id = Number(this.userdefltGwn);
    this.gdid = Number(this.userdefltGwn);
    this.update = false;
    this.gd_id_active = 0;
    this.previewData = [];
    this.dbConnected = false,
      this.up = this.coreService.getUserPrivilage();
    this.pageLoadingImg = false;
    this.getGodownList();
    this.getPurchase();
    // this.searchForm('');
    $('#batch1').hide();
    $('#srchbatchbtn').hide();
    this.getsaveTempPurchase();
    this.searchSupplier('');
    this.searchResult = '';
    this.is_edit = 'true';
    this.p_type_id = 0;
    this.purchType = 1
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
      this.taxListCategories = resp.data;
      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);



    });


    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_name = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.img_url = this.coreService.getUserData('img_url');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;
    this.getPurchSetting();
    this.item_desc = '';
    this.is_description = [];
    this.is_prew_description = [];

    this.qtn_id = this.ActiveRoute.snapshot.paramMap.get('id');
    if (this.qtn_id)
      this.importQtn(this.qtn_id);

    this.getfullBranches();
    if(this.cmp_tax==2){
      this.branch_state = this.coreService.getUserData('branch_state_id');
     
    }

  }
  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias ='alias_true';
    }else{
      this.prod_alias =null;
      
    }
  }
  getfullBranches() {
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      this.fullbranches = branch['data'];
    });
  }
  // ngOnDestroy(){
  //  this.modalRef.close();
  // }

  getFreight(pageNo) {
    this.pageLoadingImg = true;
    this.freightTypeTotal =[];
    this.apiService.getAllFreightType(pageNo,50).subscribe((res) => {
       this.freightList = res.data;
     
      this.freightList.forEach(element => {

      
        this.freightTypeTotal.push({ total_freight_type_id: element.freight_type_id, total_freight_type_name: element.freight_type_name, total_freight_type_ledget_id: element.freight_type_ledger_id,total_freight_type_amount:0,total_freight_type_cur_id:0,total_freight_type_ex_rate:1,total_freight_type_tax_id:3,total_freight_type_tax:0,total_freight_type_net_total:0,total_freight_type_rate:0,total_freight_type_tax_no:"",total_freight_type_invoice_no:"" })
        this.searchLedger(element.ledger_name);
      });
      

  


      
    });
  }

  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  getPurchSetting() {

    this.apiService.getPurchSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.purch_pay_type = this.settings.ps_pay_type;
        this.purchtypesel = this.settings.ps_purch_type;
        this.srch_mtd = this.settings.ps_srch_mtd;
        this.freight_charges_on_total = this.settings.ps_freight_charges_on_total;
        this.purch_sub_description = this.settings.ps_show_purch_sub_description;
      } else { // Default values if not setted
        this.purch_pay_type = 1;
        this.purchtypesel = 1;
        this.srch_mtd = 1;
        this.freight_charges_on_total = 0;
        this.purch_sub_description = 0;
      }
      if (this.srch_mtd == 2) {
        this.inputItemId.nativeElement.focus();
      } else {
        this.selectproductId.focus();
      }
      this.changePayType();
      this.selectVat(this.purchtypesel);
    });

  }

  onChange(event) {
    this.counter = this.counter + 1;
  }

  updateTaxPerc(taxCatId) {
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.taxpercentage = selTax[0].taxcat_tax_per;

    
    
    if(this.cmp_tax==2){
      this.cgst_perc = selTax[0].cgst_tax_per;
      this.sgst_perc = selTax[0].sgst_tax_per;
      this.selecTed.cgst_tax_per=this.cgst_perc;
      this.selecTed.sgst_tax_per=this.sgst_perc;
    }
   
    
    this.basicRates();
  }
  // modal  for click open only
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  savetest() {
    this.modalRef.close();
  }


  purchase_add(formdata: { value: any; }) {
    this.printError = [];
     this.copy_sup_invoice_no = this.sup_invoice_no.find(x => x.spplier_invoice_number == this.purch_inv_no); 
   if( this.copy_sup_invoice_no){
    
    this.supp_invoice_array = this.sup_invoice_no.filter((invoice_no) => invoice_no.spplier_invoice_number == this.purch_inv_no).map(x => x.purch_id);
   alert("Invoice number already exists for this supplier, Ref. No: " + this.supp_invoice_array.join(", "));
 
   }
    
    if (formdata.value.purch_date == undefined) {
      this.errObjArr.dtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (formdata.value.purch_date > new Date()) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.dtErr = "f";
      }
    }

    if (formdata.value.purch_round_sign == undefined || formdata.value.purch_round_sign == '') {
      this.errObjArr.purch_round_sign = "t";
      this.printError.push({ 'purch_round_sign': 'Required' });
    } else {
      this.errObjArr.purch_round_sign = "f";
    }
    if (this.roundoff == undefined || this.roundoff === '') {
      this.errObjArr.purch_roundoff = "t";
      this.printError.push({ 'purch_roundoff': 'Required' });
    } else {
      this.errObjArr.purch_roundoff = "f";
    }


    if (this.printError.length <= 0) {


      formdata.value.purch_type = this.purchType;

      if (formdata.value.purch_tax == null)
        formdata.value.purch_tax = 0;
      // var confrm = confirm("Are you sure?");
      if (confirm("Are you sure?")) {
        this.purchpageLoadingImg = true;
        formdata.value.items = this.items;
        formdata.value.purch_is_include_tax = this.excludeTax;
        if (formdata.value.purch_discount == null || formdata.value.purch_discount == "")
          formdata.value.purch_discount = 0;
        if (formdata.value.purch_tax_ledger_id == null && formdata.value.purch_tax_ledger_id == undefined) {
          formdata.value.purch_tax_ledger_id = 0;
        }
        // if (!formdata.value.purch_frieght_ledger_id && this.subtotalfrieght > 0) {
        //   this.frieghtledid = 1;
        //   this.purchpageLoadingImg = false;
        // } else {
        //   this.frieghtledid = 0;
        //   this.purchpageLoadingImg = true;
        // }
        formdata.value.purch_frieght_tax = this.subTotalFrieghtTax;
        // if (this.freight_charges_on_total) {
          formdata.value.purch_frieght = this.subtotalfrieght;
        // }
        if(this.freight_type == 2){
          formdata.value.purch_frieght_tax = this.total_freight_total_tax;
        }

        if (this.frieghtledid != 1) {
          formdata.value.purch_date = (formdata.value.purch_date) ?
            this.apiService.formatDate(formdata.value.purch_date) : formdata.value.purch_date;
          formdata.value.purch_inv_date = (formdata.value.purch_inv_date) ?
            this.apiService.formatDate(formdata.value.purch_inv_date) : formdata.value.purch_inv_date;

          if (this.qtn_id) {
            formdata.value.pord_no = this.qtn_id;
            formdata.value.pord_ref_no = this.pord_ref_no;
          }

          formdata.value.purch_rebate_amounts = this.totalRebateAmount;
          if(this.cmp_tax==2){
            formdata.value.purch_cgst_tax = this.purch_cgst_tax ;
            formdata.value.purch_sgst_tax = this.purch_sgst_tax ;

            if((this.cmp_tax==2)&&(this.igst)){

              formdata.value.purch_igst_tax=this.purch_igst_tax;
              formdata.value.purch_is_igst=1;
            }
          }

          this.apiService.addPurchase(formdata.value).subscribe((res: Stockedit) => {
            this.purchpageLoadingImg = false;

            if (res.error != null) {
              this.resultobj = res.error;
              this.resultobj = this.coreService.getValidation(this.resultobj);
            }
            else {

              this.previewData = res.preview;
              this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
              this.itemcode_show = this.previewData['items'].filter(x => x.prd_code);

              
              

              setTimeout(function () { $("#previewButton").click(); }, 2000);


              if (formdata.value.draft_id) {
                this.removePurchaseFromDraft(formdata.value.draft_id);
              }

              this.resultobj = {};
              this.coreService.createfunct(formdata, this.resultobj, 'Purchase Saved');
              $('.puchase-list-table').hide();
              this.items = [];

              this.refreshAll();
              this.getPurchase();
              this.subtotalfrieght = 0;
              this.subTotalFrieghtTax = 0;
              this.purchFreightTotal = 0;
              this.totalRebateAmount = 0;
              this.totpurchprice = 0;
              this.totpriceVat = 0;
              this.purch_amount = 0;
              this.purch_tax = 0;
              this.purch_cgst_tax = 0;
              this.purch_sgst_tax = 0;
              this.purch_igst_tax = 0;
              this.igst=false;
              this.date = new Date();
              this.serializedDate = new FormControl((new Date()).toISOString());
              this.changeBool();
              this.discount = 0;
              this.is_edit = 'true';
              this.purchType = 1;
              setTimeout(() => {
                this.round_sign = 1;
                this.roundoff = '0';
              }, 3000);

              this.supplierSelect.focus();
              this.getFreight(1);
              this.total_freight_total_rate = 0;
              this.total_freight_total_tax = 0;
              this.total_freight_total_net_amount =0;
              this.pageLoadingImg = false;

            }
          });

          this.apiService.updatebatchExNotification();
        }
      }
    }
  }

  purchaseSave(formdata: { value: any; }, savetype) {
    // this.totpurchprice = 0;
    // this.purch_tax = 0;
    // this.subtotalfrieght = 0;
    // for (var i = 0; i < this.items.length; i++) {
    //   this.totpurchprice = this.totpurchprice + this.items[i]['purch_price'];
    //   this.purch_tax = this.purch_tax + this.items[i]['purchsub_tax'];
    //   this.subtotalfrieght = this.subtotalfrieght + this.items[i]['freight'];
    // }
    this.updateTotalAmount();
    // this.updateRebateAmount();
    formdata.value.purch_amount = this.totpurchprice;
    formdata.value.purch_tax = this.purch_tax;
    formdata.value.purch_frieght = this.subtotalfrieght;
    formdata.value.purch_frieght_tax = this.purchFreightTotal;
    formdata.value.purch_rebate_amounts = this.totalRebateAmount;
    formdata.value.freight_type = this.freight_type;



    this.pageLoadingImg = true;
    formdata.value.items = this.items;
    this.apiService.saveTempPurchase(formdata.value).subscribe((res: Stockedit) => {
      this.draft_id = res;
      this.pageLoadingImg = false;
      this.getsaveTempPurchase();

      if (savetype == 'manual') {
        this.coreService.showMessage('Saved To Draft');
      }

    });

  }

  getTempPurchase(purch_id) {
    let searchval = new FormData();
    searchval.append("purch_id", purch_id);

    this.apiService.getTempPurchase(searchval).subscribe((res: any) => {
      res.data.items.forEach((element, i) => {
        res.data.items[i].price_vat_ttl = Number(element.purch_price) + Number(element.purchsub_tax);
        res.data.items[i].purch_item_total_without_item_disc = Number(element.purchaserateperbox);

     
        if(element.purchsub_rebate_amount == undefined){
          res.data.items[i].purchsub_rebate_amount =0;
        }
      });
      this.items = res.data.items;
      this.draft_id = res.draft_id;
      this.supp_id = Number(res.data.purch_supp_id);
      this.purch_inv_no = res.data.purch_inv_no;
      this.stkprdselbc = res.data.stkprdselbc;
      this.purch_note = res.data.purch_note;
      this.freight_type =res.data.freight_type;

      this. getSupBalance(this.supp_id, null);

      setTimeout(() => {
        this.round_sign = res.data.purch_round_sign ? res.data.purch_round_sign : 1;
        this.roundoff = '' + res.data.purch_roundoff ? res.data.purch_roundoff : 0;
      }, 3000);
      this.discount = res.data.purch_discount ? res.data.purch_discount : 0;


      this.purch_pay_type = Number(res.data.purch_pay_type);
      if (res.data.purch_inv_date) {
        this.purch_inv_date = new Date(res.data.purch_inv_date);
      } else {
        this.purch_inv_date = '';
      }
      this.date = new Date(res.data.purch_date);

      $('.puchase-list-table').show();
      $('.save').show();
      $('.box').show();
      this.changeBool();
      this.refreshAll();
      $('.puchase-list-table').show();

      var scrollBottom = $('.table-responsive').height();
      $('.scroll-table').scrollTop(300);

    });
  }


  //  form seach select product new codes 10/26/19
  selectStockPrdBcode(res: any, content) {
    
    if (res != "Select Product") {
      this.updateMrp = true;
    
      //this.selecTed.purchase_rate = this.baseUnitRate*stkunitsel.unit_base_qty; 
      this.selecTed = res;
      this.baseUnitRate = this.selecTed.purchase_rate;
      this.salesRate = this.selecTed.prd_base_sales_rate;

      this.batchSellRate = [];
      this.freightTypes =[];
      
      if(this.freight_type == 1)
      {
          this.frChecked =1;
      }

      if (res) {
        res.stock_unit_rate.forEach(element => {
          this.batchSellRate.push({ rate1: '', rate2: '', checked: false, unit_id: element.sur_unit_id })
        });

        res.freight_type.forEach(element => {

            
          this.freightTypes.push({ freight_type_id: element.freight_type_id, freight_type_name: element.freight_type_name, freight_type_ledget_id: element.freight_type_ledger_id,freight_type_amount:0,freight_type_amount_temp:0,freight_type_cur_id:0,freight_type_ex_rate:1,freight_type_tax_id:3,freight_type_tax:0,freight_type_net_total:0,freight_type_rate:0,freight_type_tax_no:"",freight_type_invoice_no:"", freight_type_ledget_name:element.ledger_name, })
          this.searchLedger(element.ledger_name);

         
        });
      }


      this.baseUnit = this.selecTed.units.find(x => x.unit_base_qty === 1);
      this.baseUnitName = this.selecTed.units.find(x => x.unit_base_qty === 1).unit_code;
      this.unit_qty = 1;
      this.purchsub_unit_id = res['prd_base_unit_id_bcode'];
      this.basicRatesBox();
      this.boxdiplayname = res['prd_base_unit_id_bcode'];
      this.prd_base_unit_name = this.baseUnitName;
      this.prd_base_unit_name_master = res['prd_base_unit_name_master'];
      this.purchsub_unit_id = res['prd_base_unit_id'];
      // this.purchsub_unit_id = res['prd_base_unit_id_bcode'];
      this.stkunitsel = this.baseUnit;

      if (this.purchType == 1) {
        this.prd_tax_cat_id = res['prd_tax_cat_id'];
      } else if (this.purchType == 2) {
        this.prd_tax_cat_id = 3;
      } else {
        this.prd_tax_cat_id = this.purch_type_taxcat_id;
      }
      const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === this.prd_tax_cat_id);
      this.taxpercentage = selTax[0].taxcat_tax_per;
      if(this.cmp_tax==2){
        this.cgst_perc = selTax[0].cgst_tax_per;
        this.sgst_perc = selTax[0].sgst_tax_per;
      }
      // this.selecTed.purchase_rate = this.baseUnitRate * this.unit_qty;


      if (this.unit_qty != 1) {
        this.boxname = res['prd_base_unit_name_bcode'];
      }
      else {
        this.boxname = "nil";
      }

      this.modalRef = this.modalService.open(content, { size: 'xl', backdrop: 'static' });
      this.modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });

    }
  }

  selectStockPrd(res: any, content) {

    if (res != "Select Product") {
      this.updateMrp = true;
      this.selecTed = res;
      this.baseUnitRate = this.selecTed.purchase_rate;
      this.unit_qty = res['prd_base_unit_qty_bcode'];
      this.purchsub_unit_id = res['prd_base_unit_id_bcode'];
      this.basicRatesBox();
      this.boxdiplayname = res['prd_base_unit_id_bcode'];
      this.prd_base_unit_name = res['prd_base_unit_name'];
      this.prd_base_unit_name_master = res['prd_base_unit_name_master'];
      // this.purchsub_unit_id = res['prd_base_unit_id'];
      this.purchsub_unit_id = res['prd_base_unit_id_bcode'];
      this.stkunitsel = res['prd_base_unit_name_bcode'];
      // this.prd_tax_cat_id = res['prd_tax_cat_id'];
      if (this.purchType == 1) {
        this.prd_tax_cat_id = res['prd_tax_cat_id'];
      } else if (this.purchType == 2) {
        this.prd_tax_cat_id = 3;
      } else {
        this.prd_tax_cat_id = this.purch_type_taxcat_id;
      }
      const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === this.prd_tax_cat_id);
      this.taxpercentage = selTax[0].taxcat_tax_per;

      if(this.cmp_tax==2){
        this.cgst_perc = selTax[0].cgst_tax_per;
        this.sgst_perc = selTax[0].sgst_tax_per;
      }
      this.selecTed.purchase_rate = this.baseUnitRate * this.unit_qty;

      if (this.unit_qty != 1) {
        this.boxname = res['prd_base_unit_name_bcode'];
      }
      else {
        this.boxname = "nil";
      }

      this.modalRef = this.modalService.open(content, { size: 'xl', backdrop: 'static' });
      this.modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });

    }
  }


  selectStockPrdSel(res: any, content) {

     if (res && res != "Select Product") {
 
       if(this.purch_items_rebate_cost_effect == true){
        // this.purchsub_rebate_cost_effect == true;
        this.purchsub_rebate_cost_effect =1;
       }


 
       this.purchsub_qty = '';
       // this.purchsub_rebate_amount = 0;
       // this.purchsub_rebate_cost_effect =0;
       this.purchsub_rebate = 0;
       // this.freeGoods =false;
       this.total_freight = '';
       this.excise_duty = '';
       this.customs_duty = '';
       this.shipping_cost = '';
       this.commissions = '';
       // this.frChecked =0;
       $('#freight').val('');
       this.selRateQty = 1;
       this.updateMrp = true;
       this.selecTed = res;
       this.selecTed.purchase_rate = this.selecTed.purchase_rate / this.exchange_rate;
       this.currentStock = this.selecTed.current_stock;
       // this.pageLoadingImg = true;
       // let searchval = new FormData();
       // searchval.append("keyword", res.prd_id);
       // this.apiService.getPurchasebyName(searchval).subscribe((dt) => {
       //   this.pageLoadingImg = false;
       //   this.selecTed =  dt['data'];
       // this.selecTed = res;
       if (this.purchType == 1) {
         this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
       } else if (this.purchType == 2) {
         this.prd_tax_cat_id = 3;
       } else {
         this.prd_tax_cat_id = this.purch_type_taxcat_id;
       }
       // this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
       const selTax = this.taxCategories.filter((taxCat) => Number(taxCat.taxcat_id) == this.prd_tax_cat_id);
       if (selTax.length > 0) {
         this.taxpercentage = selTax[0].taxcat_tax_per;
       } else {
         alert('Failed, Please Select Tax Category First');
         return false;
       }
 
       this.baseUnitRate = this.selecTed.purchase_rate;
       this.prd_base_unit_name = res['prd_base_unit_name'];
       this.prd_base_unit_name_master = res['prd_base_unit_name_master'];
       this.purchsub_unit_id = res['prd_base_unit_id'];
       this.stkunitsel = this.prd_base_unit_name_master;
       this.item_desc = res['prd_remarks'];
 
       // Old Default Unit Code Changes Start
 
       // if(res['prd_def_unit_id']){
       //   this.prd_base_unit_name_master = res['prd_def_unit_name_master'];
       //   this.stkunitsel = this.prd_base_unit_name_master;
       //   this.purchaseBox(this.selecTed.def_unit_det)
       // }
       //this.unit_qty = 1;
 
       // Old Default Unit Code Changes End
 
 
       // New Default Unit Code Changes Start
       if(res['prd_def_unit_id']) {
         this.prd_base_unit_name_master = res['prd_def_unit_name_master'];
         this.stkunitsel = this.prd_base_unit_name_master;
         // this.purchaseBox(this.selecTed.def_unit_det);
 
         this.selecTed.purchase_rate = this.baseUnitRate * this.selecTed.def_unit_det.unit_base_qty;
         //this.selecTed.current_stock = this.currentStock / this.selecTed.def_unit_det.unit_base_qty;
         // this.selecTed.mrp = this.selecTed.def_unit_det.mrp;
         this.unit_qty = this.selecTed.def_unit_det.unit_base_qty;
         // this.purchsub_unit_id = this.selecTed.def_unit_det.unit_id;
         this.boxname = this.selecTed.def_unit_det.unit_code;
         if(this.isEdit == false){
          this.purchaseBox(this.selecTed.def_unit_det);
          this.salesRate =this.selecTed.def_unit_det.salesRate;
         }
        
          
         
        
 
 
       }
       else {
 
        this.salesRate =this.selecTed.prd_base_sales_rate;
         this.unit_qty = 1;
       }

       // New Default Unit Code Changes End
 
       this.updateRateTypes();
 
         
       // Old Default Unit Code Changes Start
 
       // if (this.unit_qty != 1) {
       //   this.boxname = this.prd_base_unit_name;
       // }
       // else {
       //   this.boxname = "nil";
       // }
 
       // Old Default Unit Code Changes End
 
       
        // New Default Unit Code Changes Start
       if (this.unit_qty != 1) {
         if (res['prd_def_unit_id']) {
           this.boxname = this.selecTed.def_unit_det.unit_code;
         }
         else {
           this.boxname = this.prd_base_unit_name;
         }
 
       }
       else {
         this.boxname = "nil";
       }
 
       // New Default Unit Code Changes End
 
       this.modalRef = this.modalService.open(content, { size: 'xl' });
       this.modalRef.result.then((result) => {
         this.closeResult = `Closed with: ${result}`;
       }, (reason) => {
         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       });
       // });
 
       this.productSearchLoader = false;
 
 
     }
   }

  rate_history(prd_id) {
    this.hist_prd_id = prd_id;
    this.desktoken = localStorage.cmptoken;

    // if(this.usr_hide_others_sales_data){
    //   this.user_id=this.user_id;

    //   }else{
    //     this.user_id='';
    //   }

    $("#ifrm").attr("src", environment.php_api_server + "/product-history?prod_id=" + prd_id + "&desktoken=" + this.desktoken + "&user=" + this.user_id+ "&Lang=" + this.translate.currentLang);

    // const searchval = new FormData();
    // searchval.append("product_id", prd_id);
    // this.apiService.rate_history(searchval).subscribe((res) => {
    //   this.rate_histry = res['data']['sales'];
    //   this.product_name = res['data']['product'];


    // });



  }


  //form search productW
  searchStkPrd(search: any, eventChar) {
    let searchval = new FormData();
    searchval.append("prod_name", search);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.quickSearch(searchval).subscribe((res) => {
      // this.apiService.getProdByName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      $('#addpurchase').hide();

    });
  }

 

  getPurchaseProd(id, content) {

    if(this.multipleCurrency && !this.supp_id){
      if (id) {
        this.coreService.showMessage("Please Choose Supplier First");
        setTimeout(() => {
          // this.stkprd_all = [];
          this.stkprdsel = '';
        }, 1000);
      }
    } else {
      if (id) {
        this.updateMrp = true;
        this.productSearchLoader = true;
        let searchval = new FormData();
        searchval.append("prd_id", id);
        this.apiService.getPurchaseProduct(searchval).subscribe((res) => {
          this.stkprdbc_all = res['data'];
          this.prd_id=this.stkprdbc_all.prd_id



          this.stkbtch_all = this.stkprdbc_all.batches;
          this.batchSellRate = [];
          this.freightTypes =[];
          if (this.stkprdbc_all) {
            this.stkprdbc_all.stock_unit_rate.forEach(element => {
              this.batchSellRate.push({ rate1: '', rate2: '', checked: false, unit_id: element.sur_unit_id })
            });

            this.isEdit = false;
            this.freeGoods = false;
            this.selectStockPrdSel(res['data'], content);
            this.searchResult = '';
            this.allCurrency();

            this.stkprdbc_all.freight_type.forEach(element => {

            
              this.freightTypes.push({ freight_type_id: element.freight_type_id, freight_type_name: element.freight_type_name, freight_type_ledget_id: element.freight_type_ledger_id,freight_type_amount:0,freight_type_amount_temp:0,freight_type_cur_id:0,freight_type_ex_rate:1,freight_type_tax_id:3,freight_type_tax:0,freight_type_net_total:0,freight_type_rate:0,freight_type_tax_no:"",freight_type_invoice_no:"", freight_type_ledget_name:element.ledger_name, })
              this.searchLedger(element.ledger_name);

             
            });

           

          } else {
            this.productSearchLoader = false;
            this.searchResult = 'Not found';
          }

        });
        this.getLastPurchaseOfProd(id);
        this.setforTotqty =0;
      }
      
      if(this.freight_type == 1)
      {
          this.frChecked =1;
      }
    }

    this.freight_total_tax = 0;
    this.freight_total_net_amount = 0;
    this.freight_total_rate =0;
    this.freightTaxCatStatus =false;
  }

  //form search product
  getsaveTempPurchase() {

    this.apiService.getsaveTempPurchase('').subscribe((res) => {
      this.draftList = res;
      this.purch_amt = Number(res.purch_amount);
      this.prch_tax = Number(res.purch_tax);

    });
  }

  removePurchaseFromDraft(purch_id) {
    let searchval = new FormData();
    searchval.append("purch_id", purch_id);
    this.apiService.removeFromDraft(searchval).subscribe((res) => {
      this.apiService.getsaveTempPurchase('').subscribe((res) => {
        this.draftList = res;
      });

    });
  }

  removeFromDraft(search) {

    var remopstk = confirm("Are you sure you want to delete this purchase from draft ? ");
    if (remopstk) {

      let searchval = new FormData();
      searchval.append("purch_id", search);
      this.apiService.removeFromDraft(searchval).subscribe((res) => {
        this.apiService.getsaveTempPurchase('').subscribe((res) => {
          this.draftList = res;
          this.coreService.showMessage('Removed from draft');

        });

      });

    }
  }


  updateRateTypes() {
    var unit_name;
    if (typeof this.stkunitsel === 'string' || this.stkunitsel instanceof String) {
      unit_name = this.stkunitsel;
    } else {
      unit_name = (this.stkunitsel) ? this.stkunitsel.unit_name : this.selecTed.units.find(x => x.unit_base_qty === 1).unit_name;
    }
    this.rate_set_for = [
      { value: 1, name: 'For Single ' + unit_name },
    ]
    if (parseInt(this.purchsub_qty) != 1 && !isNaN(parseInt(this.purchsub_qty)) && this.purchsub_qty != '') {
      this.rate_set_for.push({ value: this.purchsub_qty, name: 'For ' + this.purchsub_qty + ' ' + unit_name });
    }
  }
  // selectStockBox(stkunitsel: any) {
  //   if (stkunitsel.unit_base_qty == 1) {
  //     this.filter_types = [
  //       { id: '1', name: 'For 1 Box' }
  //     ];
  //   } else {
  //     this.filter_types = [
  //       { id: '1', name: 'For 1 Box' },
  //       { id: stkunitsel.unit_base_qty, name: 'For' + stkunitsel.unit_base_qty + 'Box' }
  //     ];

  //   }
  // }

  purchaseQty(purchaseqty: any, event) {
    this.basicRates();
  }


  purchaseBox(stkunitsel: any) {

 

    if (this.freeGoods == true) {
      this.selecTed.purchase_rate = 0;
    }
    else {
      this.selecTed.purchase_rate = this.baseUnitRate * stkunitsel.unit_base_qty;
    }
 

    this.selecTed.current_stock = this.currentStock / stkunitsel.unit_base_qty;
    this.selecTed.mrp = stkunitsel.mrp;
    this.unit_qty = stkunitsel.unit_base_qty;
    this.purchsub_unit_id = stkunitsel.unit_id;
    // this.updateRateTypes();
    this.basicRatesBox();
    this.boxdiplayname = stkunitsel.unit_code;
    if (this.unit_qty != 1) {
      this.boxname = stkunitsel.unit_code;
      this.unitType = 'box';
    }
    else {
      this.boxname = "nil";
      this.unitType = 'piece';
    }

    let searchval = new FormData();
    searchval.append("prd_id", this.selecTed.prd_id);
    searchval.append("unit_id", stkunitsel.unit_id);

    this.apiService.getUnitSalesRate(searchval).subscribe((res) => {
      this.salesRateData =res['data'];
      this.salesRate =this.salesRateData.sur_unit_rate;
    });
   
  
  }

  purchaseRate(baserate: any, event) {
    this.baserate = baserate;
    this.basicRates();
  }

  taxPercent(taxpercentagevalue: any) {
    this.basicRates();
  }


  updatePurchase(draft: { value: any; }, list) {


    this.purqtyval = $('#purchaseqty').val();
    this.baserateval = $('#basicrate').val();
  
    this.taxval = $('#tax').val();
    this.basicRates();


    this.validateBatch(list);
    this.validateForm();
    if (this.printError.length <= 0 && this.batchError.length <= 0) {
      $('#err_dp').hide();
      $('#err_gen').hide();

      this.resDup = this.coreService.checkItem(this.items, this.selecTed.prd_id, this.purchaserateperbox, this.purchsub_unit_id, this.item_desc, this.batchcode);
      var existingArray = this.coreService.findItemPurchase(this.items, this.selecTed.prd_id, this.purchaserateperbox, this.purchsub_unit_id, this.item_desc, this.batchcode);

      if (!this.isEdit && this.resDup && existingArray.length > 0) {
        var existQty = this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_qty;
        var existPrc = this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_price;
        this.taxvaltotqty = ((Number(existQty) + Number(this.totalqty)) * this.taxvalperqty);
        this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_qty = Number(existQty) + Number(this.totalqty);
        this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_price = Number(existPrc) + Number(this.purchpricetotal);

        this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_unit_id = this.items.find(x => x.sl_no === existingArray[0].sl_no).purchased_unit.unit_id


        this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_item_total_without_item_disc = this.items.find(x => x.sl_no === existingArray[0].sl_no).purchaserateperbox;
        this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_tax = this.taxvaltotqty;
        this.items.find(x => x.sl_no === existingArray[0].sl_no).batch_sell_rate = this.batchSellRate;
        this.purchpricetotal = this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_price;
        this.items.find(x => x.sl_no === existingArray[0].sl_no).boxdisplaybaseqty = this.items.find(x => x.sl_no === existingArray[0].sl_no).boxdisplaybaseqty + this.boxdisplaybaseqty;
        this.items.find(x => x.sl_no === existingArray[0].sl_no).boxqty = this.items.find(x => x.sl_no === existingArray[0].sl_no).boxqty + this.boxqty;
        this.items.find(x => x.sl_no === existingArray[0].sl_no).price_vat_ttl = this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_price + this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_tax;
        // calculate frieght  
        var existFrieght = ($('#totalfreight').val() ? $('#totalfreight').val() : 0);
        // this.setforTotqty = $('#setforTotqty').val();


        if (this.unitType == 'piece') {



          if (this.setforTotqty) {

            this.totalfrieghtproduct = (Number(this.totalfrieghtproduct) + Number(existFrieght));
          } else {

            this.totalfrieghtproduct = (Number(this.totalfrieghtproduct) + Number(existFrieght));

            //this.totalfrieghtproduct = (Number(existQty) + Number(existFrieght) * Number(this.totalqty));
          }

          // this.totalfrieghtproduct = (Number(existQty) + Number(this.totalqty)) * Number(existFrieght);      
          this.items.find(x => x.sl_no === existingArray[0].sl_no).freight = this.totalfrieghtproduct;
        }
        else {

          this.items.find(x => x.sl_no === existingArray[0].sl_no).freight = Number(existFrieght)
            * this.items.find(x => x.sl_no === existingArray[0].sl_no).boxqty;
        }

      } else {
        if (this.gdid == undefined)
          this.gdid = 0;
        this.calFreight();
        this.slno = (this.items.length) + 1;
        if (this.boxname == "nil") {
          this.boxname = this.selecTed.unit_display;
        }
       
        
        
        const ubq = this.selecTed.units.find(x => x.unit_id == this.purchsub_unit_id).unit_base_qty;
        const s_u = this.selecTed.units.find(x => x.unit_id == this.purchsub_unit_id);

        if (this.purchsub_rebate_cost_effect == true) {
          this.purchsub_rebate_cost_effect = 1;
        }
        else {
          this.purchsub_rebate_cost_effect = 0;
        }


        var info = <any>{
          "sl_no": this.slno, 'purchsub_prd_id': this.selecTed.prd_id, 'prd_name': this.selecTed.prd_name,'prd_code': this.selecTed.prd_code, 'ean': $('#ean').val(), 'purchsub_stock_id': this.selecTed.cmp_stock_id, 'purchsub_qty': this.totalqty,
          'purchsub_rate': this.purchaserateperbox, 'purchsub_tax': this.taxvaltotqty, 'purchsub_tax_per': this.taxpercentage, 'purchsub_price': this.purchpricetotal,
          'purchpricesingle': this.purchprice, 'purchsub_gd_id': this.gdid, 'freight':  this.freight_total_rate,
          'freight_tax':this.freight_total_tax,
          'expiry_date': (list.value.expiry_date) ?
            this.apiService.formatDate(list.value.expiry_date) : list.value.expiry_date,
          'manufacture_date': (list.value.manufacture_date) ?
            this.apiService.formatDate(list.value.manufacture_date) : list.value.manufacture_date,
          'freeGoods': this.freeGoods,
          'purchsub_rebate_amount': Number(this.purchsub_rebate_amount / this.selRateQty),
          'purchsub_rebate_cost_effect': this.purchsub_rebate_cost_effect,
          'batch_code': this.batchcode || this.stkbatchsel, 'commissions': (list.value.commissions) ? list.value.commissions : "",
          'shipping_cost': (list.value.shipping_cost) ? list.value.shipping_cost : "",
          'customs_duty': (list.value.customs_duty) ? list.value.customs_duty : "",
          'excise_duty': (list.value.excise_duty) ? list.value.excise_duty : "",
          'total_freight': $('#totalfreight').val(),



          'purchaserateperbox': this.purchaserateperbox, 'purch_price': this.purchpricetotal,
          'boxname': this.boxname, 'taxvalperqty': this.taxvalperqty, 'taxpercentage': this.taxpercentage,
          'boxdiplayname': this.boxdiplayname, 'boxdisplaybaseqty': this.boxdisplaybaseqty,
          'prd_base_unit_name': this.prd_base_unit_name, 
          'purchsub_cgst_tax_per':this.selecTed.cgst_tax_per,
          'purchsub_sgst_tax_per':this.selecTed.sgst_tax_per,
          
          // 'purchsub_unit_id': this.purchsub_unit_id,
          'purchsub_unit_id': s_u.unit_id,

          'boxqty': this.boxqty,
          'purchsub_frieght': this.freight_total_rate, 'setfrightforTotqty': this.setforTotqty, 'prd_barcode': this.selecTed.prd_barcode, 'prd_tax_cat_id': this.prd_tax_cat_id
          , 'item_desc': this.item_desc, 'price_vat_ttl': (this.purchpricetotal + this.taxvaltotqty),
          'ubq': ubq, 'purchased_unit': s_u, 'purchased_rate': this.selecTed.purchpricesingle,
          'purchsub_exchange_rate': (this.selcurrency && this.exchange_rate) ? this.exchange_rate : 1,
          'purchsub_currency_id': (this.selcurrency && this.exchange_rate) ? this.selcurrency.cur_id : 0,
          'batch_sell_rate': this.batchSellRate, 'freight_data':this.freightTypes,
          'salesRate' :this.salesRate,
        }
       
        info.purch_item_total_without_item_disc = this.purchaserateperbox;
        if (this.isEdit) {
          var slNo = this.items[this.editIndex].sl_no;
          this.items[this.editIndex] = info;
          this.items[this.editIndex].sl_no = slNo;
        } else {
          this.items.push(info);
        }
        this.is_edit = 'false';

      
        

      }


      this.excludeTax = 0;
      this.is_description = this.items.filter(x => x.item_desc !== '');

      if (draft && draft.value != null) {
        this.purchaseSave(draft, 'auto');
      } else {
        $('#autosave').click();
      }
      // end existing array
      this.modalRef.close();
      list.reset();
      $('.puchase-list-table').show();
      $('.save').show();
      $('.box').show();
      this.changeBool();
      this.refreshAll();
       this.updateFreightAmounts();
      $('.puchase-list-table').show();
      // this.updateItemDiscount();
      // this.inputItemId.nativeElement.focus();  
      if (this.srch_mtd == 2) {
        this.inputItemId.nativeElement.focus();
      } else {
        this.selectproductId.focus();
      }

      this.freight_total_tax = 0;
      this.freight_total_net_amount = 0;
      this.freight_total_rate =0;

      

    } else {
      $('#err_gen').show();
    }

    if(this.freight_type == 2)
    {
      this.freightBillwise(this.freightTypeTotal);
    }

  }



  basicRates() {
    //  calculate single rate
    this.purchaseqty = $('#purchaseqty').val();
    this.unit_qty_inp = $('#unit_qty_inp').val();
    this.updateRateTypes();
    var exchgRate = (this.selcurrency && this.exchange_rate) ? this.exchange_rate : 1;
    this.baserate = Number($('#basicrate').val()) * exchgRate;
    this.singlerate = this.baserate / (this.purchaseqty * this.unit_qty_inp);





    //   change tax amount
    // this.taxpercentage = $('#tax').val();
    this.taxamount = (this.baserate * this.taxpercentage) / 100;
    $('#taxamount').val(this.taxamount);
    this.totalqty = this.purchaseqty * this.unit_qty_inp;
    this.purchprice = this.baserate;
    this.taxamounttotal = this.totalqty * this.taxamount;
    this.purchaserateperbox = this.purchprice / this.unit_qty_inp / this.selRateQty;
    this.purchpricetotal = this.purchaserateperbox * this.totalqty;
    this.boxqty = this.totalqty / this.unit_qty_inp;
    this.taxvalperqty = this.purchaserateperbox * this.taxpercentage / 100;
    this.taxvaltotqty = this.purchpricetotal * this.taxpercentage / 100;
    this.boxdisplaybaseqty = this.unit_qty_inp * this.purchaseqty;
  }

  basicRatesBox() {
    //  calculate single rate
    this.purchaseqty = $('#purchaseqty').val();
    //diff
    this.unit_qty_inp = this.unit_qty;
    this.updateRateTypes();
    this.baserate = $('#basicrate').val();
    this.singlerate = this.baserate / (this.purchaseqty * this.unit_qty_inp);

    //   change tax amount
    // this.taxpercentage = $('#tax').val();
    this.taxamount = (this.baserate * this.taxpercentage) / 100;
    $('#taxamount').val(this.taxamount);
    this.totalqty = this.purchaseqty * this.unit_qty_inp;
    this.purchprice = this.baserate;
    this.taxamounttotal = this.totalqty * this.taxamount;
    this.purchaserateperbox = this.purchprice / this.unit_qty_inp / this.selRateQty;
    this.purchpricetotal = this.purchaserateperbox * this.totalqty;
    this.boxqty = this.totalqty / this.unit_qty_inp;
    this.taxvalperqty = this.purchaserateperbox * this.taxpercentage / 100;
    this.taxvaltotqty = this.purchpricetotal * this.taxpercentage / 100;
    this.boxdisplaybaseqty = this.unit_qty_inp * this.purchaseqty;

  }

  calFreight() {
    this.custduty = $('#custduty').val();
    this.exciseduty = $('#exciseduty').val();
    this.shipcost = $('#shipcost').val();
    this.impcomm = $('#impcomm').val();
    // this.setforTotqty = $('#setforTotqty').val();



    this.totalfreight = Number(this.custduty) + Number(this.exciseduty) + Number(this.shipcost) + Number(this.impcomm);
    this.totalfreight = (isNaN(this.totalfreight) ? 0 : this.totalfreight);

    $('#totalfreight').val(this.totalfreight);
    if (this.setforTotqty) {
      this.totalfrieghtproduct = this.totalfreight;
    } else {
      this.totalfrieghtproduct = this.totalfreight * this.boxqty;
    }

  }

  refreshAll() {
    this.totalqty = 0;
    this.taxamounttotal = 0;
    this.taxamount = 0;
    this.taxpercentage = 0;
    this.singlerate = 0;
    this.purch_amount = Number(this.purch_amount) + Number(this.baserate);
    this.baserate = 0;
    this.unit_qty = 0;
    this.purchaseqty = 0;
    this.unit_qty_inp = 0;
    this.purchsub_qty = '';
    this.purchsub_rebate_amount = 0;
    this.purchsub_rebate_cost_effect = 0;
    this.purchsub_rebate = 0;
    this.freeGoods = false;
    this.total_freight = '';
    this.excise_duty = '';
    this.customs_duty = '';
    this.shipping_cost = '';
    this.commissions = '';
    this.frChecked = 0;
    this.selRateQty = 1;
    $('#freight').val('');
    // this.gdid = null;
    // this.totpurchprice = 0;
    // this.purch_tax = 0;
    // this.subtotalfrieght = 0;
    // for (var i = 0; i < this.items.length; i++) {
    //   this.totpurchprice = this.totpurchprice + Number(this.items[i]['purch_price']);
    //   this.purch_tax = this.purch_tax + Number(this.items[i]['purchsub_tax']);
    //   this.subtotalfrieght = this.subtotalfrieght + Number(this.items[i]['freight']);


    // }
    this.updateTotalAmount();
    // this.updateRebateAmount();
    this.purchpricetotal = 0;
    this.purchprice = 0;
    this.totalfrieghtproduct = 0;
    this.totalfreight = 0;


    $('#taxamount').val('');
    $('#tax').val('');
    $('#basicrate').val('');
    $('#purchaseqty').val('');
    $('#unit_qty_inp').val('');
    $('#freight').val('');
    $('#purchaseunit').val('');
    $('#custduty').val('');
    $('#exciseduty').val('');
    $('#shipcost').val('');
    $('#impcomm').val('');
    $('#totalfreight').val('');

    $('#purchaseqty').val('');
    $('#basicrate').val('');
    $('#tax').val('');
    $('#taxamount').val('');
    $('#taxamount').val('');

    this.stkbtch_all = [];

    // batch
    this.batchcode = '';
    $('#manufacture_date').val('');
    $('#expiry_date').val('');
    this.stkbatchselected = '';

    this.stkunitsel = "Select Unit";
    this.sel_gd_id = 0;
    this.stkprdsel = "Select Product";
    this.stkprd_all = [];
    this.stkbatchsel = "";
    this.stkbtch_all = [];
    this.isChecked = false;
    this.boxdiplayname = '';
    this.boxdisplaybaseqty = null;
    this.prd_base_unit_name = null;
    this.purchsub_unit_id = null;
    this.unitType = 'piece';
    this.item_desc = '';
    this.item_barcode = '';
    this.expiry_date = null;
    this.sb_expiry_date = null;
    this.manufacture_date = null;
    this.sb_manufacture_date = null;
    this.addstkbatchsel = '';
    this.up_last_purch_amt = false;

  }


  // search godown
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

      //this.gd_id_active ={ "gd_id": 0, 'gd_name': 'Shop' };
      this.godown_all.reverse();

    });

  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      // this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

      this.godown_all.reverse();

    });
  }

  selectGodown(gdid: number) {
    this.gdid = gdid;
  }

  editItem(data, content, editIndex) {

   
    // alert('sss');
    // this.freeGoods =true;
    // alert('ok')

    // this.freeGoods = data.freeGoods;

    if (data.freeGoods == 1) {
      this.freeGoods = true;

    }
    else {
      this.freeGoods = false;
    }
    if (data.purchsub_rebate_cost_effect == 1) {
      this.purchsub_rebate_cost_effect = true;
    }
    else {
      this.purchsub_rebate_cost_effect = false;
    }

    this.purchsub_rebate_amount = data.purchsub_rebate_amount;
    
    if(data.setfrightforTotqty == 1){
      this.setforTotqty =1;
    }
    else if(data.setfrightforTotqty == 0){
      this.setforTotqty =0;
    }



    // this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    // this.modalRef.result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
    this.editIndex = editIndex;
    this.stkprdsel = data.purchsub_prd_id;
    // this.sb_manufacture_date = data.expiry_date;
    this.sb_manufacture_date = data.manufacture_date?new Date(data.manufacture_date):'';
    this.sb_expiry_date = data.expiry_date?new Date(data.expiry_date):'';
    this.stkbatchsel = data.batch_code;
    this.purchsub_gd_id = data.purchsub_gd_id;
    this.selectGodown(this.purchsub_gd_id);
    // this.stkprdbc_all = data;
    // if (this.stkprdbc_all) {
    //   this.selectStockPrdSel(data, content);
    //   this.searchResult = '';
    // } else {
    //   this.productSearchLoader = false;
    //   this.searchResult = 'Not found';
    // }
    // this.getPurchaseProd(data.purchsub_prd_id, content);

    this.productSearchLoader = true;
    this.isEdit = true;
    let searchval = new FormData();
    searchval.append("prd_id", data.purchsub_prd_id);
    this.apiService.getPurchaseProduct(searchval).subscribe((res) => {
      this.stkprdbc_all = res['data'];

      this.stkbtch_all = this.stkprdbc_all.batches;
      this.batchSellRate = [];
      if (this.stkprdbc_all) {
        this.stkprdbc_all.stock_unit_rate.forEach(element => {
          this.batchSellRate.push({ rate1: '', rate2: '', checked: false, unit_id: element.sur_unit_id })
        });

        this.selectStockPrdSel(res['data'], content);
        this.purchsub_qty = data.boxqty;
        if (typeof data.purchased_rate !== 'undefined') {
          this.selecTed.purchase_rate = data.purchased_rate;
        } else {
          this.selecTed.purchase_rate = data.purchaserateperbox;
        }
        this.total_freight = data.total_freight;
        this.excise_duty = data.excise_duty;
        this.customs_duty = data.customs_duty;
        this.shipping_cost = data.shipping_cost;
        this.commissions = data.commissions;

        this.prd_id=data.purchsub_prd_id;
        this.stkunitsel = data.purchased_unit;
        this.baseUnitRate = this.selecTed.purchase_rate / this.exchange_rate;
        this.purchaseBox(this.stkunitsel)
        this.searchResult = '';
      } else {
        this.productSearchLoader = false;
        this.searchResult = 'Not found';
      }

      // this.selecTed.freight_type = data.freight_data;
    

      this.freightTypes =[];

      // data.freight_data.forEach(element => {

  
      
      // });
     
      this.freight_total_tax =  0;
      this.freight_total_net_amount = 0;
      this.freight_total_rate = 0;
   

      if(data.freight_data == undefined || data.freight_data.length  == 0){
  

        this.stkprdbc_all.freight_type.forEach(element => {

            
          this.freightTypes.push({ freight_type_id: element.freight_type_id, freight_type_name: element.freight_type_name, freight_type_ledget_id: element.freight_type_ledger_id,freight_type_amount:0,freight_type_amount_temp:0,freight_type_cur_id:0,freight_type_ex_rate:1,freight_type_tax_id:3,freight_type_tax:0,freight_type_net_total:0,freight_type_rate:0,freight_type_tax_no:"",freight_type_invoice_no:"", freight_type_ledget_name:element.ledger_name, })
          this.searchLedger(element.ledger_name);

         
        });
      }
      else{

   
        data.freight_data.forEach((element, i) => {

       

        this.freightTypes.push({ freight_type_id: element.freight_type_id, freight_type_name: element.freight_type_name, freight_type_ledget_id: element.freight_type_ledget_id,freight_type_amount:element.freight_type_amount,freight_type_cur_id:element.freight_type_cur_id,freight_type_ex_rate:element.freight_type_ex_rate,freight_type_tax_id:element.freight_type_tax_id,freight_type_tax:0,freight_type_net_total:0,freight_type_rate:0,
        freight_type_tax_no:element.freight_type_tax_no,
        freight_type_invoice_no:element.freight_type_invoice_no,
        freight_type_amount_temp:element.freight_type_amount_temp,
        freight_type_ledget_name:element.freight_type_ledget_name,
        });
        this.searchLedger(element.freight_type_ledget_name);

        this.updateFreightTax(this.freightTypes[i].freight_type_tax_id,i);
        });
      }
  
     

      if(this.freight_type == 1)
      {
          this.frChecked =1;
      }

    });
  }
  // delete product from table
  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the table");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.items.length - 1; i >= 0; --i) {
        if (this.items[i].sl_no == type) {
          this.items.splice(i, 1);
        }
      }

      this.is_description = this.items.filter(x => x.item_desc !== '');

      this.totpurchprice = Number(this.totpurchprice) - Number(data.purch_price);
      this.totpriceVat = Number(this.totpriceVat) - Number(data.price_vat_ttl);
      this.purch_tax = Number(this.purch_tax) - Number(data.purchsub_tax);
      // this.purch_cgst_tax = 0;
      // this.purch_sgst_tax = 0;
      this.subtotalfrieght = Number(this.subtotalfrieght) - Number(data.freight);
      this.subTotalFrieghtTax = Number(this.subTotalFrieghtTax) - Number(data.freight);
      $('#autosave').click();

      if (this.items.length == 0) {
        this.is_edit = 'true';
        $('.puchase-list-table').hide();
        $('.save').hide();
        $('.box').hide();
      }
    }

  }


  // form search supplier
  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }
  searchSupplierAlias(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }


  changeBool() {
    if (this.changeBox == 1) {
      this.changeBox = 0;
    } else {
      this.changeBox = 1;
    }

  }



  //form search batch
  searchStkBatch(search: string) {
    if (search) {
      let searchval = new FormData();
      searchval.append('keyword', search);
      searchval.append('prd_id', this.selecTed.prd_id);
      searchval.append('like', '1');
      this.apiService.getBatchbyName(searchval).subscribe((res) => {
        this.stkbtch_all = res['data'];
      });
    }
    else {
      this.stkbtch_all = [];
    }
  }



  //select batch  
  selectBatch(res: Batch) {
    if (res) {
      this.selBatch = res;
      this.batchcode = res.sb_batch_code;
      this.sb_expiry_date = this.datePipe.transform(res.sb_expiry_date, 'dd/MM/yyyy');
      this.sb_manufacture_date = this.datePipe.transform(res.sb_manufacture_date, 'dd/MM/yyyy');
      this.date = new Date(this.sb_expiry_date);
      var from = this.sb_manufacture_date.split("/");
      this.sb_manufacture_date = new Date(from[2], from[1] - 1, from[0]);
      var to = this.sb_expiry_date.split("/");
      this.sb_expiry_date = new Date(to[2], to[1] - 1, to[0]);
      this.getExistingBatchSellRate();
    }

  }

  selectVat(ptypeid) {
    // different purchase types
    // { id: 1, name: 'Standard Rated Domestic' },
    // { id: 2, name: 'Zero Rated Domestic' },
    // { id: 3, name: 'Exempt Purchase' },
    // { id: 4, name: 'Imports Vat Paid to Custom' },
    // { id: 5, name: 'Imports Vat - reverse change mechanism' }
    this.purch_type_taxcat_id = 0;
    this.prd_tax_cat_id = 0;
    this.purch_tax_ledger_id = 0;
    if (ptypeid === 4 || ptypeid === 5) {
      // to set tax category international only
      this.taxListCategories = this.taxCategories.filter((taxCat) => Number(taxCat.is_international) == 1);
      if (this.taxListCategories.length > 0) {
        this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
      }
    } else if (ptypeid === 3) {
      // to set tax category with 0% tax percentage
      this.taxListCategories = this.taxCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);
      if (this.taxListCategories.length > 0) {
        this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
      }
    } else {
      this.taxListCategories = this.taxCategories;
    }

    if (ptypeid === 2 || ptypeid === 3) {
      this.p_type_id = 1;
      this.prd_tax_cat_id = 3;
    } else {
      this.p_type_id = 0;
    }
    const selTax = this.taxCategories.filter((taxCat) => Number(taxCat.taxcat_id) == this.prd_tax_cat_id);
    if (selTax.length > 0) {
      this.taxpercentage = selTax[0].taxcat_tax_per;
    }

    if (ptypeid == 4) {
      $('#purch_tax_ledger_id').show();
    } else {
      $('#purch_tax_ledger_id').hide();
    }
    this.purchType = ptypeid;
  }

  //form search  ledger
  searchLedger(search: string) {
    let searchval = new FormData();
    searchval.append("ledger_name", search);
    this.apiService.getLedByName(searchval).subscribe((res) => {
      this.ledger_all = res['data'];

    });
  }

  allCurrency(){
   
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currency_all = res.data;
      this.currency_all.push({ "cur_id": 0, 'cur_name': 'Default' });

   
     
    });

  }

  changeCurrency(cur_id,i){
 

   if(cur_id != null){

    if(cur_id == 0){
      this.freightTypes[i].freight_type_ex_rate = 1;

    
    }
    else{
      this.apiService.getCurrency({cur_id: cur_id}).subscribe((res) => {
     
        this.freightTypes[i].freight_type_ex_rate = res.cur_exchange_rate;

     
       this.updateFreightTax(this.freightTypes[i].freight_type_tax_id,i);
       
      });
    }
   
   }
   
   else{
    this.freightTypes[i].freight_type_ex_rate = '';
   }

 

  //  this.updateFreightTax(this.freightTypes[i].freight_type_tax_id,i);
   
      
  }

  // discount
  // calDiscount(eventvalue) {
  //   this.discount = eventvalue;
  // }


  changeTotalFreightCurrency(cur_id,i){


   if(cur_id != null){

    if(cur_id == 0){
      this.freightTypeTotal[i].total_freight_type_ex_rate = 1;

     
    }
    else{
      this.apiService.getCurrency({cur_id: cur_id}).subscribe((res) => {
     
        this.freightTypeTotal[i].total_freight_type_ex_rate = res.cur_exchange_rate;

      
       this.updateTotalFreightTax(this.freightTypeTotal[i].total_freight_type_tax_id,i);
       
      });
    }
   
   }
   
   else{
    this.freightTypeTotal[i].total_freight_type_ex_rate = '';
   }

 

  //  this.updateFreightTax(this.freightTypes[i].freight_type_tax_id,i);
   
      
  }






  valiDation() {
    this.errObj = 'no';
    this.purqtyval = $('#purchaseqty').val();
    if (this.purqtyval < 0 || !this.purchaseqty) {
      $('#err_qty').show();
      return this.errObj = 'yes';
    }

    this.baserateval = $('#basicrate').val();
    if (this.baserateval < 0 || !this.baserateval) {
      $('#err_brte').show();
      return this.errObj = 'yes';
    }

  }


  // purchase no. generation
  getPurchase() {
    let searchval = new FormData();
    this.apiService.getPurchase(searchval).subscribe((res) => {
      this.purchaseno = res['data'].purch_num;
      this.purchasedate = new Date();
      // this.purchase_types = [
      //   { id: 1, name: 'Standard Rated Domestic' },
      //   { id: 2, name: 'Zero Rated Domestic' },
      //   { id: 3, name: 'Exempt Purchase' },
      //   { id: 4, name: 'Imports Vat Paid to Customs' },
      //   { id: 5, name: 'Imports Vat - reverse charge mechanism' }
      // ];
      this.getPurchSetting()
    });

  }



  checkValue(event: any) {
    if (event == 'Y') {
      $('#stkbatchsel').show();
      $('.batchdetails').show();
      $('#batchlabel').show();
    }
    else {
      $('#stkbatchsel').hide();
      $('.batchdetails').hide();
      $('#batchlabel').hide();
      $('#batch1').hide();

    }
  }

  freeGoodscheckValue() {
    if (this.freeGoods) {

      this.selecTed.purchase_rate = 0;
      // this.freeGoods = true;

    }
    else {
      this.selecTed.purchase_rate = this.baseUnitRate;

      // this.freeGoods =false;

    }
  }

  // checkFreight(frChecked: any) {
  //   if (frChecked == 'Y') {
  //     $('#freight').show();
  //   }
  //   else {
  //     $('#freight').hide();
  //   }
  // }

  addBatch() {
    $('#srchbatchbtn').show();
    $('#addbatchbtn').hide();
    $('#stkbatchsel').hide();
    $('#batch1').show();
    this.searchExisting = false;
    this.getExistingBatchSellRate();
  }

  addshow() {
    $('#mfgext').hide();
    $('#mfgnew').show();

    $('#expext').hide();
    $('#expnew').show();

    $('#stkbatchsel').show();
    $('#batch1').hide();
    this.searchExisting = true;

    $('#srchbatchbtn').hide();
    $('#addbatchbtn').show();
    $('#batch1').hide();
    this.getExistingBatchSellRate();
  }
  addPurch(event, purchase, addPurchase) {
    if (event.keyCode == 13) {
      this.updatePurchase(purchase, addPurchase);
    }

  }

  barcodeSearch(search: string, content) {
    if (search) {
      let searchval = new FormData();
      searchval.append("keyword", search);
      this.prodUnitLoading = true;
      this.apiService.getPurchasebyBcode(searchval).subscribe((res) => {
        this.stkprdbc_all = res['data'];
      
        
        this.prodUnitLoading = false;
        this.isEdit = false;
        if (this.stkprdbc_all.length > 0) {
          this.prd_id=this.stkprdbc_all[0].prd_id;
         
          
          this.getLastPurchaseOfProd(res['data'][0].prd_id);
          this.selectStockPrdBcode(res['data'][0], content);
          this.searchResult = '';
        } else {
         
          this.barcodeSearchUean(search, content);
        }

      });
    }
  }



  barcodeSearchUean(search: string, content) {

    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPurchasebyUean(searchval).subscribe((res) => {

      this.stkprdbc_all = [];
      this.stkprdbc_all = res['data'];
      this.prd_id=this.stkprdbc_all[0].prd_id;
      

      this.batchSellRate = [];
      this.freightTypes =[];
      if(this.freight_type == 1)
      {
          this.frChecked =1;
      }
     
      
      

      if (this.stkprdbc_all.length > 0) {

        res['data'][0].stock_unit_rate.forEach(element => {
          this.batchSellRate.push({ rate1: '', rate2: '', checked: false, unit_id: element.sur_unit_id })
        });

        res['data'][0].freight_type.forEach(element => {

            
          this.freightTypes.push({ freight_type_id: element.freight_type_id, freight_type_name: element.freight_type_name, freight_type_ledget_id: element.freight_type_ledger_id,freight_type_amount:0,freight_type_amount_temp:0,freight_type_cur_id:0,freight_type_ex_rate:1,freight_type_tax_id:3,freight_type_tax:0,freight_type_net_total:0,freight_type_rate:0,freight_type_tax_no:"",freight_type_invoice_no:"", freight_type_ledget_name:element.ledger_name, })
          this.searchLedger(element.ledger_name);

         
        });
        this.salesRate =res['data'][0].prd_base_sales_rate;
        this.getLastPurchaseOfProd(res['data'][0].prd_id);
        this.selectStockPrd(res['data'][0], content);
        this.searchResult = '';
      } else {
        this.searchResult = 'No items found';
      }

    });
  }


  resetPurchase() {
    var confrm = confirm("Are you sure you want to reset?");
    if(confrm){
    $('.puchase-list-table').hide();
    this.items = [];
    this.refreshAll();
    this.getPurchase();
    this.subtotalfrieght = 0;
    this.subTotalFrieghtTax = 0;
    this.purchFreightTotal =0;
    this.totalRebateAmount = 0;
    this.totpurchprice = 0;
    this.totpriceVat = 0;
    this.purch_amount = 0;
    this.purch_tax = 0;
    this.purch_cgst_tax = 0;
    this.purch_sgst_tax = 0;
    this.purch_igst_tax = 0;
    this.date = new Date();
    this.serializedDate = new FormControl((new Date()).toISOString());
    this.changeBool();
    this.discount = 0;
    this.is_edit = 'true';
    this.purchType = 1;
    setTimeout(() => {
      this.round_sign = 1;
      this.roundoff = '0';
    }, 3000);

    this.qtn_id = this.ActiveRoute.snapshot.paramMap.get('id');
    if (this.qtn_id)
      this.route.navigate(['/purchase/purchase']);

    }
  }



  validateForm() {
    this.printError = [];
    if (this.unit_qty == "" || this.unit_qty == undefined) {
      this.printError.push({ 'unitqtyErr': 'Required' });
      $('#purchaseunit').css("border", "1px solid red");
    }
    else {
      $('#purchaseunit').css("border", "1px solid #dedede");
    }
    if (this.selecTed.prd_type == 0) {
      $('#purchaseqty').val() == 1;
      this.purchsub_qty = 1;
    }
    if (($('#purchaseqty').val() == "" || Number($('#purchaseqty').val()) <= 0)) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#purchaseqty').css("border", "1px solid red");
    }
    else {
      $('#purchaseqty').css("border", "1px solid #dedede");
    }
    if (this.freeGoods == true) {
      if ($('#basicrate').val() == "" || Number($('#basicrate').val()) < 0) {
        this.printError.push({ 'prateErr': 'Required' });
        $('#basicrate').css("border", "1px solid red");
      } else {
        $('#basicrate').css("border", "1px solid #dedede");
      }

    }
    else {

      if ($('#basicrate').val() == "" || Number($('#basicrate').val()) <= 0) {
        this.printError.push({ 'prateErr': 'Required' });
        $('#basicrate').css("border", "1px solid red");
      } else {
        $('#basicrate').css("border", "1px solid #dedede");
      }

    }


    if ($('#tax').val() == "") {
      this.printError.push({ 'prateErr': 'Required' });
      $('#tax').css("border", "1px solid red");
    } else {
      $('#tax').css("border", "1px solid #dedede");
    }

    if (($('#purchsub_rebate_amount').val() == "" || Number($('#purchsub_rebate_amount').val()) > Number($('#basicrate').val()))) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#purchsub_rebate_amount').css("border", "1px solid red");
    }


    this.valErrors = {};
    let retVal = true;
  
   
   
    this.freightTypes.forEach((element, i) => {
      
        if(element.freight_type_ledget_id == undefined && element.freight_type_amount > 0){
          // this.valErrors['freight_type_ledget_id_' + i] = {msg : 'Required'}
          // retVal =  false;
          this.printError.push({ 'freight_type_ledget_id_': 'Required' });
          $('#freight_type_ledget_id_' + i).css("border", "1px solid red");

        

        }
        if((element.freight_type_ex_rate <= 0 )){
       
          // this.valErrors['freight_type_ex_rate_' + i] = {msg : 'Required'}
          // retVal =  false;

          this.printError.push({ 'freight_type_ex_rate_': 'Required' });
          $('#freight_type_ex_rate_' + i).css("border", "1px solid red");
        }

        if(element.freight_type_tax_id != 3)
        {
         if(element.freight_type_tax_no == "" ||element.freight_type_tax_no == undefined) 
         {

          this.printError.push({ 'freight_type_tax_no_': 'Required' });
          $('#freight_type_tax_no_' + i).css("border", "1px solid red");

         }

         if(element.freight_type_invoice_no == "" ||element.freight_type_invoice_no == undefined) 
         {

          this.printError.push({ 'freight_type_invoice_no_': 'Required' });
          $('#freight_type_invoice_no_' + i).css("border", "1px solid red");

         }

        }
    
      
    });
    
    return retVal;


  }

  validateBatchold() {
    if (this.batchcode == undefined && this.isChecked == true && this.searchExisting == true) {
      this.batchError.push({ 'batchErr': 'Required' });
      this.batchid = 1
      $('#stkbatchsel').css("border", "1px solid red");
    } else {
      this.batchid = 0;
      $('#stkbatchsel').css("border", "1px solid #dedede");
    }
    if (this.searchExisting == false) {
      if ($('#batch1').val() == "" || $('#expiry_date').val() == "" || $('#manufacture_date').val() == "") {
        this.batchid = 1;
      } else {
        this.batchcode = $('#batch1').val();
      }
    }

  }

  validateBatch(list) {
    if (this.isChecked == true && this.searchExisting == true) {
      this.batchError = [];
      if (this.batchcode == undefined) {
        this.batchError.push({ 'batchErr': 'Required' });
        $('#stkbatchsel').css("border", "1px solid red");
      } else {
        $('#stkbatchsel').css("border", "1px solid #dedede");
      }

      if (list.value.manufacture_date == "") {
        this.batchError.push({ 'mdateErr': 'Required' });
        $('#manufacture_date').css("border", "1px solid red");
      } else {
        $('#manufacture_date').css("border", "1px solid #dedede");
      }

      if (list.value.expiry_date == "") {
        this.batchError.push({ 'edateErr': 'Required' });
        $('#expiry_date').css("border", "1px solid red");
      } else {
        $('#expiry_date').css("border", "1px solid #dedede");
      }
    }
    else if (this.isChecked == true && this.searchExisting == false) {
      this.batchError = [];
      if ($('#batch1').val() == "") {
        this.batchError.push({ 'batchErr': 'Required' });
        $('#batch1').css("border", "1px solid red");
      } else {
        this.batchcode = $('#batch1').val();
        $('#batch1').css("border", "1px solid #dedede");
      }

      if ($('#manufacture_date').val() == "") {
        this.batchError.push({ 'mdateErr': 'Required' });
        $('#manufacture_date').css("border", "1px solid red");
      } else {
        $('#manufacture_date').css("border", "1px solid #dedede");
      }

      if ($('#expiry_date').val() == "") {
        this.batchError.push({ 'edateErr': 'Required' });
        $('#expiry_date').css("border", "1px solid red");
      } else {
        $('#expiry_date').css("border", "1px solid #dedede");
      }
    }
    else if (this.isChecked != true) {
      $('#stkbatchsel').css("border", "1px solid #dedede");
      $('#batch1').css("border", "1px solid #dedede");
      $('#manufacture_date').css("border", "1px solid #dedede");
      $('#expiry_date').css("border", "1px solid #dedede");
      this.batchError = [];
    }
    else {
      this.batchError = [];
    }

  }




  db_activate(search: string) {

    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('db', search);
    this.apiService.sqlConnect(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.message) {
        this.dbConnected = true;
        this.coreService.showMessage(res.message);
      } else {
        this.dbConnected = false;
        this.coreService.showMessage(res.error);
      }


    });
  }



  purch_search(search: string, db: string) {

    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    searchval.append('db', db);
    this.apiService.getPurchInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.gd_id = 0;
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.purchData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,
          inactive: null,
          items: null

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdpurch = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.purchData = res.data;
      }
      this.gd_id_active = 0;

    });
  }

  sale_search(search: string) {
    this.update = false;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInvBranch(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }


  addItemsToGrid(soldData) {
    this.update = false;
    this.items = [];
    this.purch_inv_no = soldData.sales_branch_inv;

    soldData.sales_sub.forEach((element, index) => {
      this.items.push({
        "sl_no": index + 1,
        'purchsub_prd_id': element.salesub_prod_id,
        'prd_name': element.prd_name,
        'ean': null,
        'purchsub_stock_id': element.salesub_stock_id,
        'purchsub_qty': element.salesub_qty,
        'purchsub_rate': element.salesub_rate,
        'purchsub_tax': element.salesub_tax_rate,
        'purchsub_tax_per': element.salesub_tax_per,
        'purchsub_price': element.sale_amount,
        'purchpricesingle': element.salesub_rate,
        'purchsub_gd_id': 0,
        'freight': 0,
        'expiry_date': null,
        'commissions': null,
        'customs_duty': null,
        'excise_duty': null,
        'total_freight': null,
        'shipping_cost': null,
        'manufacture_date': null,
        'batch_code': null,
        'purchaserateperbox': element.salesub_rate,
        'purch_price': element.sale_amount,
        'purch_item_total_without_item_disc': element.salesub_rate,
        'boxname': 'nil',
        'taxvalperqty': element.salesub_tax_rate / element.salesub_qty,
        'taxpercentage': element.salesub_tax_per,
        'boxdiplayname': element.unit_display,
        'boxdisplaybaseqty': element.salesub_qty,
        'prd_base_unit_name': element.unit_display,
        'purchsub_unit_id': element.salesub_unit_id,
        'boxqty': 0,
        'purchsub_frieght': 0,
        'setfrightforTotqty': 0,
        'prd_barcode': element.prd_barcode,
        'prd_tax_cat_id': element.salesub_taxcat_id,
        'item_desc': '',
        'price_vat_ttl': (element.sale_amount + element.salesub_tax_rate)
      });

    });

    this.saleData = [];





    $('.puchase-list-table').show();
    $('.save').show();
    $('.box').show();
    this.changeBool();
    this.refreshAll();
    $('.puchase-list-table').show();

    var scrollBottom = $('.table-responsive').height();
    $('.scroll-table').scrollTop(300);
    $('#closemodal2').click();

  }


  // searchDefaultLedger(search: string) {
  //   const searchval = new FormData();
  //   searchval.append('ledger_name', search);
  //   searchval.append('ledger_grp', '3');
  //   this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
  //     this.defLedger = res['data'];
  //   });
  // }
  searchDefaultLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    if (this.purch_pay_type === 2)
      searchval.append('ledger_grp', '3');
    else if (this.purch_pay_type === 3)
      searchval.append('ledger_grp', '4');

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }
  changePayType() {
    this.defLedger = [];
    if (this.purch_pay_type == 1) {
      this.purch_acc_ledger_id = '';
    } else if (this.purch_pay_type === 2) {
      if (this.purch_acc_ledger_id != '0') {
        this.defLedger = this.coreService.getUserData('cash_ledger');
        this.purch_acc_ledger_id = this.coreService.getUserData('usr_cash_ledger_id');
        if (!this.purch_acc_ledger_id) {

          this.apiService.getBranchCashAccount().subscribe((led_res) => {
            this.defLedger = led_res.data.ledger;
            this.purch_acc_ledger_id = led_res.data.taxled_ledger_id;
          });
        }
      }
    } else if (this.purch_pay_type === 3) {
      if (this.purch_acc_ledger_id != '0') {
        this.defLedger = this.coreService.getUserData('bank_ledger');
        this.purch_acc_ledger_id = this.coreService.getUserData('usr_bank_ledger_id');
      }
    }
  }
  changeGodown(soldData, gdid: number) {
    soldData.items.forEach((element, index) => {

      soldData.items[index].gd_id = gdid;
    });

  }


  addItemsToPurchGrid(soldData, gd_id_active) {

    this.update = false;

    if (gd_id_active == undefined || gd_id_active == null) {

      $('#import_gd_id').css("border", "1px solid red");
      alert("select godown to import data ");
    } else {
      $('#import_gd_id').css("border", "1px solid #dedede");

      this.datevar = this.datePipe.transform(soldData.purch_date, 'dd/MM/yyyy');
      this.supp_id = (soldData.supp) ? Number(soldData.supp.supp_id) : null;

      this.subtotalfrieght = soldData.purch_freight;
      this.purch_note = soldData.note;
      var to = this.datevar.split("/");
      this.purchasedate = new Date(to[2], to[1] - 1, to[0]);
      // this.purch_pay_type = (soldData.purch_pay_type == 1) ? 1 : 2;
      this.purch_pay_type = soldData.purch_pay_type;
      this.discount = soldData.purch_discount;

      this.items = [];
      this.purch_inv_no = soldData.purch_inv_no;
      soldData.items.forEach((element, index) => {
        this.items.push({
          "sl_no": index + 1,
          'purchsub_prd_id': element.db_prd_id,
          'prd_name': element.db_prd_name,
          'ean': element.stock_ean,
          'purchsub_stock_id': element.bs_stock_id,
          'purchsub_qty': element.stk_unit_base_qty,
          'purchsub_rate': element.purchsub_prate / element.stk_unit_qty,
          'purchsub_tax': (element.purchsub_prate / element.stk_unit_qty) * (element.purchsub_tax_per / 100) * element.stk_unit_base_qty,
          'purchsub_tax_per': element.purchsub_tax_per,
          'purchsub_price': element.purchsub_qty * element.purchsub_prate,
          'purchpricesingle': element.purchsub_prate / this.selRateQty,
          'purchsub_gd_id': element.gd_id,
          'freight': 0,
          'expiry_date': null,
          'commissions': null,
          'customs_duty': null,
          'excise_duty': null,
          'total_freight': null,
          'shipping_cost': null,
          'manufacture_date': null,
          'batch_code': null,
          'purchaserateperbox': element.purchsub_prate / element.stk_unit_qty / this.selRateQty,
          'purch_price': element.purchsub_qty * element.purchsub_prate,
          'purch_item_total_without_item_disc': element.purchsub_prate / element.stk_unit_qty / this.selRateQty,
          'boxname': element.stk_unit_name,
          'taxvalperqty': (element.purchsub_prate / element.stk_unit_qty) * (element.purchsub_tax_per / 100),
          'taxpercentage': element.purchsub_tax_per,
          'boxdiplayname': element.unit_display,
          'boxdisplaybaseqty': element.stk_unit_base_qty,
          'prd_base_unit_name': element.unit_disp,
          'purchsub_unit_id': element.db_produnit_id,
          'boxqty': element.unit_base_qty,
          'purchsub_frieght': 0,
          'setfrightforTotqty': 0,
          'prd_barcode': element.db_barcode,
          'prd_tax_cat_id': element.salesub_taxcat_id,
          'item_desc': '',
          'price_vat_ttl': ((element.purchsub_qty * element.purchsub_prate) + (element.purchsub_prate / element.stk_unit_qty) * (element.purchsub_tax_per / 100) * element.stk_unit_base_qty)
        });

      });
      this.purchData = {
        sales_id: null,
        sales_inv_no: null,
        error: null,
        message: null,
        data: null,
        inactive: null,
        items: null
      };

      $('.puchase-list-table').show();
      $('.save').show();
      $('.box').show();
      this.changeBool();
      this.refreshAll();
      $('.puchase-list-table').show();

      var scrollBottom = $('.table-responsive').height();
      $('.scroll-table').scrollTop(300);
      $('#closepurchImprt').click();


    }

  }

  showList(val) {
    if (val)
      this.listing = false;
    else
      this.listing = true;

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  listPurchase(pageNo = 1) {
    let searchval = new FormData();
    // if(keyword)
    // searchval.append("keyword", keyword);
    this.pageLoadingImg = true;
    this.apiService.getPurchaseList(searchval, pageNo).subscribe((res) => {
      this.list_purchase = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });

    this.pageLoadingImg = false;
  }

  searchInp(keyword) {
    let searchval = new FormData();
    if (keyword)
      searchval.append("keyword", keyword);
    this.pageLoadingImg = true;
    this.apiService.getPurchaseList(searchval, 1).subscribe((res) => {
      this.list_purchase = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.pageLoadingImg = false;
  }


  previewPurchase(id) {
    const searchval = new FormData();
    searchval.append('purch_id', id);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.previewData = res.data;
      this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
      this.itemcode_show = this.previewData['items'].filter(x => x.prd_code);
      setTimeout(function () { $("#previewButton").click(); }, 1000);

    });
  }




  purcheditsearch(search: string) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', search);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {

     
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.editData = [];
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showedit = true;

        this.resultobj = {};
        this.purchaseItems = res.data;

        this.editData = res.data;
        this.purchasenumber = this.editData.purch_id;



        // this.purchData.purch_date = this.datePipe.transform(this.purchData.purch_date,"dd-MM-yyyy");         
        // this.purchId = res.data.purch_id;
        // this.purchNote = res.data.purch_note;
        // this.purchTotal = Number(res.data.purch_amount) + Number(res.data.purch_tax) + Number(res.data.purch_frieght) - Number(res.data.purch_discount);
      }



    });
  }

  editPurchase(soldData) {

    this.subtotalfrieght = 0;
    this.subTotalFrieghtTax = 0;
    this.total_freight_total_rate =0;

    this.total_freight_total_rate = 0;
    this.total_freight_total_tax = 0;
    this.total_freight_total_net_amount =0;

  
    if (soldData.cash_account) {
      this.defLedger = soldData.cash_account;
    }
    this.update = true;
    if (soldData.fright_ledger) {
      this.searchLedger(soldData.fright_ledger.ledger_name);
      this.purch_frieght_ledger_id = soldData.fright_ledger.ledger_id;
  
    }
  
    this.purch_inv_no = soldData.purch_inv_no;
  
  
  
    this.supp_id = (soldData.supplier) ? Number(soldData.supplier.supp_id) : null;
    if (this.supp_id) {
      this.getSupBalance(this.supp_id, soldData.purch_exchange_rate);
      this.purchaseno = soldData.purch_id;
      this.purchasenumber =soldData.purch_id;
    }
  
    // this.supp_id = soldData.purch_supp_id;
    this.subtotalfrieght = soldData.purch_frieght;
    this.purch_note = soldData.purch_note;
    this.freight_type =soldData.purch_frieght_type;
  
    this.purch_acc_ledger_id = soldData.purch_acc_ledger_id;
    this.purch_type = soldData.purch_type;
    this.purchtypesel=soldData.purch_type;
    this.selectVat(this.purchtypesel);
    this.datevar = this.datePipe.transform(soldData.purch_date, 'dd/MM/yyyy');
    var to = this.datevar.split("/");
    this.purchasedate = new Date(to[2], to[1] - 1, to[0]);
    if (soldData.purch_inv_date) {
      this.dateinvvar = this.datePipe.transform(soldData.purch_inv_date, 'dd/MM/yyyy');
      this.dateinfo = this.dateinvvar.split("/");
      this.purch_inv_date = new Date(this.dateinfo[2], this.dateinfo[1] - 1, this.dateinfo[0]);
  
    }
  
  
  
  
  
    // this.purch_pay_type = (soldData.purch_pay_type == 1) ? 1 : 2;
    this.purch_pay_type = soldData.purch_pay_type;
    this.discount = soldData.purch_discount;
    setTimeout(() => {
      this.round_sign = (soldData.purch_roundoff && soldData.purch_roundoff < 0) ? -1 : 1;
      this.roundoff = '' + Math.abs(soldData.purch_roundoff);
    }, 3000);
    this.items = [];
    
    
    this.purch_inv_no = soldData.purch_inv_no;
  
    this.purchsub_rebate_amount = soldData.purchsub_rebate_amount;
    this.purchsub_rebate_cost_effect = soldData.purchsub_rebate_cost_effect;
   
    
  
    soldData.items.forEach((element, index) => {

     
      // if (element.purchsub_free_goods == 1)
      //   {
      //     this.editfreeGoods = true;
      //   }
      //   else{
      //     this.editfreeGoods = false;
      //   }
        element.purchsub_rate = element.purchsub_rate + element.purchsub_disc;
      this.items.push({
        "sl_no": index + 1,
        'purchsub_prd_id': element.purchsub_prd_id,
        'prd_name': element.prd_name,
        'prd_code': element.prd_code,
        'ean': null,
        'purchsub_stock_id': element.purchsub_stock_id,
        'purchsub_qty': element.purchsub_qty,
        'purchsub_rate': element.purchsub_rate,
        'purchsub_tax': element.purchsub_tax,
        'purchsub_tax_per': element.purchsub_tax_per,
        'purchsub_price': element.purchsub_qty * element.purchsub_rate,
        'purchpricesingle': element.purchsub_rate,
        'purchsub_gd_id': element.purchsub_gd_id,
        'freight': element.purchsub_frieght,
        'expiry_date': element.sb_expiry_date,
        'commissions': element.commissions,
        'customs_duty': element.customs_duty,
        'excise_duty': element.excise_duty,
        'freeGoods': element.purchsub_free_goods,
        'total_freight': $('#totalfreight').val(),
        'shipping_cost': element.shipping_cost,
        'manufacture_date': element.sb_manufacture_date,
        'batch_code': element.sb_batch_code,
        'purchaserateperbox': element.purchased_rate,
        'purch_price': element.purchsub_qty * element.purchsub_rate,
        'purch_item_total_without_item_disc': element.purchsub_rate,
        // 'boxname': element.purchased_unit.unit_display,
        'boxname': element.purchased_unit.unit_code,
        'ubq': element.purchased_unit.unit_base_qty,
        'taxvalperqty': element.purchsub_tax / element.purchsub_qty,
        'taxpercentage': element.purchsub_tax_per,
        'boxdiplayname': element.unit_display,
        'boxdisplaybaseqty': element.purchsub_qty,
        'prd_base_unit_name': element.baseunit.unit_code,
        'purchsub_unit_id': element.baseunit.unit_id,
        'boxqty': element.purchased_qty,
        'purchsub_frieght': element.purchsub_frieght,
        'setfrightforTotqty': element.purchsub_set_fright_for_tot_qty,
        'prd_barcode': element.prd_barcode,
        'prd_tax_cat_id': element.purchsub_taxcat_id,
        'item_desc': element.purchsub_item_desc,
        'purchased_unit': element.purchased_unit,
        'price_vat_ttl': ((element.purchsub_qty * element.purchsub_rate) + element.purchsub_tax),
        'purchsub_exchange_rate': element.purchsub_exchange_rate,
        'purchsub_currency_id': element.purchsub_currency_id,
        'purchsub_rebate_amount': element.purchsub_rebate_amount,
        'purchsub_rebate_cost_effect': element.purchsub_rebate_cost_effect,
        'freight_data' :element.freight_type,
        'purchsub_cgst_tax_per' : element.cgst_tax_per,
        'purchsub_sgst_tax_per':element.sgst_tax_per,
        // 'purchsub_sgst_tax_per1':element.cgst_tax_per,
      });

  
    });


    if(this.freight_type == 2){

      this.freightTypeTotal =[];
      soldData.freightList.forEach((element, i) => {
  
        this.freightTypeTotal.push({ total_freight_type_id: element.freight_type_id, total_freight_type_name: element.freight_type_name, total_freight_type_ledget_id: element.freight_type_ledger_id,total_freight_type_amount:element.freight_type_amount,total_freight_type_cur_id:element.freight_type_cur_id,total_freight_type_ex_rate:element.freight_type_ex_rate,total_freight_type_tax_id:element.freight_type_tax_id,total_freight_type_tax:0,total_freight_type_net_total:0,total_freight_type_rate:0,
          total_freight_type_tax_no:element.freight_type_tax_no,total_freight_type_invoice_no:element.freight_type_invoice_no })
        this.searchLedger(element.ledger_name);
        this.updateTotalFreightTax(this.freightTypeTotal[i].total_freight_type_tax_id,i);
       this.freightBillwise(this.freightTypeTotal);
       $('#closeModalFreight').click();
      });
      //this.freightBillwise(this.freightTypeTotal);

    }
    this.editData = [];
    this.purchData = {
      sales_id: null,
      sales_inv_no: null,
      error: null,
      message: null,
      data: null,
      inactive: null,
      items: null
    };
  
    this.excludeTax = soldData.purch_is_include_tax;
    setTimeout(() => {
      this.updateItemDiscount();
    }, 1000);
  
    $('.puchase-list-table').show();
    $('.save').show();
    $('.box').show();
    this.changeBool();
    this.refreshAll();
    $('.puchase-list-table').show();
  
    var scrollBottom = $('.table-responsive').height();
    $('.scroll-table').scrollTop(300);
    $('#closepurchedit').click();
  
    this.is_edit = 'false';
  
  
  
  }



  purchaseUpdate(formdata: { value: any; }) {

    this.printError = [];

    if (formdata.value.purch_date == undefined) {
      this.errObjArr.dtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (formdata.value.purch_date > new Date()) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.dtErr = "f";
      }
    }

    if (formdata.value.purch_round_sign == undefined || formdata.value.purch_round_sign == '') {
      this.errObjArr.purch_round_sign = "t";
      this.printError.push({ 'purch_round_sign': 'Required' });
    } else {
      this.errObjArr.purch_round_sign = "f";
    }

    if (formdata.value.purch_roundoff == undefined || formdata.value.purch_roundoff == '') {
      this.errObjArr.purch_roundoff = "t";
      this.printError.push({ 'purch_roundoff': 'Required' });
    } else {
      this.errObjArr.purch_roundoff = "f";
    }


    if (!formdata.value.purch_frieght_ledger_id && this.subtotalfrieght > 0) {
      this.frieghtledid = 1;
      this.purchpageLoadingImg = false;
    } else {
      this.frieghtledid = 0;
      //  this.purchpageLoadingImg = true;
    }

    // if (this.freight_charges_on_total) {
      formdata.value.purch_frieght = this.subtotalfrieght;
    // }



    if (this.printError.length <= 0 ) {
   
      this.copy_sup_invoice_no = this.sup_invoice_no.find(x => x.spplier_invoice_number == this.purch_inv_no);
      
    if( this.copy_sup_invoice_no){
      this.supp_invoice_array = this.sup_invoice_no.filter((invoice_no) => invoice_no.spplier_invoice_number == this.purch_inv_no).map(x => x.purch_id);
      var remopstk =   confirm("Invoice number already exists for this supplier, Ref. No: " + this.supp_invoice_array.join(", "));
 
     }

      if(!this.copy_sup_invoice_no||remopstk){

      this.purchpageLoadingImg = true;

      formdata.value.purch_id = this.purchaseno;
      formdata.value.items = this.items;
      formdata.value.purch_date = (formdata.value.purch_date) ?
        this.apiService.formatDate(formdata.value.purch_date) : formdata.value.purch_date;
      formdata.value.purch_inv_date = (formdata.value.purch_inv_date) ?
        this.apiService.formatDate(formdata.value.purch_inv_date) : formdata.value.purch_inv_date;
      formdata.value.purch_rebate_amounts = this.totalRebateAmount;
        // Code for GST
        if(this.cmp_tax==2){
          formdata.value.purch_cgst_tax = this.purch_cgst_tax ;
          formdata.value.purch_sgst_tax = this.purch_sgst_tax ;

          if((this.cmp_tax==2)&&(this.igst)){

            formdata.value.purch_igst_tax=this.purch_igst_tax;
            formdata.value.purch_is_igst=1;
          }       
        }

      this.apiService.updatePurchase(formdata.value).subscribe((res: Stockedit) => {
        this.purchpageLoadingImg = false;

        if (res.error != null) {
          this.resultobj = res.error;
          this.resultobj = this.coreService.getValidation(this.resultobj);
        }
        else {



          // this.coreService.showMessage(res.message);
          this.coreService.createfunct(formdata, this.resultobj, res.message);

          this.previewData = res.preview;
          this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
          this.itemcode_show = this.previewData['items'].filter(x => x.prd_code);

          setTimeout(function () { $("#previewButton").click(); }, 2000);
          this.resultobj = {};
          $('.puchase-list-table').hide();
          this.items = [];
          this.refreshAll();
          this.getPurchase();
          this.subtotalfrieght = 0;
          this.subTotalFrieghtTax = 0;
          this.purchFreightTotal =0;
          this.totalRebateAmount = 0;
          this.totpurchprice = 0;
          this.totpriceVat = 0;
          this.purch_amount = 0;
          this.purch_tax = 0;
          this.purch_cgst_tax = 0;
          this.purch_sgst_tax = 0;
          this.purch_igst_tax = 0;
          this.igst=false;
          this.purchasenumber=null;
          this.date = new Date();
          this.serializedDate = new FormControl((new Date()).toISOString());
          this.changeBool();
          this.discount = 0;
          this.is_edit = 'true';
          this.purchType = 1;
          this.update = false;
          this.purch_id_no="";
          setTimeout(() => {
            this.round_sign = 1;
            this.roundoff = '0';
          }, 3000);


        }

      });
    }
    }
  }

  closePreview() {
    this.supplierSelect.focus();
    this.qtn_id = this.ActiveRoute.snapshot.paramMap.get('id');
    if (this.qtn_id)
      this.route.navigate(['/purchase/purchase']);
  }


  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {

        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }




  importQtn(qtn_id) {

    
   
    this.productSearchLoader = true;
    const searchval = new FormData();
    searchval.append('pq_no', qtn_id);
    this.apiService.importPurchaseQtn(searchval).subscribe((soldData) => {
      // if (soldData.error != null) {

      if (soldData.cash_account) {
        this.defLedger = soldData.cash_account;
      } else {
        this.defLedger = this.coreService.getUserData('cash_ledger');
      }
      //   this.update = true;
      //   if (soldData.fright_ledger) {
      //     this.searchLedger(soldData.fright_ledger.ledger_name);
      //     this.purch_frieght_ledger_id = soldData.fright_ledger.ledger_id;

      //   }

      //   this.purch_inv_no = soldData.purch_inv_no;



      this.supp_id = (soldData.supplier) ? Number(soldData.supplier.supp_id) : null;
      if(this.supp_id){
        this. getSupBalance(this.supp_id, null);
      }
      //   this.purchaseno = soldData.purch_id;

      //   // this.supp_id = soldData.purch_supp_id;
      //   this.subtotalfrieght = soldData.purch_frieght;
      this.purch_note = this.pord_ref_no = soldData.purch_note;


      if (soldData.purch_acc_ledger_id)
        this.purch_acc_ledger_id = soldData.purch_acc_ledger_id;
      else
        this.purch_acc_ledger_id = this.coreService.getUserData('usr_cash_ledger_id');

      this.purch_type = soldData.purch_type;
      //   this.datevar = this.datePipe.transform(soldData.purch_date, 'dd/MM/yyyy');
      //   var to = this.datevar.split("/");
      //   this.purchasedate = new Date(to[2], to[1] - 1, to[0]);
      //   if (soldData.purch_inv_date) {
      //     this.dateinvvar = this.datePipe.transform(soldData.purch_inv_date, 'dd/MM/yyyy');
      //     this.dateinfo = this.dateinvvar.split("/");
      //     this.purch_inv_date = new Date(this.dateinfo[2], this.dateinfo[1] - 1, this.dateinfo[0]);

      //   }


      this.subtotalfrieght = 0;
      this.subTotalFrieghtTax = 0;
      this.totalRebateAmount = 0;
      this.totpurchprice = soldData.purch_amount;
      this.purch_tax = soldData.purch_tax;

      if(this.cmp_tax==2){
        this.purch_cgst_tax = soldData.pq_cgst;
        this.purch_sgst_tax = soldData.pq_sgst;
        this.purch_igst_tax = soldData.pq_igst;

        if(this.supp_id){
          this.cust_state_id = soldData.supplier.supp_state_id;
          this.iGSTchecking();
        }
      }
      // this.purch_pay_type = (soldData.purch_pay_type == 1) ? 1 : 2;
      this.purch_pay_type = soldData.purch_pay_type;
      this.discount = soldData.purch_discount;
      setTimeout(() => {
        this.round_sign = (soldData.purch_roundoff && soldData.purch_roundoff < 0) ? -1 : 1;
        this.roundoff = '' + Math.abs(soldData.purch_roundoff);
      }, 3000);
      this.items = [];
      //   this.purch_inv_no = soldData.purch_inv_no;
      soldData.items.forEach((element, index) => {
        this.items.push({
          //       "sl_no": index + 1,
          'purchsub_prd_id': element.purchsub_prd_id,
          'prd_name': element.prd_name,
          //       'ean': null,
          'purchsub_rebate_cost_effect':0,
          'purchsub_rebate_amount':0,
          'boxqty':1,
          'purchsub_stock_id': element.purchsub_stock_id,
          'purchsub_qty': element.purchsub_qty,
          'purchsub_rate': element.purchsub_rate,
          'purchsub_tax': element.purchsub_tax,
          'purchsub_tax_per': element.purchsub_tax_per,
          'purchsub_cgst_tax_per': element.cgst_tax_per,
          'purchsub_sgst_tax_per': element.sgst_tax_per,
          'purchsub_price': element.purchsub_qty * element.purchsub_rate,
          'purchpricesingle': element.purchsub_rate,
          'purchsub_gd_id': element.purchsub_gd_id,
          //       'freight': element.purchsub_frieght,
          //       'expiry_date': element.purchsub_expiry,

          'purchsub_currency_id': element.pqsub_currency_id,
           'purchsub_exchange_rate': element.pqsub_exchange_rate,
          'manufacture_date': null,
          'batch_code': null,
          'purchaserateperbox': element.purchsub_rate,
          'purch_price': element.purchsub_qty * element.purchsub_rate,
          'purch_item_total_without_item_disc': element.purchsub_rate,
          'boxname': 'nil',
          'taxvalperqty': element.purchsub_tax / element.purchsub_qty,
          'taxpercentage': element.purchsub_tax_per,
          'boxdiplayname': element.unit_display,
          'boxdisplaybaseqty': element.purchsub_qty,
          'prd_base_unit_name': element.baseunit.unit_code,
          'purchsub_unit_id': element.baseunit.unit_id,
          //'boxqty': element.boxqty,
          'purchsub_frieght': 0,
          'freight': element.freight,
          'setfrightforTotqty': 0,
          'prd_barcode': element.prd_barcode,
          // 'prd_tax_cat_id': element.purchsub_taxcat_id,
          'item_desc': element.purchsub_item_desc,
          'price_vat_ttl': ((element.purchsub_qty * element.purchsub_rate) + element.purchsub_tax)
        });

      });
     
      
      //   this.editData = [];
      // this.purchData = {
      //   sales_id: null,
      //   sales_inv_no: null,
      //   error: null,
      //   message: null,
      //   data: null,
      //   inactive: null,
      //   items: null
      // };

      // $('.puchase-list-table').show();
      // $('.save').show();
      $('.box').show();
      this.changeBool();
      // this.refreshAll();
      this.getPurchase();

      $('.puchase-list-table').show();

      var scrollBottom = $('.table-responsive').height();
      $('.scroll-table').scrollTop(300);
      //   $('#closepurchedit').click();

      //   this.is_edit = 'false';
      this.productSearchLoader = false;


      // }
    });
  }
  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = 'createSup';
    if (this.userType == 'ADMIN') {
      this.customer_dt.usr_type = 1;
    } else {
      this.customer_dt.usr_type = 0;
    }

    this.apiService[callFunction](this.customer_dt).subscribe((res) => {

      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.coreService.showMessage(res.message);
        this.resultobj = {};
        this.modalRef.close();
        this.clearForm();
      }
    });
  }
  clearForm() {
    this.customer_dt = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email: '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      brch_id:0,
      usr_type:0,
      supp_state_id:null,
      is_supplier : false,
      op_bal_type: true,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
  }
  getSupBalance(sup_id, $exchngeRate = null) {
    this.showBal = false;
    this.accLoading = false;
    if (sup_id) {
      this.accLoading = true;
      this.apiService.getSupBalance({ sup_id: sup_id }).subscribe((res) => {
        this.accLoading = false;
        this.sup_balance = res.data.bal_without_sign;
        this.sup_bal_sign = res.data.bal_sign;
        this.sup_tin = res.data.supp_tin;
        this.supp_default_currency_id = res.data.default_currency_id;
        if (this.supp_default_currency_id) {
          this.selcurrency = this.currencies.find(x => x.cur_id == this.supp_default_currency_id);
          if ($exchngeRate == null) {
            this.exchange_rate = this.selcurrency.cur_exchange_rate;
          } else {
            this.exchange_rate = $exchngeRate;
          }
        } else {
          this.selcurrency = null;
          this.exchange_rate = 1;
        }
        this.showBal = true;
      });

      if(this.cmp_tax==2){
        this.apiService.getSupplierDetails({ supp_id: sup_id }).subscribe((res) => {
          this.supp_details=res.data;
         
          if(this.supp_details){
            this.cust_state_id = this.supp_details.supp_state_id;
            this.iGSTchecking();
          }
          });
          
          
          
        }
     
      let searchval = new FormData();
      if(this.purchasenumber){
        searchval.append('purchaseno', this.purchasenumber);
      }
       searchval.append('sup_id', sup_id);
      this.apiService.getSupInvoice(searchval).subscribe((res) => {
        this.sup_invoice_no = res.data;
      });

    }

  }

  iGSTchecking(){

        
    if(this.cmp_tax==2){
  
      // for is igst verify
      if((this.cust_state_id!=this.branch_state)&&(this.cust_state_id)&&(this.supp_id)){
        this.igst=true;
      }else{
        this.igst=false;
      }
      console.log(this.igst);
    
      
     
      }
  }
  showMrpUpdate(){
    if(this.selecTed.stock_unit_rate && this.selecTed.stock_unit_rate.length > 0){
      this.updateMrp = true;
    } else {
      this.coreService.showMessage('No stock unit available', 2000);
    }
  }
  hideMrpUpdate() {
    this.updateMrp = false;
  }

  showCalculateMdl(id: number) {

    this.bind_sp = '';
    this.bind_sp_tax = '';
    this.bind_sp_inc_tax = '';

    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#mrp_id").val(id);
    $("#calculateMdl").show();
  }

  closeCalculateMdl() {
    this.bind_sp = '';
    this.bind_sp_tax = '';
    this.bind_sp_inc_tax = '';

    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#sp_tax").val('');
    $("#calculateMdl").hide();
  }
  calculateTax(keyword) {
    if (!isNaN(keyword)) {
      let t = keyword - ((keyword * 100) / (this.selecTed.prd_tax + 100));
      let p = keyword - t;
      $("#sp").val(p);
      $("#sp_tax").val(t);
      this.bind_sp = p;
      this.bind_sp_tax = t;
    } else {
      $("#sp").val('');
      $("#sp_tax").val('');
    }
  }
  showCalculatedTax() {
    let mrp_id = $("#mrp_id").val();
    let sp = $("#sp").val();
    $(".stk_mrp_" + mrp_id).val(sp);
    $("#calculateMdl").hide();
  }

  updateStock() {
   
    this.pageLoadingImgCalc = true;
    var upt_unit_rates = [];
    var units_rates = [];
    var errorobj = false;
    $('input[name="unitratechk"]:checked').each(function () {
      upt_unit_rates.push({ "unit_id": $(this).val() });
    });


    $('input.edit_unit_rate').each(function (index1) {
      var attr_id = this.getAttribute("id");
      var unit_rt = $(this).val();
      var unit_rt2 = $('input.edit_unit_rate2').eq(index1).val();

      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          if (Number(unit_rt) <= 0) {
            errorobj = true;
          }
          units_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt, 'unit_rate2': unit_rt2 });
        }
      });

    });
    var info = {
      prd_id: this.selecTed.prd_id,
      branch_ids: this.branchselids,
      stock_id: this.selecTed.cmp_stock_id,
      unit_rates: units_rates
    }
    //form.value.stkprdsel=undefined;
    // form.value.unit_rates = units_rates;
    if (errorobj) {
      $('#error_in').html('Please enter  value greater than zero');
      this.pageLoadingImgCalc = false;
    }
    else {
      $('#error_in').html('');

      this.apiService.updateStockMultiBranch(info).subscribe((res: Stockedit) => {
        this.pageLoadingImgCalc = false;
        if (res.error != null) {
          $('#error_in').html(res.error);
        } else {
          this.updateMrp = false;
          this.resultobj = {};
          units_rates.forEach((element, index) => {
            this.selecTed.units.find(x => x.unit_id == element.unit_id).mrp = element.unit_rate;
          });

          if (typeof this.stkunitsel === 'string' || this.stkunitsel instanceof String) {
            this.selecTed.mrp = this.selecTed.units.find(x => x.unit_base_qty === 1).mrp;
          } else {
            this.purchaseBox(this.stkunitsel);
          }
          this.coreService.showMessage('Updated', 2000);

        }

      });
    }
  }

  getLastPurchaseOfProd(prd_id) {
    this.lastPurchs = [];
    this.loadingImgLastPurch = true;
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    searchval.append("limit", "3");
    this.apiService.getLastPurchaseOfProd(searchval).subscribe((res) => {
      this.lastPurchs = res['data'];
      this.loadingImgLastPurch = false;
    });
  }

  updateItemDiscount() {
    let gttl = 0;
    this.items.forEach((element, i) => {
      gttl += Number(this.items[i].purch_item_total_without_item_disc * this.items[i].purchsub_qty);
    });
    // let gttl = this.totpurchprice;
    let InpbillDisc = this.discount || 0;
    this.items.forEach((element, i) => {
      if (this.excludeTax == 1) {
        let grnd_totl = this.items[i].purch_item_total_without_item_disc;
        let billDiscPrec = grnd_totl * this.items[i].purchsub_qty * 100 / gttl;
        let billDiscountAmt = (InpbillDisc / 100 * billDiscPrec) / this.items[i].purchsub_qty;
        if (billDiscountAmt >= 0) {
          // this.items[i].purchaserateperbox = (((grnd_totl  - billDiscountAmt) * 100) / (this.items[i].taxpercentage + 100));	
          // this.items[i].purchsub_disc = grnd_totl - billDiscountAmt - this.items[i].purchaserateperbox;	
          this.items[i].purchaserateperbox = grnd_totl - billDiscountAmt;
          this.items[i].purchsub_disc = billDiscountAmt;
        }
      } else {
        this.items[i].purchaserateperbox = this.items[i].purch_item_total_without_item_disc;
        // this.items[i].purchaserateperbox = this.items[i].purchaserateperbox;	
        this.items[i].purchsub_disc = 0;
      }
      this.items[i].purchsub_rate = this.items[i].purchaserateperbox;
      this.items[i].purchsub_price = this.items[i].purchaserateperbox * this.items[i].purchsub_qty;
      this.items[i].purch_price = this.items[i].purchaserateperbox * this.items[i].purchsub_qty;
      // this.items[i].purch_price = this.items[i].purch_price;	
      this.items[i].taxvalperqty = this.items[i].purchaserateperbox * this.items[i].taxpercentage / 100;
      this.items[i].purchsub_tax = this.items[i].taxvalperqty * this.items[i].purchsub_qty;
      // this.items[i].purchsub_tax = this.items[i].purchsub_tax;

     
      this.items[i].purchsub_cgst_tax_per = this.items[i].purchsub_cgst_tax_per;
      this.items[i].purchsub_sgst_tax_per = this.items[i].purchsub_sgst_tax_per;
      this.items[i].price_vat_ttl = this.items[i].purch_price + this.items[i].purchsub_tax;
    });
    this.items = JSON.parse(JSON.stringify(this.items));

    this.updateTotalAmount();
  }



  updateTotalAmount() {
    this.totpurchprice = 0;
    this.totpriceVat = 0;
    this.purch_tax = 0;
    this.purch_cgst_tax = 0;
    this.purch_sgst_tax = 0;
    this.purch_igst_tax = 0;
    this.subtotalfrieght = 0;
   
    if (!this.purchsub_rebate) {
      this.totalRebateAmount = 0;
    }
    else {

      this.totalRebateAmount = Number(this.totalRebateAmount);
    }
    
    for (var i = 0; i < this.items.length; i++) {
      this.totpurchprice = this.totpurchprice + Number(this.items[i]['purch_price']);
      this.totpriceVat = this.totpriceVat + Number(this.items[i]['price_vat_ttl']);
      this.purch_tax = this.purch_tax + Number(this.items[i]['purchsub_tax']);
      if(this.cmp_tax==2){
        // code to identify zerorated or not
        let zerotax =this.items[i]['prd_tax_cat_id']==3?0:1;
        this.purch_cgst_tax = this.purch_cgst_tax + Number((this.items[i]['purchsub_price']/100)*this.items[i]['purchsub_cgst_tax_per'])*zerotax;
        this.purch_sgst_tax = this.purch_sgst_tax + Number((this.items[i]['purchsub_price']/100)*this.items[i]['purchsub_sgst_tax_per'])*zerotax;
        this.purch_igst_tax =this.purch_tax;
      }

      if (!this.purchsub_rebate) {
        this.totalRebateAmount +=  Number(this.items[i]['purchsub_rebate_amount']) * Number(this.items[i].boxqty);
      }
     

    }


  }

  updateFreightAmounts() {
   
    if (this.total_freight_total_rate == 0) {
      this.subtotalfrieght = 0;
      this.subTotalFrieghtTax = 0;

    }
    else {
      this.subtotalfrieght = Number(this.subtotalfrieght);
      this.subTotalFrieghtTax = Number(this.subTotalFrieghtTax);

    }

  
   
    let purch_sub_ttl = 0;
    let purch_Freight_sub_ttl =0;
    for (var i = 0; i < this.items.length; i++) {
      purch_sub_ttl = purch_sub_ttl + Number(this.items[i]['price_vat_ttl']);
      purch_Freight_sub_ttl = purch_Freight_sub_ttl + Number(this.items[i]['freight']);
    }


    for (var i = 0; i < this.items.length; i++) {
     

      if (this.total_freight_total_rate == 0) {
        this.subtotalfrieght = this.subtotalfrieght + Number(this.items[i]['freight']);
        this.subTotalFrieghtTax = this.subTotalFrieghtTax + Number(this.items[i]['freight_tax']);


      } else {
        this.items[i]['freight'] = (Number(this.items[i]['purchsub_price']) / purch_sub_ttl) * this.subtotalfrieght;
        this.items[i]['purchsub_frieght'] = this.items[i]['freight'];
       
        this.items[i]['freight_tax'] = (Number(this.items[i]['freight']) / purch_Freight_sub_ttl) * this.subTotalFrieghtTax;
      }

    }


  }
  langChange() {
    this.translate.get(['Common.standard_rated_domestic', 'Common.zero_rated_intrnl', 'Common.exempt_purchase', 'Common.imports_vat_paid_custms', 'Common.imports_vat_rvs_chage_mechnsm', 'HOME.credit', 'Common.cash', 'HOME.Bank']).subscribe((res: string) => {

      this.purchase_types = [
        { id: 1, name: res['Common.standard_rated_domestic'] },
        { id: 2, name: res['Common.zero_rated_intrnl'] },
        // { id: 3, name: res['Common.exempt_purchase'] },
        // { id: 4, name: res['Common.imports_vat_paid_custms'] },
        // { id: 5, name: res['Common.imports_vat_rvs_chage_mechnsm'] },
      ];

      this.purchasePayType = [
        { id: 1, name: res['HOME.credit'] },
        { id: 2, name: res['Common.cash'] },
        { id: 3, name: res['HOME.Bank'] }

      ];
      this.freight_types =[
        { id: 0, name: 'None' },
        { id: 1, name: 'Item Wise' },
        { id: 2, name: 'Bill Wise' },
      ];
    });


  }

  getAllState(){
    let searchval = new FormData();
    this.apiService.getAllState(searchval).subscribe((res) => {
      this.states = res.data;
      
    });
  }
  getExistingBatchSellRate(){
    // this.searchExisting = true;
    // this.selBatch
    // get_batch_sell_rate

    this.loadingBatchSellRate = true;
    var batch_id = (this.searchExisting && this.selBatch && this.selBatch.sb_id) ? this.selBatch.sb_id : 0;
    this.apiService.getBatchSellRate({ batch_id: batch_id, prd_id: this.selecTed.prd_id, branch_stock_id: this.selecTed.branch_stock_id }).subscribe((resp) => {
      var unitRates = resp.data;
      // this.taxListCategories = resp.data;
      // this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
      this.batchSellRate.forEach((element, i) => {
        this.batchSellRate[i].rate1 = unitRates[element.unit_id].sur_unit_rate
        this.batchSellRate[i].rate2 = unitRates[element.unit_id].sur_unit_rate2
      });

    });
    this.loadingBatchSellRate = false;

  }

  updateFreightTax(taxCatId,i) {

    if(this.freightTypes[i].freight_type_amount_temp == ""){
      this.freightTypes[i].freight_type_amount_temp ==0;
    }

    if(this.freightTypes[i].freight_type_amount_temp >= 0 && this.freightTypes[i].freight_type_ex_rate > 0){

      if (this.setforTotqty && this.freight_type == 1) {
        this.freightTypes[i].freight_type_amount = this.freightTypes[i].freight_type_amount_temp;
  
        
      }
      else{
  
        if(this.setforTotqty == undefined && this.freight_type == 1) {
       
          // this.freightTypes[i].freight_type_amount =0;
          this.freightTypes[i].freight_type_amount = this.freightTypes[i].freight_type_amount_temp * this.purchsub_qty;
          
         
        }
        else{
          if(this.setforTotqty == undefined && this.freight_type == 2) {
             this.freightTypes[i].freight_type_amount = this.freightTypes[i].freight_type_amount_temp;
            
          }
          else{
            this.freightTypes[i].freight_type_amount = this.freightTypes[i].freight_type_amount_temp * this.purchsub_qty;
           
  
          }
  
        }
  
       
  
      }
      
    
  
     
  
      if(taxCatId != 3){
        this.freightTaxCatStatus =true;
      }
      // else{
      //   this.freightTaxCatStatus =false;
      // }
      
      this.freight_total_tax -=  this.freightTypes[i].freight_type_tax;
      this.freight_total_net_amount -= this.freightTypes[i].freight_type_net_total;
      this.freight_total_rate -= this.freightTypes[i].freight_type_rate;
  
     
      const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
      this.freightTaxPercentage = selTax[0].taxcat_tax_per;
  
  
      var freightExchgRate = (this.freightTypes[i].freight_type_ex_rate) ? this.freightTypes[i].freight_type_ex_rate : 1;
  
   
       this.Freightbaserate = this.freightTypes[i].freight_type_amount * freightExchgRate;
      //this.Freightbaserate = this.freightTypes[i].freight_type_amount ;
      this.singlerate = this.baserate / (this.purchaseqty * this.unit_qty_inp);
      
  
  
      this.freightTaxamount = (this.Freightbaserate * this.freightTaxPercentage) / 100;
      this.freightTypes[i].freight_type_tax =this.freightTaxamount;
      //this.freightTypes[i].freight_type_net_total =Number(this.freightTypes[i].freight_type_amount) + Number(this.freightTypes[i].freight_type_tax);
      this.freightTypes[i].freight_type_net_total =Number(this.Freightbaserate) + Number(this.freightTypes[i].freight_type_tax);
      this.freightTypes[i].freight_type_rate =Number(this.Freightbaserate);
      this.freight_total_tax +=  this.freightTypes[i].freight_type_tax;
      this.freight_total_net_amount += this.freightTypes[i].freight_type_net_total;
      this.freight_total_rate += this.freightTypes[i].freight_type_rate;
  
      this.totalfrieghtproduct = this.freight_total_net_amount;
  
      // for (var k = 0; i < this.items.length; k++) {
      
        
      //     this.subtotalfrieght = this.subtotalfrieght + Number(this.items[i]['freight']);
  
      //   this.purchFreightTotal = this.purchFreightTotal + Number(this.items[i]['this.freight_total_tax']);
  
        
  
      // }
      
    }

  }

  updateFreightAmount(data){

    data.forEach((element, i) => {
      
      this.updateFreightTax(element.freight_type_tax_id,i);
    
  });
  }
 
  updateTotalFreightTax(taxCatId,i){



    if(this.freightTypeTotal[i].total_freight_type_amount == ""){
      this.freightTypeTotal[i].total_freight_type_amount ==0;
    }
    

   
    if(taxCatId != 3){
      this.totalFreightTaxCatStatus =true;
    }
    // else{
    //   this.totalFreightTaxCatStatus =false;
    // }
  

     if(this.freightTypeTotal[i].total_freight_type_amount >= 0 && this.freightTypeTotal[i].total_freight_type_ex_rate > 0){

      this.total_freight_total_tax -=  Number(this.freightTypeTotal[i].total_freight_type_tax);
      this.total_freight_total_net_amount -= Number(this.freightTypeTotal[i].total_freight_type_net_total);
      this.total_freight_total_rate -= Number(this.freightTypeTotal[i].total_freight_type_rate);
  
      
  
    
      const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
      this.totalfreightTaxPercentage = selTax[0].taxcat_tax_per;
  
     
      var totalfreightExchgRate = (this.freightTypeTotal[i].total_freight_type_ex_rate) ? this.freightTypeTotal[i].total_freight_type_ex_rate : 1;
  
  
       this.totalFreightbaserate = this.freightTypeTotal[i].total_freight_type_amount * totalfreightExchgRate;
      //this.totalFreightbaserate = this.freightTypeTotal[i].freight_type_amount ;
     // this.singlerate = this.baserate / (this.purchaseqty * this.unit_qty_inp);
      
    
  
      this.totalfreightTaxamount = (Number(this.totalFreightbaserate) * Number(this.totalfreightTaxPercentage)) / 100;
      this.freightTypeTotal[i].total_freight_type_tax =this.totalfreightTaxamount;
      //this.freightTypeTotal[i].freight_type_net_total =Number(this.freightTypes[i].freight_type_amount) + Number(this.freightTypeTotal[i].freight_type_tax);
      this.freightTypeTotal[i].total_freight_type_net_total =Number(this.totalFreightbaserate) + Number(this.freightTypeTotal[i].total_freight_type_tax);
      this.freightTypeTotal[i].total_freight_type_rate =Number(this.totalFreightbaserate);
      this.total_freight_total_tax += Number(this.freightTypeTotal[i].total_freight_type_tax);
      this.total_freight_total_net_amount += Number(this.freightTypeTotal[i].total_freight_type_net_total);
      this.total_freight_total_rate += Number(this.freightTypeTotal[i].total_freight_type_rate);
  
     // this.totalfrieghtproduct = this.freight_total_net_amount;
    
    }
  
    
  }

  freightBillwise(data){

   
  
  if(this.vaidateForm()){
  
    // if (this.total_freight_total_rate == 0) {
    //   this.subtotalfrieght = 0;
    //   this.subTotalFrieghtTax = 0;


    // } else {
      this.subtotalfrieght = Number(this.total_freight_total_rate);
      this.subTotalFrieghtTax = Number(this.total_freight_total_tax);

    // }

  
   
    let purch_sub_ttl = 0;
    let purch_Freight_sub_ttl = 0;
    for (var i = 0; i < this.items.length; i++) {
      purch_sub_ttl = purch_sub_ttl + Number(this.items[i]['purchsub_price']);
      purch_Freight_sub_ttl = purch_Freight_sub_ttl + Number(this.items[i]['freight']);

    }


    for (var i = 0; i < this.items.length; i++) {
    
      // if (this.total_freight_total_rate == 0) {
      //   this.subtotalfrieght = this.subtotalfrieght + Number(this.items[i]['freight']);

      //   this.subTotalFrieghtTax = this.subTotalFrieghtTax + Number(this.items[i]['freight_tax']);

      

      // } else {

       
        this.items[i]['freight'] = (Number(this.items[i]['purchsub_price']) / purch_sub_ttl) * this.subtotalfrieght;
        this.items[i]['purchsub_frieght'] = this.items[i]['freight'];

        this.items[i]['freight_tax'] = (Number(this.items[i]['freight']) / purch_Freight_sub_ttl) * this.subTotalFrieghtTax;

      
      // }
     


  

      this.purchFreightTotal = this.purchFreightTotal + Number(this.items[i]['this.freight_total_tax']);

      

    }

    this.items.forEach(element => {

    
      element.freight_data.forEach(element1 => {

        data.forEach((element2, i) => {

          if(element2.total_freight_type_id == element1.freight_type_id)
          {
            if(element2.total_freight_type_cur_id == 0){
              
              element1.freight_type_amount =(element2.total_freight_type_amount /this.subtotalfrieght) * element.freight;
              element1.freight_type_rate =  element1.freight_type_amount;

              // element1.freight_type_tax =  element1.freight_type_tax;
              // element1.freight_type_net_total =  element1.freight_type_net_total;

              //element1.freight_type_amount = element2.total_freight_type_amount;
           
            }
            else{
              element1.freight_type_amount =((element2.total_freight_type_amount /this.subtotalfrieght) * element.freight) /element2.total_freight_type_ex_rate;
              element1.freight_type_rate =  element1.freight_type_amount * element2.total_freight_type_ex_rate;

              // element1.freight_type_tax =  element1.freight_type_tax;
              // element1.freight_type_net_total =  element1.freight_type_net_total;

            }
            element1.freight_type_tax_id =element2.total_freight_type_tax_id;
            element1.freight_type_ex_rate =element2.total_freight_type_ex_rate;
            
            
            const selTax1 = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === element1.freight_type_tax_id);
            this.totalsubfreightTaxPercentage = selTax1[0].taxcat_tax_per;

            var freightExchgRate = (element1.freight_type_ex_rate) ? element1.freight_type_ex_rate : 1;
            this.totalsubFreightbaserate = element1.freight_type_amount * freightExchgRate;

            this.totalsubfreightTaxamount = (this.totalsubFreightbaserate * this.totalsubfreightTaxPercentage) / 100;
            element1.freight_type_tax =this.totalsubfreightTaxamount;
            element1.freight_type_net_total =element1.freight_type_rate + element1.freight_type_tax;

          




            element1.freight_type_cur_id =element2.total_freight_type_cur_id;
           
            element1.freight_type_ledget_id =element2.total_freight_type_ledget_id;
            element1.freight_type_name =element2.total_freight_type_name;
         
            element1.freight_type_invoice_no =element2.total_freight_type_invoice_no;
            element1.freight_type_tax_no =element2.total_freight_type_tax_no;
            element1.freight_type_amount_temp =element1.freight_type_amount;
            // element1.freight_type_rate =  element1.freight_type_amount;
          

          

           // this.updateFreightTax(this.freightTypes[i][element1.freight_type_tax_id],i);
           //this.updateFreightTax(this.freightTypes[i].freight_type_tax_id,i);
          }

        //  this.updateFreightTax(this.freightTypes[i].freight_type_tax_id,i);
        });

      });


    });
   

    $('#closeTest').click();
    // this.totalFreightTaxCatStatus =false;
   

   //this.getFreight(1);
   
  }
   




  }

  vaidateForm(){

    this.valErrors = {};
    let retVal = true;
  
   
   
    this.freightTypeTotal.forEach((element, i) => {
      
        if(element.total_freight_type_ledget_id == undefined && element.total_freight_type_amount > 0){
          this.valErrors['total_freight_type_ledget_id_' + i] = {msg : 'Required'}
          retVal =  false;

        

        }
        if((element.total_freight_type_ex_rate <= 0 )){
       
          this.valErrors['total_freight_type_ex_rate_' + i] = {msg : 'Required'}
          retVal =  false;
        }


        if(element.total_freight_type_tax_id != 3)
        {
         if(element.total_freight_type_tax_no == "" ||element.total_freight_type_tax_no == undefined) 
         {

          this.valErrors['total_freight_type_tax_no_' + i] = {msg : 'Required'}
          retVal =  false;

         }

         if(element.total_freight_type_invoice_no == "" ||element.total_freight_type_invoice_no == undefined) 
         {

          this.valErrors['total_freight_type_invoice_no_' + i] = {msg : 'Required'}
          retVal =  false;

         }

        }
    
      
    });
   
    
    return retVal;

   


  }

  clearFreightBillwise(){
    this.getFreight(1);
    this.total_freight_total_rate = 0;
    this.total_freight_total_tax = 0;
    this.total_freight_total_net_amount =0;
  }

  changeFrightType(){
   
    if(this.freight_type == 1){
    
     
      this.freightTypes =[];
      this.items.forEach((element, i) => {
        element.freight =0;
        element.purchsub_frieght =0;
        element.freight_tax =0;
         element.freight_data=[];
      });

      this.subtotalfrieght = 0;
      this.subTotalFrieghtTax = 0;
      this.total_freight_total_rate =0;

        
    }
    if(this.freight_type == 2)
    {

  
      this.clearFreightBillwise();
      this.items.forEach((element, i) => {
        element.freight =0;
        element.purchsub_frieght =0;
        element.freight_tax =0;
       // element.freight_data=[];
      });

      this.subtotalfrieght = 0;
      this.subTotalFrieghtTax = 0;
      this.total_freight_total_rate =0;
    }

    if(this.freight_type == 0)
    {

      this.clearFreightBillwise();
      this.freightTypes =[];
      this.items.forEach((element, i) => {
        element.freight =0;
        element.purchsub_frieght =0;
        element.freight_tax =0;
        element.freight_data=[];
      });
      

      

      this.subtotalfrieght = 0;
      this.subTotalFrieghtTax = 0;
      this.total_freight_total_rate =0;
    }
  }

}
