
import { Component, OnInit, ɵConsole } from '@angular/core';
import { Catagory } from 'src/app/model/catagory.model';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-branch-stock-receipt',
  templateUrl: './branch-stock-receipt.component.html',
  styleUrls: ['./branch-stock-receipt.component.css']
})
export class BranchStockReceiptComponent implements OnInit {
  received_date: any;
  cat_name: any;
  cat_remarks: any;
  cat_pos: any;
  allcat: any;
  showrate:any;

  resultobj: any = {};
  selectedData: any = { cat_id: null, error: null, cat_name: null, message: null, cat_pos: null };
  result = '';
  catagories: string[];
  previewData = [];
  formula_list = [];
  accepted_list = [];
  rejected_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  from: any;
  rlastpage: any;
  rcurpage: any;
  rpgend: boolean;
  rpgstart: boolean;
  rfrom: any;
  acurpage: any;
  alastpage: any;
  afrom: any;
  apgstart: boolean;
  apgend: boolean;
  accept: any;
  godowns: any;
  option: any;
  gd_id: any;
  is_purch_rate: number;
  country_dec: string;
  base_url: any;
  img_url: any;
  sales_settings: any;
  cmp_tax: any;
  header_type: any;
  logoImg: any;
  logoImgUrl: any;
  logoImgUrlgst: string;
  tempImgUrl: string;

  constructor(private apiService: ApiService,
    private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.accept = 0;
    this.country_dec=this.coreService.setDecimalLength();
    this.listTransfers();
    this.listAccepted();
    this.listRejected();
    this.getGodownList();
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.option = 0;
    this.gd_id = Number(this.coreService.getUserData('usr_default_godown_id'));
    if(this.gd_id > 0){
      this.gd_id = this.gd_id;
    }else{
      this.gd_id = 0;
    }
    // console.log(this.gd_id);
    this.is_purch_rate = 0;
    this.getTrasferSetting();

    this.getHeaderImage();
    
  }
  getTrasferSetting() {

    this.apiService.getTrasferSetting().subscribe((res) => {
      if (res['data']) {
        this.is_purch_rate = res['data'].change_purch_rate;
        this.sales_settings = res['data'];
      } 
    });

  }
  
  showOption(opt){

    if(opt){
        this.option = 0;
    }else{
        this.option = 1;
    }

  }

  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
      //this.godowns.push({ "gd_id": '0', 'gd_name': 'Shop' });

         });
  }

  listTransfers() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '0');
    this.apiService.listReceivedTransfers(searchval, 1).subscribe((res) => {

      this.formula_list = res['data'];


      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  pageNext(status, pageno: any) {
    console.log(status);
    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listReceivedTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }

      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.from = res['from'];

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  productionPreview(prdn_id) {
    this.previewData = [];
    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      this.received_date = new Date(res.stocktr_date);
      console.log(this.previewData);
      console.log(this.previewData['frombranch']['branch_name']);

      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }
  listAccepted() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '1');
    this.apiService.listReceivedTransfers(searchval, 1).subscribe((res) => {

      this.accepted_list = res['data'];
      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;
    });
  }

  acceptpageNext(status, pageno: any) {
    console.log(status);
    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listReceivedTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }

      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;

    });
  }

  apgEnd(curr, end) {
    if (curr == end)
      this.apgend = true;
    else
      this.apgend = false;
  }

  apgStart(curr) {
    if (curr == 1)
      this.apgstart = true;
    else
      this.apgstart = false;
  }






  listRejected() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '2');
    this.apiService.listReceivedTransfers(searchval, 1).subscribe((res) => {

      this.rejected_list = res['data'];


      this.pageLoadingImg = false;
      this.rcurpage = res['current_page'];
      this.rlastpage = res['last_page'];
      this.rfrom = res['from'];

      this.rpgEnd(this.rcurpage, this.rlastpage);
      this.rpgStart(this.rcurpage);
      this.slnum = 0;
    });
  }

  rejectpageNext(status, pageno: any) {
    console.log(status);
    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listReceivedTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }

      this.pageLoadingImg = false;
      this.rcurpage = res['current_page'];
      this.rlastpage = res['last_page'];
      this.rfrom = res['from'];

      this.rpgEnd(this.rcurpage, this.rlastpage);
      this.rpgStart(this.rcurpage);
      this.slnum = 0;

    });
  }

  rpgEnd(curr, end) {
    if (curr == end)
      this.rpgend = true;
    else
      this.rpgend = false;
  }

  rpgStart(curr) {
    if (curr == 1)
      this.rpgstart = true;
    else
      this.rpgstart = false;
  }

  createCat(formdata: { value: Catagory; }) {
    this.pageLoadingImg = true;

    this.apiService.createCat(formdata.value).subscribe((catagory: Catagory) => {
      this.pageLoadingImg = false;

      if (catagory.error != null) {
        this.result = catagory.error;
        console.log(this.result);
      }
      else {
        this.coreService.createfunct(formdata, this.result, catagory.message);
      }

    });
  }


  acceptTransfer(formdata: { value: any; }) {
    if (confirm('Are you sure you want to accept the transfer? ')) {
      // const searchval = new FormData();
      formdata.value.status = 1;
      // searchval.append('stocktr_id', tranID);
      // searchval.append('status', '1');
      this.pageLoadingImg = true;
      this.apiService.acceptOrrejectTransfer(formdata.value).subscribe((res) => {
        this.pageLoadingImg = false;
        this.ngOnInit();
        $('.close').click();
        this.coreService.createfunct(formdata.value, this.resultobj, res.message);
      });
    }
  }

  rejectTransfer(tranID: string) {
    var confrm = confirm("Are you sure you want to reject the transfer? ");

    if (confrm) {
      let searchval = new FormData();
      searchval.append('stocktr_id', tranID);
      searchval.append('status', '2');
      this.pageLoadingImg = true;
      this.apiService.acceptOrrejectTransfer(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
        this.ngOnInit();
        $('.close').click();
        this.coreService.createfunct(searchval, this.resultobj, res.message);
      });

    }
  }


  //form search
  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    $('.prev-list').show();
  }


  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    $('.prev-list').hide();
  }

  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    $('.prev-list').hide();
  }

  selectCat(cat: Catagory) {
    if (cat) {
      this.result = '';
      $("#editcatf").show();
      this.selectedData = cat;
    }
    this.catagories = [];
  }

  tabClick(tab: { index: number; }) {
    if (tab.index == 1)
      this.result = '';
  }

  getHeaderImage() {
    this.apiService.getSettings().subscribe((res) => {
    this.base_url = this.apiService.PHP_API_SERVER
    this.img_url = res.data.prnt_common_header_img;
    this.header_type = res.data.prnt_header_type;
    this.logoImg = res.data.prnt_header_logo;
    this.logoImgUrl = res.data.prnt_header_logo_img_path;
    this.logoImgUrlgst = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
    this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
    });
  }

}