import { Component, OnInit } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-batchstockbyperiod',
  templateUrl: './rep-batchstockbyperiod.component.html',
  styleUrls: ['./rep-batchstockbyperiod.component.css']
})

export class RepBatchstockbyperiodComponent implements OnInit {


  fileName = 'batch_stock_summary_datewise.xlsx';
  searchval: any;
  pageLoadingImg: boolean;
  prodData: any;
  pageFrom: any;
  from:any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  repDate: any;
  // period_type: any = 't';
  period_type:any;
  date1: any;
  date2: any;

  barcode_filter: any;
  bar_val1: any;
  bar_val2: any;
  prd_id: any;
  cat_id: any;
  products: any; catagories: any;
  users: any;
  prateType:any = 0;

  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];


  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

 


  total_amount: any;
  total_discount_amount: any;
  total_qty_sold: any;
  branch_all: any;
  filter_branch_id: number;
  filter_batch_id: number;
  userType: any;
  up: any;
  exportLoader: boolean;
  ExprepDate: any;
  ExpprodData: any;
  Exptotal_amount: any;
  Exptotal_discount_amount: any;
  Exptotal_qty_sold: any;


  branch_display_name: any;
  branch_name: any;
  branch_address:any;
  sb_batch_code :any;
  branch_display_code: any;
  report_type: any;
  Expreport_type: any;
  total_item_cost: any;
  total_item_profit: any;
  enablePrft: boolean;
  errorPassword: any;
  ExpenablePrft: boolean;
  Exptotal_item_cost: any;
  Exptotal_item_profit: any;
  show = false;
  country_dec: string;
  total_closing_stock: any;
  total_closing_amount: any;
  Extotal_closing_stock: any;
  Extotal_closing_amount: any;
  batch_all: any;
  remark: any;
  Expremark: any;
  godown_all: any;
  

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    // this.filter_batch_id = 1;
    let searchval = new FormData();
    // searchval.append('period_type',"");
    this.apiService.batchStockRepPeriod(searchval, 1).subscribe((res: Sales) => {
       
      console.log(res['data']['remark']);
     // console.log(res['data']['total_closing_stock']);
      this.show = true;
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.remark = res['data']['remark'];
      this.prodData = res['data']['data'];
      this.from =1;
      this.total_closing_stock = res['data']['total_closing_stock'];
      this.total_closing_amount = res['data']['total_closing_amount'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];
      if(res.data.profit_rep){
        this.enablePrft = true;
        this.total_item_cost = res.data.profit.total_item_cost;
        this.total_item_profit = res.data.profit.total_item_profit;
      }else{
        this.enablePrft = false;
      }
      this.errorPassword = '';
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
   
    
    this.searchGdwn('');
    this.country_dec=this.coreService.setDecimalLength();
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','Common.detailed','Common.detailed_with_profit','Common.last_purchase_rate','Common.average_purchase_rate']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_prate = [
        { id: 0, name: res['Common.last_purchase_rate'] },
        { id: 1, name: res['Common.average_purchase_rate'] }
      ];
    
    });

  }

 
  // changeRepType(){
  //   this.show = false;
  // }

  

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });
    });

  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      // if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }

  searchBatch(search: string) {
    let searchval = new FormData();
    searchval.append("batch_name", search);
    this.apiService.getBatchByName(searchval).subscribe((res) => {
      this.batch_all = res['data'];
      // if (this.userType == 'ADMIN') {
       // this.batch_all.push({ 'sb_id': 'All', 'sb_batch_code': 'All Batch' })
      // }
    });
  }

  ngOnDestroy() {
    $('.close').click();

  }

 


  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.batchStockRepPeriod(formdata.value, pageno).subscribe((res: Sales) => {
      this.show = true;
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.remark = res['data']['remark'];
      this.prodData = res['data']['data'];
      this.from =1;
      this.total_closing_stock = res['data']['total_closing_stock'];
      this.total_closing_amount = res['data']['total_closing_amount'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];
      if(res.data.profit_rep || res.data.det_profit_rep){
        if(res.data.profit_rep){
          this.enablePrft = true;
        }
        this.total_item_cost = res.data.profit.total_item_cost;
        this.total_item_profit = res.data.profit.total_item_profit;
      }else{
        this.enablePrft = false;
      }
      this.errorPassword = '';

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      if(!res.data.msg){
        $('.close').trigger('click');
      }else{
        this.errorPassword = res.data.msg;
      }
    });

  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }



  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

 

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    this.apiService.batchStockRepPeriod(Expformdata.value, 1).subscribe((res: Sales) => {
      this.show = true;

       console.log(res.data.Date);
      this.ExprepDate = res.data.Date;
      this.Expremark = res['data']['remark'];
      this.ExpprodData = res['data']['data'];
      this.from =1;
      this.Extotal_closing_stock = res['data']['total_closing_stock'];
      this.Extotal_closing_amount = res['data']['total_closing_amount'];
      

      this.Exptotal_amount = res['data']['total_amount'];
      this.Exptotal_discount_amount = res['data']['total_discount_amount'];
      this.Exptotal_qty_sold = res['data']['total_qty_sold'];

      if(res.data.profit_rep || res.data.det_profit_rep){
        if(res.data.profit_rep){
          this.ExpenablePrft = true;
        }
        this.Exptotal_item_cost = res.data.profit.total_item_cost;
        this.Exptotal_item_profit = res.data.profit.total_item_profit;
      }else{
        this.ExpenablePrft = false;
      }


      setTimeout(() => {
        this.exportexcel();
      }, 3000);


      Expformdata.value.export = '';
    });



  }

 
// pdf download
generatePdf(Expformdata: { value: any; }) {

  this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    this.apiService.batchStockRepPeriod(Expformdata.value, 1).subscribe((res: Sales) => {
      this.show = true;

       console.log(res.data.Date);
      this.ExprepDate = res.data.Date;
      this.Expremark = res['data']['remark'];
      this.ExpprodData = res['data']['data'];
      this.from =1;
      this.Extotal_closing_stock = res['data']['total_closing_stock'];
      this.Extotal_closing_amount = res['data']['total_closing_amount'];
      

      this.Exptotal_amount = res['data']['total_amount'];
      this.Exptotal_discount_amount = res['data']['total_discount_amount'];
      this.Exptotal_qty_sold = res['data']['total_qty_sold'];

      if(res.data.profit_rep || res.data.det_profit_rep){
        if(res.data.profit_rep){
          this.ExpenablePrft = true;
        }
        this.Exptotal_item_cost = res.data.profit.total_item_cost;
        this.Exptotal_item_profit = res.data.profit.total_item_profit;
      }else{
        this.ExpenablePrft = false;
      }


      const heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('Table.item_name'),
        this.translate.instant('Common.itm_code'),
        this.translate.instant('Table.barcode'),
        this.translate.instant('Table.batch'),
        this.translate.instant('Table.sales_rate_mrp'),
        this.translate.instant('HOME.purchase_rate'),
        this.translate.instant('HR.expired_in'),
        this.translate.instant('HOME.closing_stock'),
        this.translate.instant('HOME.closing_amount')
      ];

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
    
        this.ExpprodData.forEach((item,i)=>{
          data.push([
            i+1,
            item.prd_name,
            item.prd_code,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
          ])

          item.items.forEach(item1=>{

            const arr = [
              '',
              '',
              '',
              item1.prd_barcode,
              item1.sb_batch_code,
              this.currencyPipe.transform(item1.bbs_srate, '', '', this.country_dec),
              this.currencyPipe.transform(item1.rate, '', '', this.country_dec),
              item1.bsdu_stock_quantity,
              this.currencyPipe.transform(item1.closing_amount, '', '', this.country_dec)
            ]

            if(item1.exppired_in){
              arr.splice(7,0, item1.exppired_in  + ' ' +  this.translate.instant('HOME.days'))
            } else {
              arr.splice(7,0, this.translate.instant('HOME.days'))
            }

            data.push(arr)
          })

          data.push([
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            item.product_total_closing_stock,
            this.currencyPipe.transform(item.product_total_closing_value, '', '', this.country_dec)
          ])

        })
    
        let doc = this.excelService.getPdfObj();
    
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
    
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Common.batch_stock_summary_datewise')}`,  valueXPos, headerHeight + 15);
  
        if(this.prodData.length != 0){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 20);
          doc.setFontSize(10);
          doc.text(':  ' + this.prodData.length,  valueXPos, headerHeight + 20);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_closing_stock')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.total_closing_stock,  valueXPos, headerHeight + 25);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_closing_amount')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + this.total_closing_amount,  valueXPos, headerHeight + 30);

        }
        
        doc.setFontSize(10);
        doc.text(this.remark, lblXPos, headerHeight + 35);

        doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
    
       
        doc.save(`Batch Stock Summary Datewise.pdf`);

        this.exportLoader = false;

      Expformdata.value.export = '';
    });
  
}


}


