import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-purchase-return',
  templateUrl: './purchase-return.component.html',
  styleUrls: ['./purchase-return.component.css']
})
export class PurchaseReturnComponent implements OnInit {

  datevalue: any;
  purchret_refno: any;
  purchret_supp_inv_no: any;
  purchret_retid: any;
  purchret_supp_id: any;
  purchret_date: any;
  purchret_type: any;
  purchret_supp_retno: any;
  purchret_amount: any;
  gd_id: any;
  brcode: any;
  vantran_godown_id: any;
  filter_types = [
    { id: 1, name: 'Reported/Cleared'},
    { id: 2, name: 'Failed/Pending'},
    { id: 3, name: 'All'}
    
  ];


  selecTed: any = { bs_prate: null };
  prd_id: any;
  tran_id: number;
  vantran_purch_price: number;
  prd_barcode: String;
  vantransub_qty: number;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount = 0;
  sel_gd_id = 0;
  tranArray: any;
  slno: number;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  resultobj: any = {};
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any[];
  preview_items: any[];
  prdSel: any;
  selunit: any;
  itemsreturn = [];
  avlqty: any;
  updatepurchasepricetot: any;
  item_barcode: any;
  purchaseretno: number;
  purch_note: number;
  bs_prate: any;
  purch_amount: any;
  purchData: any;
  pageLoadingImg:boolean;
  qr_inv: any;
  checkBatch =false;



  godown_all = [{ "gd_id": 0, 'gd_name': 'Shop' }];
  purchret_gd_id = 0;
  pay_type = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank' }
  ];

  pay_type_name={
    '1':'Credit',
    '2':'Cash',
    '3':'Bank',

  }
  gdid: number;
  errObjArr = {
    unitqtyErr: null, unitErr: null,
    prodErr: null, gdErr: null, pstkErr: null
  };

  ledger_all: string[];
  items = [];
  purchretsub_tax: any;
  netamount = 0;
  nettax = 0;
  netcgst = 0;
  netsgst = 0;
  netigst = 0;
  netqty = 0;
  netprice = 0;
  netretamount = 0;
  purchret_discount = 0;
  gdwnid: any;
  punitname: any;
  punitqty: any;
  boxqty: any;
  purchretsub_tax_peritem: any;
  purchretsub_taxvalue_peritem: any;
  itemtype: string;
  baseunit: string;
  expiry: any;
  seltaxamount = 0;
  purchstock: any;
  availableQty: any;
  remeQty =0;
  existpurchaseprice: any;
  updatepurchaseprice: any;
  totbox: any;
  totprice: any;
  updatepurchasepricete: any;
  purchpricesingleorbox: any;
  purchpricesingleorbox_singlerate: any;
  prd_stock_statusUnchecked = "alias";
  prd_stock_statusChecked = "name";
  prd_stock_status = "name";
  retItems: any[];
  totalItem = 0;
  purchDataItems: string[];
  product_summary: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  searchStkBatch: any;
  purchDataRetAmount:any;
  gd_name:any;
  purch_acc_ledger_id:any;
  taxCategories = [];
  prd_tax_cat_id = 0;
  private modalRef: NgbModalRef;
  closeResult: string;
  payTypes = ['Cash', 'Credit', 'Bank / Card','Both'];
  //date = new FormControl(new Date());
  //serializedDate = new FormControl((new Date()).toISOString());
  date = new Date();
  up: any;
  defLedger: any;

  savedTemplate = {
    title_text: 'Purchase Return',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  inventoryType = [
    {id: 0, value: 'With Inventory'},
    {id: 1, value: 'Without inventory'},
  ];
  is_without_stock = 0; 
  sales_print_name: any;
  country_dec: string;
  cmp_tax: any;
  cgst_perc: any;
  sgst_perc: any;
  supp_details: any;
  cust_state_id: any;
  igst: boolean=false;
  branch_state: any;
  batches = [];
  selBatch:any;
  saleData: { sales_id: any; sales_inv_no: any; error: any; message: any; data: any; items: any; };
  showdata: boolean;
  stkprdbc_all: any;
  errObjArr1 = 0;
  purchretsub_rate = 0;
  stock_id = 0;
  branch_stock_id= 0;
  ref_num_search = 0;
  purch_return= false;
  cur_lang: string;
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
  ];
  eInvoiceSupport: boolean=false;
  _tax_invoice = 'Tax Invoice';
  _simplified_tax_invoice = 'Simplified Tax Invoice';
  _invoice = 'Tax Invoice';

  // "b2BRequirements": {
  //   "isSelfBilling": false,
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isExport": false,
  //   "isNominal": false
  // },
  // "b2CRequirements": {
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isNominal": false
  // }
  // NNPNESB
  // - 01 for tax invoice
  // - 02 for simplified tax invoice
  // P (position 3) = 3rd Party invoice 
  // transaction, 0 for false, 1 for true
  // N (position 4) = Nominal invoice 
  // transaction, 0 for false, 1 for true
  // ubl:Invoice / ubl:Invoice / cbc:InvoiceTypeCode /@name
  // Version 1.1 40 / 57
  // E (position 5) = Exports invoice 
  // transaction, 0 for false, 1 for true
  // S (position 6) = Summary invoice 
  // transaction, 0 for false, 1 for true 
  // B (position 7) = Self billed invoice
  _Exports = 'Exports';
  _self_billing = 'Self Billing';
  _none = 'None';
  _third_party = 'Third Party';
  _nominal = 'Nominal';
  _summary = 'Summary';
  inv_sub_types = [
    { id: '00000', name: 'None' },
    { id: '10000', name: 'Third Party' },
    { id: '01000', name: 'Nominal' },
    { id: '00010', name: 'Summary' },
    { id: '00100', name: 'Exports' },
    { id: '00001', name: 'Self Billing' }
  ];
  inv_sub_type = '00000';
  xmlPath = '';
  branch_code = '';
  mode = {
    1: "production",
    2: "simulation",
    3: "development",
  }
  branch_e_invoice_enabled = false;
  inv_type = 1;
  cmpny: any;
  einv_simplfied_print_style = 1;
  invoiceId: any;
  invoice_type: any;
  constructor(private apiService: ApiService, private coreService: CoreService,
    private datePipe: DatePipe, private modalService: NgbModal,private translate: TranslateService) { }

  ngOnInit() {

    this.cur_lang = sessionStorage.getItem("baseLang");
     this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })

    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });
    
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.xmlPath = this.apiService.PHP_API_SERVER + '/public/xml/' + this.cmpny + '/' + this.branch_code + '/' ;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    

    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');

    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
      }
    });
    this.sales_print_name=1;
    this.up = this.coreService.getUserPrivilage();
    $("#purchase-ret").hide();
    this.invoice_type=3
    this.getPurchaseReturn();
    this.getReturnedList();
    this.getTemplete();
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });
    this.getSalesSetting();

    if(this.cmp_tax==2){
      this.branch_state = this.coreService.getUserData('branch_state_id');
     
    }
    this.country_dec=this.coreService.setDecimalLength();
  }

  aliasChecked() {
    if (this.cur_lang !== undefined && this.cur_lang !== null && this.cur_lang !== 'English') {
      this.prd_stock_status = this.prd_stock_statusUnchecked;
    } else {
      this.prd_stock_status = this.prd_stock_statusChecked;
    }
  }
  
  langChange(){
    this.translate.get(['Common.With_invtry', 'Common.Without_invtry','HOME.credit', 'Common.cash','Common.last_yr','HOME.Bank',
    'Common.tax_invoice', 'Common.simplified_tax_invoice','Common.none','Branch.third_party','Branch.nominal','Branch.exports','Common.summary','Branch.self_billing'
    ]).subscribe((res: string) => {    
     
      this.inventoryType = [
        {id: 0, value: res['Common.With_invtry']},
        {id: 1, value: res['Common.Without_invtry']},
      ];
      this.pay_type = [
        { id: 1, name: res['HOME.credit']},
        { id: 2, name: res['Common.cash'] },
        { id: 3, name: res['HOME.Bank'] }
      ];

      this._tax_invoice = res['Common.tax_invoice'];
      this._simplified_tax_invoice = res['Common.simplified_tax_invoice'];
      this.inv_types = [
        { id: 1, name: this._tax_invoice},
        { id: 2, name: this._simplified_tax_invoice},
      ];

      this._Exports = res['Branch.exports'];
      this._self_billing = res['Branch.self_billing'] ;
      this._none = res['Common.none'];
      this._third_party = res['Branch.third_party'];
      this._nominal = res['Branch.nominal'];
      this._summary =  res['Common.summary'];
      this.inv_sub_types = [
        { id: '00000', name: this._none},
        { id: '10000', name: this._third_party },
        { id: '01000', name: this._nominal },
        { id: '00010', name: this._summary }
      ];
      if (this.inv_type == 1) {
        this.inv_sub_types.push({ id: '00100', name:  this._Exports });
        this.inv_sub_types.push({ id: '00001', name: this._self_billing});
      }
     
    });

  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {        
        this.sales_print_name=res['data'].sales_print_name;
        this.einv_simplfied_print_style = res['data'].einv_simplfied_print_style;
      } else {
        this.sales_print_name=1;
      }
    });
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }


  //form search  ledger
  searchLedger(search: string) {
    let searchval = new FormData();
    searchval.append("supp_name", search);
    // if(this.eInvoiceSupport){
    //   searchval.append('einvoice', '1');
    // }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.ledger_all = res['data'];

    });
  }
  searchLedgerAlias(search: string) {
    let searchval = new FormData();
    searchval.append("supp_name", search);
    searchval.append('with-alias','1'); 
    if(this.eInvoiceSupport){
      searchval.append('einvoice', '1');
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.ledger_all = res['data'];

    });
  }

  purchaseReturn(content) {
    this.inv_sub_type = '00000';
    this.inv_type = 1;
    this.selecTed = null;
    this.availableQty = 0;
    this.purchstock='';
    this.selunit = null;
    this.purchret_discount = 0;
    this.prdSel= null;
    this.modalRef = this.modalService.open(content, { size: 'xl',backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  changePayType(){
    this.searchDefaultLedger('');
  }
  searchDefaultLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    if(this.purchret_type==2){
      searchval.append('ledger_grp', '3');
    }else if(this.purchret_type==3){
      searchval.append('ledger_grp', '4');
    }
    
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  searchProducts(search: string, gdid: any) {
    this.prd_all = [];
    let searchval = new FormData();
    if (search) {
      searchval.append("keyword", search);
      searchval.append("gd_id", this.gdwnid);
      searchval.append("search_type", this.prd_stock_status);
    }
    this.apiService.getProdByNamePrret(searchval).subscribe((res) => {
      this.prd_all = res['data'];
    });
  }

  // search godown
  searchGodown(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });
  }


  resetItemsearch() {
    this.vantran_purch_price = 0;

    this.prd_barcode = '';
    this.default_unit = [];
    this.vantransub_qty = 0;
  }

  selItem(prdSel: any) {
    console.log("shameer test");
    console.log(prdSel);
    $('#purchret-item').show();
    this.selecTed = prdSel;
    console.log(prdSel);
    if (this.selecTed) {
      this.selBatch=0;
      this.selecTed.purchsub_id =0;
      this.batches = this.selecTed.batches;
      this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
      this.seltaxamount = Number(this.selecTed.bs_prate) * Number(this.selecTed.prd_tax) / 100;
      this.boxqty = this.selecTed.avialble_qty;
      this.availableQty = this.selecTed.avialble_qty;
      this.selunit = this.selecTed.base_unit;

      if(this.selecTed.prd_default_unit_id){

        let searchval = new FormData();
        searchval.append("unit_id", this.selecTed.prd_default_unit_id);
       this.apiService.getUnit(searchval).subscribe((res) => {
      // this.godown_all = res['data'];
      // this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });
      // console.log("res");
      // console.log(res);
      this.selunit = res;

      this.selUnit(this.selunit);

    });
      

      }

      this.punitname = this.selecTed.base_unit.unit_code;
      this.punitqty = this.selecTed.base_unit.unit_base_qty;
      this.availableQty = Number(this.selecTed.avialble_qty) / Number(this.selecTed.base_unit.unit_base_qty);
      this.updatepurchaseprice = this.selecTed.bs_prate;
      this.updatepurchasepricetot = this.selecTed.bs_prate * this.selecTed.avialble_qty;
      $('#purchstock').focus();
      $('#purchstock').select();
      // this.selecTed = null;
    } else{
      $('#purchret-item').hide();
    }
  }

  appendItem(result) {
    this.errObjArr1 = 0;
    
    console.log("this.availableQty");
    console.log(this.availableQty);
    console.log("result.purchsub_rem_qty");
    console.log(result.purchsub_rem_qty);

    if (this.availableQty >result.purchsub_rem_qty) {

      $('#purchstock').css("border", "1px solid red");
      this.errObjArr1 = 1;

    } 
    if (this.errObjArr1 > 0) {
    }
    else {
    
      this.selecTed.avialble_qty = $('#purchstock').val();
    console.log("result");
    console.log(result);
    this.validateForm();
    
    if (this.gdwnid == undefined)
      this.gdwnid = 0;
    if (this.selecTed.batches.length > 0) {
      this.expiry = this.selecTed.batches[0].sb_expiry_date;
    }
    else {
      this.expiry = 'nil';
    }

    this.slno = (this.items.length) + 1;
    this.calcUnit();
    //  rate calculation
    this.purchpricesingleorbox = $('#purchprice').val();
    if (this.itemtype == 'piece') {
      this.selecTed.bs_prate = this.purchpricesingleorbox;
    } else {
      this.selecTed.bs_prate = Number(this.purchpricesingleorbox) / Number(this.punitqty);
    }
    // end rate calc
    //  cal tax
    this.purchretsub_tax = 0;
    this.purchretsub_tax_peritem = 0;
    // tax percentage of single item
    // this.purchretsub_tax_peritem = $('#purchtaxper').val();
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === this.prd_tax_cat_id);
    this.selecTed.prd_tax = this.purchretsub_tax_peritem = selTax[0].taxcat_tax_per;
    // tax value of single item

    if(this.selecTed.purchsub_id > 0){
      this.purchretsub_rate = this.selecTed.purchsub_rate;
      this.purchretsub_taxvalue_peritem = Number(this.selecTed.purchsub_rate) * (Number(this.purchretsub_tax_peritem) / 100);
      this.stock_id =this.selecTed.purchsub_stock_id;
      this.branch_stock_id =this.selecTed.purchsub_branch_stock_id;
    }
    else{
      this.purchretsub_rate = this.selecTed.bs_prate;
      this.purchretsub_taxvalue_peritem = Number(this.selecTed.bs_prate) * (Number(this.purchretsub_tax_peritem) / 100);
      this.stock_id =this.selecTed.bs_stock_id;
      this.branch_stock_id =this.selecTed.branch_stock_id;
    }
    
    console.log("this.purchretsub_taxvalue_peritem");
    console.log(this.purchretsub_taxvalue_peritem);
    console.log("this.boxqty");
    console.log(this.boxqty);

    this.purchretsub_tax = Number(this.boxqty) * Number(this.purchretsub_taxvalue_peritem);
    console.log("this.purchretsub_tax");
    console.log(this.purchretsub_tax);
    //  end tax calc   
    // console.log(this.selecTed.prd_id);
    // console.log(this.punitname);
    // console.log(this.selecTed.bs_prate);   
    // console.log(this.selecTed.prd_tax);     
    this.resDup = this.coreService.checkDuplicateValPchseret(this.items, this.selecTed.prd_id, this.selecTed.bs_prate, this.punitname, this.selecTed.prd_tax);
    if (this.selecTed.prd_id != undefined && this.printError.length <= 0) {
      
     
      if(this.cmp_tax==2){
        this.updateTaxPerc(this.prd_tax_cat_id);
      }
      
      if (this.resDup == undefined) {
        this.items.push({
          "sl_no": this.slno, "purchretsub_prd_id": this.selecTed.prd_id, "itemname": this.selecTed.prd_name, "purchretsub_stock_id":  this.stock_id,
          "purchretsub_branch_stock_id": this.branch_stock_id, "purchretsub_batch_id": this.selBatch, "purchretsub_qty": this.boxqty,
          "purchretsub_rate": this.purchretsub_rate, 
          // "purchretsub_unit_id": this.selecTed.prd_base_unit_id,
          "purchretsub_unit_id": this.selunit.unit_id,
          "purchretsub_tax": this.purchretsub_tax, "purchretsub_tax_per": this.selecTed.prd_tax, "prd_barcode": this.selecTed.prd_barcode,
          "punitqty": this.punitqty, "punitname": this.punitname, "boxqty": this.boxqty,
          "purchretsub_tax_peritem": this.purchretsub_taxvalue_peritem,
          "itemtype": this.itemtype, "baseunit": this.baseunit, "totbox": this.totbox, "updatepurchaseprice": this.updatepurchaseprice * this.selecTed.avialble_qty,"purchretsub_taxcat_id":this.prd_tax_cat_id,
           "purchsub_id":this.selecTed.purchsub_id
        });
        this.itemsreturn.push({
          "sl_no": this.slno, "purchretsub_prd_id": this.selecTed.prd_id, "purchretsub_stock_id":  this.stock_id,
          "purchretsub_branch_stock_id": this.branch_stock_id, "purchretsub_batch_id": this.selBatch, "purchretsub_qty": this.boxqty,
          "purchretsub_rate": this.purchretsub_rate, 
          // "purchretsub_unit_id": this.selecTed.prd_base_unit_id,
          "purchretsub_unit_id": this.selunit.unit_id,
          "purchretsub_tax": this.purchretsub_tax, "purchretsub_tax_per": this.selecTed.prd_tax, "purchretsub_expiry": this.expiry, "purchretsub_taxcat_id":this.prd_tax_cat_id,
          "purchsub_id":this.selecTed.purchsub_id
        });
      } else {
        // console.log('already exists');
        //items
        var existingArray = [];
        var slNo = this.coreService.findItemRet(this.items, this.selecTed.prd_id, this.selecTed.bs_prate, this.punitname, this.selecTed.prd_tax);
        // console.log(slNo);
        // console.log(this.updatepurchaseprice);
        //base unit
        //quantity
        this.items.find(x => x.sl_no === slNo).purchretsub_qty = Number(this.items.find(x => x.sl_no === slNo).purchretsub_qty) + Number(this.boxqty);
        this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_qty = Number(this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_qty) + Number(this.boxqty);
        //item price    
        this.items.find(x => x.sl_no === slNo).updatepurchaseprice = Number(this.items.find(x => x.sl_no === slNo).purchretsub_qty) * Number(this.selecTed.bs_prate);

        // tax amount
        console.log("before : "+this.items.find(x => x.sl_no === slNo).purchretsub_tax);

        console.log(this.purchretsub_tax);
        console.log("number: "+Number(this.purchretsub_tax));
        this.items.find(x => x.sl_no === slNo).purchretsub_tax = Number(this.items.find(x => x.sl_no === slNo).purchretsub_tax) + Number(this.purchretsub_tax);
        this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_tax = Number(this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_tax) + Number(this.purchretsub_tax);
        console.log("after : ");
        console.log(this.items);
        
        //der unit
        //box qty piece
        this.items.find(x => x.sl_no === slNo).boxqty = Number(this.items.find(x => x.sl_no === slNo).boxqty) + Number(this.boxqty);

        // box nos
        this.items.find(x => x.sl_no === slNo).totbox = Number(this.items.find(x => x.sl_no === slNo).totbox) + Number(this.totbox);

      }

      console.log("shhhameer");
      console.log(this.selecTed);
     if(this.selecTed.purchsub_id > 0){
 
       this.saleData.items.forEach((value, i) => {
         if (value.purchsub_id == result.purchsub_id) {
   
           // this.saleData.items[i].salesub_rem_qty -= this.qty_rtn;
   
           if (this.saleData.items[i].salesub_rem_qty == 0) {
             //this.saleData.sales_sub.splice(i, 1);
             this.saleData.items[i].purchsub_flag = 0
           }
           else {
             this.saleData.items[i].purchsub_flag = 0
           }
   
         }
       });
 
     }

      // console.log(this.itemsreturn);
      this.listRefresh();
      this.refreshAll();
      this.selecTed = null;
      this.prdSel= null;
      this.availableQty = 0;
      this.purchstock='';
      this.selunit = null;
    }
    
    this.purch_return= false;

  }

  }

  removeItem(data) {
    console.log("data");
    console.log(data);
    var remopstk = confirm("Remove this Item");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.items.length - 1; i >= 0; --i) {
        if (this.items[i].sl_no == type) {
          this.items.splice(i, 1);
        }
      }
      for (i = this.itemsreturn.length - 1; i >= 0; --i) {
        if (this.itemsreturn[i].sl_no == type) {
          this.itemsreturn.splice(i, 1);
        }
      }
    }

    this.saleData.items.forEach((value, k) => {



      if (value.purchsub_id == data.purchsub_id) {
        this.saleData.items[k].purchsub_flag = 1;

        // this.saleData.items[k].salesub_rem_qty = Number(this.saleData.items[k].salesub_rem_qty * 1) + Number(data.qty_rtn * 1);
      }
      console.log("data.purchsub_id");
      console.log(data.purchsub_id);

      console.log("value.purchsub_id");
      console.log(value.purchsub_id);

      console.log("this.saleData.items[k].purchsub_flag");
      console.log(console.log(this.saleData.items[k].purchsub_flag)); 
    });

   


    this.selecTed = {};
    this.listRefresh();
    this.refreshAll();

  }

  purchaseReturnSub(formdata: { value: any; }) {
    this.resultobj = [];

    if (formdata.value.purchret_date == undefined) {
      this.resultobj.purchret_date = "t";
      this.resultobj.push({ 'purchret_date': 'Required' });
    }
    else {
      if (formdata.value.purchret_date > new Date()) {
        this.resultobj.purchret_date = "t";
        this.resultobj.push({ 'purchret_date': 'Required' });
      } else {
        this.resultobj.purchret_date = "f";
      }
    }
    if (this.resultobj.length <= 0) {
    // var precnfrm = confirm("Are you sure?");
    if (confirm("Are you sure?")) {
      this.pageLoadingImg = true;
      formdata.value.item_name = undefined;
      formdata.value.itebar = undefined;
      formdata.value.itenam = undefined;
      formdata.value.purchrate = undefined;
      formdata.value.purchstock = undefined;
      formdata.value.purchret_qty = undefined;
      formdata.value.selunit = undefined;
      formdata.value.purchtaxper = undefined;
      formdata.value.purchtaxamount = undefined;
      formdata.value.purchprice = undefined;
      formdata.value.purchret_retid = undefined;
      formdata.value.item_barcode = undefined;
      formdata.value.prd_stock_status = undefined;
      formdata.value.items = this.itemsreturn;
      formdata.value.purchret_gd_id = this.gdwnid;

      if(this.cmp_tax==2){
        formdata.value.netcgst=this.netcgst;
        formdata.value.netsgst=this.netsgst;

        if((this.cmp_tax==2)&&(this.igst)){

          formdata.value.netigst=this.netigst;
          formdata.value.purchret_is_igst=1;
        }
      }
      formdata.value.purchret_date = (formdata.value.purchret_date) ?
      this.apiService.formatDate(formdata.value.purchret_date) : formdata.value.purchret_date;
      this.apiService.purchaseReturn(formdata.value).subscribe((res: any) => {

        let isError = false;
        if(this.eInvoiceSupport){
          if(res.error && res.error['error']){
            this.coreService.showMessage(res.error['error'], 4000);
            isError = true;
            this.pageLoadingImg = false;
          }
          if(res.message && res.message.preview){
            this.coreService.showMessage(res.message.msg, 4000);
          }
        }

        if(!isError){
          this.pageLoadingImg = false;
          if (res.error != null) {
            if (typeof res.is_cust_err !== 'undefined') {
              this.coreService.showMessage(res.error,6000);
            }else{
              this.resultobj = res.error;
              this.resultobj = this.coreService.getValidation(this.resultobj);
            }
            
          }
          else {
            this.resultobj = {};
            this.coreService.createfunct(formdata, this.resultobj, res.message);
            this.netamount = null;
            this.modalRef.close();
            this.items = [];
            this.itemsreturn = [];
            this.selecTed = {};
            this.netqty = 0;
            this.netprice = 0;
            this.netamount = 0;
            this.nettax = 0;
            this.netcgst = 0;
            this.netsgst = 0;
            this.netigst = 0;
            this.igst = false;
            this.netretamount = 0;
            this.vantransub_qty = 0;
            this.is_without_stock = 0; 
            this.prd_stock_statusUnchecked = "alias";
            this.prd_stock_statusChecked = "name";
            this.prd_stock_status = "name";
            this.getPurchaseReturn();
            this.getReturnedList();
            this.date = new Date();
          }
        }
      });
      this.apiService.updatebatchExNotification();
    }
    this.ref_num_search = 0;
  }
  }

  selGd(gdid) {
    this.gdwnid = gdid;
    this.items = [];
    this.prd_all = [];
    this.prdSel = "Select Product";
    this.listRefresh();
    this.refreshAll();
    //}
  }

  RateChange(result){
    console.log("result");
    console.log(result);
    if(result.purchsub_id > 0)
    {

      this.selunit =result.purchased_unit;
      this.selecTed.units = result.units;
      this.selecTed.avialble_qty = $('#purchstock').val();
      this.availableQty = Number(this.selecTed.avialble_qty) * Number(result.punit_base_qty);

    }
   
  }

  selUnit(selunit) {

    console.log(this.selecTed);
    if (selunit) {

      if(this.selecTed.purchsub_id > 0)
      {

        console.log(selunit);
        this.availableQty = 0;
        this.punitname = selunit.unit_code;
        this.punitqty = selunit.unit_base_qty;
        this.selecTed.bs_prate =this.selecTed.purchsub_rate;
        this.selecTed.avialble_qty = $('#purchstock').val();
        console.log("this.selecTed.avialble_qty");
        console.log(this.selecTed.avialble_qty);
  
  
        this.availableQty = Number(this.selecTed.avialble_qty) * Number(selunit.unit_base_qty);
        console.log("this.availableQty");
        console.log(this.availableQty);
        //this.existpurchaseprice = $('#purchrate').val();
       
      }
      else{

        // console.log(selunit);
      this.availableQty = 0;
      this.punitname = selunit.unit_code;
      this.punitqty = selunit.unit_base_qty;


      this.availableQty = Number(this.selecTed.avialble_qty) / Number(selunit.unit_base_qty);
      //this.existpurchaseprice = $('#purchrate').val();
    

      }

      this.updatepurchaseprice = Number(this.punitqty) * Number(this.selecTed.bs_prate);
      // console.log(this.updatepurchaseprice);
      this.purchstock = $('#purchstock').val();
      this.updatepurchasepricetot = Number(this.updatepurchaseprice) * this.purchstock;
      $('#purchprice').val(this.updatepurchaseprice);
     
    }
  }

  calpurchPrice(inpvalue) {
    this.updatepurchaseprice = (Number(this.punitqty)) * (Number(inpvalue));
    this.updatepurchasepricetot = Number(this.updatepurchaseprice) * this.purchstock;

  }

  addPurch(inptext) {
    var purchtaxamount = (Number($('#purchrate').val()) * (inptext / 100)).toFixed(2);
    $('#purchtaxamount').val(purchtaxamount);
  }

  validateForm() {
    this.printError = [];
    var purchstock = $('#purchstock').val();
    if (purchstock == "" || purchstock == undefined) {
      this.errObjArr.pstkErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.pstkErr = "f";
    }


  }
  calPrice() {
    this.totprice = 5;
  }

  calcUnit() {
    console.log("selected ");
    console.log(this.selecTed) ;
    if(this.selecTed.purchsub_id > 0)
    {
      this.baseunit = this.selecTed.baseunit.unit_code;
    }
    else{
      this.baseunit = this.selecTed.base_unit.unit_code;
    }
   
    this.avlqty = $('#purchstock').val();
    if (this.punitqty == 1) {
      this.itemtype = "piece";
      this.boxqty = this.avlqty;
      this.totbox = this.avlqty;
    } else {
      this.itemtype = "box";
      this.boxqty = this.avlqty * this.punitqty;
      this.totbox = this.avlqty;
    }

  }


  listRefresh() {
    this.netqty = 0;
    this.netprice = 0;
    this.netamount = 0;
    this.nettax = 0;
    this.netcgst = 0;
    this.netsgst = 0;
    this.netigst = 0;
    this.netretamount = 0;

    console.log(this.items);
    
    for (var j = 0; j < this.items.length; j++) {
      this.netprice = Number(this.netprice) + Number(this.items[j]['purchretsub_rate']);
      this.netamount = Number(this.netamount) + (Number(this.items[j]['purchretsub_rate']) * Number(this.items[j]['purchretsub_qty']));
      this.nettax = Number(this.nettax) + Number(this.items[j]['purchretsub_tax']);
      this.netqty = Number(this.netqty) + Number(this.items[j]['purchretsub_qty']);

      if(this.cmp_tax==2){
        this.netcgst = Number(this.netcgst) + Number(this.items[j]['updatepurchaseprice']/100)*this.cgst_perc;
        this.netsgst = Number(this.netsgst) + Number(this.items[j]['updatepurchaseprice']/100)*this.sgst_perc;
        this.netigst =this.nettax;
      }
    }
    this.netretamount = (Number(this.netamount) + Number(this.nettax) - Number(this.purchret_discount));
    if (this.items.length > 0) {
      $("#purchase-ret").show();
    } else {
      $("#purchase-ret").hide();
    }

  }


  refreshAll() {
    this.selecTed.units = [];
    this.selecTed = [];
    this.selunit = "Select unit";
    this.prd_all = [];
    this.prdSel = "Select product";
    this.punitname = undefined;
    this.punitqty = undefined;
    this.seltaxamount = 0;
    this.boxqty = 0;
    $('#purchstock').val('');
    $('#purchret-item').hide();
    this.selecTed.avialble_qty = '';
    this.godown_all = [{ "gd_id": 0, 'gd_name': 'Shop' }];
    this.purchret_gd_id = 0;
  }

  barcodeSearch(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPurchaseRetbyBcode(searchval).subscribe((res) => {

      if (res['data'][0]) {
        this.selecTed = res['data'][0];
        this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
        this.seltaxamount = Number(this.selecTed.bs_prate) * Number(this.selecTed.prd_tax) / 100;
        this.boxqty = this.selecTed.avialble_qty;
        this.availableQty = this.selecTed.avialble_qty;
        this.selunit = this.selecTed.base_unit;

        this.punitname = this.selecTed.base_unit.unit_code;
        this.punitqty = this.selecTed.base_unit.unit_base_qty;
        this.availableQty = Number(this.selecTed.avialble_qty) / Number(this.selecTed.base_unit.unit_base_qty);
        this.updatepurchaseprice = this.selecTed.bs_prate;
        this.updatepurchasepricetot = this.selecTed.bs_prate * this.selecTed.avialble_qty;
        this.selecTed.purchsub_id =0;

        $('#purchret-item').show();
        $('#purchstock').focus();
        $('#purchstock').select();
        this.item_barcode = '';
      } else {
        $('#purchret-item').hide();
      }
    });
  }


  // purchase ret no. generation
  getPurchaseReturn() {
    let searchval = new FormData();
    this.apiService.getPurchaseReturn(searchval).subscribe((res) => {
      this.purchaseretno = res['data'].purch_ret_num;
    });
  }

  getReturnedList() {
    let searchval = new FormData();
   
    this.apiService.listPurchaseReturns(searchval, 1).subscribe((res: any) => {
      this.retItems = res['data']['data'];
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }


  
Select_invoiceType(id){

   
  if (id){
    this.invoiceId=id;
    let searchval = new FormData();
    searchval.append("invoiceType_id",this.invoiceId);
    this.apiService.listPurchaseReturns(searchval, 1).subscribe((res: any) => {
      this.retItems = res['data']['data'];
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
}

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    let searchval = new FormData();
    if(this.invoice_type &&this.eInvoiceSupport){
      searchval.append("invoiceType_id",this.invoice_type);
    }
    this.apiService.listPurchaseReturns(searchval, pageno).subscribe((res: any) => {
      this.retItems = res['data']['data'];
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  getPreview(id: any,gd_name) {
    const searchval = new FormData();
    searchval.append('purchret_id', id);
    this.apiService.getpurchaseRetDetails(searchval).subscribe((res) => {
      this.purchData = res['data'][0];
      this.purchDataItems = res['data'][0]['items']; 
      if(!res['data'][0].purchret_frieght)
      res['data'][0].purchret_frieght = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0; 
      this.gd_name = gd_name;

      if(!this.gd_name)
      this.gd_name = 'Shop';

      this.purchDataRetAmount = Number(res['data'][0].purchret_amount);
      this.purchData.purchret_amount = Number(res['data'][0].purchret_amount)-Number(res['data'][0].purchret_tax)
      +Number(res['data'][0].purchret_discount);
      this.qr_inv = res['data'][0].qr_link;
    });

  }

  purchase_search(search: string) {
   
    this.purch_return =true;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', search);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      console.log(res);

      if (res.error != null) {
        this.resultobj = res.error;
       
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,
          items: []

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      }

      else {
         this.showdata = true;
        this.resultobj = {};
        // this.purchaseItems = res.data;
        this.saleData = res.data;
        this.purchret_supp_id =res.data.purch_supp_id;
       // alert(res.data.items[0].purchsub_gd_id);
        this.searchLedger(res.data.supp_name);
        this.purchret_type =res.data.purch_pay_type;
        this.purch_acc_ledger_id =res.data.purch_acc_ledger_id;
        this.searchDefaultLedger(res.data.purch_acc_ledger_id);
        this.purchret_gd_id =res.data.items[0].purchsub_gd_id;
        this.purchret_supp_inv_no =res.data.purch_inv_no;
        if(res.data['cash_account'][0]){
          this.searchDefaultLedger(res.data['cash_account'][0].ledger_name);
        }
      
        console.log("fsddddddddddd");
        // console.log(res.data.items[0]['godown'].gd_name);
        if(res.data.items[0]['godown']){
          this.searchGodown(res.data.items[0]['godown'].gd_name);
        }
      
       
        this.ref_num_search = 1;
      }
      // IGST checking
      if(this.cmp_tax==2){
        this.purchret_supp_id=res.data.purch_supp_id;
        this.cust_state_id = res.data.supp_state_id;
        this.iGSTchecking();
      }

    });
    
     


  }

  addQuantiy(item, i) {

    this.purch_return= true;
    $('#purchret-item').show();
    console.log("item");
   console.log(item);

   let searchval = new FormData();
    searchval.append("prd_id", item.purchsub_prd_id);
    // console.log(this.selecTed);
    // console.log(data);
    this.selunit =item.purchased_unit;
    this.apiService.getPurchaseProduct(searchval).subscribe((res) => {
      this.stkprdbc_all = res['data'];
      // console.log("this.stkprdbc_all");
      // console.log(this.stkprdbc_all.batches);
      this.selecTed.units = this.stkprdbc_all.units;
      this.selecTed.batches = this.stkprdbc_all.batches;
      this.batches = this.selecTed.batches;
      
    });
   this.selecTed =item;
   this.purchstock =item.purchased_qty;
  
   this.bs_prate =item.purchased_rate;
   this.updatepurchaseprice =item.purchased_rate;
   this.prd_tax_cat_id =item.purchsub_taxcat_id;

  //  this.batches = this.selecTed.batches
  //     this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
  //     this.seltaxamount = Number(this.selecTed.bs_prate) * Number(this.selecTed.prd_tax) / 100;
        // this.boxqty = this.selecTed.purchsub_qty;
       this.availableQty = this.selecTed.purchsub_qty;
       this.remeQty = this.selecTed.purchsub_rem_qty;
      // this.selunit = this.selecTed.base_unit;

      // this.punitname = this.selecTed.baseunit.unit_code;
      this.punitname =this.selecTed.punit_code;
      //this.punitqty = this.selecTed.baseunit.unit_base_qty;
      this.punitqty = this.selecTed.punit_base_qty;
  //    this.availableQty = Number(this.selecTed.avialble_qty) / Number(this.selecTed.base_unit.unit_base_qty);
  //     this.updatepurchaseprice = this.selecTed.bs_prate;
  //     this.updatepurchasepricetot = this.selecTed.bs_prate * this.selecTed.avialble_qty;

  }

  removeItemGrid() {

    $('#purchret-item').hide();
  }

  clsmodl(){
    this.listRefresh();
    this.refreshAll();
  }

  updatePayment() {
    this.inv_sub_types = [
      { id: '00000', name: this._none},
      { id: '10000', name: this._third_party },
      { id: '01000', name: this._nominal },
      { id: '00010', name: this._summary }
    ];
    if (this.inv_type == 1) {
      this.inv_sub_types.push({ id: '00100', name:  this._Exports });
      this.inv_sub_types.push({ id: '00001', name: this._self_billing});
    }
  }

  eInvoiceResubmit(refno,i){
    if(confirm('Do you wish to continue?')){
      let tmp = {
        type: 'purchaseReturn',
        ref_no : refno
      }
      this.retItems[i].resubmitLoadingImg = true;
      this.apiService.eInvoiceResubmit(tmp).subscribe((res) => {
        this.retItems[i].resubmitLoadingImg = false;
        this.coreService.showMessage(res.msg, 4000);
        if (res.status) {
          this.getReturnedList();
        }
      });
    }
  }
  

  updateTaxPerc(taxCatId) {
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    // this.taxpercentage = selTax[0].taxcat_tax_per;
    if(this.cmp_tax==2){
      this.cgst_perc = selTax[0].cgst_tax_per;
      this.sgst_perc = selTax[0].sgst_tax_per;
    }
    // this.basicRates();
  }

  supplierdetails(sup_id){

    if(sup_id){
      
      if(this.cmp_tax==2){
        this.apiService.getSupplierDetails({ supp_id: sup_id }).subscribe((res) => {
          this.supp_details=res.data;
         
          if(this.supp_details){
            this.cust_state_id = this.supp_details.supp_state_id;
            this.iGSTchecking();
          }
          });
          
        }
      }
  
    }


    iGSTchecking(){ 
      console.log(this.cust_state_id,'cust_state_id');
      console.log(this.branch_state,'branch_state');
      console.log(this.purchret_supp_id,'purchret_supp_id');
      
      
      if(this.cmp_tax==2){
    
        // for is igst verify
        if((this.cust_state_id!=this.branch_state)&&(this.cust_state_id)&&(this.purchret_supp_id)){
          this.igst=true;
        }else{
          this.igst=false;
        }
       
        }
    }

   
}
