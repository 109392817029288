import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'app-gst-template6-thermal',
  templateUrl: './gst-template6-thermal.component.html',
  styleUrls: ['./gst-template6-thermal.component.css']
})
export class GstTemplate6ThermalComponent implements OnInit {

  @Input()salesRetPreview;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;

  constructor() { }

  ngOnInit() {
  }

}
