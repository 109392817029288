import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-sales-prod-rep',
  templateUrl: './sales-prod-rep.component.html',
  styleUrls: ['./sales-prod-rep.component.css']
})
export class SalesProdRepComponent implements OnInit {
  fileName= 'sale_product_summary.xlsx';

  searchval:any;
  pageLoadingImg:boolean;
  salesSum:any;
  pageFrom:any;
  curpage:any;
  lastpage:any;
  pgend:any;
  pgstart:any;
  repDate:any;
  period_type:any = 't';
  date1:any;
  date2:any;
  inv_filter:any;
  inv_val1:any;
  inv_val2:any;
  vat_filter:any;
  vat_val1:any;
  vat_val2:any;
  gd_id:any;
  mfs: any;prd_manufact_id:any;

  // purch_qty_filter:any;
  // purch_qty:any;
  // purch_qty2:any;
  // barcode_filter:any;
  // bar_val1:any;
  // bar_val2:any;
  prd_id:any;
  cat_id:any;
  added_by:any;
  products:any;catagories:any;
  users:any;


  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  summary: any;
  godowns: any;

  rept_type = [
    { id: 1, name: 'Sold & Returns' },
    { id: 2, name: 'Sold' },
    { id: 3, name: 'Returns' },
  ];
  rep_type: number;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  subcatagories: any;
  up: any;
  vans: any;
  ExprepDate: any;
  ExpsalesSum: any;
  Expsummary: any;
  exportLoader: boolean;
  branch_display_name:any;
  branch_name:any;
  branch_display_code:any;
  branch_address:any;
  customer: any;
  custName: any;
  country_dec: string;
  cur_lang: string;
  SaleProdRepInv: any;
  unitwise: any;
  heads: any[];
  total_avg_unit_wise: any;
  group_name: any;
  salesub_qty: any;
  total_sold_qty: any;
  usr_hide_purch_cost: any;
  cmp_tax: any;
  rate: any;
  vat: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    if(this.cmp_tax==2){
     this.rate= this.translate.instant('Average GST Rate'),
     this.vat=this.translate.instant('GST Amount')
    }else{
      this.rate= this.translate.instant('Sales.Avg_Vat_Rate'),
      this.vat=this.translate.instant('Purchase.vat_amount')
    }
    this.langChange();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');
   this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.up = this.coreService.getUserPrivilage();
    this.rep_type = 2;
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.pageLoadingImg = true;
    let searchval = new FormData(); 
    searchval.append('rep_type','2');
    searchval.append('period_type','t');
    this.salesSum = [];

    this.apiService.salesProductReport(searchval, 1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      this.salesSum = res['data']['data'];
      this.summary = res['data'];

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.searchUser('');
    this.getGodownList();

    this.country_dec=this.coreService.setDecimalLength();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.sold_rtn','Common.sold','Purchase.Returned','Common.this_month']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.rept_type = [
        { id: 1, name: res['Common.sold_rtn'] },
        { id: 2, name: res['Common.sold'] },
        { id: 3, name: res['Purchase.Returned'] },
      ];
    });

  }
  ngAfterContentInit(){
    
    this.searchVans('');
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }

    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }


  ngOnDestroy(){
    $('.close').click();
 
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    // console.log(formdata.value.unitwise);
    this.unitwise = formdata.value.unitwise;

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.salesSum = [];

    this.apiService.salesProductReport(formdata.value, pageno).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.salesSum = res['data']['data'];
      // this.custName = res['data']['data'];
      if(formdata.value.rep_type == '1'){ 
        this.apiService.getsaleReturnTotal(formdata.value).subscribe((tot: Sales) => { // to get summary of report
          console.log('sale and return');
        this.summary = tot['data'];
        });
      }else{
        this.summary = res['data'];

      }

      this.pageFrom = res['data']['from'];
      this.curpage =  res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
// search customer
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });
  
  }
  
  
  // search product
  selectProd(name: string) {

    console.log("test lang");
    console.log(this.translate.currentLang);

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      this.subcatagories.push({ "subcat_id": 57, 'subcat_name': 'NONE' });
    });
  }

  searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.users = res['data'];                  
        });
 }

 searchVans(search: string) {
  const searchval = new FormData();
  searchval.append('van_name', search);
  this.apiService.searchVans(searchval).subscribe((res) => {
    this.vans = res['data'];
  this.vans.push({ "van_id": 'All', 'van_name': 'All' });
  });

}

 getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
         });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(Expformdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      Expformdata.value.export = 'export';
      
      if (Expformdata.value.period_type === 'c') {
        Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
        Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      }
      this.apiService.salesProductReport(Expformdata.value, 1).subscribe((res: Sales) => {
        this.ExprepDate = res.data.Date;
        this.ExpsalesSum = res['data']['data'];
        if(Expformdata.value.rep_type == '1'){ 
          this.apiService.getsaleReturnTotal(Expformdata.value).subscribe((tot: Sales) => { // to get summary of report
            // console.log('sale and return');
          this.Expsummary = tot['data'];
          });
        }else{
          this.Expsummary = res['data'];
  
        }
  
        Expformdata.value.export = '';
     
      
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
   
    Expformdata.value.export = '';
    });



    }

      // pdf download
  generatePdf(Expformdata: { value: any; }) {

    this.exportLoader = true;

    Expformdata.value.export = 'export';
      
    this.apiService.salesProductReport(Expformdata.value, 1).subscribe((res: Sales) => {
      this.ExprepDate = res.data.Date;
      this.ExpsalesSum = res['data']['data'];
      this.SaleProdRepInv = res.data;

      console.log(this.SaleProdRepInv);
      

      if(Expformdata.value.rep_type == '1'){ 
        this.apiService.getsaleReturnTotal(Expformdata.value).subscribe((tot: Sales) => { // to get summary of report
          // console.log('sale and return');
        this.Expsummary = tot['data'];
        });
      }else{
        this.Expsummary = res['data'];

      }

      Expformdata.value.export = '';

    if(this.unitwise){
      this.heads = [

        this.translate.instant('Common.prd_name'),
        this.translate.instant('Common.itm_code'),
        this.translate.instant('Common.category'),
        this.translate.instant('Common.sub_category'),
        this.translate.instant('Sales.Avg_Rate'),
        this.translate.instant('HOME.unit_wise_qty'),
        this.translate.instant('Sales.Sales_Amt'),
        this.rate,
        this.vat
      ];

    }
    else{

      this.heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('Common.itm_code'),
        this.translate.instant('Common.category'),
        this.translate.instant('Common.sub_category'),
        this.translate.instant('Sales.Avg_Rate'),
        this.translate.instant('Common.Sold_Qty'),
        this.translate.instant('Sales.Sales_Amt'),
        this.rate,
        this.vat
      ];

    }

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 65;
      const data = [];
      const priceGroup = [];

      this.SaleProdRepInv.data.forEach(item=>{
        item.items.forEach(item1=>{
          const priceGroup =[];
          if(  item1.price_group){
            item1.price_group.forEach(item2=>{
              priceGroup.push([
                this.group_name =item2.group_name,
                this.salesub_qty =item2.salesub_qty 
              ])
              
            })
            
          }
          

          console.log(priceGroup);

          
          

          if(this.unitwise){
            data.push([
              item1.prd_name + '\n' + item1.prd_barcode, 
              item1.prd_code, 
              item1.cat_name, 
              item1.subcat_name, 
              this.currencyPipe.transform(item1.total_avg_unit_wise, '', '', this.country_dec),
              item1.total_sold_qty,
              this.currencyPipe.transform(item1.sale_amount, '', '', this.country_dec),
              this.currencyPipe.transform(item1.avg_tax, '', '', this.country_dec),
              this.currencyPipe.transform(item1.tax_amt, '', '', this.country_dec)
            ])
          } else{
            data.push([
              item1.prd_name + '\n' + item1.prd_barcode, 
              item1.prd_code, 
              item1.cat_name, 
              item1.subcat_name, 
              this.currencyPipe.transform(item1.sale_avg_amount, '', '', this.country_dec),
              item1.salesub_qty + '\n' + priceGroup.map(item => `${item[0]}  ${item[1]}`).join('\n') ,
              this.currencyPipe.transform(item1.sale_amount, '', '', this.country_dec),
              this.currencyPipe.transform(item1.avg_tax, '', '', this.country_dec),
              this.currencyPipe.transform(item1.tax_amt, '', '', this.country_dec)
            ])
          }
          
        })
      });

      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Sales.Sold_Products_Summary_Product_Based')}`,  valueXPos, headerHeight + 10);
  
      if(this.ExprepDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 15);
      }
      
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Sales_Amt')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.SaleProdRepInv.tot_inv_amt, '', '', this.country_dec),  valueXPos, headerHeight + 20);

      if(this.cmp_tax==2){
        doc.setFontSize(10);
      doc.text(`${this.translate.instant('Total GST Amount')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.SaleProdRepInv.total_vat_amt, '', '', this.country_dec),  valueXPos, headerHeight + 25);

      }else{
        doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Vat_Amt')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.SaleProdRepInv.total_vat_amt, '', '', this.country_dec),  valueXPos, headerHeight + 25);
      }
      

      if(this.cmp_tax==2){
        doc.setFontSize(10);
      doc.text(`${this.translate.instant('Total Average GST Amount')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.SaleProdRepInv.total_avg_vat_amt, '', '', this.country_dec),  valueXPos, headerHeight + 30);

      }else{
        doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Avg_Vat_Amt')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.SaleProdRepInv.total_avg_vat_amt, '', '', this.country_dec),  valueXPos, headerHeight + 30);
      }
      

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.SaleProdRepInv.total_qty, '', '', this.country_dec),  valueXPos, headerHeight + 35);
      
      // console.log("unitwise" + this.unitwise);

      if(this.unitwise){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.unit_wise_rpt')}`, lblXPos, headerHeight + 40);
        doc.setFontSize(10);
        doc.text(':  ',  valueXPos, headerHeight + 40);
      }
      

      if(this.SaleProdRepInv.reportType){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + this.SaleProdRepInv.reportType,  valueXPos, headerHeight + 45);
      }

      if(this.SaleProdRepInv.van){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.VAN')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + this.SaleProdRepInv.van.van_name, valueXPos, headerHeight + 45);
      }

      doc = this.excelService.addTableToPdf(doc, headerHeight + 50, lblXPos, this.heads, data, null);

    doc.save(`Sold Products Summary (Product Based).pdf`)
      
    this.exportLoader = false;
      
  });
    

}

}
