import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';

import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-visiting-rep',
  templateUrl: './visiting-rep.component.html',
  styleUrls: ['./visiting-rep.component.css']
})
export class VisitingRepComponent implements OnInit {
  fileName = 'visiting_report.xlsx';

  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  is_storage=[
    { id: 0, name: 'No' },
    { id: 1, name: 'Yes' },
  ];
  vans: any;
  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  curpage: number;
  lastpage: number;
  repDate: any;
  repDates: any;
  visiting_rpt: any=[];
  currentMonth: any;
  period_type: any;
  from: any;
  ttl_visit: any;
  priceGroupList: any;
  filter_branch_id: any;
  userType: any;
  branch_all: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_address: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.filter_branch_id = 1;
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth() + 1;
    this.period_type=this.currentMonth;
    const searchval = new FormData();
    searchval.append("period_type", this.currentMonth);
    searchval.append("filter_branch_id", this.filter_branch_id);
    this.pageLoadingImg = true;
    this.apiService.vanVisitingReport(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      this.visiting_rpt=res.data;
      this.repDate=res.Date;
      this.repDates=res.Dates;
      this.ttl_visit=res.totl_visitors;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.from = res.from;
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });

     this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
  }
  langChange(){
    this.translate.get(['Common.January', 'Common.February','Common.March', 'Common.April','Common.May', 'Common.June','Common.July','Common.August','Common.September','Common.October','Common.November','Common.December','Common.No','Common.Yes']).subscribe((res: string) => {    
     
 
      this.filterDate = [
        
        { id: 1, name: res['Common.January' ]},
    { id: 2, name: res['Common.February'] },
    { id: 3, name: res['Common.March' ]},
    { id: 4, name: res['Common.April'] },
    { id: 5, name: res['Common.May'] },
    { id: 6, name: res['Common.June'] },
    { id: 7, name: res['Common.July'] },
    { id: 8, name: res['Common.August'] },
    { id: 9, name: res['Common.September'] },
    { id: 10, name: res['Common.October'] },
    { id: 11, name: res['Common.November' ]},
    { id: 12, name: res['Common.December' ]},         
    ];
    this.is_storage = [
      { id: 0, name: res['Common.No'] },
      { id: 1, name: res['Common.Yes'] },
      ];
    });

  


  }
  pageNext(formdata: { value: any; },pageno){

    this.pageLoadingImg = true;
    

    this.apiService.vanVisitingReport(formdata.value,pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      // const newItem =res.data;
      // this.visiting_rpt.push(newItem);
      // console.log(this.visiting_rpt);
      
      this.visiting_rpt=res.data;
      this.repDate=res.Date;
      this.repDates=res.Dates;
      this.ttl_visit=res.totl_visitors;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.from = res.from;
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      // this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('edit-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  exportEXCL(formdata: { value: any; }) {

    this.exportLoader = true;

    formdata.value.export = 'export';

    this.apiService.vanVisitingReport(formdata.value,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      // const newItem =res.data;
      // this.visiting_rpt.push(newItem);
      // console.log(this.visiting_rpt);
      
      this.visiting_rpt=res.data;
      this.repDate=res.Date;
      this.repDates=res.Dates;
      this.ttl_visit=res.totl_visitors;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
    
 
 
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
        
 
     
     });
  }

}
