import { DatePipe, formatDate } from '@angular/common';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-post-dated-cheque',
  templateUrl: './post-dated-cheque.component.html',
  styleUrls: ['./post-dated-cheque.component.css'],


})
export class PostDatedChequeComponent implements OnInit {


  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;

  public date: Date;

  public disabledDates = (date: Date): boolean => {
    const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date);
    return (date.getTime() < yesterday.getTime());
  };


  status = {
    '0': 'Deleted',
    '1': 'Pending',
    '2': 'Submited',
    '3': 'Bounced',
    '4': 'Cancelled'
  };

  type={
    '0':'Payment',
    '1':'Reciept'

  }

  totalItem = 0;
  curpage = 1;
  lastpage = 1;
  cheq_issue_date = new Date();
  info: any;
  from: any;

  printError = []; errObjArr = {
    dateErr: null, bncAmntErr: null, descrptErr: null,

  };

  curForm = 'Submit';
  nextId = '';
  langText = {
    submit: '',
    bounce: '',
    cancel: '',
  }

  pdc_no: any;
  pdc_description: any;
  cheq_no: any;
  // cheq_date: Date;
  pds_amnt: any;
  pdcNo: any;
  isValidDate: boolean;
  model: any;
  public cheq_date: Date = new Date();
  actn_note: string;
  bnc_amnt: number;
  perPage = 20;
  pageLoadingImg: boolean;
  isSubmitInProg: boolean;
  cust_name: any;
  bank_name: any;
  pdc_type_id: any;
  base_url: string;
  pdcImgUrl: string;
  pd_type_id: any;
  country_dec: string;
  cust_alias: any;
  bank_alias: any;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,) { }

  ngOnInit() {


    this.translate.get(['Common.submit', 'Common.bounce', 'Common.cancel']).subscribe((res: string) => {
      this.langText.submit = res['Common.submit'];
      this.langText.bounce = res['Common.bounce'];
      this.langText.cancel = res['Common.cancel'];
      this.curForm = this.langText.submit;
      // this.up = this.coreService.getUserPrivilage();
      // this.getAllGroup();

    });

    this.dateWisePDClist();
    this.base_url = this.apiService.PHP_API_SERVER;

    this.country_dec=this.coreService.setDecimalLength();
  }



  validateAndSubmit(form) {

    this.printError = [];
    // if ((this.curForm != 'Submit' && this.curForm != 'Cancelled Cheque') && (form.value.cheque_bounced_amount == '' || form.value.cheque_bounced_amount == undefined)) {
    //   this.errObjArr.bncAmntErr = "t";
    //   this.printError.push({ 'fieldErr': 'Required' });

    // } else {
    //   this.errObjArr.bncAmntErr = "f";
    // }

    if (this.curForm != 'Submit' && (form.value.reason == '' || form.value.reason == undefined)) {
      this.errObjArr.descrptErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.descrptErr = "f";
    }

   


    if (this.printError.length <= 0) {

      if (confirm('Do you wish to continue?')) {

        this.isSubmitInProg = true;
        let callFunction = '';


        if (this.curForm === this.langText.submit) {

          callFunction = 'submitPDC';
        }

        // this.isSubmitInProg = true;


        if (this.curForm === this.langText.bounce) {
          callFunction = 'bouncePDC';


        }
        if (this.curForm === this.langText.cancel) {
          callFunction = 'cancelPDC';


        }

        form.value.pdc_no = this.pdc_no;
        form.value.pdc_date = (form.value.pdc_date) ? this.apiService.formatDate(form.value.pdc_date) : form.value.pdc_date;
        this.apiService[callFunction](form.value).subscribe((res) => {

          this.isSubmitInProg = false;
          if (res.error != null) {
            this.errObjArr = res.error;
          } else {
            this.closeModal.nativeElement.click();
            this.clearForm();
            this.dateWisePDClist();
            this.apiService.updatePDCNotification();
          }





        });
      }

    }




  }

  clearForm() {
    this.date = new Date();
    this.actn_note = '';
    this.bnc_amnt = null;

  }

  dateWisePDClist() {
    this.pageLoadingImg=true;

    this.apiService.dateWisePDClist({ cheq_issue_date: this.cheq_issue_date }).subscribe((res) => {
      this.pageLoadingImg=false;
      this.info = res['data']['data'];
      this.from = res.data.from;



    });
  }
  openSubmit(pdc_no) {

    this.curForm = "Submit";
    this.apiService.getPDCview({ pdc_no: pdc_no }).subscribe((res) => {

      this.pdc_no = res.pdc_no;

      this.date = new Date(res.cheq_issue_date);
      this.pdc_description = res.pdc_description;
      this.cheq_no = res.cheq_no;
      this.cust_name = res.ledger_name;
      this.cust_alias = res.ledger_alias || res.ledger_name_ar || res.ledger_name;
      this.bank_name = res.acc_ledger_name;
      this.bank_alias = res.acc_ledger_alias || res.acc_ledger_name_ar || res.acc_ledger_name ;
      this.pd_type_id = res.pdc_vchtype_id;
      this.cheq_date = new Date(res.cheq_issue_date);
      this.pds_amnt = res.pdc_amount;
      this.pdcImgUrl = this.base_url + '/' + res.cheq_img;

      // console.log(this.cheq_date);

    });

  }

  openBounce(pdc_no) {

    this.curForm = "Bounced Cheque";
    this.apiService.getPDCview({ pdc_no: pdc_no }).subscribe((res) => {

      this.pdc_no = res.pdc_no;

      this.date = new Date(res.cheq_issue_date);
      this.pdc_description = res.pdc_description;
      this.cheq_no = res.cheq_no;
      this.cust_name = res.ledger_name;
      this.cust_alias = res.ledger_alias || res.ledger_name_ar || res.ledger_name;
      this.bank_name = res.acc_ledger_name;
      this.bank_alias = res.acc_ledger_alias || res.acc_ledger_name_ar || res.acc_ledger_name ;
      this.pd_type_id = res.pdc_vchtype_id;
      this.cheq_date = new Date(res.cheq_issue_date);
      this.pds_amnt = res.pdc_amount;
      this.pdcImgUrl = this.base_url + '/' + res.cheq_img;


    });

  }

  openCancel(pdc_no) {

    this.curForm = "Cancelled Cheque";
    this.apiService.getPDCview({ pdc_no: pdc_no }).subscribe((res) => {

      this.pdc_no = res.pdc_no;

      this.date = new Date(res.cheq_issue_date);
      this.pdc_description = res.pdc_description;
      this.cheq_no = res.cheq_no;
      this.cust_name = res.ledger_name;
      this.cust_alias = res.ledger_alias || res.ledger_name_ar || res.ledger_name;
      this.bank_name = res.acc_ledger_name;
      this.bank_alias = res.acc_ledger_alias || res.acc_ledger_name_ar || res.acc_ledger_name ;
      this.pd_type_id = res.pdc_vchtype_id;
      this.cheq_date = new Date(res.cheq_issue_date);
      this.pds_amnt = res.pdc_amount;
      this.pdcImgUrl = this.base_url + '/' + res.cheq_img;


    });



  }
  searchInp(e) {
    let searchval = new FormData();
    this.date = new Date();
    searchval.append("search", e);
    searchval.append("cheq_issue_date", this.apiService.formatDate(this.date));



    this.pageLoadingImg = true;
    this.apiService.filterCheckedPDC(searchval, 1, this.perPage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }


}
