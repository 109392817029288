import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';
import { ApiService } from '../../../service/api.service';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '150px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  template = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: '',
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: '',
    sign_section_display: 0
  }


  paper: any;
  resultobj: any = {};
  hdr_img: any;
  tempImgUrl: any;
  logoImgUrl: any;
  header_image: any;
  header_logo: any;
  pageLoadingImg: boolean;
  print_style: any;
  qtn_print_style: any;
  po_print_style: any;
  settings: any;
  hide_item_discount: any;
  hide_vat: any;
  footer_image: any;
  footImgUrl: any;
  sr_print_style: any;
  tempSOImgUrl: any;
  footSOImgUrl: any;
  so_print_style: any;
  dn_print_style: any;
  sign_section: any;
  wtr_mark_img: any;
  waterMarkImg: any;
  branch_e_invoice_enabled = 0;
  eInvoiceSupport: boolean=false;
  einv_simplfied_print_style = 1;
  cmp_tax: any;
  
  constructor(private apiService: ApiService, private coreService: CoreService) { }


  ngOnInit() {
    this.paper = 'A4-P';
    this.tempImgUrl = '';
    this.header_image = null;
    this.header_logo = null;
    this.pageLoadingImg = false;
    this.hide_item_discount = 0;
    this.hide_vat = 0;
    this.getSalesSetting();
    this.getTemplete();

    this.cmp_tax = this.coreService.getUserData('cmpny_tax');

    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');

    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
      }
    });
  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.print_style = this.settings.print_style;
        this.qtn_print_style = this.settings.qtn_print_style;
        this.qtn_print_style = this.settings.qtn_print_style;
        this.po_print_style = this.settings.po_print_style;
        this.sr_print_style = this.settings.sale_rtn_print_style;
        this.so_print_style = this.settings.so_print_style;
        this.dn_print_style = this.settings.dn_print_style;
        this.sign_section = this.settings.sign_section;
        this.einv_simplfied_print_style = this.settings.einv_simplfied_print_style;
      } else {
        this.print_style = 1;
        this.qtn_print_style = 1;
        this.po_print_style = 1;
        this.sr_print_style = 1;
        this.so_print_style = 1;
        this.einv_simplfied_print_style = 1;
      }
    });

  }

  updatePrintStyle(st_id: any) {
    let searchval = new FormData();
    searchval.append("st_id", st_id);
    this.pageLoadingImg = true;
    this.apiService.updatePrintStyle(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.print_style = st_id;
        this.coreService.showMessage('Print Template Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }
  updateQtnPrintStyle(st_id: any) {
    let searchval = new FormData();
    searchval.append("st_id", st_id);
    searchval.append("type", "qtn");
    this.pageLoadingImg = true;
    this.apiService.updatePrintStyle(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.qtn_print_style = st_id;
        this.coreService.showMessage('Print Template Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }
  updateEinvoiceSimplifiedPrintStyle(st_id: any) {
    if(confirm('Do you wish to continue?')){
      let searchval = new FormData();
      searchval.append("st_id", st_id);
      searchval.append("type", "einvSimplfd");
      this.pageLoadingImg = true;
      this.apiService.updatePrintStyle(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
        if (res.data) {
          this.einv_simplfied_print_style = st_id;
          this.coreService.showMessage('Print Template Saved');
        }
      }, (error) => {
        this.pageLoadingImg = false;
      });
    }
  }
  updatePurchPrintStyle(st_id: any) {
    let searchval = new FormData();
    searchval.append("st_id", st_id);
    searchval.append("type", "po");
    this.pageLoadingImg = true;
    this.apiService.updatePrintStyle(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.po_print_style = st_id;
        this.coreService.showMessage('Print Template Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  updateSRPrintStyle(st_id: any) {
    let searchval = new FormData();
    searchval.append("st_id", st_id);
    searchval.append("type", "SR");
    this.pageLoadingImg = true;
    this.apiService.updatePrintStyle(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.sr_print_style = st_id;
        this.coreService.showMessage('Print Template Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  updateSOPrintStyle(st_id: any) {
    let searchval = new FormData();
    searchval.append("st_id", st_id);
    searchval.append("type", "SO");
    this.pageLoadingImg = true;
    this.apiService.updateSOPrintStyle(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.so_print_style = st_id;
        this.coreService.showMessage('Print Template Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  updateDNPrintStyle(st_id: any) {
    let searchval = new FormData();
    searchval.append("st_id", st_id);
    searchval.append("type", "DN");
    this.pageLoadingImg = true;
    this.apiService.updateDNPrintStyle(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.dn_print_style = st_id;
        this.coreService.showMessage('Print Template Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  changePaper(paper_type) {
    this.paper = paper_type;
  }

  submitSettings(data) {

    let searchval = new FormData(data);

    // if(this.header_logo)
    //     searchval.append('logo_img',this.header_logo,this.header_logo.name);
    // if(this.header_image)
    //     searchval.append('header_image',this.header_image,this.header_image.name);

    searchval.append('sign_section_text', this.sign_section);
    // searchval.append('title_text',data.title_text);
    // searchval.append('prnt_header_logo',data.logo);
    // searchval.append('prnt_header_type',data.header_type);
    // searchval.append('footer_text',data.footer_text);    
    // console.log(searchval);
    this.pageLoadingImg = true;

    this.apiService.updateSettings(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (!res.data.error) {
        this.coreService.showMessage('Updated Successfully');
        data.reset();
        this.getTemplete();
        this.getSalesSetting();
      } else {
        this.resultobj = res.data.error;

      }
    });
  }

  headerType(val) {
    if (val == '2') {
      $('#header_image').val('');
    }
  }

  logoType(val) {
    // console.log(val);
    if (!val) {
      // console.log('logo');
      $('#logo_img').val('');
    }

  }
  displayPage(val) {

    if (val) {
      $("#popup").slideDown();
    } else {
      $("#popup").slideUp();
    }

  }

  resetSettings() {
    this.template = this.savedTemplate;
  }

  resetDefaultSettings() {
    this.template = {
      title_text: 'SALES INVOICE فاتورة',
      color: '#000',
      email_color: '#000',
      font_size: '12',
      orientation: "P",
      paper: 'A4-P',
      m_left: '',
      m_top: '',
      m_right: '',
      m_bottom: '',
      logo: false,
      header_type: 1,
      header_img: [],
      header_all_page: false,
      header_due: false,
      sl_no: false,
      item_desc: false,
      tax_detail: false,
      tax_type: 0,
      name_type: 'N',
      footer_text: ''
    }
    this.logoImgUrl = '';
    this.tempImgUrl = '';
    this.footImgUrl = '';
  }

  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.header_image = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.tempImgUrl = event.target.result;
    }

  }

  soHeadfile(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    // this.header_image = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.tempSOImgUrl = event.target.result;
    }

  }
  footfile(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.footer_image = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.footImgUrl = event.target.result;
    }

  }

  sofootfile(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    // this.footer_image = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.footSOImgUrl = event.target.result;
    }

  }

  addlogo(file) {
    // console.log(file);
    //this.template.header_img = file;
    this.header_logo = file.target.files[0];

    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.logoImgUrl = event.target.result;
      // console.log(this.logoImgUrl);

    }

  }

  WtrmrkImg(file) {
    this.wtr_mark_img = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.waterMarkImg = event.target.result;
    }

  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: (res.data.prnt_header_logo ? true : false),
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt,
        sign_section_display: res.data.prnt_sign_section_display
      }

      this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path;
      this.template = this.savedTemplate;

      this.hide_item_discount = res.data.hide_item_discount;
      this.hide_vat = res.data.hide_vat;
      this.hide_vat = res.data.hide_vat;

    });
  }
  // let styleElement = document.getElementById('print-style');
  // styleElement.append('@media print { @page { size: A4 landscape; } }')

}
