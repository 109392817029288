import { Component, OnInit } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-free-goods-reports',
  templateUrl: './free-goods-reports.component.html',
  styleUrls: ['./free-goods-reports.component.css']
})
export class FreeGoodsReportsComponent implements OnInit {

  fileName = 'free_goods_reports.xlsx';

  units_all: any;
  products: any[];
  unit_id: string;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  summary: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  total_qty: any;
  exportLoader: boolean;
  repDate: any;
  Expsummary: any;
  exp_total_qty: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  godown_all: any;
  supplierAll:any;
  gd_id: any;
  is_godown: any;
  prd_id: any;
  period_type = "";
  supp_id:any;
  byderived: any;
  detailed:any;
  export:any;
  cur_lang: string;
  country_dec: string;
  filter_branch_id: any;
  branch_all: any;
  userType: any;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    // this.filter_branch_id = 1;
    this.getAllBranch();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();  
    });
    this.gd_id=''; 
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.apiService.freeGoodsReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.is_godown = res['data']['godown'];
      this.total_qty = res.data.all_total1;
     
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // this.colspan=8;
      // if(this.summary.){

      // }
      
      
    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  this.getGodownList();
  this.searchSupplier('');
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.userType = this.coreService.getUserData('user_type');
  
  


  }
  langChange(){
    this.translate.get(['Common.today','Common.last_day','Common.last_week','Common.this_month','Common.last_yr','Common.cust_date','Common.all',]).subscribe((res: string) => {

     this.periodTypes = [
        { id: 't', name:res['Common.today'] },
        { id: 'ld', name:res['Common.last_day'] },
        { id: 'lw', name:res['Common.last_week'] },
        { id: 'lm', name:res['Common.this_month'] },
        { id: 'ly', name:res['Common.last_yr'] },
        { id: 'c', name:res['Common.cust_date'] },
        { id: '', name:res['Common.all'] }
      ];
    
    });

  } 
 


  pageNext(formdata: { value: any; }, pageno: any) {

    console.log("hhhhhhhhhhhhhhhhhhhhhh");
    console.log(formdata);
    formdata.value.export = '';

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

  // console.log(formdata.value.hideqty);
  

    this.apiService.freeGoodsReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.is_godown = res['data']['godown'];
      this.total_qty = res.data.all_total1;
      console.log(this.is_godown);
     

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    // this.clear_form();

  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


    // search product
    selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

    //search Units
  getUnits(prd_id) {
    this.unit_id=null;
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getUnitsItemwise(searchval).subscribe((res) => {
      this.units_all = res['data'];

    });

  }

// godown list
  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godown_all = res['data'];  
      this.godown_all.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }


  // form search supplier
  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }



  exportEXCL(formdata: { value: any; }, pageno = 1) {
    formdata.value.export = 'export';
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.exportLoader = true;

    this.apiService.freeGoodsReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.Expsummary = res['data']['data'];
      this.exp_total_qty = res.data.all_total1;

      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });

  }

  // clear_form(){
  //   this.gd_id ="";
  //   this.prd_id ="";
  //   this.period_type ="";
  //   this.supp_id ="";
  //   this.unit_id = "";
  //   this.byderived ="";
  //   this.detailed ="";
  //   this.export ="";

  // }


    // pdf download
    generatePdf() {
  
      const heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('Table.item_name'),
        this.translate.instant('Purchase.unit'),
        this.translate.instant('HOME.qty')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.summary.forEach((item,i)=>{
          item.unit_wise.forEach(item1=>{
            data.push([
              i+1,
              item.prd_name,
              item1.unit.unit_name,
              this.currencyPipe.transform(item1.net_total, '', '', this.country_dec)
            ])
          })
        
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('HOME.free_goods_Reports')}`,  valueXPos, headerHeight + 15);


        if(this.byderived){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Derived_qty')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.total_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 15);
        }
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
    
       
        doc.save(`Free Goods Report.pdf`);
  
  
      
    }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      // this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

}
