import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-popup',
  templateUrl: './customer-popup.component.html',
  styleUrls: ['./customer-popup.component.css']
})
export class CustomerPopupComponent implements OnInit {
  @Input()cust_id;
  @Input()last_cust_code;
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @Output() customerUpdated = new EventEmitter();
  curForm = 'Create';
  customer = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    cr_no: '',
    op_bal: 0,
    brch_id:0,
    usr_type:0,
    cust_state: null,
    is_general_ledger:0,
    op_bal_type: true,
    inv_limit_type: false,
    storage_eqp: false,
    inv_limit: null,
    default_currency_id:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: '',
    credit_limit: 0,
    default_sales_agent:0,
    default_cus_category:0,
    enable_sale_below_mrp:0,
    is_e_invoice:false,
    einv_scheme_id: 'TIN',
    einv_scheme_no:'',
    einv_reg_name:'',
    einv_vat_no:'',
    einv_city:'',
    einv_city_sub_div:'',
    einv_street:'',
    einv_plot_identfctn:'',
    einv_build_no:'',
    einv_postal_code:'',
  };
  
  resultobj: any = {};
  customer_category :any;
  isSameAddr = false;
  storage = {
    '0': 'No',
    '1': 'Yes'
  };

  is_sup: any;
  userType: any;
  branch_all: any;
  sales_agents: any;
  sale_agent: any;
  enable_disable_sales_below_mrp = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  country_dec: string;
  sales_settings: any;
  inv_limit: any;
  equipment = [
    { id: 0, name: 'No' },
    { id: 1, name: 'yes' },


  ];

  cr_limit = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Greater Than 0' },
  ];

  due_limits = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Greater Than 0' },
  ];

  scheme_types = [
    { id: 'TIN', name: 'Tax Identification Number' },
    { id: 'CRN', name: 'Commercial registration number' },
    { id: 'MOM', name: 'Momra license' },
    { id: 'MLS', name: 'MLSD license' },
    { id: 'SAG', name: 'Sagia license' },
    { id: 'NAT', name: 'National ID' },
    { id: 'GCC', name: 'GCC ID' },
    { id: 'IQA', name: 'Iqama Number' },
    { id: 'PAS', name: 'Passport ID' },
    { id: 'OTH', name: 'Other ID' },
  ];

  up: any;
  vanLineList: any =  [];
  priceGroupList: any =  [];
  isSubmitInProg = false;
  tabIndex = 0;
  currencies: any;
  checkIsGL: boolean;
  other_branch_dt: any;
  cmp_tax: any;
  states: any;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnChanges(changes) {
    console.log('ngOnChanges this.last_cust_code');
    console.log(this.last_cust_code);
    
    this.clearForm();
    if (this.cust_id != 0) {
      this.isSubmitInProg = true;
      this.curForm = 'Update';
      this.getSingleItem(this.cust_id);
      let searchval = new FormData();

      searchval.append("cust_id", this.cust_id);
      this.apiService.isCustomerDeletable(searchval).subscribe((res) => {
        // this.delete_ledger_id = res['ledger_id'];
        this.other_branch_dt = res['other_branch'];
        // console.log(this.delete_ledger_id);
      });
      // console.log(id);
      // let searchval = new FormData();

      // searchval.append("cust_id", this.cust_id);
      // this.apiService.isCustomerDeletable(searchval).subscribe((res) => {
      //   this.delete_ledger_id = res['ledger_id'];
      //   // console.log(this.delete_ledger_id);
      // });
    } else {
      this.curForm = 'Create';
    }
  }

  getSingleItem(id){
    this.apiService.getCustomer(this.cust_id).subscribe((res) => {
      this.isSubmitInProg = false;
      this.is_sup = res.data.is_sup;
      const data = res.data;

      // console.log("aaaaaaa");
      // console.log(data.cust_category_id);

      
      // console.log(data);
      this.customer = {
        id: data.cust_id,
        ledger_id: data.ledger_id,
        name: data.name,
        alias: data.alias,
        code: data.cust_code,
        email : data.email,
        mobile: data.mobile,
        vat_no: data.vat_no,
        cr_no: data.cr_no,
        default_currency_id:data.default_currency_id,
        is_general_ledger:data.display_in_all_branch,
        op_bal: Math.abs(data.opening_balance),
        cust_state:  (data.state_id)?data.state_id:null,
        op_bal_type: (data.opening_balance < 0) ? true : false,
        inv_limit_type:  (data.inv_due_limit_no)?true:false,
        storage_eqp:  (data.is_storage_eqp)?true:false,
        inv_limit:(data.inv_due_limit_no)?data.inv_due_limit_no:'',
        brch_id:Number(data.branch_id),
        usr_type:0,
        is_e_invoice: (data.is_e_invoice == 1) ? true : false,
        einv_scheme_id: data.einv_scheme_id,
        einv_scheme_no: data.einv_scheme_no,
        einv_reg_name: data.einv_reg_name,
        einv_vat_no: data.einv_vat_no,
        einv_city: data.einv_city,
        einv_city_sub_div: data.einv_city_sub_div,
        einv_street: data.einv_street,
        einv_plot_identfctn: data.einv_plot_identfctn,
        einv_build_no: data.einv_build_no,
        einv_postal_code: data.einv_postal_code,
        add: {
          addr: data.cust_home_addr,
          zip: data.zip,
          city: data.city,
          country: data.country,
          state: data.state,
          state_code: data.state_code,
          fax: data.fax
        },
        del: {
          addr: data.dflt_delvry_addr,
          zip: data.dflt_delvry_zip,
          city: data.dflt_delvry_city,
          country: data.dflt_delvry_country,
          state: data.dflt_delvry_state,
          state_code: data.dflt_delvry_state_code,
          fax: data.dflt_delvry_fax
        },
        due_day: data.due_days,
        van_line: data.van_line_id,
        price_group: data.price_group_id,
        note: data.note,
        credit_limit: data.cust_credit_limit,
        default_sales_agent:data.default_sales_agent,
        default_cus_category:data.cust_category_id,
        enable_sale_below_mrp:data.enable_sale_below_mrp,
      };
      
    });
  }

  ngOnInit() {
    console.log('ngOnChanges this.last_cust_code');
    console.log(this.last_cust_code);
    this.country_dec=this.coreService.setDecimalLength();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    if(this.cmp_tax==2){
      this.getAllState();
    }
    this.langChange();
    this.is_sup =0;
    this.getVanLine();
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.searchBranch('');
    this.getAllAgents();
    this.getSalesSetting();
    this.getAllCustomerCategory();
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });

    this.apiService.getClientSettingBykey({ key: "general_ledger_enable" }).subscribe((res) => {
      if (res["data"]) {
        this.checkIsGL = res["data"]["cs_value"] ? true : false;
      }
    });
    
  }

  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }

  getAllState(){
    let searchval = new FormData();
    this.apiService.getAllState(searchval).subscribe((res) => {
      this.states = res.data;
      
    });
  }



  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  getVanLine() {
    this.apiService.getAllVanlines().subscribe((res) => {
      this.vanLineList = res.data;
    });
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
  }
  searchVanLine(search: string) {
    const searchval = new FormData();
    searchval.append('vanline_name', search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      // console.log(res);
      this.vanLineList = res.data;
    });
  }

  getAllCustomerCategory()
  {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
    });
  }
  updateAddress() {
    if (this.isSameAddr) {
      this.customer.del = JSON.parse(JSON.stringify(this.customer.add));
    } else {
      // this.customer.del = {
      //   addr: '',
      //   zip: '',
      //   city: '',
      //   country: '',
      //   state: '',
      //   state_code: '',
      //   fax: ''
      // }
    }
  }

  langChange(){
    this.translate.get(['Common.Disable', 'Common.Enable']).subscribe((res: string) => {    
     
      
      this.enable_disable_sales_below_mrp = [
        { id: 0, name:  res['Common.Disable'] },
        { id: 1, name:  res['Common.Enable'] },
        
      ];
    });

  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
       
        this.inv_limit=this.sales_settings.inv_due_limit;
      } else {
       
      }
      // this.updatePayment();
    });

  }

  validateAndSubmit(){
    this.isSubmitInProg = true;
    let callFunction = '';
    if (this.curForm === 'Create') {
      callFunction = 'createCustomer';
    }
    if (this.curForm === 'Update') {
      callFunction = 'updateCustomer';
    }
    if(this.userType =='ADMIN'){
      this.customer.usr_type = 1;
    }else{
      this.customer.usr_type = 0;

    }

    this.apiService[callFunction](this.customer).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        if (this.curForm === 'Create') {
          this.last_cust_code = res.last_cust_code;
        }
        $('.custPopCloseBtn').click();
        // this.getInfo(this.curpage);
        this.customerUpdated.emit();
        this.coreService.showMessage(res.message);
        this.clearForm();
        this.resultobj = {};
      }

    });
  }

  clearForm() {
    this.tabIndex =0;
    this.curForm = 'Create';
    this.customer = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      cust_state:null,
      mobile: '',
      vat_no: '',
      cr_no: '',
      op_bal: 0,
      brch_id:0,
      usr_type:0,
      is_general_ledger:0,
      op_bal_type: true,
      inv_limit_type: false,
      storage_eqp: false,
      inv_limit: null,
      default_currency_id:0,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: '',
      credit_limit: 0,
      default_sales_agent:0,
      default_cus_category:0,
      enable_sale_below_mrp : 0,
      is_e_invoice:false,
      einv_scheme_id: 'TIN',
      einv_scheme_no: '',
      einv_reg_name:'',
      einv_vat_no:'',
      einv_city:'',
      einv_city_sub_div:'',
      einv_street:'',
      einv_plot_identfctn:'',
      einv_build_no:'',
      einv_postal_code:'',
    };
  }

  addAsSupplier(customer_id) {
    this.isSubmitInProg = true;
    let searchval = new FormData();
    searchval.append("customer_id", customer_id);
    this.apiService.addAsSupplier(searchval).subscribe((res) => {

      this.isSubmitInProg = false;

        
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.closeModal.nativeElement.click();

        this.coreService.showMessage(res.message);
        this.resultobj = {};
    

        this.clearForm();

      } 
    });
  }
  disableOp(is_inter_ledger){
    if(is_inter_ledger){
      this.customer.op_bal=0;
      }
  }

}
