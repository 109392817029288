import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-settings',
  templateUrl: './sales-settings.component.html',
  styleUrls: ['./sales-settings.component.css']
})

export class SalesSettingsComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '150px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
    { id: 3, name: 'Invoice' }
  ];
  enable_disable_without_stock = [
    { id: 0, name: 'Enable' },
    { id: 1, name: 'Disable' }
  ];
  print_name = [
    { id: 1, name: 'Product Name' },
    { id: 2, name: 'Alias' },
    { id: 3, name: 'Both' }
  ];

  search_types = [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'Extended' },
  ];
  duplicate_item = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  sales_note = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  txt_colors = [
    { id: 0, name: 'Green' },
    { id: 1, name: 'Black' },
  ];

  show_pos = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  show_countr = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  show_totl = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  tax_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Sales' }
  ];
  prdct_code = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  search_mthd = [
    { id: 0, name: 'By Barcode' },
    { id: 1, name: 'By Product Name' },
  ];
  enable_disable_sales_below_mrp = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  enable_disable_van_sales_below_mrp = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  search_with_only_brcd = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  show_base_unit_in_print_values = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  inv_due_limits = [
    { id: 0, name: 'No limit' },
    { id: 1, name: 'Enable' },
  ];
  show_ttl_incld_vats = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  display_days = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  sales_sub_descriptions = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  enbl_dsbl = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  show_mob = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Show' },
  ];

  show_brcd = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Show' },
  ];
  disp_bill = [
    { id: 1, name: 'Both' },
    { id: 2, name: 'Reference No. only' },
    { id: 3, name: 'Branch Reference only' },
  ];
  vat_types = [
    { id: 0, name: 'Excl.vat' },
    { id: 1, name: 'Incl.vat' },
  ];

  item_grid_types = [
    { id: 1, name: 'With Warning' },
    { id: 0, name: 'Without Warning' },
    { id: 2, name: 'Block' },
  ];
  stock_dsplys = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Display' },
   
    
  ];

  paytypes = [
    { id: 0, name: 'Cash' },
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Bank' },
    // { id: 3, name: 'Both' },
  ];
  sales_inv_type: any;
  disable_sales_without_stock: any;
  enable_sale_below_mrp:any;
  enable_van_sale_below_mrp:any;
  show_base_unit_in_print:any;
  van_sales_without_stock: any;
  sales_print_name: any;
  search_type: any;
  pageLoadingImg: boolean;
  settings: any;
  sales_default_terms = '';
  duplicate_items = 0;
  slide: boolean;
  sales_notes: any;
  text_color: any;
  show_po: any;
  show_counter: any;
  show_total: any;
  bank_details='';
  tax_type=1;
  show_prd_code: any;
  dflt_search_methd: any;
  search_with_only_barcod: any;
  show_ttl_incld_vat: any;
  inv_due_limit: any;
  display_day: any;
  sales_sub_description : any;
  show_default_unit_in_barcode_search : any;
  show_mobile: any;
  show_bill_no: any;
  show_barcode: any;
  hide_vat: any;
  vat_type: any;
  convert_ref_display: number;
  item_grid_duplicate: any;
  pay_type: any;
  br_stock_disply: any;
  // sales_notes = 0;
  // text_color = 0;
  // show_po=0;
  // show_counter=1;
  // show_total=1;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getSalesSetting();
    this.func();
   
  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.sales_inv_type = this.settings.ss_inv_type;
        this.disable_sales_without_stock = this.settings.disable_sales_without_stock;
        this.enable_sale_below_mrp = this.settings.enable_sale_below_mrp;
        this.enable_van_sale_below_mrp = this.settings.enable_van_sale_below_mrp;
        this.show_base_unit_in_print = this.settings.show_base_unit_in_print;
        this.van_sales_without_stock = this.settings.van_sale_without_stock;
        this.sales_print_name = this.settings.sales_print_name;
        this.search_type = this.settings.search_type;
        this.sales_default_terms = this.settings.sales_default_terms;
        this.duplicate_items = this.settings.sales_duplicate_item;
        this.sales_notes = this.settings.sales_note_enb;
        this.text_color = this.settings.text_color;
        this.show_po = this.settings.show_po;
        this.show_counter = this.settings.show_counter;
        this.show_total = this.settings.show_total;
        this.bank_details = this.settings.bank_details;
        this.tax_type = this.settings.tax_type;
        this.show_prd_code = this.settings.show_prd_code;
        this.dflt_search_methd = this.settings.dflt_search_methd;
        this.search_with_only_barcod = this.settings.search_with_only_barcod;
        this.show_ttl_incld_vat = this.settings.show_ttl_incld_vat;
        this.inv_due_limit = this.settings.inv_due_limit;
        this.display_day = this.settings.display_day;
        this.sales_sub_description =  this.settings.show_sales_sub_des;
        this.show_default_unit_in_barcode_search = this.settings.show_default_unit_in_barcode_search;
        this.show_mobile = this.settings.show_user_mobile;
        this.show_bill_no = this.settings.show_bill_no;
        this.show_barcode = this.settings.show_barcode;
        this.hide_vat = this.settings.hide_vat;
        this.vat_type = this.settings.vat_type;
        this.convert_ref_display = this.settings.display_convert_ref;
        this.item_grid_duplicate = this.settings.sales_item_grid_warning;
        this.pay_type = this.settings.sales_pay_type;
        this.br_stock_disply = this.settings.ss_disply_branch_stk;
      } else { 
        this.sales_inv_type = 1;
        this.disable_sales_without_stock = 0;
        this.enable_sale_below_mrp = 0;
        this.enable_van_sale_below_mrp = 0;
        this.show_base_unit_in_print = 0;
        this.van_sales_without_stock = 1;
        this.sales_print_name = 1;
        this.search_type = 1;
        this.sales_default_terms = '';
        this.duplicate_items = 0;
         this.sales_notes= 0;
         this.text_color= 0;
         this.show_po= 0;
         this.show_counter= 1;
         this.show_total= 1;
         this.bank_details= '';
         this.tax_type= 1;
         this.show_prd_code= 0;
         this.show_default_unit_in_barcode_search = 0;
         this.convert_ref_display = 0;
      }
    });

  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateSalesSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  func(){
    this.slide = true;
  }

  langChange(){
    this.translate.get(['Common.Enable','Common.No_limit','Common.With_Warning','Common.Without_Warning','Common.block','Sales.excl_vat','HOME.hide','HOME.show','Sales.incl_vat', 'Common.Disable','Common.green', 'Common.black', 'HOME.ReferenceNo','HOME.BranchReferenceonly','Common.both','Common.by_barcode', 'Common.by_prd_name']).subscribe((res: string) => {    
     

      this.enable_disable_without_stock = [
        { id: 0, name: res['Common.Enable']},
        { id: 1, name: res['Common.Disable'] },
        
      ];
      this.duplicate_item = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];
      this.sales_note = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.show_pos = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.show_countr = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.show_totl = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.prdct_code = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.enable_disable_sales_below_mrp = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.enable_disable_van_sales_below_mrp = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.search_with_only_brcd = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.show_base_unit_in_print_values = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];

      this.txt_colors = [
        { id: 0, name: res['Common.green']},
        { id: 1, name: res['Common.black'] },
        
      ];

      this.search_mthd = [
        { id: 0, name: res['Common.by_barcode']},
        { id: 1, name: res['Common.by_prd_name'] },
        
      ];
      this.show_ttl_incld_vats = [
        { id: 0, name: res['Common.Disable'] },
        { id: 1, name: res['Common.Enable'] },
      ];
     this.display_days = [
      { id: 0, name: res['Common.Disable'] },
      { id: 1, name: res['Common.Enable'] },
      ];
    
      this.sales_sub_descriptions = [
        { id: 0, name: res['Common.Disable'] },
        { id: 1, name: res['Common.Enable'] },
      ];
      this.enbl_dsbl = [
        { id: 0, name: res['Common.Disable'] },
        { id: 1, name: res['Common.Enable'] },
      ];
      this.inv_due_limits = [
        { id: 0, name: res['Common.No_limit'] },
        { id: 1, name: res['Common.Enable'] },
      ];
      this.item_grid_types = [
        { id: 1, name: res['Common.With_Warning'] },
        { id: 0, name: res['Common.Without_Warning'] },
        { id: 2, name: res['Common.block'] },
      ];
    this.vat_types = [
        { id: 0, name: res['Sales.excl_vat'] },
        { id: 1, name: res['Sales.incl_vat'] },
     
      ];
     this.show_mob = [
      { id: 1, name: res['HOME.hide'] },
        { id: 0, name: res['HOME.show'] },
        
      ];
    
      this.show_brcd = [
        { id: 1, name: res['HOME.hide'] },
        { id: 0, name: res['HOME.show'] },
      ];
      this.disp_bill = [
        { id: 1, name:  res['Common.both'] },
        { id: 2, name:  res['HOME.ReferenceNo'] },
        { id: 3, name:  res['HOME.BranchReferenceonly'] },
      ];
    });

    this.translate.get(['Common.tax_invoice', 'Common.simplified_tax_invoice', 'Common.invoice']).subscribe((res: string) => {    
      this.inv_types = [
        { id: 1, name: res['Common.tax_invoice'] },
        { id: 2, name: res['Common.simplified_tax_invoice'] },
        { id: 3, name: res['Common.invoice'] },
      ];
    });

    this.translate.get(['Common.prd_name', 'Common.alias', 'Common.both']).subscribe((res: string) => {    
      this.print_name = [
        { id: 1, name: res['Common.prd_name'] },
        { id: 2, name: res['Common.alias'] },
        { id: 3, name: res['Common.both'] },
      ];
    });

    this.translate.get(['Common.normal', 'Common.extended']).subscribe((res: string) => {    
      this.search_types = [
        { id: 1, name: res['Common.normal'] },
        { id: 2, name: res['Common.extended'] },
        
      ];
    });

   

    this.translate.get(['Common.standard_rated_domestic', 'Common.zero_rated_domestic', 'Common.exempt_sales']).subscribe((res: string) => {    
      this.tax_types = [
        { id: 1, name: res['Common.standard_rated_domestic'] },
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_sales'] },
      ];
    });


  }

}

