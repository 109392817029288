import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-van-topsheet-rep',
  templateUrl: './van-topsheet-rep.component.html',
  styleUrls: ['./van-topsheet-rep.component.css']
})
export class VanTopsheetRepComponent implements OnInit {
  
  fileName= 'van_top_sheet.xlsx';
  pageLoadingImg:boolean;
  van_id: any;
  res: any;
  vans:string[];
  filter: any;
  base_qty: any;
  sales_der_net_qty: any;
  van_trans_net_qty: any;
  sales_net_qty: any;
  van_trans_der_net_qty: any;
  balance_qty: any;
  balance_der_qty: any;
  van_data: any;
  period_type: string;
  cash_sale_sum: any;
  cash_sale_ret_sum: any;
  collection_rcvd: any;
  other_expns: any;
  balance_cash: any;
  repDate: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  error: void;
  credit_sale_sum: any;
  total_sale: any;
  country_dec: string;
  round_of_total: any;
  branch_address:any;
  van_op_bal: any;
  bank_sale_sum: any;
  collection_rcvd_bank: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
   
    this.country_dec=this.coreService.setDecimalLength();
    this.period_type='t';
    this.pageLoadingImg=true;
    const searchval = new FormData();
    
    searchval.append('period_type', 't');

    this.apiService.topSheetReport(searchval).subscribe((res) => {
      // this.van_trans_qty =  res.vantransub_qty;
      this.pageLoadingImg = false;

      this.sales_der_net_qty =  res['sales_der_net_qty'];
      this.sales_net_qty =  res['sales_net_qty'];
      this.van_trans_der_net_qty =  res['van_trans_der_net_qty'];
      this.van_trans_net_qty =  res['van_trans_net_qty'];
      this.balance_qty =  res['balance_qty'];
      this.balance_der_qty =  res['balance_der_qty'];
      this.base_qty= res['sum'];
      this.van_data=res['van_data'];
      this.other_expns=res['other_expenses'];
      this.cash_sale_sum =  res['cash_sale_sum'];
      this.bank_sale_sum =  res['bank_sale_sum'];
      this.credit_sale_sum =  res['credit_sale_sum'];
      this.cash_sale_ret_sum =  res['cash_sale_ret_sum'];
      this.round_of_total =  res['round_of_total'];
      this.balance_cash=res['balance_cash'];
      this.total_sale=res['total_sale'];
      this.repDate=res['Date'];
      
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  topSheet(formdata: { value: any; }){
    // if(!this.van_id){
    //   $("#error").text("Select a van");
    //   return false; 
    // }

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.pageLoadingImg = true;

  this.apiService.topSheetReport(formdata.value).subscribe((res) => {
    // this.van_trans_qty =  res.vantransub_qty;
    this.pageLoadingImg = false;
    

    this.sales_der_net_qty =  res['sales_der_net_qty'];
    this.sales_net_qty =  res['sales_net_qty'];
    this.van_trans_der_net_qty =  res['van_trans_der_net_qty'];
    this.van_trans_net_qty =  res['van_trans_net_qty'];
    this.balance_qty =  res['balance_qty'];
    this.balance_der_qty =  res['balance_der_qty'];
    this.cash_sale_sum =  res['cash_sale_sum'];
    this.bank_sale_sum =  res['bank_sale_sum'];
    this.credit_sale_sum =  res['credit_sale_sum'];
    this.cash_sale_ret_sum =  res['cash_sale_ret_sum'];
    this.round_of_total =  res['round_of_total'];
    this.collection_rcvd =  res['collection_rcvd'];
    this.collection_rcvd_bank =  res['collection_rcvd_bank'];
    // this.other_expns =  res['other_expns'];
    this.base_qty= res['sum'];
    this.van_data=res['van_data'];
    this.other_expns=res['other_expenses'];
    this.balance_cash=res['balance_cash'];
    this.total_sale=res['total_sale'];
    this.repDate=res['Date'];
    // this.error=res.error;
    console.log(this.error);
    
  
  });
  }

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];


  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.vans = res['data'];
    });

  }

  exportEXCL(){
    this.exportexcel();
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  // pdf download
  generatePdf() {
  
    const heads = [
      this.translate.instant('HOME.transaction'),
      this.translate.instant('Common.Base_qty'),
      this.translate.instant('Common.Derived_qty')
    ];

    const heads1 = [
      this.translate.instant('Vat.Total_Sales'),
      this.currencyPipe.transform(this.total_sale, '', '', '1.1-1')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];
      const data1 = []
      
      if(this.van_data){
        this.van_op_bal = this.currencyPipe.transform(this.van_data.van_op_bal, '', '', '1.1-1')
      }else {
        this.van_op_bal = ' '
      }
 
      data.push(
      [
      this.translate.instant('Common.Opening_Balance'),
      this.van_op_bal,
      ''
      ],
      [
      this.translate.instant('Van.Van_Transfered_Qty'),
      this.currencyPipe.transform(this.base_qty.sum_vantrans_qty, '', '', '1.1-1'),
      this.currencyPipe.transform(this.base_qty.sum_vantrans_der_qty, '', '', '1.1-1')
      ],
      [
      this.translate.instant('Van.Van_Transfered_Returned_Qty'),
      this.currencyPipe.transform(-this.base_qty.sum_vantrans_ret_qty, '', '', '1.1-1'),
      this.currencyPipe.transform(-this.base_qty.sum_vantrans_der_ret_qty, '', '', '1.1-1')
      ],
      [
      this.translate.instant('Van.Net_Transfered_Qty'),
      this.currencyPipe.transform(this.van_trans_net_qty, '', '', '1.1-1'),
      this.currencyPipe.transform(this.van_trans_der_net_qty, '', '', '1.1-1')
      ],
      [
        this.translate.instant('Common.Sold_Qty'),
        this.currencyPipe.transform(this.base_qty.sum_salesub_qty, '', '', '1.1-1'),
        this.currencyPipe.transform(this.base_qty.sum_salesub_der_qty, '', '', '1.1-1')
      ],
      [
        this.translate.instant('Purchase.Returned_Qty'),
        this.currencyPipe.transform(-this.base_qty.sum_sale_ret_qty, '', '', '1.1-1'),
        this.currencyPipe.transform(-this.base_qty.sum_salesub_ret_der_qty, '', '', '1.1-1')
      ],
      [
        this.translate.instant('Common.Net_Sold_Qty'),
        this.currencyPipe.transform(this.sales_net_qty, '', '', '1.1-1'),
        this.currencyPipe.transform(this.sales_der_net_qty, '', '', '1.1-1')
      ],
      [
        this.translate.instant('Common.Balance_Qty'),
        this.currencyPipe.transform(this.balance_qty, '', '', '1.1-1'),
        this.currencyPipe.transform(this.balance_der_qty, '', '', '1.1-1')
      ]
      )

      data1.push(
        [
            this.translate.instant('Common.Credit_sales'),
            this.currencyPipe.transform(this.credit_sale_sum, '', '', '1.1-1')
        ],
        [
            this.translate.instant('Common.Cash_Sales'),
            this.currencyPipe.transform(this.cash_sale_sum, '', '', '1.1-1')
        ],
        [
          this.translate.instant('Common.bank__Sales'),
          this.currencyPipe.transform(this.bank_sale_sum, '', '', '1.1-1')
        ],
        [
            this.translate.instant('Common.Cash_Sales_Return'),
            this.currencyPipe.transform(-this.cash_sale_ret_sum, '', '', '1.1-1')
        ],
        [
            this.translate.instant('HOME.SALES') + ' ' + this.translate.instant('Purchase.round_off'),
            this.currencyPipe.transform(this.round_of_total, '', '', '1.1-1')
        ],
        [
            this.translate.instant('HOME.cash_Collection_Received'),
            this.currencyPipe.transform(this.collection_rcvd, '', '', '1.1-1')
        ],
        [
          this.translate.instant('HOME.bank_Collection_Received'),
          this.currencyPipe.transform(this.collection_rcvd_bank, '', '', '1.1-1')
      ],
        [
            this.translate.instant('Table.ttl_cash_in_hand'),
            this.currencyPipe.transform(this.collection_rcvd_bank+this.collection_rcvd - this.cash_sale_ret_sum + this.cash_sale_sum+this.bank_sale_sum, '', '', '1.1-1')
        ],
    );
    
    this.other_expns.forEach(item => {
        data1.push([
            item.ledger_name,
            this.currencyPipe.transform(-item.sum_vch_out, '', '', this.country_dec)
        ]);
    });
    
    data1.push([
        this.translate.instant('HOME.Balance_Cash'),
        this.currencyPipe.transform(this.balance_cash, '', '', '1.1-1')
    ]);
    
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);

        if(this.repDate.date1!=this.repDate.date2){
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date2,  valueXPos, headerHeight + 10);
        } else {
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + 'to' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Van.VAN_TOP_SHEET_INVOICE')}`,  valueXPos, headerHeight + 15);

      if(this.van_data){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Van.name')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.van_data.van_name,  valueXPos, headerHeight + 20);
      }
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 25, lblXPos, heads, data, null);
      doc = this.excelService.addTableToPdf(doc, headerHeight + 90, lblXPos, heads1, data1, null);
     
      doc.save(`Van Top Sheet Invoice.pdf`);


    
  }
  
}
