import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { DataTransferService } from '../../../../../service/data-transfer.service';
import { Router } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-print-sales-invoice',
  templateUrl: './print-sales-invoice.component.html',
  styleUrls: ['./print-sales-invoice.component.css']
})


export class PrintSalesInvoiceComponent implements OnInit {

  pageLoadingImg: boolean;
  showdata: boolean;
  resultobj: any;
  purchaseItems: any;
  sales_inv_no: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  payType = ['Cash', 'Credit'];
  up: any;
  cmpny: any;

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: any;
  tempImgUrl: any;
  inv_from_ds:any;
  qr_inv: any;
  sales_print_name: any;
  sales_settings: any;
  hide_item_discount : any;
  hide_vat : any;
  country_dec: string;
  cmp_tax: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private dataTransferService: DataTransferService,private router: Router) { }


  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange(); 
    });
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.country_dec=this.coreService.setDecimalLength();
    this.sales_print_name=1;
    this.pageLoadingImg = false;
    this.showdata = false;
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.inv_from_ds = this.dataTransferService.inv_no;
    if(this.inv_from_ds){
      this.sales_inv_no=this.inv_from_ds;
      this.sale_search(this.inv_from_ds);
      this.dataTransferService.inv_no = '';
    }else{
      this.dataTransferService.inv_no = '';
      this.sales_inv_no='';
      this.showdata = false;
    }
    // console.log(this.cmpny);
    
    this.getTemplete();

    
  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
        this.sales_print_name=this.sales_settings.sales_print_name;
      } else { 
        this.sales_print_name=1;
      }
    });

  }
  editInv(sl_id){
    this.dataTransferService.edit_sales_id=sl_id;
    this.router.navigate(['/sales-invoice']);
  }


  sale_search(search: string) {

    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.getSalesSetting();
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
        this.qr_inv = this.saleData.qr_link;
      }


    });
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER+'/'+res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }
      
      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER+'/'+res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
      this.hide_item_discount = res.data.hide_item_discount;
      this.hide_vat = res.data.hide_vat;
    });
  }
}
