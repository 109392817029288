import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Stockedit } from '../../../../model/stockedit.model';
import { CommonModule, DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../service/excel.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-opening-stock-remove',
  templateUrl: './opening-stock-remove.component.html',
  styleUrls: ['./opening-stock-remove.component.css']
})

export class OpeningStockRemoveComponent implements OnInit {
  fileName = 'opening_stock_list.xlsx';
  changeBox: boolean;
  selecTed: any = {
    prd_id: null, prd_name: null, message: null, rec_unit_rates: null,
    upt_unit_rates: null, unit_rates: null, units_rates: null, error: null, gdstock: null, branch_id: null,
    prd_units: null, manufacture_date: null, expiry_date: null, batch_code: null, cmp_stock_id: null, purchase_rate: null
  };
  stkprd_all: string[];
  list_stocks: string[];
  resultobj:{};
  stkprdsel: any;
  prd_id: any;
  country_dec: string;
  catagories: any;
  pageLoadingImg: boolean;
  prd_cat: any;
  all_check: boolean = false;
  removeLoading: boolean;
  prod_alias: any;
  cur_lang: string;

  stockVerfdList: any;
  curpage: any;
  lastpage: any;
  slnum: number;
  pgend: boolean;
  pgstart:boolean;
  from:any;
  exportLoader: boolean;
  repDate: any;
  repData: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private datePipe: DatePipe,private excelService: ExcelService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
      this.apiService.cur_lang.subscribe(value =>{
          this.cur_lang = value;
          this.aliasChecked()
        })



    this.country_dec=this.coreService.setDecimalLength();
    this.searchCat('');
    this.getAllOpeningStockList(1);
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
    }else{
      this.prod_alias =null;
    }
  }
  //form search stock
  searchStkPrd(search: string) {
    let searchval = new FormData();
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    searchval.append("keyword", search);
    this.apiService.getOpStockbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  selectOpStockPrd(id: any) {
    this.listStocks(id);    
  }

  removeAllStock(formdata: { value: any; }){

  
    if(this.all_check){
      
      var remopstk = confirm("Are you sure you want to remove this stocks ?");
      if(remopstk)
      {
        this.removeLoading=true;
      formdata.value.products = this.list_stocks;
      this.apiService.remOpStock(formdata.value).subscribe((res: Stockedit) => {
        this.removeLoading=false;

        if (res && res.error != null) {
            
          this.resultobj = this.coreService.getValidation(this.resultobj);  
        }
        else {

          if(this.prd_cat){

            let searchval = new FormData();
            searchval.append("cat_id", this.prd_cat);
            this.pageLoadingImg=true;
            this.apiService.getOpStockbyNameRem(searchval).subscribe((res) => {
              this.pageLoadingImg=false;
             this.list_stocks = res['data']['data']; 
             if(this.list_stocks!= undefined && this.listStocks.length > 0)
             {$('#opstk-list-tble').show();}
             else
             {$('#opstk-list-tble').hide();} 
           
          
          });
          }else{
            this.listStocks(formdata.value.prd_id); 
          }
          this.coreService.createfunct(formdata,this.resultobj,'Updated');    
        }
      });

    }
    }

    
  
    
  }

  removeStock(formdata: { value: any; },data) {
 formdata.value.prd_id = data.prd_id;
 formdata.value.qty = data.qty;
 formdata.value.rate = data.rate;
 formdata.value.gd_id = data.gd_id;
 formdata.value.batch_id = data.batch_id;
 formdata.value.stock_id = data.stock_id;
 formdata.value.purchase_amt = data.purchase_amt;
 formdata.value.opstklog_id = data.opstklog_id;
 formdata.value.prd_cat_id=data.prd_cat_id;
 delete formdata.value.products;


  var remopstk = confirm("Are you sure you want to remove this stock ?");
  if(remopstk)
  {
  this.apiService.remOpStock(formdata.value).subscribe((res: Stockedit) => {

    if (res && res.error != null) {
     
      this.resultobj = res.error;     
      this.resultobj = this.coreService.getValidation(this.resultobj);  
    }
    else {
      this.resultobj={};

      if(this.prd_cat){

        let searchval = new FormData();
        searchval.append("cat_id", formdata.value.prd_cat_id);
        this.pageLoadingImg=true;
        this.apiService.getOpStockbyNameRem(searchval).subscribe((res) => {
          this.pageLoadingImg=false;
         this.list_stocks = res['data']['data']; 
         if(this.list_stocks!= undefined && this.listStocks.length > 0)
         {$('#opstk-list-tble').show();}
         else
         {$('#opstk-list-tble').hide();} 
       
      
      });
      }else{
        this.listStocks(formdata.value.prd_id); 
      }
     
      this.coreService.createfunct(formdata,this.resultobj,'Updated');        
    }

  });
  }
}

listStocks(id:any)
{
   let searchval = new FormData();
  searchval.append("prd_id", id);
  this.pageLoadingImg=true;
  this.apiService.getOpStockbyNameRem(searchval).subscribe((res) => {
   this.list_stocks = res['data']['data']; 
   this.pageLoadingImg=false;
   if(this.list_stocks!= undefined && this.listStocks.length > 0)
   {$('#opstk-list-tble').show();}
   else
   {$('#opstk-list-tble').hide();} 
 

});
}

searchCat(search: string) {
  const searchval = new FormData();
  searchval.append('cat_name', search);
  this.apiService.getCatbyName(searchval).subscribe((res) => {
    this.catagories = res['data'];
    this.selecTed = null;
  });

}

selectCat(cat_id) {
  let searchval = new FormData();
  searchval.append("cat_id", cat_id);
  this.pageLoadingImg=true;
  this.apiService.getOpStockbyNameRem(searchval).subscribe((res) => {
    this.pageLoadingImg=false;
   this.list_stocks = res['data']['data']; 
   if(this.list_stocks!= undefined && this.listStocks.length > 0)
   {$('#opstk-list-tble').show();}
   else
   {$('#opstk-list-tble').hide();} 
 

});
}

pgEnd(curr, end) {
  if (curr == end)
    this.pgend = true;
  else
    this.pgend = false;

}

pgStart(curr) {
  if (curr == 1)
    this.pgstart = true;
  else
    this.pgstart = false;
}

getAllOpeningStockList(pagno) {

  let searchval = new FormData();
  this.apiService.getAllOpeningStockList(searchval, pagno).subscribe((res) => {
    this.stockVerfdList = res.data.data;

   
    this.curpage = res.data['current_page'];
    this.lastpage = res.data['last_page'];
    this.from = res.data['from'];
    this.pgEnd(this.curpage, this.lastpage);
    this.pgStart(this.curpage);
    this.slnum = 0;

  });
}

exportexcel(): void {
  /* table id is passed over here */
  let element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  this.exportLoader = false;

}

exportEXCL( pageno = 1) {
  let searchval = new FormData();
  searchval.append("export","export");
  this.pageLoadingImg = true;
  
  this.exportLoader = true;

  
    this.apiService.getAllOpeningStockList(searchval, pageno).subscribe((res) => {
    this.pageLoadingImg = false;
    this.repData = res.data;
   
    setTimeout(() => {
      this.exportexcel();
    }, 3000);


  });

}
}
