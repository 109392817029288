import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rep-damage-summary',
  templateUrl: './rep-damage-summary.component.html',
  styleUrls: ['./rep-damage-summary.component.css']
})
export class RepDamageSummaryComponent implements OnInit {
  fileName= 'damage_summary.xlsx';
  exportLoader: boolean;
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  pageFrom: any;
  summary: any;
  Expsummary : any;
  repDate: any;
  ExprepDate: any;
  tot_items: any;
  Exptot_items: any;
  total_qty: any;
  Exptotal_qty: any;
  tot_purch_amt: any;
  Exptot_purch_amt: any;
  qty_filter: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  products: any[];
  catagories: any;
  subcatagories: string[];
  changeBox: boolean;
  users: any;
  godowns: any;
  gd_id: any;
  mrpDef = 0;
  filter_rate = [
    { id: 0, name: 'Purchase Rate' },
    { id: 1, name: 'Sales Rate -(M.R. P)' }
  ];
  country_dec: string;
  cur_lang: string;
  filter_branch_id: any;
  branch_all: any;
  userType: any;

  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    // this.filter_branch_id = 1;
    this.getAllBranch();

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();

    this.apiService.damageSummaryReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];

      this.tot_items = res['data']['total']['tot_items'];
      this.total_qty = res['data']['total']['total_qty'];
      this.tot_purch_amt = res['data']['total']['tot_purch_amt'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

   this.getGodownList();
   this.gd_id = '';
   this.userType = this.coreService.getUserData('user_type');


  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.purchase_rate','Table.sales_rate_mrp']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.filter_rate = [
        { id: 0, name: res['HOME.purchase_rate'] },
        { id: 1, name: res['Table.sales_rate_mrp'] }
      ];
    
    });

  }
  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.damageSummaryReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];

      this.tot_items = res['data']['total']['tot_items'];
      this.total_qty = res['data']['total']['total_qty'];
      this.tot_purch_amt = res['data']['total']['tot_purch_amt'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }

  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }



  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

    });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  export(formdata: { value: any; }){
  this.exportLoader = true;
   
    // debugger;
    formdata.value.export = 'export';
    this.apiService.damageSummaryReport(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ExprepDate = res.data.Date;
      this.Expsummary = res['data']['data']['data'];
      
  
      this.Exptot_items = res['data']['total']['tot_items'];
      this.Exptotal_qty = res['data']['total']['total_qty'];
      this.Exptot_purch_amt = res['data']['total']['tot_purch_amt'];
          
    setTimeout(() => {
      this.exportexcel(); 
  }, 3000);
  
  formdata.value.export = '';
 

  });




  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      // this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
   
    // debugger;
    formdata.value.export = 'export';
    this.apiService.damageSummaryReport(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ExprepDate = res.data.Date;
      this.Expsummary = res['data']['data']['data'];
      
  
      this.Exptot_items = res['data']['total']['tot_items'];
      this.Exptotal_qty = res['data']['total']['total_qty'];
      this.Exptot_purch_amt = res['data']['total']['tot_purch_amt'];
          
      const heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('Common.date'),
        this.translate.instant('Common.notes'),
        this.translate.instant('Table.qty')
      ];

      if(this.mrpDef == 0){
        heads.splice(3,0, this.translate.instant('Table.purch_rate'))
        heads.splice(3,0, this.translate.instant('Common.Purch_Amt'))
      }

      if(this.mrpDef == 1){
        heads.splice(4,0, this.translate.instant('Table.mrp'))
        heads.splice(4,0, this.translate.instant('Table.total_mrp'))
      }
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.Expsummary.forEach(item=>{
          const arr=[
            item.prd_name,
            '',
            '',
            this.currencyPipe.transform(item.qty, '', '', '1.1-1'),
            this.currencyPipe.transform(item.purch_rate, '', '', this.country_dec),
            this.currencyPipe.transform(item.purch_sum_amt, '', '', this.country_dec)
          ]

          data.push(arr)

          item.items.forEach(item1=>{
            const arr=[
              item.prd_barcode,
              this.datePipe.transform(item1.damage_date, 'dd/MM/yyyy'),
              item1.damage_notes,
              this.currencyPipe.transform(item1.stk_qty, '', '', '1.1-1'),
              this.currencyPipe.transform(item1.damage_purch_rate, '', '', this.country_dec),
              this.currencyPipe.transform(item1.purch_amt, '', '', this.country_dec)
            ]
  
            data.push(arr)
          })
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('HOME.damage_summary')}`,  valueXPos, headerHeight + 15);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.tot_items,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

        if(this.mrpDef == 0){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 30);
        } else if(this.mrpDef == 1){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Table.total_mrp') + ' ' + ('Table.amnt')}`, lblXPos, headerHeight + 30);
        } 

        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.tot_purch_amt, '', '', this.country_dec),  valueXPos, headerHeight + 30);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
    
       
        doc.save(`Damage Summary.pdf`);

        this.exportLoader = false;
  
  formdata.value.export = '';
 

  });
    
  }

}
