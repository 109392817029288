import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductionService } from '../../../../../service/production.service';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-ext-stock-transfer',
  templateUrl: './ext-stock-transfer.component.html',
  styleUrls: ['./ext-stock-transfer.component.css']
})
export class ExtStockTransferComponent implements OnInit {


 

  //@ViewChild('closeModal', { static: false }) closeModal: ElementRef;


  @ViewChild('fieldName1', { static: false }) fieldName1: ElementRef;
  @ViewChild('fieldName2', { static: false }) fieldName2: ElementRef;

  @ViewChild('fieldName3', { static: false }) fieldName3: ElementRef;
  @ViewChild('fieldName4', { static: false }) fieldName4: ElementRef;
  branch_id: any;
  prodctnId: any;
  prdn_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: number;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};
  previewData = [];
  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};
  stocktr_amount = 0;
  prodFormula = {
    prdn_date: new Date(),
    productnId: 0,
    productnDate: 0,
    formula_id: '',
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: 0,
  };
  formula_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;
  pageLoadingImg1 = false;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  // rate_all = [];
  curForm = 'Create';


  unit_qty: any;
  display_qty: any;
  product_summary: any;
  purch_no: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  vantran_godown_id: any;
  vantran_van_id: any;
  brcode: any;
  prd_stock_statusChecked: any;
  prd_stock_status: any;


  tran_purch_price: any;
  transub_qty: number;
  tran_purch_amount: number;
  transub_unit_id: any;


  prd_id: any;
  tran_id: number;
  vantran_purch_price: any;
  prd_barcode: String;
  vantransub_qty: any;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount: any;
  sel_gd_id = 0;
  tranArray: any;
  sl_no = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any[];
  preview_items: any[];
  base_rate: number;
  transferdate: any;

  accepted_list = [];
  rejected_list = [];
  previewDataitems = [];
  sale_rate: any;
  salestatus: any;
  type_id = 1;

  gdid: number;
  errObjArr = {
    tandateErr: null, tanvanErr: null,
    tangdErr: null
  };
  errObjTran = {
    fromErr: null, toErr: null,
    sameErr: null
  };
  rate_status: any;
  rate_status_type: any;

  searchStkBatch: any;
  acurpage: any;
  alastpage: any;
  apgend: boolean;
  apgstart: boolean;
  afrom: any;
  rpgstart: boolean;
  rpgend: boolean;
  accpgstart: boolean;
  accpgend: boolean;
  from: any;
  rfrom: any;
  balance: any;
  batch_name: any;
  gd_id: any;
  godowns: any;
  branchto: string | number | string[];
  up: any;
  showrate: any;
  userdefltGwn: any;
  gd_id_edit: any;
  avgPurch_rate: any;
  userEncpType: any;
  selctedProd: any;
  settings :any;

  sunDrcr = [];
  accLoading = false;
  ledger_id = 0;

  savedTemplate = {

    logo: false,
    header_type: 1
  }
  logoImgUrl: string;
  tempImgUrl: string;

  branch_display_name: any;
  branch_address: any;
  branch_name: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  price_group_id:any = 1;
  voidInprogress=<any>{};
  rate_all = [
    { "type_id": 1, 'name': 'Sales Rate'  },
    { "type_id": 2, 'name': 'Purchase Rate'  },
    { "type_id": 3, 'name': 'Custom' },
    { "type_id": 4, 'name': 'Last Transfer Rate' },
    { "type_id": 5, 'name': 'Average Cost' }

  ];
  country_dec: string;
  add_perc:any;
  net_rate:any;
  add_perc_edit:any;
  net_rate_edit:any;
  isEdit = false;
  isEdit1 =false;
  draft_id: any;
  draftList: any;
  cur_lang: string;
  cmp_tax: any;
  constructor(private productionService: ProductionService, private datePipe: DatePipe, private apiService: ApiService,
    private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.up = this.coreService.getUserPrivilage();
    this.userEncpType = this.coreService.getUserData('usr_enc_rate_code');
    this.getTemplete();
    this.prodform_id = '';
    this.prdn_date = new Date();
    this.type_id = 1;

    this.add_perc=0;
    this.net_rate=0;
    this.add_perc_edit=0;
    this.net_rate_edit=0;
    
    this.listGodowns();
    this.listTransfers();
    this.getAllSundryCrDrLedgers();
    // this.setRateTypes();
    this.getNextId();

    $('#branchid').css("border", "1px solid #dedede");
    $('#branch_from').css("border", "1px solid #dedede");


    this.userdefltGwn = this.coreService.getUserData('usr_default_godown_id');
    this.gd_id = Number(this.userdefltGwn);
    this.gd_id_edit = Number(this.userdefltGwn);
    this.getTrasferSetting();
    if (this.gd_id > 0) {

    }
    else {
      this.gd_id = 0;
    }
    // console.log(this.gd_id);

    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.country_dec=this.coreService.setDecimalLength();
    this.getsaveTempExtStock();
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.commAll = 1;
      
    }else{
      this.commAll =2;
      ;
    }
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {

        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }

  getNextId() {
    this.apiService.getNextStockTransId().subscribe((res) => {
      this.prodctnId = res['data']['tran_id'];
    });
  }

  getAccLedgerBalance(ledgerId) {
    this.accLoading = true;
    this.balance = '';
    ledgerId = (ledgerId) ? ledgerId : this.ledger_id;
    this.price_group_id = this.sunDrcr.find(x => x.ledger_id === ledgerId).ledger_pricegrp_id;
    this.apiService.getLedgerBalance({ ledger_id: ledgerId }).subscribe((res) => {
      this.accLoading = false;
      // this.rec.rec_balance = res.data.bal_without_sign;
      this.balance = res.data.bal_with_sign;
    });
  }

  // Pending List 


  listTransfers() {
    let searchval = new FormData();
    this.pageLoadingImg1 = true;
    searchval.append("status", '0');
    this.apiService.listExtStockTransfer(searchval, 1).subscribe((res) => {
      this.formula_list = res['data'];
      this.pageLoadingImg1 = false;
      this.curpage = res['current_page'];
      this.from = res['from'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }



  pageNext(status, pageno: any) {


    let searchval = new FormData();
    searchval.append("status", status);
    this.pageLoadingImg1 = true;
    this.apiService.listExtStockTransfer(searchval, pageno).subscribe((res) => {
      this.formula_list = res['data'];
      this.pageLoadingImg1 = false;
      this.curpage = res['current_page'];
      this.from = res['from'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;

  }


  // Pending End 

  ngOnDestroy() {
    $('.close').click();

  }

  getTrasferSetting() {
    this.apiService.getTrasferSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.type_id = this.settings.ts_rate_type;
      } else { 
        this.type_id = 1;
      }
    });

  }



  // rlastpage(rcurpage: (rcurpage: any) => void, rlastpage: any) {
  //   throw new Error("Method not implemented.");
  // }
  // rcurpage(rcurpage: any) {
  //   throw new Error("Method not implemented.");
  // }

 
  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });

  }

  setRateTypes() {
    this.rate_all.push({ "type_id": 1, 'name': 'MRP' },
      { "type_id": 2, 'name': 'Purchase Rate' },
      { "type_id": 3, 'name': 'Custom' },
      { "type_id": 4, 'name': 'Last Transfer Rate' },
      { "type_id": 5, 'name': 'Average Cost' }

    );
  }

  getAllSundryCrDrLedgers() {
    this.apiService.getAllSundryCrDrLedgers().subscribe((res) => {
      this.sunDrcr = res['data'];
    });
  }

  // searchForm(search: string) {
  //   let searchval = new FormData();
  //   searchval.append("gd_name", search);
  //   this.apiService.getAllBranchesforTransfer().subscribe((res) => {
  //     this.godown_all = res['data'];
  //   });
  // }


  getInfo(id) {

    let searchval = new FormData();
    searchval.append("id", id);
    this.apiService.getExtTransferInfo(searchval).subscribe((res) => {
      this.previewData = res;
      this.getAccLedgerBalance(res.extstktr_ledger_id);
      // this.branch_from = res.from_branch;
      this.previewDataitems = res.items;
      // console.log(this.previewDataitems);

      this.stocktr_amount = res.stocktr_amount;
      $('#brtran-btn').show();


      this.transferdate = this.datePipe.transform(res.extstktr_date, 'dd/MM/yyyy');

      // this.transferdate = new Date(this.transferdate); 

      var to = this.transferdate.split("/");
      this.transferdate = new Date(to[2], to[1] - 1, to[0]);
      this.gd_id_edit = Number(this.userdefltGwn);
      this.findTotalSum2();

      // this.transferdate = new Date(res.stocktr_date);


    });

  }


  selectBatch(Batchcode, index) {


    this.prodFormula.productOut[index]['batch_code'] = Batchcode.sb_batch_code;
    this.prodFormula.productOut[index]['exp_date'] = new Date(Batchcode.sb_expiry_date);
    this.prodFormula.productOut[index]['manf_date'] = new Date(Batchcode.sb_manufacture_date);
    this.prodFormula.productOut[index]['gd_id'] = 0;
  }



  changeUnit(qty, index) {
    //this.prodFormula.productIngd[index]['product']['shop'] = this.prodFormula.productIngd[index]['product']['shop_available'] * qty;
    this.prodFormula.productIngd[index]['qty'] *= qty;
  }
  // getInfo(pageNo) {
  //   this.pageLoadingImg = true;
  //   this.productionService.getAllProductions(pageNo, this.perpage).subscribe((res) => {
  //     this.formula_list = res.data.data;
  //     this.pageLoadingImg = false;
  //     this.curpage = res.data.current_page;
  //     this.lastpage = res.data.last_page;
  //     this.totalItem = res.data.total;
  //   });
  // }






  filterProducts() {
    this.filterProdAll = this.prdAll.filter(e => {
      if (Object.values(this.selProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }



  deleteItem(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.selectedItems.length;
      var type = data.sl_no;



      var i;
      for (i = this.selectedItems.length - 1; i >= 0; --i) {
        if (this.selectedItems[i].sl_no == type) {
          this.selectedItems.splice(i, 1);
        }
      }
      if (this.selectedItems.length == 0)
        $('#gdtran-btn').hide();
      this.findTotalSum();
    }
  }

  

  editItem(data, i) {
    $('.edit_row').removeClass('sel_row');
    $('#myrow' + i).addClass('sel_row');
    if(this.selectedItems.length >0)
    {
     
  
        const searchval = new FormData();
        searchval.append('prd_id', data.vantransub_prod_id);
        searchval.append('gd_id', data.gd_id);
        this.apiService.getExTransProdDetails(searchval).subscribe((res) => {
          
       

          this.selctedProd = res['data'];

          this.prd_units = this.selctedProd[0].prd_units;
  
  
          this.selctedProd[0].prd_units.forEach((value, i) => {
            if (value.sur_unit_id == data.vantransub_unit_id) {

  
             this.default_unit = {
              "unit_code": value.unit_code, "unit_name": value.unit_name,
              "sur_unit_id": value.sur_unit_id, "unit_base_qty": value.unit_base_qty,

        "sale_rate": value.sale_rate, "purch_rate": value.purch_rate,
        "last_rate": value.last_rate,
        "avg_cost": value.avg_cost,
              
      
            };
  
       
      
            };
          });
    
         
        });
  
        
        data.salesub_prd_id =data.vantransub_prod_id;
        this.apiService.geteditSalesItem(data).subscribe((res: any) => {
  
          this.sl_no = data.sl_no;
          this.prd_id = res.data.product;
          // this.sale_rate = this.prd_id.bs_srate;
          //  this.base_rate = this.prd_id.bs_prate;
          this.vantransub_qty = data.vantransub_qty / data.unit_base;
         // this.vantran_purch_price =data.purchase_rate * data.unit_base;
          this.vantran_purch_price =data.rate;
          this.add_perc = data.add_perc;
          this.net_rate =data.net_rate;
          this.type_id =data.type_id;
          this.gd_id =data.gd_id;
       
  
        });
  
        this.isEdit =true;
    }

    else{
    
  
        const searchval = new FormData();
        searchval.append('prd_id', data.prd_id);
        searchval.append('gd_id', data.extstktrsub_gd_id);
        this.apiService.getExTransProdDetails(searchval).subscribe((res) => {

        

          this.selctedProd = res['data'][0];

        
  
          this.prd_units = this.selctedProd.prd_units;
  
  
          this.selctedProd.prd_units.forEach((value, i) => {
            if (value.sur_unit_id == data.vantransub_unit_id) {
      
             
  
             this.default_unit = {
              "unit_code": value.unit_code, "unit_name": value.unit_name,
              "sur_unit_id": value.sur_unit_id, "unit_base_qty": value.unit_base_qty,

        "sale_rate": value.sale_rate, "purch_rate": value.purch_rate,
        "last_rate": value.last_rate,
        "avg_cost": value.avg_cost,
              
      
            };
  
          
      
            };
          });
    
         
        });
  
        
        data.salesub_prd_id =data.prd_id;
        this.apiService.geteditSalesItem(data).subscribe((res: any) => {
  
         
          this.sl_no = data.sl_no;
          this.prd_id = res.data.product;
          
          // this.sale_rate = this.prd_id.bs_srate;
          // this.base_rate = this.prd_id.bs_prate;

          // this.transub_qty = data.unit_base_qty;
          this.transub_qty = data.box;
         // this.tran_purch_price =data.extstktrsub_rate;

          //this.convertRate(this.default_unit);

          this.tran_purch_price =data.rate;
          this.add_perc_edit = data.add_perc;
          this.net_rate_edit =data.net_rate;
          this.type_id =data.type_id;
          this.gd_id_edit =data.extstktrsub_gd_id;
       
  
        });
      
        this.isEdit1 =true;
      
    }

    

    // if (data) {
     

    // }
  }

  editSaleItem(prd_data, prd_barcode, vantransub_unit_id)
  {
    $('.edit_row').removeClass('sel_row');
   



    // alert(prd_data.prd_barcode);
    // alert(vantransub_unit_id.unit_base_qty);

    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];
      });

    }



    if (this.gd_id == undefined) {

      $('#godown_id').css("border", "1px solid red");
    }
    else {
      $('#godown_id').css("border", "1px solid #dedede");
    }









    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {


      if(this.add_perc){
        let nr = parseFloat(this.vantran_purch_price) + (parseFloat(this.vantran_purch_price)*parseFloat(this.add_perc))/100;
        this.vantran_purch_price = nr;
      }

      this.box = this.vantransub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }


      //this.sl_no = (this.selectedItems.length) + 1;


      this.vantransub_qty = Number(this.vantransub_qty);
      this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.selectedItems,
          prd_data.branch_stock_id,
          vantransub_unit_id.sur_unit_id,
          this.vantran_purch_price,
          prd_data.prd_barcode,
          vantransub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }
         

        
        else {
          // this.selectedItems.push({
          //   "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
          //   "prd_id": prd_data.prd_id,
          //   "stocktrsub_prd_id": prd_data.prd_id,

          //   "prd_name": prd_data.prd_name,
          //   "vantransub_qty": this.vantransub_qty,
          //   "stocktrsub_qty": this.vantransub_qty,
          //   "box": this.box,
          //   "gd_id": this.gd_id,
          //   "purchase_rate": this.vantran_purch_price,
          //   "purchase_amount": this.vantran_purch_amount,
          //   "vantransub_prod_id": prd_data.prd_id,
          //   "vantransub_stock_id": prd_data.bs_stock_id,
          //   "vantransub_branch_stock_id": prd_data.branch_stock_id,
          //   "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
          //   "vantransub_purch_rate": this.avgPurch_rate,
          //   "unit_name": vantransub_unit_id.unit_name,
          //   "unit_base": vantransub_unit_id.unit_base_qty,
          //   "unit_code": prd_data.base_unit_code,
          //   "base_unit_name": prd_data.base_unit_name,
          //   "display_qty": this.display_qty,
          //   "item_unit_code": vantransub_unit_id.unit_code,
          //   "prd_stock_status": prd_data.prd_stock_status,
          //   "add_perc" : this.add_perc,
          //   "net_rate" :this.net_rate,
          //   "rate" : $('#vantran_purch_price').val()

          // });


         
      this.selectedItems.find(x => x.sl_no === this.sl_no).sl_no = this.sl_no;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_barcode = prd_data.prd_barcode;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_id = prd_data.prd_id;

      this.selectedItems.find(x => x.sl_no === this.sl_no).stocktrsub_prd_id = prd_data.prd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_name = prd_data.prd_name;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_qty = this.vantransub_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).stocktrsub_qty = this.vantransub_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).box = this.box;
      this.selectedItems.find(x => x.sl_no === this.sl_no).gd_id = this.gd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_rate = this.vantran_purch_price;
      this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_amount = this.vantran_purch_amount;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_prod_id = prd_data.prd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_stock_id = prd_data.bs_stock_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_branch_stock_id = prd_data.branch_stock_id;

      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_unit_id = vantransub_unit_id.sur_unit_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_purch_rate = this.avgPurch_rate;

      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_name = vantransub_unit_id.unit_name;

      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_base = vantransub_unit_id.unit_base_qty;
     // this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = prd_data.base_unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = vantransub_unit_id.unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).base_unit_name = prd_data.base_unit_name;
      this.selectedItems.find(x => x.sl_no === this.sl_no).display_qty = this.display_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).item_unit_code = vantransub_unit_id.unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_stock_status = prd_data.prd_stock_status;
      this.selectedItems.find(x => x.sl_no === this.sl_no).add_perc = this.add_perc;
      this.selectedItems.find(x => x.sl_no === this.sl_no).net_rate = this.net_rate;
      this.selectedItems.find(x => x.sl_no === this.sl_no).rate = $('#vantran_purch_price').val();
      this.selectedItems.find(x => x.sl_no === this.sl_no).type_id = this.type_id;
     

    
     
        }
      }






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.selectedItems.length;
      this.vantransub_qty = this.box;
      $('#gdtran-btn').show();

      
      this.net_rate = 0;
      this.add_perc = 0;

      this.vantran_purch_price = 0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();

    }
    this.fieldName2.nativeElement.focus();
    this.isEdit =false;
  }

  editSaleItem1(prd_data, prd_barcode, transub_unit_id)
  {
   
    $('.edit_row').removeClass('sel_row');
 
    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }
    if (this.gd_id_edit == undefined) {

      $('#godown_id_edit').css("border", "1px solid red");
    }
    else {
      $('#godown_id_edit').css("border", "1px solid #dedede");
    }

    if (!transub_unit_id) {
      this.printError.push({ 'transub_unit_idErr': 'Required' });
      $('#transub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#transub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm2();

    if (this.printError.length <= 0) {

      if(this.add_perc_edit){
        let nr = parseFloat(this.tran_purch_price) + (parseFloat(this.tran_purch_price)*parseFloat(this.add_perc_edit))/100;
        this.tran_purch_price = nr;
      }

      this.box = this.transub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (transub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = transub_unit_id.unit_code;

        this.transub_qty = this.box * (transub_unit_id.unit_base_qty);
        this.tran_purch_price = this.tran_purch_price / (transub_unit_id.unit_base_qty);

      }


     // this.sl_no = (this.previewDataitems.length) + 1;

      this.transub_qty = Number(this.transub_qty);
      this.tran_purch_amount = this.tran_purch_price * this.transub_qty;
      var existingArray: any;
      if (this.previewDataitems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.previewDataitems,
          prd_data.branch_stock_id,
          transub_unit_id.sur_unit_id,
          this.tran_purch_price,
          prd_data.prd_barcode,
          transub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.tran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }
        else {
          // this.previewDataitems.push({
          //   "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
          //   "prd_id": prd_data.prd_id,
          //   "prd_name": prd_data.prd_name,
          //   "extstktrsub_qty": this.transub_qty,
          //   "box": this.box,
          //   "extstktrsub_gd_id": this.gd_id_edit,
          //   "extstktrsub_rate": this.tran_purch_price,
          //   "purchase_amount": this.tran_purch_amount,
          //   "vantransub_prod_id": prd_data.prd_id,
          //   "vantransub_stock_id": prd_data.bs_stock_id,
          //   "vantransub_branch_stock_id": prd_data.branch_stock_id,
          //   "vantransub_unit_id": transub_unit_id.sur_unit_id,
          //   "vantransub_purch_rate": prd_data.bs_avg_prate,
          //   "unit_name": transub_unit_id.unit_name,
          //   "unit_base": transub_unit_id.unit_base_qty,
          //   "unit_code": prd_data.base_unit_code,
          //   "base_unit_name": prd_data.base_unit_name,
          //   "display_qty": this.display_qty,
          //   "item_unit_code": transub_unit_id.unit_code

          // });
        


          this.previewDataitems.find(x => x.sl_no === this.sl_no).sl_no = this.sl_no;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).prd_barcode = prd_data.prd_barcode;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).prd_id = prd_data.prd_id;
    
          this.previewDataitems.find(x => x.sl_no === this.sl_no).stocktrsub_prd_id = prd_data.prd_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).prd_name = prd_data.prd_name;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).extstktrsub_qty = this.transub_qty;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).box = this.box;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).extstktrsub_gd_id = this.gd_id_edit;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).extstktrsub_rate = this.tran_purch_price;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).purchase_amount = this.tran_purch_amount;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_prod_id = prd_data.prd_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_stock_id = prd_data.bs_stock_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_branch_stock_id = prd_data.branch_stock_id;
    
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_unit_id = transub_unit_id.sur_unit_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_purch_rate = prd_data.bs_avg_prate;
    
          this.previewDataitems.find(x => x.sl_no === this.sl_no).unit_name = transub_unit_id.unit_name;
    
          //this.previewDataitems.find(x => x.sl_no === this.sl_no).unit_base = transub_unit_id.unit_base_qty;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).unit_code = transub_unit_id.unit_code;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).base_unit_name = prd_data.base_unit_name;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).display_qty = this.display_qty;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).item_unit_code = transub_unit_id.unit_code;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).add_perc = this.add_perc_edit;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).net_rate = this.net_rate_edit;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).rate = $('#tran_purch_price').val();
          this.previewDataitems.find(x => x.sl_no === this.sl_no).type_id = this.type_id;
          this.findTotalSum2();
        }

        this.fieldName4.nativeElement.focus();
      }






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.previewDataitems.length;
      this.transub_qty = this.box;
      $('#brtran-btn').show();

      this.net_rate_edit = 0;
      this.add_perc_edit = 0;

      this.tran_purch_price = 0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.transub_qty = 0;
      this.isEdit1 =false;


    }

  }


  deleteItemArray(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.previewDataitems.length;
      var type = data.sl_no;


      var i;
      for (i = this.previewDataitems.length - 1; i >= 0; --i) {
        if (this.previewDataitems[i].sl_no == type) {
          this.previewDataitems.splice(i, 1);
        }
      }
      this.findTotalSum2();

      if (this.previewDataitems.length == 0)
        $('#brtran-btn').hide();

    }
  }

  validateForm() {


    this.printError = [];
    // if ($('#prd_barcode').val() == "" || $('#prd_barcode').val() == undefined) {
    //   this.printError.push({ 'prd_barcodeErr': 'Required' });
    //   $('#prd_barcode').css("border", "1px solid red");
    // } else {
    //   $('#prd_barcode').css("border", "1px solid #dedede");
    // }

    if (this.vantransub_qty == undefined || this.vantransub_qty == null || this.vantransub_qty <= 0 || this.vantransub_qty == "") {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#vantransub_qty').css("border", "1px solid red");
    } else {
      $('#vantransub_qty').css("border", "1px solid #dedede");
    }

    if ($('#vantran_purch_price').val() == "" || $('#vantran_purch_price').val() == undefined || $('#vantran_purch_price').val() <= 0) {
      this.printError.push({ 'vantran_purch_priceErr': 'Required' });
      $('#vantran_purch_price').css("border", "1px solid red");
    } else {
      if ($('#vantran_purch_price').val() > 0) {
        $('#vantran_purch_price').css("border", "1px solid #dedede");
      } else {
        $('#vantran_purch_price').css("border", "1px solid red");
        this.printError.push({ 'vantran_purch_priceErr': 'Required' });
      }

    }

  }
  validateForm2() {


    this.printError = [];

    if (this.transub_qty == undefined || this.transub_qty == null || this.transub_qty < 0) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#transub_qty').css("border", "1px solid red");
    } else {
      $('#transub_qty').css("border", "1px solid #dedede");
    }

    if (this.gd_id_edit == undefined) {
      this.printError.push({ 'godown_id_edit': 'Required' });
      $('#godown_id_edit').css("border", "1px solid red");
    }
    else {
      $('#godown_id_edit').css("border", "1px solid #dedede");
    }

    if ($('#tran_purch_price').val() == "" || $('#tran_purch_price').val() == undefined) {
      this.printError.push({ 'tran_purch_priceErr': 'Required' });
      $('#tran_purch_price').css("border", "1px solid red");
    } else {
      $('#tran_purch_price').css("border", "1px solid #dedede");
    }

  }

  validateTransfer(formData) {




    this.printError = [];
    if (formData.tran_date == "" || formData.tran_date == undefined) {
      $('#tran_date').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {
      // if (formData.tran_date > new Date()) {
      //   $('#tran_date').css("border", "1px solid red");
      //   this.printError.push({ 'rec_date': 'Required' });
      // } else {
        $('#tran_date').css("border", "1px solid #dedede");
      // }

    }
    if (formData.ledger_id == "" || formData.ledger_id == undefined) {

      $('#ledger_id').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {

      $('#ledger_id').css("border", "1px solid #dedede");

    }
  }


  validateTransfer2(formData) {


    this.printError = [];
    if (formData.tran_date == "" || formData.tran_date == undefined) {
      $('#tran_date2').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {
      // if (formData.tran_date > new Date()) {
      //   $('#tran_date2').css("border", "1px solid red");
      //   this.printError.push({ 'rec_date': 'Required' });
      // } else {
        $('#tran_date2').css("border", "1px solid #dedede");
      // }


    }

    if (formData.ledger_id == "" || formData.ledger_id == undefined) {
      $('#ledger_id').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {
      $('#ledger_id').css("border", "1px solid #dedede");
    }


  }

  convertRate(vantransub_unit_id) {
    
  

    if (this.rate_status_type == 'purch') {
      if (this.userEncpType) {
        this.vantran_purch_price = this.coreService.splitToDigit(vantransub_unit_id.purch_rate); // Encrypted Rate
      } else {
        this.vantran_purch_price = vantransub_unit_id.purch_rate;
      }

    } else {

      if (this.type_id == 1) {

      }


      if (this.rate_status_type == 'last_rate') {
        this.vantran_purch_price = vantransub_unit_id.last_rate;

      } else {

        this.vantran_purch_price = vantransub_unit_id.sale_rate;
        this.rate_status_type = 'sale';
      }

    }

    this.add_perc =0;
    this.net_rate =0;


  }

  changeRate(rate, transub_unit_id) {

    if (rate == '2') {
      // this.vantran_purch_price = this.base_rate * (transub_unit_id.unit_base_qty);
      // this.vantran_purch_price = this.coreService.splitToDigit(transub_unit_id.purch_rate); // Encrypted Rate
      if (this.userEncpType) {
        this.vantran_purch_price = this.coreService.splitToDigit(transub_unit_id.purch_rate); // Encrypted Rate
      } else {
        this.vantran_purch_price = transub_unit_id.purch_rate;
      }
      this.tran_purch_price = transub_unit_id.purch_rate;
      this.rate_status_type = 'purch';

    } else if (rate == '1') {

      this.vantran_purch_price = transub_unit_id.sale_rate;
      this.tran_purch_price = transub_unit_id.sale_rate;
      this.rate_status_type = 'sale';

    }
    else if (rate == '3') {
      this.vantran_purch_price = '';
      this.tran_purch_price = '';
      this.rate_status_type = 'custom';
    }
    else if (rate == '4') {
      this.vantran_purch_price = transub_unit_id.last_rate;
      this.tran_purch_price = transub_unit_id.last_rate;
      this.rate_status_type = 'last_rate';
    }
    else if (rate == '5') {
      this.vantran_purch_price = transub_unit_id.avg_cost;
      this.tran_purch_price = transub_unit_id.avg_cost;
      this.rate_status_type = 'avg_cost';
    }

    this.net_rate = this.vantran_purch_price ? this.vantran_purch_price : 0;
    this.add_perc = 0;
    this.net_rate_edit = this.tran_purch_price ? this.tran_purch_price : 0;
    this.add_perc_edit = 0;
  }


  convertRate2(transub_unit_id) {

  

    if (this.rate_status_type == 'purch') {
      // this.tran_purch_price = this.base_rate * (transub_unit_id.unit_base_qty);
      this.tran_purch_price = transub_unit_id.purch_rate ;

    } else {

      if (this.type_id == 1) {

      }

     // this.tran_purch_price = this.sale_rate * (transub_unit_id.unit_base_qty);
     this.tran_purch_price = transub_unit_id.sale_rate;
      this.rate_status_type = 'sale';

    }

    
    this.add_perc_edit =0;
    this.net_rate_edit =0;

  }

  appendItem(prd_data, prd_barcode, vantransub_unit_id,tranformadata) {

    // alert(prd_data.prd_barcode);
    // alert(vantransub_unit_id.unit_base_qty);

  

    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];
      });

    }

  
    if (this.gd_id == undefined) {

      $('#godown_id').css("border", "1px solid red");
    }
    else {
      $('#godown_id').css("border", "1px solid #dedede");
    }









    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {


      if(this.add_perc){
        let nr = parseFloat(this.vantran_purch_price) + (parseFloat(this.vantran_purch_price)*parseFloat(this.add_perc))/100;
        this.vantran_purch_price = nr;
      }

      this.box = this.vantransub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }


      this.sl_no = (this.selectedItems.length) + 1;

      this.vantransub_qty = Number(this.vantransub_qty);
      this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.selectedItems,
          prd_data.branch_stock_id,
          vantransub_unit_id.sur_unit_id,
          this.vantran_purch_price,
          prd_data.prd_barcode,
          vantransub_unit_id.unit_code, prd_data.prd_id);
      }

    
      if (existingArray && existingArray.length > 0) {


        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }
         

        
        else {
          this.selectedItems.push({
            "sl_no": this.sl_no, 
            "prd_barcode": prd_data.prd_barcode,
            "prd_id": prd_data.prd_id,
            "stocktrsub_prd_id": prd_data.prd_id,
            "prd_alias": prd_data.prd_alias,
            "prd_name": prd_data.prd_name,
            "vantransub_qty": this.vantransub_qty,
            "stocktrsub_qty": this.vantransub_qty,
            "box": this.box,
            "gd_id": this.gd_id,
            "purchase_rate": this.vantran_purch_price,
            "purchase_amount": this.vantran_purch_amount,
            "vantransub_prod_id": prd_data.prd_id,
            "vantransub_stock_id": prd_data.bs_stock_id,
            "vantransub_branch_stock_id": prd_data.branch_stock_id,
            "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
            "vantransub_purch_rate": this.avgPurch_rate,
            "unit_name": vantransub_unit_id.unit_name,
            "unit_base": vantransub_unit_id.unit_base_qty,
            "unit_code": prd_data.base_unit_code,
            "base_unit_name": prd_data.base_unit_name,
            "display_qty": this.display_qty,
            "item_unit_code": vantransub_unit_id.unit_code,
            "prd_stock_status": prd_data.prd_stock_status,
            "add_perc" : this.add_perc,
            "net_rate" :this.net_rate,
            "rate" : $('#vantran_purch_price').val(),
            "type_id":this.type_id,

          });

        }


      }
     






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.selectedItems.length;
      this.vantransub_qty = this.box;
      $('#gdtran-btn').show();

      
      this.net_rate = 0;
      this.add_perc = 0;

      this.vantran_purch_price = 0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();

    }
    this.fieldName2.nativeElement.focus();
    tranformadata.value.items = this.selectedItems;
    tranformadata.value.total_amount = this.total_amount;

    if (tranformadata.value.items && tranformadata.value.total_amount != null) {
      this.saveDraft(tranformadata, 'auto');
    } else {
      $('#autosave').click();
    }

  }


  saveDraft(formdata,savetype){
    
    formdata.value.items = this.selectedItems;
  

    this.apiService.saveTmpExternalStockTransfer(formdata.value).subscribe((res: any) => {
      this.draft_id = res;
  
      this.pageLoadingImg = false;
      this.getsaveTempExtStock();

      if (savetype == 'manual') {
        this.coreService.showMessage('Saved To Draft');
      }

    });
        
  }
  findTotalSum2() {
    this.stocktr_amount = 0;
    this.previewDataitems.forEach(element => {
      this.stocktr_amount += Number(element.extstktrsub_rate * element.extstktrsub_qty);
    });
  }

  clearForm() {
    this.ledger_id = 0;
    this.branch_id = 0;
    this.balance = 0;
    this.batch_name = '';
    this.prd_id = '';
    this.brcode = '';
    this.prd_units = [];
    this.type_id = 0;
    this.vantransub_qty = '';
    this.default_unit = [];
    this.selectedItems =[];

    this.vantran_purch_price = 0;



  }


  findTotalSum() {
    this.total_amount = 0;
    this.selectedItems.forEach(element => {

      this.total_amount += Number(element.purchase_rate * element.vantransub_qty);
    });
  }

  stockTransfer(formData) {

    var confrm = confirm("Are you sure?");
    if (confrm) {

      this.validateTransfer(formData.value);

      if (this.printError.length <= 0) {
        this.pageLoadingImg = true;

        formData.value.items = this.selectedItems;
        formData.value.vantran_price = this.total_amount;
        this.apiService.extStockTransfer(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;
          $('.close').click();
          this.coreService.showMessage(res.message);
          this.ngOnInit();

          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;
          this.prdn_date = new Date();
          this.getInfo(res.cat_id);
          $("#previewButton").click();
          this.gd_id = Number(this.userdefltGwn);

         



        });

        if (formData.value.draft_id) {
          this.removeExtStockTranFromDraft(formData.value.draft_id);
        }
        this.draft_id = "";
      }
      else {

      }
    }
  }

  removeExtStockTranFromDraft(id) {
    let searchval = new FormData();
    searchval.append("extstktr_num", id);
    this.apiService.removeFromExStockDraft(searchval).subscribe((res) => {
      this.apiService.getsaveTempExtStock('').subscribe((res) => {
        this.draftList = res['data'];
      });

    });
  }

  getTempExtStockTran(id){
    let searchval = new FormData();
    searchval.append("extstktr_num", id);

    this.apiService.getTempExStockDraft(searchval).subscribe((res: any) => {
      
    
      this.ledger_id =res.data.ledger_id;
      if(this.ledger_id > 0){
        this.getAccLedgerBalance( this.ledger_id);
      }
      
      this.selectedItems = res.data.items;
      this.draft_id = res.draft_id;
      this.gd_id = res.data.godown_id;
      this.total_amount = res.data.total_amount;

     

    });

    $('#addNewTran').show();
    $('#gdtran-btn').show();
  }



  updateTransfer(formData) {

    var confrm = confirm("Are you sure you want to update?");
    if (confrm) {

      this.validateTransfer2(formData.value);



      if (this.printError.length <= 0) {
        this.pageLoadingImg = true;

        formData.value.items = this.previewDataitems;
        formData.value.stocktr_amount = this.stocktr_amount;

        this.apiService.extStockTransferUpdate(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;
          this.ngOnInit();
       
          // this.getInfo(formData.stocktr_id);
          // this.previewData = this.previewDataitems  =[];

          $('.close').click();

          this.coreService.createfunct(formData, this.resultobj, res.message);
          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;
          $('#brtran-btn').hide();


        });
      }
      else {

      }
    }
  }

  resetItemsearch() {
    this.vantran_purch_price = 0;

    this.prd_barcode = '';
    this.default_unit = [];
    this.vantransub_qty = '';
  }
  barcodeSearch(brcode: string, branchto: string, type: string) {

    this.net_rate = 0;
    this.add_perc = 0;
    this.net_rate_edit = 0;
    this.add_perc_edit = 0;


    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    searchval.append("brcode", brcode);
    searchval.append("branch_received", branchto);
    searchval.append('price_group_id', this.price_group_id);
    this.apiService.searchVanProductsExtStkTrans(searchval).subscribe((res) => {
      // this.prd_all = res['data'];
      this.prd_id = res['data'][0];
      this.setValues(res['data'][0], type);

    });
  }

  searchProducts(search: string, brcode: string, branchto: string) {

    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    if (search) {
      if(this.commAll==1){
        searchval.append("alias",'1');
      }
      searchval.append("keyword", search);
      searchval.append("branch_received", branchto);
    }
    searchval.append('price_group_id', this.price_group_id);
    this.apiService.searchVanProductsExtStkTrans(searchval).subscribe((res) => {
      this.prd_all = res['data'];

    });
  }

  getNxtVanTranId() {
    let searchval = new FormData();
    this.apiService.getNxtVanTranId(searchval).subscribe((res) => {
      this.tran_id = res['data'].tran_id;
    });

  }
  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    searchval.append('price_group_id', this.price_group_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];



    });

  }

  setValues(prd_data, type) {


    this.net_rate = 0;
    this.add_perc = 0;
    this.net_rate_edit = 0;
    this.add_perc_edit = 0;

    if (prd_data) {
      // if(prd_data.prd_id){
        this.getStockDetails(prd_data.prd_id, this.gd_id);

        // const searchval = new FormData();
        // searchval.append('prd_id', prd_data.prd_id);
        // searchval.append('gd_id', this.gd_id);
        // searchval.append('price_group_id', this.price_group_id);
        // this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
        //   this.selctedProd = res['data'];
        //   this.selctedProd.prod_units.forEach(element => {
        //     console.log(element);
        //     if(element.unit_base_qty == 1){
        //       this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_srate = element.sur_unit_rate;
        //       console.log('prd_data.bs_srate '  + prd_data.bs_srate);
        //     }
        //     if(element.sur_unit_rate != 0){
        //       prd_data.bs_srate = prd_data.prd_units.find(x => x.sur_unit_id === element.produnit_unit_id).sale_rate = element.sur_unit_rate;
        //     }
        //   });
        // });

      }else{
        this.selctedProd=[];
        return false;
      }
    if (prd_data.prd_base_unit_id) {

      if (type > 0) {
        this.fieldName1.nativeElement.focus();
      }
      else {
        // console.log(type + "==here");
        this.fieldName3.nativeElement.focus();
      }
    }

    this.salestatus = 1;


    this.avgPurch_rate = prd_data.bs_avg_prate;
    this.base_rate = prd_data.bs_prate;



    if (this.type_id == 2) {


      if (this.userEncpType) {
        this.tran_purch_price = this.vantran_purch_price = this.sale_rate = this.coreService.splitToDigit(prd_data.bs_prate); // Encrypted Rate
      } else {
        this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_prate;

      }
      this.rate_status_type = 'purch';

    } else if (this.type_id == 1) {
      this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_srate;

      this.rate_status_type = 'sale';

    }

    else if (this.type_id == 3) {
      this.vantran_purch_price = '';
      this.rate_status_type = 'custom';
    }
    else if (this.type_id == 5) {
      this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_avg_prate;
      this.rate_status_type = 'avg_cost';
    }
    else {
      this.type_id = 1;
      this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_srate;

      this.rate_status_type = 'sale';
    }


    if (prd_data.last_rate > 0) {

      this.type_id = 4;
      this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.last_rate;

      this.rate_status_type = 'last_rate';

    }



    this.prd_barcode = prd_data.prd_barcode;
    this.prd_units = prd_data.prd_units;
    // this.transub_qty = this.vantransub_qty = '1';

    

    if (!prd_data.eanunit) {
      this.default_unit = {
        "unit_code": prd_data.base_unit_code, "unit_name": prd_data.base_unit_name,
        "sur_unit_id": prd_data.prd_base_unit_id, "unit_base_qty": 1,
        "sale_rate": prd_data.bs_srate, "purch_rate": prd_data.bs_prate,
        "last_rate": prd_data.last_rate,
        "avg_cost": prd_data.bs_avg_prate,

      };

      
    }
    else {
      this.default_unit = prd_data.eanunit;
      this.convertRate(this.default_unit);

      
    }

   

    // this.type_id = 1;

    if (this.gd_id > 0) {

    }
    else {
      this.gd_id = 0;
    }


  }




  voidTransfer(tran_id) {
    let formData = new FormData();
    var confrm = confirm("Are you sure you want to void transfer? ");
    formData.append("tran_id", tran_id);
    if (confrm) {

      this.voidInprogress[tran_id] = true;
      this.apiService.voidExtStockTransfer(formData).subscribe((res) => {
        this.ngOnInit();
        this.pageLoadingImg = false;
        this.coreService.createfunct(formData, this.resultobj, res.message);
        this.selectedItems = [];
        this.total_qty = 0;
        this.total_amount = 0;




      });

    }

    else {

    }
  }













  accpgEnd(curr, end) {
    if (curr == end)
      this.accpgend = true;
    else
      this.accpgend = false;

  }

  accpgStart(curr) {
    if (curr == 1)
      this.accpgstart = true;
    else
      this.accpgstart = false;

  }


  appendItem2(prd_data, prd_barcode, transub_unit_id) {


    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }
    if (this.gd_id_edit == undefined) {

      $('#godown_id_edit').css("border", "1px solid red");
    }
    else {
      $('#godown_id_edit').css("border", "1px solid #dedede");
    }

    if (!transub_unit_id) {
      this.printError.push({ 'transub_unit_idErr': 'Required' });
      $('#transub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#transub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm2();

    if (this.printError.length <= 0) {

      if(this.add_perc_edit){
        let nr = parseFloat(this.tran_purch_price) + (parseFloat(this.tran_purch_price)*parseFloat(this.add_perc_edit))/100;
        this.tran_purch_price = nr;
      }

      this.box = this.transub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (transub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = transub_unit_id.unit_code;

        this.transub_qty = this.box * (transub_unit_id.unit_base_qty);
        this.tran_purch_price = this.tran_purch_price / (transub_unit_id.unit_base_qty);

      }


      this.sl_no = (this.previewDataitems.length) + 1;

      this.transub_qty = Number(this.transub_qty);
      this.tran_purch_amount = this.tran_purch_price * this.transub_qty;
      var existingArray: any;
      if (this.previewDataitems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.previewDataitems,
          prd_data.branch_stock_id,
          transub_unit_id.sur_unit_id,
          this.tran_purch_price,
          prd_data.prd_barcode,
          transub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.tran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }
        else {
          this.previewDataitems.push({
            "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
            "prd_id": prd_data.prd_id,
            "prd_name": prd_data.prd_name,
            "extstktrsub_qty": this.transub_qty,
            "box": this.box,
            "extstktrsub_gd_id": this.gd_id_edit,
            "extstktrsub_rate": this.tran_purch_price,
            "purchase_amount": this.tran_purch_amount,
            "vantransub_prod_id": prd_data.prd_id,
            "vantransub_stock_id": prd_data.bs_stock_id,
            "vantransub_branch_stock_id": prd_data.branch_stock_id,
            "vantransub_unit_id": transub_unit_id.sur_unit_id,
            "vantransub_purch_rate": prd_data.bs_avg_prate,
            "unit_name": transub_unit_id.unit_name,
            "unit_base": transub_unit_id.unit_base_qty,
            "unit_code": prd_data.base_unit_code,
            "base_unit_name": prd_data.base_unit_name,
            "display_qty": this.display_qty,
            "item_unit_code": transub_unit_id.unit_code,

            "add_perc" : this.add_perc_edit,
            "net_rate" :this.net_rate_edit,
            "rate" : $('#tran_purch_price').val(),
            "type_id":this.type_id,

        

          });
       
          this.findTotalSum2();
        }

        this.fieldName4.nativeElement.focus();
      }






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.previewDataitems.length;
      this.transub_qty = this.box;
      $('#brtran-btn').show();

      this.net_rate_edit = 0;
      this.add_perc_edit = 0;

      this.tran_purch_price = 0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.transub_qty = 0;


    }
  }

  checkEnterClicked(e) {
    if (e.keyCode === 13) {
      $("#plusButton").click();
    }
  }

  checkEnterClicked2(e) {
    if (e.keyCode === 13) {
      $("#plusButton2").click();
    }
  }


  selectAllContent(e) {
    e.target.select();
  }

  langChange(){
    this.translate.get(['Table.mrp','HOME.purchase_rate','HOME.CUSTOM','Common.last_transfer_rate','Common.avg_cost']).subscribe((res: string) => {    
      
      this.rate_all = [
        { type_id: 1, name:  res['Table.mrp'] },
        { type_id: 2, name:  res['HOME.purchase_rate'] },
        { type_id: 3, name:  res['HOME.CUSTOM'] },
        { type_id: 4, name:  res['Common.last_transfer_rate'] },
        { type_id: 5, name:  res['Common.avg_cost'] },
      ];

    });

    
  }

  update_rate(e){
    if(this.add_perc){
      let nr = parseFloat(this.vantran_purch_price) + (parseFloat(this.vantran_purch_price)*parseFloat(this.add_perc))/100;
      this.net_rate = nr;
    }else{
      this.net_rate = this.vantran_purch_price;
    }
  }
  update_rate_edit(e){
    if(this.add_perc_edit){
      let nr = parseFloat(this.tran_purch_price) + (parseFloat(this.tran_purch_price)*parseFloat(this.add_perc_edit))/100;
      this.net_rate_edit = nr;
    }else{
      this.net_rate_edit = this.tran_purch_price;
    }
  }

  getsaveTempExtStock()
  {
    this.apiService.getsaveTempExtStock('').subscribe((res) => {
      this.draftList = res['data'];
     
      // this.purch_amt = Number(res.purch_amount);
      // this.prch_tax = Number(res.purch_tax);

    });
  }

  closeModal(){
  
    $('#addNewTran').hide();

  }

  

}
