import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rep-purchase-detail-summary',
  templateUrl: './rep-purchase-detail-summary.component.html',
  styleUrls: ['./rep-purchase-detail-summary.component.css']
})
export class RepPurchaseDetailSummaryComponent implements OnInit {
  fileName= 'purchase_details.xlsx';

  pageLoadingImg: boolean;
  up: any;
  userType: any;
  purchDetSum: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  total_amount: any;
  total_discount_amount: any;
  pgend: boolean;
  pgstart: boolean;
  filter_branch_id: number;
  branch_all: any;
  users: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  sortType = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
// paytype = ['Cash','Cash','Bank'];
paytype={
  '1': 'Credit',
  '2': 'Cash',
  '3': 'Bank'
};
  filter_paytype = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank' }

  ];
  supplierAll: any;
  total_res: any;
  period_type: string;
  repDate: any;
  exportLoader: boolean;
  ExppurchDetSum: any;
  Exptotal_res: any;
  ExprepDate: any;
  godowns: any;
  vans: any;
  country_dec: string;
  cur_lang: string;

  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  suppName: any;
  cmp_tax: any;
  rate: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    
this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
  this.langChange();
});
this.langChange();
if(this.cmp_tax==2){
  this.rate=this.translate.instant('GST')
}else{
  this.rate= this.translate.instant('Purchase.vat')
}


    this.country_dec=this.coreService.setDecimalLength();
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    // this.filter_branch_id = 1;
    this.period_type = 't';
    let searchval = new FormData();
    searchval.append("period_type", 't');

    this.apiService.purchaseDetailSummary(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.purchDetSum = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;

      this.total_res =  res.data.totalRes;
      this.repDate = res.data.Date;

      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  this.searchUser('');
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
  
  


  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash','HOME.Bank']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.filter_paytype = [
        { id: 1, name: res['HOME.credit'] },
        { id: 2, name: res['Common.cash'] },
        { id: 3, name: res['HOME.Bank'] }
    
      ];
    });

  }

  ngAfterContentInit(){
    
    this.searchVans('');
    this.getGodownList();

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    this.vans.push({ "van_id": 'All', 'van_name': 'All' });
    });

  }

  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
    this.purchDetSum = [];
    this.apiService.purchaseDetailSummary(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.purchDetSum = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_res =  res.data.totalRes;
      this.repDate = res.data.Date;

 

      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }

  searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.users = res['data'];                  
        });
 }

 

searchSupplier(search: string) {
  const searchval = new FormData();
  searchval.append('supp_name', search);
   if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append("with-alias", "1");
  }
  this.apiService.searchSupplier(searchval).subscribe((res) => {
    this.supplierAll = res.data;
  });
}

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(expformdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      expformdata.value.export = 'export';
      

    this.apiService.purchaseDetailSummary(expformdata.value, 1).subscribe((res: any) => {

      this.ExppurchDetSum = res.data.data;
      
      this.Exptotal_res =  res.data.totalRes;
      this.ExprepDate = res.data.Date;

 
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);


    });



    }

    // pdf download
    generatePdf(expformdata: { value: any; }) {

      this.exportLoader = true;
     
      // debugger;
      expformdata.value.export = 'export';
      

    this.apiService.purchaseDetailSummary(expformdata.value, 1).subscribe((res: any) => {

      this.ExppurchDetSum = res.data.data;
      
      this.Exptotal_res =  res.data.totalRes;
      this.ExprepDate = res.data.Date;

 
      const heads = [
        this.translate.instant('Purchase.purch') + '\n' + this.translate.instant('Purchase.ref_no'),
        this.translate.instant('Purchase.sup') + '\n' + this.translate.instant('HOME.Invoice_no'),
        this.translate.instant('Common.Pay_Type'),
        this.translate.instant('Common.date'),
        this.translate.instant('Common.itm_code'),
        this.translate.instant('Table.description'),
        this.translate.instant('Table.qty'),
        this.translate.instant('Purchase.rate'),
        this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Van.price'),
        this.rate,
        this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.price') + '+' + this.rate,
        this.translate.instant('Purchase.Freight_Amount'),
        this.translate.instant('Common.Total_Amount')
      ];

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];

        this.ExppurchDetSum.forEach(item=>{
          if (this.translate.currentLang === "Arabic") {
            this.suppName = item.supp_alias || '';
        } else {
            this.suppName = item.supp_name || '';
        }
        
          data.push([
            item.purch_id,
            item.purch_inv_no,
            this.paytype[item.purch_pay_type],
            this.datePipe.transform(item.purch_date, 'dd/MM/yyyy'),
            '',
            this.suppName,
            '',
            '',
            '',
            '',
            '',
            '',
            ''
          ])

          item.items.forEach(item1=>{
            data.push([
              '',
              '',
              '',
              item1.ean,
              item1.itemcode,
              item1.prd_name,
              this.currencyPipe.transform(item1.purchsub_qty, '', '', '1.1-1') + '(' + this.currencyPipe.transform(item1.purchsub_qty, '', '', '1.1-1') + ' ' + item1.unit_code + ')',
              this.currencyPipe.transform(item1.purchsub_rate, '', '', this.country_dec),
              this.currencyPipe.transform(item1.purch_price, '', '', this.country_dec),
              this.currencyPipe.transform(item1.purchsub_tax, '', '', this.country_dec),
              this.currencyPipe.transform(item1.purch_total, '', '', this.country_dec),
              this.currencyPipe.transform(item1.purchsub_frieght * item1.purchsub_qty, '', '', this.country_dec),
              this.currencyPipe.transform(item1.purch_total_amount, '', '', this.country_dec),
            ])
          })

          data.push([
            '',
            '',
            '',
            '',
            '',
            this.translate.instant('HOME.total'),
            '',
            this.currencyPipe.transform(item.totrate, '', '', this.country_dec),
            this.currencyPipe.transform(item.totgross, '', '', this.country_dec),
            this.currencyPipe.transform(item.tot_taxrate, '', '', this.country_dec),
            this.currencyPipe.transform(item.totprice, '', '', this.country_dec),
            this.currencyPipe.transform(item.totfrieght, '', '', this.country_dec),
            this.currencyPipe.transform(item.totamt, '', '', this.country_dec)
          ])

          data.push([
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            this.translate.instant('Common.Disc'),
            this.currencyPipe.transform(item.purch_discount, '', '', this.country_dec),
            '',
            this.translate.instant('Table.Net_Amt'),
            this.currencyPipe.transform(item.purch_amount, '', '', this.country_dec)
          ])

        })
    
        let doc = this.excelService.getPdfObj();

        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');

        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('HOME.purch_detl_rep')}`,  valueXPos, headerHeight + 15);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.total_inv_amnt')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.inv_amt, '', '', this.country_dec),  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.total_freight')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.frieght_amt, '', '', this.country_dec),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.purchamt, '', '', this.country_dec),  valueXPos, headerHeight + 30);

        if(this.cmp_tax==2){
          doc.setFontSize(10);
        doc.text(`${this.translate.instant('Total GST Amount')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.vat, '', '', this.country_dec),  valueXPos, headerHeight + 35);


        }else{
          doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Total_VAT_Amount')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.vat, '', '', this.country_dec),  valueXPos, headerHeight + 35);

        }
        
        if(this.cmp_tax==2){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Total Amount Excl.GST')}`, lblXPos, headerHeight + 40);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.total_res.excl_vat_tot, '', '', this.country_dec),  valueXPos, headerHeight + 40);

        }else{
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Amount_Excl_VAT')}`, lblXPos, headerHeight + 40);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.total_res.excl_vat_tot, '', '', this.country_dec),  valueXPos, headerHeight + 40);
        }
      

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.total_discount_amount')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.disc, '', '', this.country_dec),  valueXPos, headerHeight + 45);

        doc = this.excelService.addTableToPdf(doc, headerHeight + 50, lblXPos, heads, data, null);

        doc.save(`Purchase Detail Report.pdf`);

        this.exportLoader = false;

    });
    }


}
